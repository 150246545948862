import React, { useState, useMemo, useEffect } from 'react';
import { createSelector } from "reselect";
import { connect } from "react-redux";
import componentDataProvider from "../../../hocs/componentDataProvider";
import PropTypes from 'prop-types';
import { SetMultipleFiltersValue } from "../../../actions/sync-actions/filters";
import { setQueryParams } from "../../../constants/queryParams";
import { useLocation } from 'react-router-dom';
import {
    addYears,
    endOfDay,
    startOfDay,
    subYears,
    setDate,
    getDate,
    subDays
} from "date-fns";
import Helpers from "../../../helpers/helperFunctions";
import { EVENT_TYPES, OPERATOR_TABS_TYPES } from "../../../constants/uiConstants";
import { REPORTING_PAGE } from "../../../constants/pageRoutes";
import { nextDateShortcuts, prevDateShortcuts } from '../../../constants/dateRanges';
import DatePickerInput from "../datePickerInput";
import { EVENTS } from "../../../constants/reducerKeys";

const DateRangeFilter = (props) => {
    const {
        reducerKey,
        reducerSubKey,
        startFilterName,
        endFilterName,
        dispatch,
        start,
        end,
        onChange,
        filterData,
        appearance,
        labelAppearance,
        ...restProps
    } = props;
    const { pathname } = useLocation();
    const defaultParams = setQueryParams(null, reducerKey, reducerSubKey);
    const [isClosed, setIsClosed] = useState(true);

    const checkMaxAndMinDate = (ts) => {
        const timeZoneDay = Helpers.getTimeZoneTime();
        const dayFromFilter = new Date(ts);
        return pathname === REPORTING_PAGE || reducerSubKey === EVENT_TYPES.HISTORY || ['reports', 'balanceHistory', 'financialReport', 'account', 'operators'].includes(reducerKey)
            ? timeZoneDay < dayFromFilter ? setDate(new Date(ts), getDate(timeZoneDay)).getTime() : ts
            : startOfDay(timeZoneDay) > dayFromFilter ? (reducerKey === EVENTS || reducerKey === OPERATOR_TABS_TYPES.BOOKED_MATCHES) ? dayFromFilter.getTime() : setDate(new Date(ts), getDate(timeZoneDay)).getTime() : ts;
    };

    const initialStartDate = (start && new Date(start))
        || (filterData[startFilterName] && checkMaxAndMinDate(filterData[startFilterName] * 1000))
        || new Date(defaultParams.start * 1000);

    const initialEndDate = (end && new Date(end))
        || (filterData[endFilterName] && checkMaxAndMinDate(filterData[endFilterName] * 1000))
        || new Date(defaultParams.end * 1000);

    const [startDate, setStartDate] = useState(Helpers.convertDateByTimeZone(initialStartDate * 1000));
    const [endDate, setEndDate] = useState(Helpers.convertDateByTimeZone(initialEndDate * 1000));

    useEffect(() => {
        if (!filterData[startFilterName] && !filterData[endFilterName]) {
            setStartDate((start && new Date(start)) || (filterData[startFilterName] && checkMaxAndMinDate(filterData[startFilterName] * 1000)) || new Date(defaultParams.start * 1000));
            setEndDate((end && new Date(end)) || (filterData[endFilterName] && checkMaxAndMinDate(filterData[endFilterName] * 1000)) || new Date(defaultParams.end * 1000));
        }
    }, [filterData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isClosed && filterData[startFilterName] && filterData[endFilterName]) {
            new Date(filterData[startFilterName] * 1000) !== (new Date(startDate)) && setStartDate((filterData[startFilterName] && checkMaxAndMinDate(filterData[startFilterName] * 1000)));
            new Date(filterData[endFilterName] * 1000) !== (new Date(endDate)) && setEndDate(filterData[endFilterName] && checkMaxAndMinDate(filterData[endFilterName] * 1000));
        }
    }, [filterData, startDate, endDate, startFilterName, endFilterName, isClosed]); // eslint-disable-line react-hooks/exhaustive-deps

    const tzDate = Helpers.getTimeZoneTime();

    const staticRanges = useMemo(() => {
        return [
            ...(pathname !== REPORTING_PAGE && reducerKey !== "reports" && reducerSubKey !== EVENT_TYPES.HISTORY && reducerKey !== "balanceHistory" && reducerKey !== "financialReport" && reducerKey !== "account" ? nextDateShortcuts(tzDate) : prevDateShortcuts(tzDate))
        ];
    }, [pathname, reducerSubKey, reducerKey, tzDate]);

    const getMinAndMaxDates = (type, key) => {
        return (type === EVENT_TYPES.HISTORY || key === "reports" || key === "balanceHistory" || key === "financialReport" || key === "account" || key === 'operators')
            ? {
                minDate: subYears(Helpers.getTimeZoneTime(), 30),
                maxDate: endOfDay(Helpers.getTimeZoneTime())
            } : {
                minDate: startOfDay((reducerKey === EVENTS || reducerKey === OPERATOR_TABS_TYPES.BOOKED_MATCHES) ? subDays(Helpers.getTimeZoneTime(), 1) : Helpers.getTimeZoneTime()),
                maxDate: addYears(Helpers.getTimeZoneTime(), 30)
            };
    };

    const handleDateChange = (range) => {
        const { startDate, endDate } = range;

        setStartDate(checkMaxAndMinDate(startOfDay(startDate.getTime())));
        setEndDate(checkMaxAndMinDate(endOfDay(endDate.getTime())));
    };

    const handleDataUpdate = () => {
        setIsClosed(true);
        const start = Math.floor(new Date(startDate).getTime() / 1000);
        const end = Math.floor(new Date(endDate).getTime() / 1000);
        if (onChange) {
            onChange({ start, end });
        } else {
            dispatch(SetMultipleFiltersValue(
                reducerSubKey || reducerKey,
                { [startFilterName]: start, [endFilterName]: end },
                reducerSubKey && reducerKey, true),
            );
        }
    };
    return (
        <DatePickerInput
            dateRange={{ startDate, endDate }}
            staticRanges={staticRanges}
            onChange={handleDateChange}
            onOpen={() => setIsClosed(false)}
            onClose={handleDataUpdate}
            appearance={appearance}
            labelAppearance={labelAppearance}
            {...getMinAndMaxDates(reducerSubKey, reducerKey)}
            {...restProps}
        />
    );
};

DateRangeFilter.defaultProps = {
    daysUpToToday: true,
    daysStartingToday: true,
};

DateRangeFilter.propTypes = {
    appearance: PropTypes.oneOf(['default', 'inline']),
    reducerSubKey: PropTypes.string,
    reducerKey: PropTypes.string,
    startFilterName: PropTypes.string,
    endFilterName: PropTypes.string,
    filterData: PropTypes.object,
    layoutType: PropTypes.string,
    resetOffset: PropTypes.bool,
    isEnabledLiveTime: PropTypes.bool,
    userProfile: PropTypes.object,
    pathname: PropTypes.string,
    daysUpToToday: PropTypes.bool,
    daysStartingToday: PropTypes.bool,
};

DateRangeFilter.requiredProps = {
    filterData: PropTypes.object,
    userProfile: PropTypes.object
};

const mapStateToProps = (_, ownParams) => {
    const { reducerKey, reducerSubKey, startFilterName, endFilterName } = ownParams;
    return createSelector(
        [
            state => state.filters[reducerKey] && state.filters[reducerKey][startFilterName],
            state => state.filters[reducerKey] && state.filters[reducerKey][endFilterName],
            state => state.filters[reducerKey] && state.filters[reducerKey].subKeys && state.filters[reducerKey].subKeys[reducerSubKey] && state.filters[reducerKey].subKeys[reducerSubKey][startFilterName],
            state => state.filters[reducerKey] && state.filters[reducerKey].subKeys && state.filters[reducerKey].subKeys[reducerSubKey] && state.filters[reducerKey].subKeys[reducerSubKey][endFilterName],
        ],
        (parentStartDate, parentEndDate, subStartDate, subEndDate) => {
            return {
                filterData: {
                    [startFilterName]: subStartDate || parentStartDate,
                    [endFilterName]: subEndDate || parentEndDate
                },
            };

        }
    );
};

export default connect(mapStateToProps)(componentDataProvider(DateRangeFilter));
