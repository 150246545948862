// eslint-disable-next-line import/no-webpack-loader-syntax
import WorkerLoader from 'worker-loader!./utils/worker.js';
import workerHelperFunctions from './utils/helpers';

class WebWorker {
    constructor (options = {}) {
        this.count = 0;
        this.workers = {};
        this.options = {
            closeTimeOut: 180000,
            emptyWorkerName: "WebWorker",
            ...options
        };
    }

    open (key = "", functionName = "", params = [], callback = () => 0, options = {}) {
        if (!window.Worker) {
            console.error('Web Worker not supported');
            return callback(workerHelperFunctions?.[functionName](...params));
        }

        options = { ...this.options, ...options };
        key = key || options.emptyWorkerName;
        const uniqueCallBackKey = `${key}_${this.count += 1}`;

        this.workers?.[key]?.timeOutKey && clearTimeout(this.workers?.[key]?.timeOutKey);

        if (!this.workers[key]) (this.workers[key] = {});

        if (!this.workers[key].worker) {
            this.workers[key].worker = new WorkerLoader();
            console.log(`\x1B[36m WEB WORKER |\x1B[32m OPENED =>\x1B[35m ${key}`);
        }

        this.workers[key].callBacks = {
            ...this.workers[key].callBacks || {},
            [uniqueCallBackKey]: callback
        };

        this.workers[key].worker.onmessage = (e = {}) => this.sendResponse(key, e, options);

        this.workers[key].worker.postMessage([ functionName, params, uniqueCallBackKey ]);
    }

    close (key = "") {
        key = key || this.options.emptyWorkerName;
        const removeWorker = (workerKey = key) => {
            if (!this.workers?.[workerKey]) return;
            // eslint-disable-next-line no-unused-expressions
            this.workers[workerKey]?.worker?.terminate();
            delete this.workers?.[workerKey];
            console.log(`\x1B[36m WEB WORKER |\x1B[31m CLOSED =>\x1B[35m ${key}`);
        };
        if (key === '*') {
            for (let workerKey of Object.keys(this.workers || {})) {
                removeWorker(workerKey);
            }
        } else {
            removeWorker(key);
        }

    }

    sendResponse = (key = "", e = {}, options = {}) => {
        const { result, uniqueCallBackKey } = e?.data;
        const cb = this.workers[key]?.callBacks?.[uniqueCallBackKey];
        // eslint-disable-next-line no-unused-expressions
        cb?.(result);

        delete this.workers[key]?.callBacks[uniqueCallBackKey];
        this.workers[key].timeOutKey && clearTimeout(this.workers[key].timeOutKey);
        this.workers[key].timeOutKey = setTimeout(() => this.close(key), options.closeTimeOut);
    };
}

export default new WebWorker({});
