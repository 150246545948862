import React, {useState} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from "../icon";

import { inputConfig } from "../../../configs/uiConfig";

import './input.scss';

const Input = React.forwardRef((props, ref) => {
    const {
        appearance,
        className,
        cornerRadius,
        description,
        disabled,
        errorText,
        icon,
        itemsDirection,
        label,
        labelAppearance,
        onChange,
        placeholder,
        readOnly,
        required,
        autoFocus,
        size,
        type,
        value,
        writeProtected,
        ...restProps
    } = props;

    const [localValue, setLocalValue] = useState('');

    const isControlled = props.hasOwnProperty('value');
    const inputVal = isControlled ? value : localValue;

    const handleChange = (e) => {
        !isControlled && setLocalValue(e.target.value);
        onChange && onChange(e);
    };

    const asterisk = required ? ' *' : '';
    const inputLabel = (
        <>
            {label || placeholder}
            {required && <span className="asterisk">{asterisk}</span>}
        </>
    );

    const sharedProps = {
        required,
        type,
        readOnly,
        autoFocus,
        onChange: handleChange,
        className: classnames('input-element', {
            'read-only': readOnly,
        }),
        placeholder: `${placeholder}${asterisk}`,
        value: inputVal,
        ...restProps,
    };

    const inputIcon = type === 'search' ? 'icon-search' : icon;

    return (
        <div
            ref={ref}
            className={classnames(
                'input-holder',
                'not-animation-fadeInUp',
                `a-${appearance}`,
                `s-${size}`,
                `id-${itemsDirection}`,
                `cr-${cornerRadius}`,
                className,
                {
                    'disabled': disabled,
                    'read-only': readOnly,
                    'write-protected': writeProtected,
                    'error-color': !!errorText,
                }
            )}>
            {appearance === 'inline' ? (
                <div className="inline-input-holder" {...restProps}>
                    <div className="ellipsis-text">{inputVal}</div>
                    <Icon type="icon-arrow-down" size={18} />
                </div>
            ) : (
                <React.Fragment>
                    {labelAppearance === 'title' && (
                        <div className="input-title ellipsis-text">{inputLabel}</div>
                    )}
                    <div className="input-structure">
                        {(icon || type === 'search') && <Icon type={inputIcon} />}
                        <label className="not-animation-fadeInUp">
                            {type !== 'textarea' ? (
                                <input {...sharedProps} autoComplete="off" />
                            ) : (
                                <textarea {...sharedProps} />
                            )}
                            <div className="input-element-back">
                                {labelAppearance === 'swap' && (
                                    <div className="input-swap-label">
                                        <div className="input-swap">
                                            <div className="input-swap-animate ellipsis-text">{inputLabel}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </label>
                    </div>
                    {errorText && (
                        <div className="information-message color-danger">{errorText}</div>
                    )}
                    {description && (
                        <div className="input-description">
                            {description}
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    )
});

Input.defaultProps = {
    appearance: inputConfig.appearance[0],
    cornerRadius: inputConfig.cornerRadius[0],
    disabled: false,
    itemsDirection: inputConfig.itemsDirection[0],
    labelAppearance: inputConfig.labelAppearance[0],
    readOnly: false,
    required: false,
    writeProtected: false,
    size: inputConfig.size[1],
    type: inputConfig.type[0],
};

Input.propTypes = {
    appearance: PropTypes.oneOf(inputConfig.appearance),
    className: PropTypes.string,
    cornerRadius: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    icon: Icon.propTypes.type,
    itemsDirection: PropTypes.oneOf(inputConfig.itemsDirection),
    label: PropTypes.string,
    labelAppearance: PropTypes.oneOf(inputConfig.labelAppearance),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    writeProtected: PropTypes.bool,
    size: PropTypes.oneOf(inputConfig.size),
    type: PropTypes.oneOf(inputConfig.type),
    value: PropTypes.any,
};

export default Input;
