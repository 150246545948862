import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { popupConfig } from '../../../configs/popupConfig';
import { CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR } from '../../../constants/userRoles';
import Helpers from '../../../helpers/helperFunctions';

import federationsDataService from '../../../services/federationsDataService';
import usersDataService from '../../../services/usersDataService';

import { closeRightPanel, openPopup, registerToastMessage } from '../../../actions/sync-actions/uiActions';
import OverlayContent from '../../settings/rightPanel/overlayContent';
import { t } from '../../../helpers/translate';
import { TOAST_TYPES } from '../../../constants/uiConstants';

import DropDownItemWithDescription from '../../shared/dropDown/pieces/dropDownItemWithDescription';
import DynamicSelect from '../../shared/dynamicSelect';
import DropDown from '../../shared/dropDown';
import Button from '../../shared/button';
import Title from '../../shared/title';
import Input from '../../shared/input';
import Tabs from '../../shared/tabs';
import Tab from '../../shared/tab';

import './index.scss';

const validate = (values) => {
    return Object.keys(values)
        .reduce((acc, curr) => {
            switch (curr) {
                case 'name':
                    !values[curr].length && (acc[curr] = t('Group name is required'));
                    return acc;
                case 'users': {
                    if (values[curr]?.map(item => !!item.user_name?.length || !!item.email?.length).includes(false)) {
                        acc[curr] = t('Required');
                    }
                    if (values[curr]?.map(item => !!item.role_id).includes(false)) {
                        acc[curr] = t('Required');
                    }
                    return acc;
                }
                case 'organization_ids': {
                    !values[curr].length && (acc[curr] = t('Required'));
                    return acc;
                }
                default:
                    return acc;
            }
        }, {});
};

const roleList = [
    {
        id: 17,
        name: 'Federation Group Coordinator',
    },
    {
        id: 16,
        name: 'Federation Group Manager',
    }
];

const FederationsGroupForm = (props) => {
    const { dispatch, federationsGroup, callback } = props;
    const { users, name, id, organizations } = federationsGroup || {};
    const [validateError, setValidateError] = useState();
    const [federationsList, setFederationsList] = useState();
    const [initialValue] = useState(
        {
            name: name ? name : '',
            organization_ids: organizations ? organizations.map(item => item.id) : [],
            users,
        });
    const isGroupManager = Helpers.checkRole(CP_GROUP_MANAGER);

    useEffect(() => {
        federationsDataService.getFederationsByParams({ active: 1, limit: 100, offset: 0 })
            .then(res => {
                setFederationsList(res.data);
            })
            .catch(err => err);
    }, [initialValue]);

    const handleUpdateFederationGroup = (values) => {
        const requestParams = values.users.map(user => ({ user_id: user.user_id, role_id: user.role_id }));
        federationsDataService.updateFederationsGroupByIds(id, { ...values, users: requestParams })
            .then((res) => {
                callback && callback(res[0]);
                dispatch(registerToastMessage({
                    message: 'Successfully saved',
                }));
                dispatch(closeRightPanel());
            })
            .catch((err) => {
                dispatch(registerToastMessage({
                    type: TOAST_TYPES.ERROR,
                    message: "Oops something went wrong"
                }));
                setValidateError(err?.body?.data);
            });
    };

    const handleCreateFederationsGroup = (values) => {
        federationsDataService.createFederationsGroup(values)
            .then((res) => {
                callback && callback(res[0]);
                dispatch(registerToastMessage({
                    message: 'Successfully created'
                }));
                dispatch(closeRightPanel());
            })
            .catch(err => {
                dispatch(registerToastMessage({
                    type: TOAST_TYPES.ERROR,
                    message: "Oops something went wrong"
                }));
                setValidateError(err?.body?.data);
            });
    };

    return (
        <Formik
            onSubmit={() => {
            }}
            initialValues={initialValue}
            enableReinitialize
            validate={validate}
            key={JSON.stringify(initialValue)}
            validateOnChange
            isInitialValid={false}
            render={({ values, setFieldValue, isValid, errors }) => (
                <OverlayContent
                    title={t('Federations Group Setup')}
                    subTitle=""
                    appearance="fit"
                    secondaryButton={{
                        name: t('Cancel'),
                        onClick: () => dispatch(closeRightPanel()),
                    }}
                    primaryButton={{
                        name: name ? t('Save') : t('Create'),
                        color: 'confirm',
                        disabled: !isValid || !!Object.keys(errors).length,
                        onClick: () => id ? handleUpdateFederationGroup(values) : handleCreateFederationsGroup(values)
                    }}
                >
                    <Tabs appearance="inline">
                        <Tab title={t('Group')}>
                            <div className="form-grid col-2">
                                <Field name="name">
                                    {({ field: { name } }) => (
                                        <Input
                                            value={values[name]}
                                            type="text"
                                            errorText={validateError?.name || errors.name}
                                            labelAppearance="title"
                                            label={t('Group Name')}
                                            placeholder={t('Write group name')}
                                            onChange={(e) => {
                                                setValidateError({ ...validateError, name: '' });
                                                setFieldValue(name, e.target.value);
                                            }}
                                        />
                                    )}
                                </Field>
                                <Field name="organization_ids">
                                    {({ field: { name } }) => (
                                        <DropDown
                                            keys={{ key: 'id', value: 'name' }}
                                            label={t('Federations')}
                                            placeholder={t('Select Federations')}
                                            labelAppearance="title"
                                            errorText={validateError?.organization_ids}
                                            service={federationsDataService.getFederationsByParams}
                                            requestParams={{ active: 1 }}
                                            initialSelections={federationsList?.reduce((acc, curr) => {
                                                if (values[name].includes(curr.id)) {
                                                    return { ...acc, [curr.id]: curr };
                                                }
                                                return acc;
                                            }, {})}
                                            onChange={(federations) => {
                                                setValidateError({ ...validateError, organization_ids: '' });
                                                setFieldValue(name, federations.map(item => item.id));
                                            }}
                                            multiSelect
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="form-grid col-4" style={{ paddingTop: '15px' }}>
                                <Title
                                    label={t('Users')}
                                    className="span-3"
                                />
                                <Button icon="icon-plus"
                                        size="medium"
                                        color="primary"
                                        disabled={!(values.users || []).every(el => !!el.role_id && (!!el.user_name || !!el.email))}
                                        onClick={() => {
                                            setFieldValue('users', [...values.users || [], {
                                                user_name: '',
                                                role_name: '',
                                                user_id: Date.now(),
                                                role_id: '',
                                                email: '',
                                            }]);
                                        }}>{'Assign User'}</Button>
                            </div>
                            <div>
                                <Field
                                    name="users"
                                    render={({ field: { name } }) => (
                                        <DynamicSelect
                                            data={!isGroupManager ? values[name] : values[name]?.filter(user => user.role_id === FEDERATION_GROUP_COORDINATOR || user.role_id === '')}
                                            className="span-2"
                                            onDelete={(user_id) => {
                                                users?.find(el => el.user_id === user_id)?.role_id ? dispatch(
                                                    openPopup('message', {
                                                        ...popupConfig.federationsUserDelete,
                                                        handleCallBack: () => {
                                                            setFieldValue(name, values[name].filter(el => el.user_id !== user_id));
                                                        }
                                                    })) : setFieldValue(name, values[name].filter(el => el.user_id !== user_id));
                                            }}
                                            interactive
                                            allowDelete
                                            allowAll
                                            fieldArrayError
                                            primaryDropdown={{
                                                label: t('User'),
                                                labelAppearance: 'title',
                                                placeholder: 'Select User',
                                                key: 'user_id',
                                                errorText: validateError?.users,
                                                service: usersDataService.getUsersByParams,
                                                onChange: (user, value) => {
                                                    if (!values[name].find(value => value.user_id === user.id)) {
                                                        setFieldValue(name, values[name].map(el => el.user_id === value.user_id ? {
                                                            ...value,
                                                            email: user.email,
                                                            user_name: (user.first_name || user.last_name),
                                                            user_id: user.id,
                                                        } : el));
                                                    } else {
                                                        setFieldValue(name, values[name].map(el => el.user_id === value.user_id ? ({
                                                            ...value,
                                                            email: null,
                                                            user_name: null,
                                                            user_id: null
                                                        }) : el));
                                                        dispatch(registerToastMessage({
                                                            type: TOAST_TYPES.ERROR,
                                                            message: 'This user already attached'
                                                        }));
                                                    }
                                                },
                                                initialSelections: (value) => ({
                                                    id: value.user_id,
                                                    name: !!value.user_name ? value.user_name : value.email,
                                                }),
                                                rowRenderer: (item) => <DropDownItemWithDescription item={{
                                                    id: item.email,
                                                    name: !!(item.last_name || item.first_name) ? `${item.last_name} ${item.first_name}` : item.email
                                                }}/>,
                                            }}
                                            secondaryDropdown={{
                                                label: t('Roles'),
                                                labelAppearance: 'title',
                                                placeholder: 'Select role',
                                                withLazyLoad: false,
                                                key: 'role_id',
                                                externalData: !isGroupManager ? roleList : roleList.filter(role => role.id === FEDERATION_GROUP_COORDINATOR),
                                                onChange: (role, value) => {
                                                    setFieldValue(name, values[name].map(el => el.user_id === value.user_id ? {
                                                        ...value,
                                                        role_id: role.id,
                                                        role_name: role.name
                                                    } : el));
                                                },
                                                initialSelections: value => ({
                                                    id: value.role_id,
                                                    name: value.role_name
                                                }),
                                            }}
                                        />

                                    )}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </OverlayContent>
            )}
        />
    );
};

export default FederationsGroupForm;
