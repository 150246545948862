import React, {Component} from 'react';
import {AppInit} from "../actions/async-actions/application";
import ReactGA from 'react-ga';
import Config from "../configs/mainConfig";
import usersDataService from '../services/usersDataService';

const InitApp = ComposedComponent => {
    class InitApp extends Component {
        init () {
            if (Config.main.googleAnalytics) {
                ReactGA.initialize(Config.main.googleAnalytics);
                ReactGA.pageview(window.location.pathname + window.location.search);
            }

            this.props.dispatch(AppInit());
        }

        postMessageHandler (event = {}) {
            const { message } = event?.data || {};
            if (!message) return;

            switch (message) {
                case 'profile':
                    usersDataService.getUserProfile().then(r => r).catch(e => e);
                    break;
                default:
                    break;
            }
        }

        render () {
            return <ComposedComponent {...this.props} {...this.state} />;
        }

        componentDidMount () {
            this.init();
            window.addEventListener('message', this.postMessageHandler);
        }

        componentWillUnmount() {
            window.removeEventListener('message', this.postMessageHandler);
        }
    }

    return InitApp;
};

export default InitApp;
