import React from 'react';
import {Field, Formik} from "formik";
import {closeRightPanel} from "../../../../actions/sync-actions/uiActions";
import {useDispatch} from "react-redux";
import {createPartnerRequest} from "../../../../actions/async-actions/userAction";
import leagueDataService from "../../../../services/leagueDataService";
import Input from "../../../shared/input";
import DropDown from "../../../shared/dropDown";
import OverlayContent from "../../rightPanel/overlayContent";
import DropDownItemWithDescription from "../../../shared/dropDown/pieces/dropDownItemWithDescription";
import { t } from '../../../../helpers/translate';

const validate = (values) => {
    return Object.keys(values)
        .reduce((acc, curr) => {
            switch (curr) {
                case 'email': {
                    if (!values.email) {
                        acc[curr] = t('Email is required');
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        acc[curr] = t('Invalid email address');
                    }
                    return acc;
                }
                case 'phone': {
                    if ((values[curr].length === 1 && values[curr][0] === '+') || !values[curr]) {
                        acc[curr] = t('Phone number is required');
                    } else if ((values[curr].length < 9 || values[curr].length >= 15) && /^\+[0-9 ()-]+$/g.test(values[curr])) {
                        acc[curr] = t('Invalid phone number');
                    }
                    return acc;
                }
                case 'address': {
                    !values[curr].length && (acc[curr] = t('Address is required'));
                    return acc;
                }
                case 'project_name': {
                    !values[curr].length && (acc[curr] = t('Project name is required'));
                    return acc;
                }
                case 'full_name': {
                    !values[curr].length && (acc[curr] = t('Full name is required'));
                    return acc;
                }
                default:
                    return acc;
            }
        }, {});
};

const initialValues = {
    full_name: '',
    sport_ids: [],
    league_ids: [],
    email: '',
    phone: '',
    skype: '',
    country_code: null,
    address: '',
    domain: '',
    project_name: ''
};

const PartnerRequestForm = (props) => {
    const dispatch = useDispatch();
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnChange
            validateOnBlur
            onSubmit={() => {}}
            validate={validate}
            render={({ values, touched, errors, setFieldValue, setValues, setTouched, isValid }) => (
                <OverlayContent
                    title={t('Request to become a partner')}
                    subTitle={t('as a content provider')}
                    secondaryButton={{
                        name: t('Cancel'),
                        onClick: () => dispatch(closeRightPanel())
                    }}
                    primaryButton={{
                        name: t('Request'),
                        color: 'confirm',
                        disabled: !isValid,
                        onClick: () => {
                            const data = Object.keys(values).reduce((acc, curr) => {
                                if (curr === "sport_ids" || curr === "league_ids") {
                                    return {
                                        ...acc,
                                        [curr]: values[curr].reduce((items, el) => {
                                            return { ...items, [el.id]: el.name };
                                        }, {})
                                    }
                                }
                                return { ...acc, [curr]: values[curr] };
                            }, {});
                            dispatch(createPartnerRequest(data));
                        }
                    }}
                >
                    <div className="form-grid col-2">
                        <Field
                            name="full_name"
                            render={({field}) => (
                                <Input
                                    label={t('Full Name (rights owner)')}
                                    required
                                    type='text'
                                    placeholder={t('Enter full name here')}
                                    value={values[field.name]}
                                    errorText={touched[field.name] && errors[field.name]}
                                    labelAppearance="title"
                                    onChange={(e) => {
                                        setFieldValue(field.name, e.target.value);
                                        setTouched({ ...touched, [field.name]: true })
                                    }}
                                />
                            )}
                        />
                        <Field
                            name="project_name"
                            render={({field}) => (
                                <Input
                                    label={t('Project name')}
                                    required
                                    type='text'
                                    placeholder={t('Enter project name here')}
                                    value={values[field.name]}
                                    errorText={touched[field.name] && errors[field.name]}
                                    labelAppearance="title"
                                    onChange={(e) => {
                                        setFieldValue(field.name, e.target.value);
                                        setTouched({ ...touched, [field.name]: true })
                                    }}
                                />
                            )}
                        />
                        <Field
                            name="sport_ids"
                            render={() => (
                                <DropDown
                                    label={t('Sport')}
                                    labelAppearance="title"
                                    required
                                    multiSelect
                                    service={leagueDataService.getSportsByParams}
                                    placeholder={t('Select sport')}
                                    requestParams={{all: 1}}
                                    onChange={sports => {
                                        setValues({
                                            ...values,
                                            sport_ids: sports
                                        });
                                    }}
                                />
                            )}
                        />
                        <Field
                            name="league_ids"
                            render={() => (
                                <DropDown
                                    key={values.sport_id}
                                    required
                                    label={t('Leagues')}
                                    labelAppearance="title"
                                    multiSelect
                                    requestParams={{
                                        includes: "sport,region",
                                        sportFields: "name,alias",
                                        regionFields: "name",
                                        unattached: 1,
                                        ...(values.sport_ids.length ? {sport_ids: values.sport_ids.map(val => val.id).join(',')} : {}),
                                    }}
                                    iconKey="sport.alias"
                                    rowRenderer={(item) => <DropDownItemWithDescription item={item} />}
                                    disabled={!values.sport_ids.length}
                                    service={leagueDataService.getLeaguesByParams}
                                    placeholder={t('Select league')}
                                    onChange={leagues => {
                                        setValues({
                                            ...values,
                                            league_ids: leagues
                                        });
                                    }}
                                />
                            )}
                        />
                        <Field
                            name="email"
                            render={({field}) => (
                                <Input
                                label={t('Email')}
                                required
                                type='text'
                                placeholder={t('Enter email here')}
                                value={values[field.name]}
                                onChange={(e) => {
                                    setFieldValue(field.name, e.target.value);
                                    setTouched({ ...touched, [field.name]: true })
                            }}
                                errorText={touched[field.name] && errors[field.name]}
                                labelAppearance="title"
                                />
                            )}
                        />
                        <Field
                            name="phone"
                            render={({field}) => (
                                <Input
                                    value={field.value}
                                    type="text"
                                    onChange={(e) => {
                                        if (!e.target.value || /^[0-9 ()+-]+$/g.test(e.target.value)) {
                                            let val = e.target.value.includes('+') ? e.target.value : `+${e.target.value}`;
                                            if (values[field.name].length === 1 && values[field.name][0] === "+" && !e.target.value.length) val = '';
                                            setTouched({...touched, [field.name]: true});
                                            setFieldValue(field.name, val);
                                        }
                                    }}
                                    placeholder={t('Enter phone number here')}
                                    labelAppearance="title"
                                    errorText={touched[field.name] && errors[field.name]}
                                    label={t('Phone Number')}
                                    required
                                />
                            )}
                        />
                        <Field
                            name="skype"
                            render={({field}) => (
                                <Input
                                    type='text'
                                    label='Skype'
                                    placeholder='Enter Skype here'
                                    labelAppearance="title"
                                    {...field}
                                />
                            )}
                        />
                        <Field
                            name="country_code"
                            render={({field}) => (
                                <DropDown
                                    label={t('Country')}
                                    labelAppearance='title'
                                    required
                                    errorText={errors[field.name]}
                                    service={leagueDataService.getCountriesByParams}
                                    keys={{key: 'code', value: 'name'}}
                                    placeholder={!field.value ? t('Select Country') : " "}
                                    multiSelect={false}
                                    onChange={(value) => {
                                        setFieldValue(field.name, {[value.code]: value.name})
                                    }}
                                />
                            )}
                        />
                        <Field
                            name="address"
                            render={({field}) => (
                                <Input
                                    type='text'
                                    label={t('Address')}
                                    required
                                    placeholder={t('Enter address here')}
                                    labelAppearance="title"
                                    value={values[field.name]}
                                    errorText={touched[field.name] && errors[field.name]}
                                    onChange={(e) => {
                                        setFieldValue(field.name, e.target.value);
                                        setTouched({ ...touched, [field.name]: true })
                                    }}
                                />
                            )}
                        />
                        <Field
                            name="domain"
                            render={({field}) => (
                                <Input
                                    label={t('Domain')}
                                    labelAppearance='title'
                                    errorText={errors[field.name]}
                                    type='text'
                                    placeholder={t('Enter domain here')}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                </OverlayContent>
            )}>
        </Formik>
    );
};

export default PartnerRequestForm;
