export const getFilterParamsKey = (filter, exclude = false) => {
    let paramsKey = "", excludeKey = "";
    switch (filter) {
        case "sports":
        case "reportsSport":
            paramsKey = "sport_ids";
            break;
        case "countries":
        case "reportsCountries":
            paramsKey = "country_codes";
            break;
        case "regions":
        case "reportsRegions":
            paramsKey = "region_ids";
            break;
        case "filterLeagues":
        case "leagues":
        case "reportsCompetitions":
            paramsKey = "league_ids";
            break;
        case "teams":
            paramsKey = "team_ids";
            break;
        case "statuses":
            paramsKey = "status_ids";
            break;
        case "search":
            paramsKey = "q";
            break;
        case "startDate":
            paramsKey = "start";
            break;
        case "endDate":
            paramsKey = "end";
            break;
        case "role":
            paramsKey = "role_ids";
            break;
        case "devices":
            paramsKey = "device_ids";
            break;
        case "browsers":
            paramsKey = "browser_ids";
            break;
        case "content_providers-content_providing_user_ids":
            paramsKey = "content_providers-content_providing_user_ids";
            excludeKey = "content_providers-exclude_content_providing_user_id";
            break;
        case "product_ids":
            paramsKey = "content_providers-product_ids";
            excludeKey = "content_providers-exclude_product_ids";
            break;
        case "federations":
            paramsKey = "organization_ids";
            break;
        case "operators":
            paramsKey = "operator_ids";
            break;
        default:
            paramsKey = filter;
            break;
    }
    return exclude ? excludeKey || `exclude_${paramsKey}` : paramsKey;
};

export const DEFAULT_LIMIT = 50;
