import {PureComponent} from "react";
import PropTypes from "prop-types";
import usersDataService from "../../../services/usersDataService";
import {matchPath, withRouter} from "react-router-dom";
import { getSubPath } from '../../../helpers/routeHelper';

const checkRoute = (props) => {
    let isAvailable = false;
    if (props.availableRoutes) {
        isAvailable = props.availableRoutes.some(route => matchPath(
            getSubPath(),
            route
        ));
    }
    if (props.restrictedRoutes) {
        isAvailable = !props.restrictedRoutes.some(route => matchPath(
            getSubPath(),
            route
        ));
    }
    return isAvailable;
};

class CheckAvailability extends PureComponent {
    static propTypes = {
        restrictedRoles: PropTypes.array,
        availableRoles: PropTypes.array,
        availableRoutes: PropTypes.array,
        restrictedRoutes: PropTypes.array,
        location: PropTypes.object
    };

    constructor (props) {
        super(props);
        this.profileHandler = this.profileHandler.bind(this);
        this.state = {
            pathname: (props.location && props.location.pathname) || "",
            isRoleAvailable: true,
            isRouteAvailable: (!props.availableRoutes && !props.restrictedRoutes) || checkRoute(props)
        };
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if ((nextProps.availableRoutes || nextProps.restrictedRoutes) && nextProps.location.pathname !== prevState.pathname) {
            return {
                pathname: nextProps.location.pathname,
                isRouteAvailable: checkRoute(nextProps)
            };
        }
        return null;
    }

    componentDidMount () {
        this.subscription = usersDataService.profile.subscribe(this.profileHandler);
    }

    profileHandler (profile) {
        let {roles = []} = profile || {},
            {availableRoles, restrictedRoles} = this.props,
            userRoleIds = roles.map(role => role.id);

        if (!availableRoles && !restrictedRoles) return;
        let isRoleAvailable = availableRoles && availableRoles.some(roleId => userRoleIds.includes(roleId));

        if (restrictedRoles && (isRoleAvailable === undefined || isRoleAvailable)) {
            isRoleAvailable = !userRoleIds.every(roleId => restrictedRoles.includes(roleId));
        }
        !isRoleAvailable && this.setState({isRoleAvailable});
    }

    componentWillUnmount () {
        this.subscription && this.subscription.unsubscribe();
    }

    render () {
        const {isRoleAvailable, isRouteAvailable} = this.state;
        return (
            isRoleAvailable && isRouteAvailable && this.props.children
                ? this.props.children : null
        );
    }
}

export default withRouter(CheckAvailability);
