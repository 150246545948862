import { EXPORT_DATA, SET_CURRENT_PRODUCT, SET_USER_INIT_DATA, SWITCH_INTERCOM } from "../actions/action-types";
import { toggleAutobookingRulesSection } from '../actions/sync-actions/autoBooking';
import { EVENT_TYPES } from '../constants/uiConstants';
import { getBasePathForInitialLoad } from '../helpers/routeHelper';
import usersDataService from '../services/usersDataService';
import { getParentParams } from "./filtersMiddleware";
import { setQueryParams } from "../constants/queryParams";
import Helpers from "../helpers/helperFunctions";
import { openPopup, selectEventsType, setIntercomState } from "../actions/sync-actions/uiActions";
import { popupConfig } from "../configs/popupConfig";
import { ADMIN, CLIENT, CLIENT_ADMIN, MODERATOR, STREAMING_MANAGER } from "../constants/userRoles";
import { getDefaultEventType } from "../helpers/uiHelpers";
import { REPORTING, PAYMENTS } from "../constants/api-resources";
import { addMonths } from 'date-fns';
import newsModalBC from "../scripts/newsmodalbc";
import intercomLiveChat from "../scripts/intercomLiveChat";
import reportingDataService from "../services/reportingDataService";

/**
 * @description Get filter key name for route.
 * @param route
 * @returns {*}
 */
const getFilterKeyNameByRoute = (route) => {
    switch (route) {
        case "/events":
            return "events";
        case "/reporting":
        case "/federations":
            return "reports";
        case "/settings/payments":
            return "clientCurrentInvoice";
        default:
            return route;
    }
};

const getExportUrl = (key) => {
    switch (true) {
        case key === "events" && Helpers.checkRole(CLIENT, CLIENT_ADMIN, STREAMING_MANAGER):
            return "mygames";
        case key === "events" && Helpers.checkRole(ADMIN, MODERATOR):
            return "moderator/events";
        case key === "events":
            return "events";
        case key === "reports":
            return `${reportingDataService.reportingUrl}/${REPORTING.REPORTS_FULL.URL}`;
        case key === "clientCurrentInvoice":
            return PAYMENTS.CURRENT_INVOICE_EVENTS.URL;
        case key === "operators":
            return "moderator/operators";
        default:
            throw new Error('no such type');
    }
};

/**
 * @description The middleware is designed for ui global actions handling.
 * @param store
 * @returns {function(*): Function}
 * @constructor
 */
const UIMiddleware = store => next => action => {
    switch (action.type) {
        case EXPORT_DATA: {
            const currentState = store.getState();
            const { meta, payload, params } = action;
            //Get current param's without some keys.
            const filterKey = getFilterKeyNameByRoute(payload);
            const filtersParams = (meta
                ? currentState.filtersParams[filterKey] && currentState.filtersParams[filterKey].subKeys && currentState.filtersParams[filterKey].subKeys[meta]
                : currentState.filtersParams[filterKey]) || {};
            const filters = setQueryParams(filtersParams, filterKey);
            const diffDates = Helpers.checkDatesDiff(filters.start, filters.end);
            const downloadCSV = (filterParams) => {
                const url = getExportUrl(filterKey);
                return Helpers.exportDataByQueryParams(url, {
                    ...(getParentParams(filterParams, true) || {}), ...(params || {}),
                    is_csv: true,
                    without_pagination: 1
                }, undefined, store.dispatch);
            };
            if (!Helpers.getCookieParam("exportDataConfirmation") && diffDates.months >= 1 && diffDates.days !== 0) {
                return store.dispatch(openPopup(
                    "message",
                    {
                        ...(
                            filterKey === "events"
                                ? meta !== EVENT_TYPES.HISTORY ? popupConfig.eventsExportData : popupConfig.eventsExportHistoryData
                                : popupConfig.reportsExportData
                        ),
                        handleCallBack: () => {
                            const filtersParams = {
                                ...filters,
                                end: parseInt(addMonths(new Date(filters.start * 1000), 1) / 1000)
                            };
                            return downloadCSV(filtersParams);
                        }
                    }));
            }
            return downloadCSV(filters);
        }
        case SET_USER_INIT_DATA: {
            const userProfile = usersDataService.userProfile.getValue();
            const partnerId = userProfile?.organization_id;
            !sessionStorage.getItem('organizationId') && partnerId && sessionStorage.setItem('organizationId', partnerId);
            next(selectEventsType(getDefaultEventType(action.payload.roles, getBasePathForInitialLoad(action.payload.roles))));
            newsModalBC(action.payload);
            intercomLiveChat(action.payload, userProfile);
            const { Intercom } = window;
            if (typeof Intercom === 'function') {
                Intercom('onHide', () => {
                    next(setIntercomState(false));
                });
                Intercom('onShow', () => {
                    next(setIntercomState(true));
                });
            }
            break;
        }
        case SWITCH_INTERCOM:
            const { Intercom } = window;
            if (typeof Intercom === 'function') {
                const { ui: { intercomState } } = store.getState();
                if (!intercomState) {
                    Intercom('show');
                } else {
                    Intercom('hide');
                }
            }
            break;
        case SET_CURRENT_PRODUCT: {
            const currentState = store.getState();
            action.payload === 'scouting' && currentState.autoBookingRules.hideRules && next(toggleAutobookingRulesSection());
            break;
        }
        default:
            break;
    }
    next(action);
};
export default UIMiddleware;
