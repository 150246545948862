import {
    SELECT_AUTOBOOKING_RULE,
    TOGGLE_AUTOBOOKING_RULES,
    CURRENTLY_EDITING_RULE_ID,
    CLEAR_AUTOBOOKING_RULES,
    SET_MULTIPLE_AUTOBOOKING_RULES
} from "../actions/action-types";

const initialState = {hideRules: false, editingRuleId: null};
/**
 * @description Toggle cart events.
 * @param state
 * @param action
 * @returns {*}
 * @constructor
 */
const AutoBookingReducer = (state = initialState, action) => {
    const data = state[action.key] || {};
    switch (action.type) {
        case SELECT_AUTOBOOKING_RULE: {
            return {
                ...state,
                [action.key]: {
                    ...data,
                    selectedRuleIds: data.selectedRuleIds && data.selectedRuleIds.includes(action.payload)
                        ? data.selectedRuleIds.filter(id => id !== action.payload)
                        : [...(data.selectedRuleIds || []), action.payload]
                }
            };
        }
        case SET_MULTIPLE_AUTOBOOKING_RULES: {
            return {
                ...state,
                [action.key]: {
                    ...data,
                    selectedRuleIds: action.payload
                }
            };
        }
        case CLEAR_AUTOBOOKING_RULES: {
            return {
                ...state,
                [action.key]: {}
            };
        }
        case TOGGLE_AUTOBOOKING_RULES: {
            return {
                ...state,
                hideRules: !state.hideRules
            };
        }
        case CURRENTLY_EDITING_RULE_ID: {
            return {
                ...state,
                editingRuleId: action.payload
            };
        }
        default:
            break;
    }

    return state;
};

export default AutoBookingReducer;
