import {
    ADD_FILTERS_SELECTION,
    SET_LAST_FILTERS,
    RESET_FILTERS_DATA,
    RESET_FILTERS_BY_PARENT_KEY
} from "../actions/action-types";
import {setFiltersParams} from "./parts/filtersParentSubsPart";

const INITIAL_STATE = {};

const FiltersReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case SET_LAST_FILTERS: {
            return setFiltersParams(state, action);
        }
        case ADD_FILTERS_SELECTION: {
            return { //@TODO change logic with exclude functionality.
                ...state,
                [action.key]: {
                    ...state[action.key],
                    [action.meta]: [
                        ...((state[action.key] && state[action.key][action.meta]) || {}),
                        action.payload
                    ]
                }
            };
        }
        case RESET_FILTERS_DATA: {
            return {
                ...state,
                [action.parentKey]: {
                    ...(state[action.parentKey]?.subKeys ? {
                        subKeys: {
                            ...((state[action.parentKey] && state[action.parentKey].subKeys) || {}),
                            [action.key]: {}
                        }
                    } : {})
                }
            };
        }
        case RESET_FILTERS_BY_PARENT_KEY: {
            return {
                ...state,
                [action.key]: {},
            }
        }
        default:
            return state;
    }
};

export default FiltersReducer;
