import React, {Component, Fragment} from "react";
import Proptypes from "prop-types";
import {format} from "date-fns";
import {DAYS_OF_WEEK} from "../../../../constants/dataConstants";
import {openPopup} from "../../../../actions/sync-actions/uiActions";
import { popupConfig } from "../../../../configs/popupConfig";
import {setAutobookingRuleEditId} from "../../../../actions/sync-actions/autoBooking";
import ModalContent from "./content";
import Tag from "../../tag";
import Input from "../../input";
import WeekDayPicker from "../../weekDayPicker";
import componentDataProvider from '../../../../hocs/componentDataProvider';
import { t } from '../../../../helpers/translate';

import '../../../reusableUIComponents/competitorsDynamicSelect/teamsVS.scss';

const sectionTitles = {
    products: "Covered With",
    sport: "Sport",
    leagues: "Leagues",
    regions: "Regions",
    valid_date_period: "Date",
    valid_time_period: "Time Frame",
    valid_week_days: "Days",
    teams: "Matches",
    countries_list: "Countries for streaming"
};

class RuleDetails extends Component {
    static propTypes = {
        popupParams: Proptypes.object,
        closePopup: Proptypes.func,
        dispatch: Proptypes.func,
        countriesList: Proptypes.array,
    };

    static requiredProps = {
        countriesList: Proptypes.array,
    };

    getDataByType = (rule, type, index) => {
        const notRestrictedTypesOnFullPackage = ["sport", "countries_list", "valid_date_period"];
        switch (type) {
            case 'teams':
            case 'valid_date_period':
            case 'valid_time_period':
            case 'valid_week_days':
            case 'time_zone':
                return (
                    <Fragment key={index}>
                        {this.getItem(type, rule)}
                    </Fragment>
                );
            default:
                return (
                    rule.sport || (!rule.sport && notRestrictedTypesOnFullPackage.includes(type)) ? (
                        <Fragment key={index}>
                            {((Array.isArray(rule[type]) && rule[type].length) || !Array.isArray(rule[type])) && (
                                <div className={`modal-section span-3 t-${type}`} key={index}>
                                    <div className="ms-head">
                                        {t(sectionTitles[type])}
                                    </div>
                                    <div className="ms-body">
                                        {this.getItem(type, rule)}
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    ) : null
                )
        }
    };

    getItem = (type, rule) => {
        switch (type) {
            case "sport":
                return (
                    <Tag
                        key={rule[type] ? rule[type].name : "all"}
                        name={rule[type] ? rule[type].name : "All"}
                        clipped
                    />
                );
            case "valid_date_period":
                const isIndefinite = !rule[type].start && !rule[type].end;
                return (
                    <Input
                        readOnly
                        itemsDirection="end"
                        icon="icon-calendar"
                        label={t('Dates')}
                        labelAppearance="title"
                        value={isIndefinite ? t('Permanent booking') : `${(rule[type].start && format(new Date(rule[type].start * 1000), "dd MMM yyyy")) || "no date"} - ${(rule[type].end && format(new Date(rule[type].end * 1000), "dd MMM yyyy")) || "no date"}`}
                    />
                );
            case "valid_time_period":
                return (
                    <>
                        <Input
                            readOnly
                            itemsDirection="end"
                            icon="icon-clock"
                            labelAppearance="title"
                            value={rule[type].start || ""}
                            placeholder="no time"
                            label={t('Start time')}
                        />
                        <Input
                            readOnly
                            itemsDirection="end"
                            icon="icon-clock"
                            labelAppearance="title"
                            value={rule[type].end || ""}
                            placeholder="no time"
                            label={t('End time')}
                        />
                    </>
                );
            case "time_zone": {
                return (
                    <Input
                        readOnly
                        itemsDirection="end"
                        icon="icon-clock"
                        labelAppearance="title"
                        value={rule[type]}
                        label={t('Timezone')}
                    />
                )
            }
            case "valid_week_days": {
                const ruleWeekDays = [];
                DAYS_OF_WEEK.forEach(item => rule[type].includes(item.id) && ruleWeekDays.push(item.id));
                return (
                    <WeekDayPicker
                        className="span-3"
                        readOnly
                        selectedIds={ruleWeekDays}
                    />
                );
            }
            case "teams": {
                const teams = [];
                rule[type].forEach((team, index) => teams.push(this.getTeamName(team.team_1_id, team.team_2_id, rule, index)));
                return teams;
            }
            case 'countries_list':
                return this.props.countriesList.length !== rule[type].length ? (
                    rule[type].map(data => (
                        <Tag
                            key={data.id || data["iso3_code"]}
                            name={data.name}
                            clipped
                        />
                    ))
                ) : <Tag
                    name='All'
                    clipped
                />;
            default:
                return rule[type].map(data => (
                    <Tag
                        key={data.id || data["iso3_code"]}
                        name={data.name}
                        clipped
                    />
                ));
        }
    };

    getTeamName = (id1, id2, rule, key) => {
        const team1Key = "team_1", team2Key = "team_2";
        const team1 = rule[team1Key].filter(team => team.id === id1);
        const team2 = rule[team2Key].filter(team => team.id === id2);

        return (
            <ul className="teams-vs-holder span-3" key={`${key}/teams`}>
                <li>
                    <Input
                        value={(team1.length && team1[0] && team1[0].name) || t('All')}
                        labelAppearance="title"
                        label={t('First Team')}
                        readOnly
                    />
                </li>
                <li>
                    <small>{t('vs')}</small>
                </li>
                <li>
                    <Input
                        value={(team2.length && team2[0] && team2[0].name) || t('All')}
                        labelAppearance="title"
                        label={t('Second Team')}
                        readOnly
                    />
                </li>
            </ul>
        );
    };

    handleEdit = (rule) => () => {
        this.props.dispatch(openPopup('message', {
            ...popupConfig.ruleEdit,
            handleCallBack: () => {
                this.props.dispatch(setAutobookingRuleEditId(rule.id));
                this.props.closePopup();
                setTimeout(() => {
                    this.props.dispatch(openPopup('ruleCreation', {
                        ...popupConfig.ruleCreation,
                        'title': t('Update Autobooking rule')
                    }));
                });
            }
        }));
    };

    render() {
        const {
            popupParams: {
                rule
            }
        } = this.props;

        const dataTypes = [
            'teams',
            'valid_date_period',
            'time_zone',
            'valid_time_period',
            'valid_week_days',
            'sport',
            'leagues',
            'regions',
            'countries_list'
        ];

        return (
            <ModalContent
                primaryButton={{
                    name: t('Edit'),
                    disabled: !!Number(rule.is_frozen),
                    onClick: this.handleEdit(rule),
                    color: 'accent',
                }}
                secondaryButton={{
                    name: t('Close'),
                    onClick: this.props.closePopup,
                }}
            >
                <div className="form-grid col-3">
                    {dataTypes.map((type, index) => this.getDataByType(rule, type, index))}
                </div>
            </ModalContent>
        );
    }
}

export default componentDataProvider(RuleDetails);
