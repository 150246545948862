/**
 * @description Get user role ids from user roles data.
 * @param roles
 * @returns {*}
 */
export const getRoleIds = (roles) => roles && Array.isArray(roles) && roles.map(role => role.id);

/**
 * @description Check if user has auth data
 * @return {boolean}
 */

export const checkIsStaging = host => host === "vstream.feedconstruct.com" || host === "friendship.feedconstruct.com";
