import { startOfDay } from "date-fns";

const intercomLiveChat = ({first_name, last_name, email, created_at}) => {
    window.intercomSettings = {
        app_id: "aebowsdc",
        name: `${first_name} ${last_name}`, // Full name
        email: email, // Email address
        created_at: parseInt(startOfDay(new Date(created_at)) / 1000), // Signup date as a Unix timestamp
        hide_default_launcher: true
    };

    const w = window;
    const ic = w.Intercom;
    if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
    } else {
        const d = document;
        const i = function () {
            i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        w.Intercom = i;
        const l = () => {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/aebowsdc';
            const x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
        };
        l();
    }
};

export default intercomLiveChat;