import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import PropTypes from "prop-types";
import BusinessModelSetup from '../../federations/businessModelSetup';
import Message from "./parts/message";
import PresetInfo from "./parts/presetInfo";
import {withCookies} from "react-cookie";
import {closePopup} from "../../../actions/sync-actions/uiActions";
import { getBasePath } from '../../../helpers/routeHelper';
import { useDispatch } from "react-redux";
import RuleDetails from "./parts/ruleDetails";
import AddRecipient from "./parts/addRecipient";
import CreateStream from "./parts/createStream";
import AutoBookingRulesTab from "../../events/autoBookingRulesTab";
import SettingsWrapper from "../../../containers/settingsWrapper";
import {useLocation, useHistory} from "react-router-dom";
import FederationsDetails from "../../federations/federationsDetails";
import AssignNewLeague from "../../federations/assignNewLeague";
import TemplateCreationForm from "../../settings/formTemplates/templateCreationForm";
import TemplateDetails from '../../federations/templates/parts/templateDetails';
import PerLeagueTemplates from '../../federations/perLeagueTemplates';
import ChangeStreamer from './parts/changeStreamer';
import Button from "../button";
import Wallet from '../../wallet';
import PaymentMethodAddition from '../../wallet/parts/paymentMethodAddition';
import { t } from '../../../helpers/translate';

import "./modal.scss";

const Modal = (props) => {
    const {popup: {popupParams = {}, popupType}, cookies} = props;
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [hasClickedOutside, toggleClickOutside] = useState(false);
    const [previousLocation, setPreviousLocation] = useState(location.pathname);


    useEffect(() => {
        // close popup if user clicked outside of modal
        if (hasClickedOutside) {
            handlePopupClose()();
            toggleClickOutside(!hasClickedOutside);
        }
    }, [hasClickedOutside]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const handleKeyDown = (e) => {
            e.keyCode === 27 && toggleClickOutside(!hasClickedOutside);
        };

        if (popupType) {
            // adding event listener for closing modal on esc
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [popupType]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (previousLocation !== location.pathname) setPreviousLocation(location.pathname);

        if (previousLocation !== location.pathname && (previousLocation !== '/' && previousLocation !== getBasePath())) {
            handlePopupClose(true)();
        }
    }, [previousLocation, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClose = () => {
        const now = new Date();
        if (popupParams.showOnlyOnce) {
            cookies.set(popupParams.showOnlyOnce, true, { path: "/", expires: new Date(now.setMonth(now.getMonth() + 3)) });
        }
        if (popupParams.functionOnClickOutside === undefined || popupParams.functionOnClickOutside) {
            popupParams.handleCloseCallBack && setTimeout(popupParams.handleCloseCallBack, 200);
        }
        dispatch(closePopup(popupParams));
    };

    const handlePopupClose = (fromInside = false) => () => {
        const { type, closeOnClickOutside, handleCallBack } = popupParams;

        if (closeOnClickOutside === undefined || closeOnClickOutside || fromInside) {
            if (window.location.hash) history.push(window.location.pathname);
            type && type === "logout" && handleCallBack && handleCallBack();
            onClose();
        }
    };

    const closeButton = () => (
        <Button
            icon="icon-clear"
            onClick={handlePopupClose(true)}
            appearance="minimal"
            color="default"
            size="big"
        />
    );

    const getModal = (type) => {
        const availableModals = {
            presetInfo: () => <PresetInfo popupParams={popupParams} closePopup={onClose} />,
            ruleDetails: () => <RuleDetails popupParams={popupParams} closePopup={onClose} dispatch={dispatch}/>,
            createStream: () => <CreateStream popupParams={popupParams} closePopup={onClose} dispatch={dispatch}/>,
            message: () => <Message closePopup={onClose} popupParams={popupParams} />,
            ruleCreation: () => <AutoBookingRulesTab closePopup={onClose} />,
            settings: () => <SettingsWrapper closeButton={closeButton()} closeAction={handlePopupClose(true)}/>,
            addRecipient: () => <AddRecipient closePopup={onClose} />,
            federationDetails: () => <FederationsDetails closePopup={onClose} popupParams={popupParams} history={history} dispatch={dispatch} />,
            assignNewLeague: () => <AssignNewLeague closePopup={onClose} popupParams={popupParams} pathname={location.pathname} dispatch={dispatch} />,
            templateCreation: () => <TemplateCreationForm closePopup={onClose} popupParams={popupParams} dispatch={dispatch} />,
            templateDetails: () => <TemplateDetails popupParams={popupParams} />,
            perLeagueTemplates: () => <PerLeagueTemplates popupParams={popupParams} />,
            changeStreamer: () => <ChangeStreamer closePopup={onClose} popupParams={popupParams} dispatch={dispatch} />,
            wallet: () => <Wallet closePopup={onClose} />,
            addPaymentMethod: () => <PaymentMethodAddition dispatch={dispatch} closePopup={onClose} popupParams={popupParams} />,
            businessModelSetup: () => <BusinessModelSetup federation={popupParams.federation} data={popupParams.data} tab={'perLeague'} dispatch={dispatch} closePopup={onClose}/>,
        };

        return availableModals[type]();
    };

    if (!popupType) return null;

    return createPortal(
        <div className={`modal-holder t-${popupType}`}>
            <div className="modal-backdrop animation-fadeInUp" onClick={handlePopupClose()} />
            <div className="modal transformReveal">
                {popupType !== 'settings' && (
                    <ul className="modal-head">
                        <li />
                        <li>{t(popupParams.title)}</li>
                        <li>
                            {closeButton()}
                        </li>
                    </ul>
                )}
                {getModal(popupType)}
            </div>
        </div>,
        document.body
    );
};

Modal.propTypes = {
    popup: PropTypes.object,
    cookies: PropTypes.object
};

export default withCookies(Modal);
