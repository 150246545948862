import React from 'react';
import classnames from 'classnames';
import leagueDataService from "../../../services/leagueDataService";
import DropDown from "../../shared/dropDown";
import Button from "../../shared/button";
import { t } from '../../../helpers/translate';

import './teamsVS.scss';

const CompetitorsDynamicSelect = (props) => {
    const {values, setFieldValue} = props.form;
    const {value = [], name} = props.field;
    const {useParams = true, allowAll = true, className, disabled} = props;

    const teamsList = value.map((team) => ({id: Date.now(), ...team}));

    const addNewCompetitors = (e) => {
        setFieldValue(name, [...teamsList, {team_1_id: null, team_2_id: null}]);
    };

    const removeCompetitors = (id) => {
        if (teamsList.length === 1) {
            setFieldValue(name, [{team_1_id: null, team_2_id: null}]);
        } else {
            setFieldValue(name, teamsList.filter(team => team.id !== id));
        }
    };

    const handleChange = (id, newValue) => {
        setFieldValue(name, teamsList.map(team => team.id === id ? newValue : team));
    };

    const requestParams = (teams, key) => (useParams ? {
        ...(values.sport_id ? {sport_ids: values.sport_id} : {}),
        ...(values.league_ids.length ? {league_ids: values.league_ids} : {}),
        ...(values.region_ids.length ? {region_ids: values.region_ids} : {}),
        ...(teams[key] ? {exclude_ids: teams[key]} : {})
    } : {
        ...(teams[key] ? {exclude_ids: teams[key]} : {})
    });

    return teamsList.map((teams, index) => (
        <ul
            key={teams.id}
            className={classnames('teams-vs-holder', className, {
                'interactive': allowAll && value.some(teams => teams.team_1_id || teams.team_2_id)
            })}
        >
            <li>
                <DropDown
                    placeholder={!teams.team_1_id ? ((allowAll && t('All')) || t('Select first team')) : " "}
                    disabled={disabled}
                    onChange={team => {
                        handleChange(teams.id, {...teams, team_1_id: team.id, team_1_name: team.name});
                    }}
                    initialSelections={teams.team_1_id  === - 1 ? {id: -1, name: teams.team_1_name} : null}
                    externalData={teams.team_1_id  === - 1 ? [{id: -1, name: teams.team_1_name}] : null}
                    requestParams={requestParams('team_2_id')}
                    initialRequestParams={teams.team_1_id && teams.team_1_id !== -1 ? {ids: teams.team_1_id} : null}
                    service={leagueDataService.getTeamsByParams}
                    keys={{key: 'id', value: 'name'}}
                    suggestionMode={true}
                    labelAppearance="title"
                    label={t('First Team')}
                />
            </li>
            <li>
                <small>{t('vs')}</small>
            </li>
            <li>
                <DropDown
                    placeholder={!teams.team_2_id ? ((allowAll && t('All')) || t('Select second team')) : " "}
                    onChange={team => {
                        handleChange(teams.id, {...teams, team_2_id: team.id, team_2_name: team.name});
                    }}
                    disabled={disabled}
                    requestParams={requestParams(teams, 'team_1_id')}
                    initialSelections={teams.team_2_id  === - 1 ? {id: -1, name: teams.team_2_name} : null}
                    externalData={teams.team_2_id  === - 1 ? [{id: -1, name: teams.team_2_name}] : null}
                    initialRequestParams={teams.team_2_id && teams.team_2_id !== -1 ? {ids: teams.team_2_id} : null}
                    service={leagueDataService.getTeamsByParams}
                    keys={{key: 'id', value: 'name'}}
                    suggestionMode={true}
                    labelAppearance="title"
                    label={t('Second Team')}
                />
            </li>
            {allowAll && (
                <li>
                    <div className="teams-actions-holder">
                        {(!!(teams.team_1_id || teams.team_2_id) || (index === value.length - 1 && index !== 0)) && <Button
                            color="danger"
                            tooltip={t('Delete')}
                            icon="icon-trash"
                            onClick={() => removeCompetitors(teams.id)}
                            appearance="minimal"
                            iconSize={18}
                        />}
                        {!!(teams.team_1_id || teams.team_2_id) && (index === value.length - 1) && <Button
                            color="confirm"
                            tooltip={t('Add more')}
                            icon="icon-plus" onClick={addNewCompetitors}
                            appearance="minimal"
                        />}
                    </div>
                </li>
            )}
        </ul>
    ));
};

export default CompetitorsDynamicSelect;
