import React from "react";
import Config from "../../../../configs/mainConfig";

const DropDownItemWithPrice = ({ item }) => (
    <ul className="d-option d-option-spaced">
        <li className="ellipsis-text">{item['name']}</li>
        <li className="option-detail ellipsis-text">{Config.main.currency}{item["price"]}</li>
    </ul>
);

export default DropDownItemWithPrice;
