import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import Helpers from '../../helpers/helperFunctions';
import InitApp from "../../hocs/initApp";
import Popup from "../../components/shared/modal";
import {
    buildRoutes,
    getBasePath,
    getBasePathForInitialLoad,
    getPathName,
    isRouteAvailableForUser
} from "../../helpers/routeHelper";
import { openPopup, setCurrentProduct, switchIntercomState } from '../../actions/sync-actions/uiActions';
import { Logout } from '../../actions/async-actions/authorization';
import { popupConfig } from '../../configs/popupConfig';
import Loader from "../../components/reusableUIComponents/loader";
import Header from "../../components/shared/header";
import SubHeader from "../../components/shared/subHeader";
import { useLocation, Redirect, Route, Switch, withRouter } from "react-router-dom";
import ErrorNotFounded from "../../components/application/routeNotFound";
import RightPanel from "../../components/settings/rightPanel";
import useBeforeUnload from '../../hooks/useBeforeUnload';
import translationService from '../../services/translationService';
import usersDataService from "../../services/usersDataService";
import { getRoleIds } from "../../helpers/userHelpers";
import Toaster from "../../components/shared/toaster";

import './layout.scss';

/**
 * @description Function renders passed component based on condition or redirects to events
 * @param Component
 * @param props
 * @return {JSX.Element}
 */
export const RootRoute = ({component: Component, ...props}) => {
    const profile = usersDataService.profile.getValue();
    const userRoles = getRoleIds(profile.roles);
    const basePath = getBasePathForInitialLoad(userRoles);

    const renderRoute = () => (
        isRouteAvailableForUser()
            ? <Redirect exact from="/" to={basePath}/>
            : <Redirect to={basePath}/>
    );

    return <Route {...props} render={renderRoute}/>;
};

const Layout = (props) => {
    const {isLoggedIn, loginState, popupData, dispatch} = props;
    const {pathname} = window.location || {};
    const userProfile = usersDataService.profile.getValue();
    const location = useLocation();

    const [translationsIsReady, setTranslationsStatus]  = useState(false);

    let NotRouterComponent;

    const setLastActiveProject = useCallback(() => {
        return Helpers.setLastActiveProject(getBasePath());
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    useBeforeUnload(setLastActiveProject);

    useEffect(() => {
        const subscription = translationService.translationsIsReady.subscribe(setTranslationsStatus);
        return () => subscription && subscription.unsubscribe();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.body.className = `${getPathName().split('/')[1]}-page`;
        dispatch(setCurrentProduct(getBasePath().slice(1)))
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userProfile && !userProfile.active) {
            dispatch(openPopup("message", {
                ...popupConfig.blockedUser,
                handleCloseCallBack: () => dispatch(Logout()),
                handleCallBack: () => dispatch(switchIntercomState())
            }));
        }
    }, [userProfile?.active]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderModal = useMemo(() => {
        return popupData.length ? popupData.map((data, index) => (<Popup popup={data} key={index}/>)) : null;
    }, [popupData.length]); // eslint-disable-line react-hooks/exhaustive-deps

    switch (true) {
        case loginState.logoutInProgress || (!isLoggedIn && loginState.loading):
        case !userProfile:
        case !translationsIsReady:
            NotRouterComponent = Loader;
            break;
        case !isLoggedIn && !loginState.loading: {
            if (window.location.pathname === "/logout") {
                NotRouterComponent = Loader;
                //window.location.href = Config.main.ssoLogin;
                break;
            }/* else if (!hasAuthData()) {
                window.location.href = Config.main.ssoLogin;
            }*/
            return null;
        }
        case !isRouteAvailableForUser():
            NotRouterComponent = ErrorNotFounded;
            break;
        default:
            break;
    }

    if (NotRouterComponent) return <NotRouterComponent/>;

    const mainPath = getBasePath();

    return (
        <>
            <header>
                <Header pathname={pathname}/>
                <SubHeader pathname={pathname}/>
            </header>
            <main>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        <RootRoute exact path="/"/>
                        {buildRoutes(mainPath)}
                        {/*<Route component={ErrorNotFounded}/>*/}

                        {/*<Route path="/events" component={routerConfig["/scouting"]["/events"].component()}/>*/}
                        {/*<Route path="/home" component={() => <div>Home</div>}/>*/}
                        {/*<Route path="/leagues" component={LeaguesWrapper}/>*/}
                        {/*<Route path="/watchlist" component={WatchListWrapper}/>*/}

                        {/*<Route path={["/federations/:id", "/federations"]} component={FederationsWrapper}/>*/}
                        {/*<Route strict path="/reporting" render={renderReportingDashboard}/>*/}
                        {/*<Route path="/settings" component={renderSettingsWrapper}/>*/}
                        {/*<Route path="/streamer/:eventId" component={renderStreamerView}/>*/}
                        {/*<Route path={["/operators/:id", "/operators"]} component={OperatorsWrapper}/>*/}
                        {/*<Route component={ErrorNotFounded}/>*/}
                    </Switch>
                </Suspense>
            </main>
            <RightPanel/>
            {renderModal}
            <Toaster/>
        </>
    );
};

const mapStateToProps = () => {
    return createSelector(
        [
            state => state.user.loggedIn,
            state => state.dataStates.userState || {},
            state => state.popup || []
        ],
        (isLoggedIn, loginState, popupData) => ({isLoggedIn, loginState, popupData})
    );
};

Layout.propTypes = {
    isLoggedIn: PropTypes.bool,
    loginState: PropTypes.object,
};

export default connect(mapStateToProps)(InitApp(withRouter(Layout)));
