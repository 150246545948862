import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TOAST_TYPES } from '../../../constants/uiConstants';
import Helpers from '../../../helpers/helperFunctions';
import Popover from "../../shared/popover";
import PropTypes from "prop-types";
import WalletBalance from '../walletBalance';
import { createSelector } from "reselect";
import { NavLink, withRouter } from "react-router-dom";
import componentDataProvider from "../../../hocs/componentDataProvider";
import HeaderMenu from "./parts/headerMenu";
import { getAvailableRoutes, getBasePath, getSubPath } from "../../../helpers/routeHelper";
import {
    CLIENT,
    CLIENT_ADMIN,
    LEAGUE_OWNER,
    SCOUT_OPERATOR,
    SCOUT_TRADER,
    STREAMING_MANAGER, STREAMING_VIEWER,
} from "../../../constants/userRoles";
import CheckAvailability from "../checkAvailability";
import { popupConfig } from "../../../configs/popupConfig";
import { getRoleIds } from "../../../helpers/userHelpers";
import Config from "../../../configs/mainConfig";
import { skipTrial } from "../../../actions/async-actions/userAction";
import Button from "../button";
import Icon from "../icon";
import { showPopupByPartnerStatus } from "../../../helpers/modalHelpers";
import { getDefaultEventType, getProjectStatus } from "../../../helpers/uiHelpers";
import BcApps from "../bcApps";
import usersDataService from '../../../services/usersDataService';
import {
    openPopup,
    openRightPanel,
    selectEventsType,
    registerToastMessage,
} from "../../../actions/sync-actions/uiActions";
import { t } from '../../../helpers/translate';

import './header.scss';

const disableScouting = process.env.REACT_APP_MODE === 'production';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alreadyB2BPartner: false,
        };
    }

    static propTypes = {
        isDemo: PropTypes.bool
    };
    static requiredProps = {
        userProfile: PropTypes.object,
    };

    openPartnerRequestForm = () => {
        this.props.dispatch(openRightPanel({rightPanelKey: 'partnerRequestForm'}));
    };

    partnerRequestButton = () => {
        const {isLive, onlyIsPending} = getProjectStatus();
        if (isLive) return null;
        return (
            <Button
                onClick={this.openPartnerRequestForm}
                disabled={onlyIsPending}
                flexibility="content-size"
                size="medium"
                color={onlyIsPending ? 'accent' : 'confirm'}
            >
                {onlyIsPending ? t('Partner Status: Pending') : t('Become a partner')}
            </Button>
        );
    };

    handleSkipTrial = () => {
        const {userProfile} = this.props;
        const {organization_id} = userProfile;
        this.props.dispatch(openPopup("message", {
            ...popupConfig.skipTrial,
            handleCallBack: () => {
                const trial_end = Math.round(new Date().getTime() / 1000);
                this.props.dispatch(skipTrial(organization_id, {trial_end}, {
                    successMsg: t('Trial skipped successfully')
                }));
            }
        }));
    };

    partnerStatusButton = () => {
        const {onlyIsDemo, onlyIsPending, isLive, isTrial, isEnabled, isApiPartner} = getProjectStatus();
        const {dispatch} = this.props;
        switch (true) {
            case !isEnabled:
                return (
                    <Button
                        tag="a"
                        flexibility="content-size"
                        size="medium"
                        target="_blank"
                        color="confirm"
                        href={`${Config.main.consoleBetconstruct}/products/friendShip/overview`}
                    >
                        Enable Friendship
                    </Button>
                );
            case onlyIsDemo && !!isEnabled: {
                const checkAPIPartner = () => {
                    if (isApiPartner) {
                        return dispatch(openPopup("message", {
                            ...popupConfig.goLiveForAPI,
                            handleCallBack: () => {
                                usersDataService.goLiveForApiPartner()
                            }
                        }));
                    } else {
                        window.open(`${Config.main.springBME}/dashboard`, '_blank');
                    }
                };
                return (
                    <Button
                        flexibility="content-size"
                        size="medium"
                        target="_blank"
                        color="confirm"
                        onClick={checkAPIPartner}
                    >
                        {t('Go Live')}
                    </Button>
                );
            }
            case onlyIsPending && !!isEnabled:
                return (
                    <Button
                        tag="a"
                        flexibility="content-size"
                        size="medium"
                        target="_blank"
                        href={`${Config.main.consoleBetconstruct}/projects`}
                    >
                        {t('Check Status')}
                    </Button>
                );
            case isLive && isTrial:
                return (
                    <Button
                        onClick={this.handleSkipTrial}
                        size="medium"
                        color="confirm"
                        flexibility="content-size"
                    >
                        {t('Skip Trial')}
                    </Button>
                );
            default:
                return null;
        }
    };

    renderNavigation = (route, availableRoutes) => {
        const pathname = getSubPath();
        const showRoutesExceptWatchlist = pathname !== '/watchlist' && route !== '/watchlist';
        const showOnlyWatchlist = (pathname === '/watchlist' && (route === '/home' || route === '/watchlist'));

        if (showRoutesExceptWatchlist || showOnlyWatchlist) {
            return <NavLink to={getBasePath() + route} key={route}>{t(availableRoutes[route].name)}</NavLink>;
        }
    };

    render() {
        const {dispatch, userProfile, isDemo} = this.props;
        const {first_name, last_name, roles, email, organization} = userProfile || {};
        const {partner_id, name} = organization
        const userName = `${first_name ? `${first_name}`?.[0] + '.' : ''} ${last_name ? `${last_name}`?.[0] + '.' : ''}`;
        const availableRoutes = getAvailableRoutes(getBasePath());
        const isStreaming = getBasePath() === '/streaming';
        const scoutRoles = [SCOUT_OPERATOR, SCOUT_TRADER];
        const streamingRoles = [STREAMING_MANAGER, STREAMING_VIEWER];

        const rolesForProjectSwitching = [
            CLIENT, CLIENT_ADMIN,
            ...(isStreaming && Helpers.checkRole(...scoutRoles) ? [...(roles || []).map(role => role.id)] : []),
            ...(!isStreaming && Helpers.checkRole(...streamingRoles) ? [...(roles || []).map(role => role.id)] : []),
        ];

        const logoClassName = `mini-logo ${isStreaming ? 'icon-scouting-mini-logo' : 'icon-friendship-mini-logo'}`;

        return (
            <div className="header">
                <div className="guide">
                    <ul className="header-content">
                        <li>
                            <NavLink
                                to={isStreaming ? '/streaming' : '/scouting'}
                                className="logo-holder"
                            >
                                <span
                                    className={`icon-logo ${isStreaming ? 'icon-streaming-logo' : 'icon-scouting-logo'}`}
                                />
                                <span className="icon-friendship-logo-spaced"/>
                            </NavLink>
                            <CheckAvailability availableRoles={rolesForProjectSwitching}>
                                <div className='divider'/>
                                {(disableScouting) ? (
                                    <div className={logoClassName}>
                                        <div className='mini-logo-tooltip'>
                                            Scout Data - Coming Soon
                                        </div>
                                    </div>
                                ) : (
                                    <NavLink
                                        to={isStreaming ? '/scouting' : '/streaming'}
                                        onClick={() => {
                                            dispatch(registerToastMessage({
                                                timeout: 4000,
                                                message: `Successfully switched to ${!isStreaming ? 'Streaming' : 'Scouting'} dashboard.You are now using your ${!isStreaming ? ` "${userProfile.organization.name}"` : 'main Scouting'} project.`
                                            }));
                                        }}
                                        className={`${logoClassName} clickable`}
                                    />
                                )}
                            </CheckAvailability>
                            {getProjectStatus().isWalletPayment && !userProfile.is_betconstruct_user && (
                                <CheckAvailability availableRoles={[CLIENT, CLIENT_ADMIN]}>
                                    <div className="divider"/>
                                    <Button
                                        flexibility="content-size"
                                        size="medium"
                                        color="confirm"
                                        disabled={this.state.alreadyB2BPartner}
                                        onClick={() => {
                                            dispatch(openPopup('message', {
                                                ...popupConfig.b2cBecomePartner,
                                                handleCallBack: () => {
                                                    usersDataService.b2cBecomePartner({id: userProfile.organization.id})
                                                        .then(() => {
                                                            this.setState({ alreadyB2BPartner: true });
                                                            dispatch(registerToastMessage({ message: t('Successfully sent') }));
                                                        })
                                                        .catch(() => {
                                                            this.dispatch(registerToastMessage({
                                                                type: TOAST_TYPES.ERROR,
                                                                message: t('Oops something went wrong')
                                                            }));
                                                        });
                                                }
                                            }));
                                        }}
                                    >
                                        {t('Become a Partner')}
                                    </Button>
                                </CheckAvailability>
                            )}
                            <CheckAvailability availableRoles={[LEAGUE_OWNER]}>
                                <div className="divider"/>
                                {this.partnerRequestButton()}
                            </CheckAvailability>
                            <CheckAvailability availableRoles={[CLIENT]}>
                                <div className="divider"/>
                                {this.partnerStatusButton()}
                            </CheckAvailability>
                        </li>
                        <li>
                            <nav>
                                {Object.keys(availableRoutes).map(route => (availableRoutes[route].showInTopMenu && (
                                    this.renderNavigation(route, availableRoutes)
                                )))}
                            </nav>
                        </li>
                        <li className="header-group-items">
                            {
                                getProjectStatus().isWalletPayment && (
                                    <CheckAvailability availableRoles={[CLIENT, CLIENT_ADMIN, SCOUT_OPERATOR, STREAMING_MANAGER]}>
                                        <WalletBalance dispatch={dispatch}/>
                                    </CheckAvailability>
                                )
                            }
                            <BcApps email={email} partnerId={partner_id} dispatch={dispatch} name={name}/>
                            <div className="divider"/>
                            <Popover
                                selfSizing
                                position="bottom-end"
                                content={
                                    <HeaderMenu dispatch={dispatch} isDemo={isDemo} {...userProfile}/>
                                }
                            >
                                <div className="user-profile-holder">
                                    <Icon type="icon-user-outline"/>
                                    <strong>{userName}</strong>
                                    <Icon type="icon-arrow-down" size={18}/>
                                </div>
                            </Popover>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const {userProfile} = this.props;
        const roles = getRoleIds(userProfile.roles) || [];
        const isClient = roles.length === 1 && roles.includes(CLIENT);
        isClient && showPopupByPartnerStatus(this.props.dispatch, userProfile);

        if (isClient && getProjectStatus().isWalletPayment) {
            usersDataService.isAlreadyPartner()
                .then(() => this.setState({ alreadyB2BPartner: false }))
                .catch(err => err.statusCode === 422 && this.setState({ alreadyB2BPartner: true }));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevLocation = getBasePath(1, prevProps.location.pathname);
        const currentLocation = getBasePath();
        const { userProfile, dispatch } = this.props;
        if (prevLocation !== currentLocation) {
            dispatch(selectEventsType(getDefaultEventType(userProfile.roles)));
        }
    }
}

const mapStateToProps = () => {
    return createSelector(
        [
            state => state.user.isDemo
        ],
        (isDemo) => ({isDemo})
    );
};

export default withRouter(connect(mapStateToProps)(componentDataProvider(Header)));
