import {
    CLEAR_ALL_SELECTED_EVENTS,
    CHECK_CART_EVENTS_EXISTENCE,
    INIT_SELECTIONS_DATA_WATCHER,
    ADD_TO_UPCOMING
} from "../actions/action-types";
import eventsDataService from "../services/eventsDataService";
import { EVENT_TYPES, TOAST_TYPES } from "../constants/uiConstants";
import {
    closeRightPanel,
    registerToastMessage,
} from "../actions/sync-actions/uiActions";
import {updateSelectedEvents} from "../actions/sync-actions/eventData";
import {updateSelectedEvents as updateEventsSelections} from "../actions/async-actions/eventActions";
import { CLIENT, CLIENT_ADMIN, SCOUT_OPERATOR, STREAMING_MANAGER } from "../constants/userRoles";
import storeDataService from "../services/storeDataService";
import {prepareEventsForUpcoming} from "../helpers/eventsHelper";
import usersDataService from "../services/usersDataService";
import scoutDataService from '../services/scoutDataService';
import Helpers from '../helpers/helperFunctions';

/**
 * @description Middleware will handle selected events actions.
 * @param store
 * @return {function(*): Function}
 * @constructor
 */
const EventsMiddleware = store => next => action => { //@TODO combine cart and selected events functionality.
    switch (action.type) {
        case CHECK_CART_EVENTS_EXISTENCE: {
                storeDataService.getStoredData('cartEvents').subscribe(data => {
                    data?.length && eventsDataService.toggleSelectedEventsByIds(data, EVENT_TYPES.LIST);
                });
                Helpers.checkRole(CLIENT, CLIENT_ADMIN) && storeDataService.getStoredData('scoutCartEvents').subscribe(data => {
                    data?.length && scoutDataService.toggleSelectedScoutEventsByIds(data, EVENT_TYPES.LIST);
                });
            break;
        }
        case INIT_SELECTIONS_DATA_WATCHER: {
                eventsDataService.selectedEventsData.subscribe(selectionsData => {
                    const currentState = store.getState();
                    Object.keys(EVENT_TYPES).forEach(key => {
                        const selectedIds = currentState.eventData?.selectedIds[EVENT_TYPES[key]] || [];
                        const data = selectionsData[EVENT_TYPES[key]] || [];
                        if (data.length !== selectedIds.length) {
                            const newIds = data.map(val => val.id);
                            Helpers.checkRole(CLIENT, CLIENT_ADMIN, STREAMING_MANAGER, SCOUT_OPERATOR) && EVENT_TYPES[key] === EVENT_TYPES.LIST && storeDataService.storeData('cartEvents', newIds);
                            // TODO CHECK FOR MODERATOR
                            next(updateSelectedEvents(newIds, EVENT_TYPES[key]));
                        }
                        //close cart if no selected items
                        if (!data.length && EVENT_TYPES[key] === EVENT_TYPES.LIST && currentState.ui?.rightPanel?.rightPanelKey === 'userCart') {
                            next(closeRightPanel());
                        }
                    })
                });
                Helpers.checkRole(CLIENT, CLIENT_ADMIN, SCOUT_OPERATOR) && scoutDataService.selectedScoutEventsList.subscribe(selectionsData => {
                    const currentState = store.getState();
                    Object.keys(EVENT_TYPES).forEach(key => {
                        const data = selectionsData[EVENT_TYPES[key]] || [];
                        const selectedIds = data.map(val => val.game_id);
                        EVENT_TYPES[key] === EVENT_TYPES.LIST && selectionsData[EVENT_TYPES[key]] && storeDataService.storeData('scoutCartEvents', selectedIds);
                        //close cart if no selected items
                        if (!data.length && EVENT_TYPES[key] === EVENT_TYPES.LIST && currentState.ui?.rightPanel?.rightPanelKey === 'userCart') {
                            next(closeRightPanel());
                        }
                    })
                });
            break;
        }
        case CLEAR_ALL_SELECTED_EVENTS:
            //Reset cart data and close cart block.
           // store.dispatch(resetCart());
            !action.meta.skipToggle && store.dispatch(closeRightPanel());
            eventsDataService.removeAllSelectedEvents(action.key);
            break;
        case ADD_TO_UPCOMING: {
            const selectedEvents = eventsDataService.selectedEventsData.getValue()[EVENT_TYPES.LIST];
            const userProfile = usersDataService.userProfile.getValue();
            const {ui: {eventsType}} = store.getState();
            const {upcomingApproved = [], upcomingReject = [], errors} = prepareEventsForUpcoming(selectedEvents, userProfile);
            if (upcomingApproved.length) {
                const params = upcomingApproved.map(item => ({ ...item, upcoming_status: true }));
                next(updateEventsSelections(eventsType, params, 'Successfully added to upcoming', ({ data }) => {
                    if (data) {
                        eventsDataService.removeSelectedEventById(eventsType, data.map(val => val.id));
                        eventsDataService.removeEventsByKey(eventsType, data);
                        typeof action.callback === 'function' && action.callback();
                    }
                }));
            }
            if (upcomingReject.length) {
                const {approved, countryPrices} = errors || {};
                if (approved) {
                    next(registerToastMessage({type: TOAST_TYPES.ERROR, message: `{${errors.approved}} matches are not approved yet`}));
                }
                if (countryPrices) {
                    next(registerToastMessage({type: TOAST_TYPES.ERROR, message: `Please set up the price for {${errors.countryPrices}} matches`}));
                }
                //next(openPopup("message", popupConfig.upcomingReject));
            }
            break;
        }
        default:
            break
    }
    next(action);
};
export default EventsMiddleware;
