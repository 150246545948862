import React, { Fragment } from 'react';
import usersDataService from "../services/usersDataService";
import routerConfig from "../configs/routerConfig";
import { Redirect, Route } from 'react-router-dom';
import Helpers from './helperFunctions';
import {
    ADMIN,
    CLIENT,
    CLIENT_ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER,
    MODERATOR,
    SCOUT_OPERATOR,
    SCOUT_TRADER,
    STREAMING_MANAGER, STREAMING_VIEWER
} from '../constants/userRoles';
import { getRoleIds } from './userHelpers';
import { ALLOWED_ROLES_KEY_BY_PROVIDER } from '../constants/pageRoutes';

export const isRouteAvailableForUser = (route = window.location.pathname, depth) => {
    const userProfile = usersDataService.profile.getValue(),
        userRoles = userProfile.roles.map(role => role.id);
    return routeAvailabilityCalculator(route, depth, routerConfig, userRoles, userProfile);
};

const routeAvailabilityCalculator = (route, depth = 2, availableRoutes, roles, profile) => {
    const basePath = getBasePath();
    const routeParts = route.split("/");
    const subRoute = `/${routeParts.slice(depth - 1, depth)}`;
    const permissionKey = ALLOWED_ROLES_KEY_BY_PROVIDER[basePath];
    if (!profile.organization.is_api_partner && availableRoutes[subRoute] && availableRoutes[subRoute].showOnlyInIntegration) return false;
    const availableFor = availableRoutes[subRoute] && (availableRoutes[subRoute][permissionKey] || availableRoutes[subRoute].allowed || []);
    const isAvailable = availableFor === '*' || roles?.some(role => availableFor?.includes(role));
    const isDemo = profile && profile.organization && (profile.organization.approve_status === 0 || profile.organization.approve_status === 1);
    const userWalletType = profile?.organization?.payment_type;
    // is user doesn't have required payment type forbid access to route
    if ((availableRoutes[subRoute]?.allowedPaymentType && userWalletType !== availableRoutes[subRoute]?.allowedPaymentType)) return false;
    // get route availability in demo
    const demoAvailability = isDemo ? availableRoutes[subRoute]?.showInDemo : true;
    if (demoAvailability && isAvailable && routeParts.length !== depth && availableRoutes[subRoute] && availableRoutes[subRoute].routes) {
        return routeAvailabilityCalculator(route, depth + 1, availableRoutes[subRoute].routes, roles, profile);
    }
    return demoAvailability ? isAvailable : demoAvailability;
};

/**
 * @description
 * @param route
 * @param availableRoutes
 * @param depth
 * @returns {{}}
 */
const calculateAvailableRoutes = (route, availableRoutes, depth = 2) => {
    const routeParts = route.split("/");
    const subRoute = `/${routeParts.slice(depth - 1, depth)}`;
    if (availableRoutes[subRoute] && availableRoutes[subRoute].routes) {
        if (routeParts.length !== depth) {
            return calculateAvailableRoutes(route, availableRoutes[subRoute].routes, depth + 1);
        }
        const routes = availableRoutes[subRoute].routes;
        return Object.keys(routes).reduce((acc, route) => {
            return isRouteAvailableForUser(subRoute + route, depth) ? {...acc, [route]: routes[route]} : acc;
        }, {});
    }
    return {};
};

export const getDefaultRouteForRole = (config = {}) => {
    const userRoles = getRoleIds(usersDataService.profile.getValue().roles);
    const routes = Object.keys(config);
    for (let route of routes) {
        if (Array.isArray(config[route]) && config[route].find(role => userRoles.includes(role))) {
            return route;
        }
    }

    return routes.find(route => config[route] === "*");
};

export const buildRoutes = (path, config = routerConfig) => {
    const availableRoutes = getAvailableRoutes(path);
    const basePath = getBasePath();
    const defaultRoute = getDefaultRouteForRole(config[basePath].defaultRoute);
    return (
        <Fragment>
            {defaultRoute && <Route exact path={basePath}>
                <Redirect to={basePath + defaultRoute} />
            </Route>}
            {Object.keys(availableRoutes).filter(route => !route.showInPopover).map(routePath => {
                const route = availableRoutes[routePath];
                return (
                    <Fragment key={`${basePath}${routePath}`}>
                        {route.component && <Route
                            path={Array.isArray(route.path) ? route.path.map(val => `${basePath}${val}`) : `${basePath}${route.path || routePath}`}
                            component={route.component}
                            {...(route.routeProps || {})}
                        />}
                        {route.routes && buildRoutes(basePath + routePath, availableRoutes)}
                    </Fragment>
                )
            })}
            {config[basePath].component && <Route path={basePath} component={config[basePath].component}/>}
        </Fragment>
    );
};

export const getSubPath = (depth = 1, route = window.location.pathname) => {
    return route === '/' ? route : new RegExp(`((/[a-z_A-Z]+){${depth}})/?\\d*?$`, 'g').exec(route)[1]
};

export const getBasePath = (depth = 1, route = window.location.pathname) => {
    return new RegExp(`^(/[a-z_A-Z]+){${depth}}|/`).exec(route)[0];
};

export const getPathName = (path = window.location.pathname) => {
    return new RegExp(`/\\w+((/.+)+)|/`, 'g').exec(path)[1] || '/'
};

/**
 * @description
 * @param route
 * @returns {{}}
 */
export const getAvailableRoutes = (route = window.location.pathname) => {
    return calculateAvailableRoutes(route, routerConfig);
};

export const getSettingsRouteComponent = (route, routeConfig, closeAction) => {
    const Component = routeConfig[route].component;
    if (!Component) return null;
    switch (route) {
        case "/users":
            return <Component showTitle={false} reducerKey={Helpers.checkRole(ADMIN, MODERATOR, CP_ADMINISTRATOR, CP_GROUP_MANAGER ) && "moderatorUsers"} />;
        case "/payments": {
            const isClient = getRoleIds(usersDataService.profile.getValue().roles).includes(CLIENT);
            return <Component mode={isClient ? 'client' : 'federation'} />;
        }
        default:
            return <Component closeAction={closeAction}/>;
    }
};


export const getBasePathForInitialLoad = (roles) => {
    const profile = usersDataService.profile.getValue();
    const validBasePath = (path) => path === '/streaming' || path === '/scouting';
    const clientRoles = [CLIENT, CLIENT_ADMIN, SCOUT_OPERATOR, SCOUT_TRADER, STREAMING_MANAGER, STREAMING_VIEWER];
    const lastActiveProject = Helpers.checkRole(...clientRoles) && isRouteAvailableForUser()
        ? Helpers.getCookieParam(`project_${profile.id}`)
        : null;

    if (roles.length === 1) {
        return [SCOUT_OPERATOR, SCOUT_TRADER].includes(roles[0]) && isRouteAvailableForUser()
            ? '/scouting'
            : lastActiveProject && validBasePath(lastActiveProject) && isRouteAvailableForUser(lastActiveProject) ? lastActiveProject : '/streaming';
    }

    return lastActiveProject && validBasePath(lastActiveProject) && isRouteAvailableForUser(lastActiveProject) ? lastActiveProject : '/streaming';
};
