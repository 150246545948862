import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { t } from '../../../helpers/translate';

import Button from "../../shared/button";

import { closeRightPanel } from "../../../actions/sync-actions/uiActions";

const OverlayContent = props => {
    const {
        title,
        subTitle,
        children,
        secondaryButton,
        primaryButton,
        footerTitle,
        footerSubTitle,
        appearance,
        leftCloseButton,
    } = props;

    const dispatch = useDispatch();

    return (
        <>
            <div className="overlay-head">
                { !leftCloseButton && <div className="overlay-close-button">
                    <Button
                        icon="icon-clear"
                        onClick={() => dispatch(closeRightPanel())}
                        appearance="minimal"
                        color="default"
                        size="big"
                        iconSize={32}
                    />
                </div>
                }
                {title && <h2>{title}</h2>}
                {subTitle && <h3>{subTitle}</h3>}
            </div>
            <div className={`overlay-body a-${appearance}`}>
                {children}
            </div>
            {(footerTitle || footerSubTitle || secondaryButton || primaryButton) && (
                <div className="overlay-footer">
                    <ul>
                        <li>
                            {footerSubTitle && <h3>{footerSubTitle}</h3>}
                            {footerTitle && <h2>{footerTitle}</h2>}
                        </li>
                        <li>
                            {secondaryButton && (
                                <Button
                                    onClick={secondaryButton.onClick}
                                    flexibility="default"
                                    appearance="minimal"
                                    color={secondaryButton.color || 'default'}
                                    size="big"
                                >
                                    {t(secondaryButton.name || 'Cancel')}
                                </Button>
                            )}
                            {primaryButton && (
                                <Button
                                    onClick={primaryButton.onClick}
                                    flexibility="default"
                                    disabled={primaryButton.disabled}
                                    color={primaryButton.color}
                                    size="big"
                                >
                                    {t(primaryButton.name || 'Ok')}
                                </Button>
                            )}
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

OverlayContent.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    children: PropTypes.any,
    secondaryButton: PropTypes.shape({
        name: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        color: Button.propTypes.color,
    }),
    primaryButton: PropTypes.shape({
        name: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        color: Button.propTypes.color,
    }),
    footerTitle: PropTypes.string,
    footerSubTitle: PropTypes.string,
    appearance: PropTypes.oneOf(['default', 'fit']),
};

OverlayContent.defaultProps = {
    appearance: 'default',
};

export default OverlayContent;
