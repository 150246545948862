import React, { useRef } from 'react';
import ModalContent from '../../shared/modal/parts/content';
import HostedField from '../../shared/paymentForm/parts/hostedField';
import PaymentForm from '../../shared/paymentForm';
import { registerToastMessage } from '../../../actions/sync-actions/uiActions';
import { TOAST_TYPES } from '../../../constants/uiConstants';
import { t } from '../../../helpers/translate';

const PaymentMethodAddition = ({ popupParams, closePopup, dispatch }) => {
    const errorMessages = {
        expirationDate: t('Expiration date is invalid'),
        cvv: t('Cvv code is invalid'),
        number: t('Card number is invalid')
    };

    const { authorization, addPaymentMethod } = popupParams;

    const tokenize = useRef();
    const getFormState = useRef();

    const throwError = (message = 'Oops something went wrong') => {
        return dispatch(registerToastMessage({
            type: TOAST_TYPES.ERROR,
            message,
        }));
    };

    const handleTokenization = (callback) => {
        tokenize.current()
            .then(({ nonce, details }) => {
                addPaymentMethod({ nonce, ...details });
                dispatch(registerToastMessage({ message: "Card has been securely added" }));
                closePopup();
                setTimeout(() => {
                    typeof callback === 'function' && callback({ nonce, ...details });
                });
            })
            .catch((e) => {
                const originalError = e.details?.originalError;
                throwError(originalError?.error?.message || originalError?.message)
            });
    };

    const handleCardValidation = () => {
        return getFormState.current()
            .then(val => {
                let message;
                const fields = val.fields;
                const errors = Object.keys(fields).filter(key => !fields[key].isValid);

                if (errors.length) {
                    message = errors.length === 3 ? t('All fields are required') : errorMessages[errors[0]];
                    return Promise.reject({ message, type: 'customError' });
                }
                return Promise.resolve();
            })
            .catch((err) => {
                throwError(err.type === 'customError' ? err.message : undefined);
                return Promise.reject(err);
            });
    }

    return (
        <ModalContent
            primaryButton={{
                name: t('Save'),
                color: 'confirm',
                onClick: () => {
                    handleCardValidation()
                        .then(() => handleTokenization())
                        .catch(e => Promise.reject(e));
                }
            }}
            secondaryButton={{
                name: t('Cancel'),
                onClick: closePopup,
            }}
        >
            <div className="form-grid col-2">
                <PaymentForm
                    authorization={authorization}
                    setTokenRef={(ref) => tokenize.current = ref}
                    setFormStateRef={(ref) => getFormState.current = ref}
                >
                    <HostedField
                        id="card-number"
                        type="number"
                        label={t('Card Number')}
                        placeholder="**** **** **** ****"
                        className='span-2'
                    />
                    <HostedField
                        id="expiration-date"
                        type="expirationDate"
                        label={t('Expiration Date')}
                        placeholder={`12/${String((new Date()).getFullYear() + 2).slice(2)}`}
                    />
                    <HostedField
                        id="cvv"
                        type="cvv"
                        label={t('Cvv')}
                        placeholder="123"
                    />
                </PaymentForm>
            </div>
        </ModalContent>
    )
};

export default PaymentMethodAddition;
