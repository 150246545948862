import {
    SELECT_TAB_MENU_ITEM,
    RESET_SCROLL_OFFSET,
    OPEN_RIGHT_PANEL,
    CLOSE_RIGHT_PANEL,
    SELECT_RIGHT_TAB_MENU_ITEM,
    SELECT_EVENTS_TYPE,
    TOGGLE_UI_KEY,
    EXPORT_DISABLE,
    API_INTEGRATION_PAGE,
    CHANGE_COLOR,
    ENABLE_CREATE_BUTTON,
    MAIN_LOADER,
    SHOW_INTERCOM,
    SET_APPLIED_PRESET_ID,
    RESET_APPLIED_PRESET_IDS,
    USER_EDIT_ID,
    SELECT_OPERATOR_TAB_TYPE,
    SELECT_FEDERATIONS_TAB_TYPE,
    SELECT_UNIQUE_FEDERATION_TAB_TYPE, SET_BREADCRUMB_BASE, SET_CURRENT_PRODUCT
} from "../actions/action-types";

import {LEFT_TAB_MENU_ITEMS, RIGHT_TAB_MENU_ITEMS} from "../constants/uiConstants";
import {EVENTS_PAGE, REPORTING_PAGE} from "../constants/pageRoutes";

const uiReducer = (state = {
    mainLoader: null,
    ruleCreatingButtonState: true,
    colorChange: " ",
    appPreferences: {},
    resetScrollOffset: {},
    disableApiIntegrationButton: false,
    isExportDisabled: true,
    appliedPresetIdsByKey: {},
    federationsTabType: window.location.href.includes('group') ? "groupFederations" : "federations",
    activeRightTabMenuItem: {
        [EVENTS_PAGE]: {
            active: true,
            tab: RIGHT_TAB_MENU_ITEMS.DETAILS
        }
    },
    activeTabMenuItem: {
        [EVENTS_PAGE]: LEFT_TAB_MENU_ITEMS.FILTERS,
        [REPORTING_PAGE]: null
    }
}, action) => {
    switch (action.type) {
        case SELECT_TAB_MENU_ITEM:
            return {
                ...state,
                activeTabMenuItem: {
                    ...state.activeTabMenuItem,
                    [action.key]: action.payload
                }
            };
        case SELECT_RIGHT_TAB_MENU_ITEM:
            return {
                ...state,
                activeRightTabMenuItem: {
                    ...state.activeRightTabMenuItem,
                    [action.key]: {
                        ...(state.activeRightTabMenuItem[action.key] || {}),
                        active: !!action.payload,
                        ...(action.payload ? {tab: action.payload} : {})
                    }
                }
            };
        case RESET_SCROLL_OFFSET:
            return {
                ...state,
                resetScrollOffset: {
                    ...state.resetScrollOffset,
                    [action.key]: action.payload
                }
            };
        case OPEN_RIGHT_PANEL:
            return {
                ...state,
                rightPanel: {
                    ...state.rightPanel,
                    isOpen: true,
                    ...action.payload
                }
            };
        case CLOSE_RIGHT_PANEL:
            return {
                ...state,
                rightPanel: {
                    isOpen: false
                }
            };
        case SELECT_EVENTS_TYPE:
            return {
                ...state,
                eventsType: action.payload
            };
        case SELECT_OPERATOR_TAB_TYPE:
            return {
                ...state,
                operatorTabType: action.payload
            };
        case SELECT_FEDERATIONS_TAB_TYPE:
            return {
                ...state,
                federationsTabType: action.payload
            };
        case SELECT_UNIQUE_FEDERATION_TAB_TYPE:
            return {
                ...state,
                uniqueFederationTabType: action.payload
            };
        case TOGGLE_UI_KEY:
            return {
                ...state,
                [action.key]: !state[action.key]
            };
        case EXPORT_DISABLE:
            return {
                ...state,
                isExportDisabled: !state.isExportDisabled
            };
        case API_INTEGRATION_PAGE:
            return {
                ...state,
                disableApiIntegrationButton: action.payload
            };
        case CHANGE_COLOR:
            return {
                ...state,
                colorChange: action.payload
            };
        case ENABLE_CREATE_BUTTON:
            return {
                ...state,
                ruleCreatingButtonState: action.payload
            };
        case MAIN_LOADER:
            return {
                ...state,
                mainLoader: action.payload
            };
        case SHOW_INTERCOM:
            return {
                ...state,
                intercomState: action.payload
            };
        case SET_APPLIED_PRESET_ID:
            return {
                ...state,
                appliedPresetIdsByKey: {
                    ...state.appliedPresetIdsByKey,
                    [action.key]: action.payload
                }
            };
        case RESET_APPLIED_PRESET_IDS:
            return {
                ...state,
                appliedPresetIdsByKey: {}
            };
        case USER_EDIT_ID:
            return {
                ...state,
                userEditId: action.payload
            };
        case SET_BREADCRUMB_BASE:
            return {
                ...state,
                breadcrumbBase: action.payload
            };
        case SET_CURRENT_PRODUCT:
            return {
                ...state,
                currentProduct: action.payload
            };
        default:
            return state;
    }
};

export default uiReducer;
