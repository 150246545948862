import React, { Children, useState, useRef, useEffect, useCallback, Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useMutationObserver from "../../../hooks/useMutationObserver";
import Button from "../button";
import Icon from "../icon";

import './tabs.scss';

const Tabs = (props) => {
    const {
        children,
        selectedTabId,
        onChange,
        color,
        appearance,
        rightAction,
        scrollerRef,
        unwrappedChildes,
    } = props;

    const swipeable = useRef(null);
    const [activeTabId, setActiveTabId] = useState(0);
    const [activeTabHeight, setActiveTabHeight] = useState(0);

    const childes = Children.toArray(children).filter(child => child);
    const width = `var(--tab-item-custom-width, ${childes.length > 1 ? `${100 / childes.length}%` : `${childes[0].props.title.length * 8 / 10}rem`})`;

    const isControlled = props.hasOwnProperty('selectedTabId');
    const activeTab = isControlled ? selectedTabId : activeTabId;
    const activeTabIndex = isControlled ? childes.map(child => child.props.id).indexOf(activeTab) : activeTab;

    const tabOffset = childes.length === 1 ? 0 : (Number(activeTab) ? activeTab : activeTabIndex) * 100;

    const setActiveTabHeightCb = useCallback(() => {
        setActiveTabHeight(swipeable.current?.children[activeTabIndex]?.scrollHeight);
    }, [swipeable.current?.children, activeTabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useMutationObserver(swipeable, setActiveTabHeightCb);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setActiveTabHeight(swipeable.current?.children[activeTabIndex]?.scrollHeight);
        }, 0);
        return () => timeoutId && clearTimeout(timeoutId);
    }, [activeTabIndex]);

    const contentRenderer = (child, index) => activeTab === (child.props.id || index) ? child : null;

    return (
        <>
            <div className={`tabs-holder c-${color} a-${appearance}`}>
                <div className="tab-head">
                    <ul>
                        <li>
                            <ul className="tabs-head-content">
                                {childes.map((child, index) => {
                                    const {
                                        id,
                                        icon,
                                        badge,
                                        isBadgeActive,
                                        title,
                                    } = child.props;
                                    const key = id || index;
                                    return (
                                        <li
                                            key={key}
                                            style={{width}}
                                            className={activeTab === key && childes.length !== 1 ? 'active' : ''}
                                            onClick={() => {
                                                isControlled ? onChange(key) : setActiveTabId(key)
                                            }}
                                        >
                                            <Icon type={icon} />
                                            <div className="tab-title-holder">
                                                <p className="ellipsis-text">{title}</p>
                                                {!!badge && (
                                                    <small className={isBadgeActive ? 'active' : ''}>{badge}</small>
                                                )}
                                            </div>
                                        </li>
                                    )
                                })}
                                <li style={{width, transform: `translateX(${tabOffset}%)`}} className="box"/>
                            </ul>
                        </li>
                        {rightAction && (
                            <li>
                                {rightAction}
                            </li>
                        )}
                    </ul>
                </div>
                {!unwrappedChildes && (
                    <div className="tab-body">
                        <ul style={{transform: `translateX(-${tabOffset}%)`, height: appearance === 'glass' ? activeTabHeight : null}} ref={swipeable}>
                            {childes.map((child, index) => (
                                <li
                                    className={classnames(
                                        `c-${child.props.title.replace(' ', '-').toLowerCase()}`,
                                        {
                                            'as-grid': child.props.secondaryButton || child.props.primaryButton,
                                        }
                                    )}
                                    key={index}
                                    ref={val => {typeof scrollerRef === 'function' && scrollerRef({[(child.props.id || index)]: val})}}
                                >
                                    <div className="tab-content">
                                        {contentRenderer(child, index)}
                                    </div>
                                    {(child.props.secondaryButton || child.props.primaryButton) && (
                                        <div className="tab-footer-button">
                                            {child.props.secondaryButton && <Button
                                                appearance="minimal"
                                                color="default"
                                                onClick={child.props.secondaryButton.onClick}
                                                disabled={child.props.secondaryButton.disabled}
                                            >
                                                {child.props.secondaryButton.name}
                                            </Button>}
                                            {child.props.primaryButton && <Button
                                                color={child.props.primaryButton.color}
                                                disabled={child.props.primaryButton.disabled}
                                                onClick={child.props.primaryButton.onClick}
                                            >
                                                {child.props.primaryButton.name}
                                            </Button>}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            {unwrappedChildes && childes.map((child, index) => (
                <Fragment key={child.id || index}>
                    {contentRenderer(child, index)}
                </Fragment>
            ))}
        </>
    );
};

Tabs.defaultProps = {
    appearance: 'default',
    color: 'primary',
};

Tabs.propTypes = {
    appearance: PropTypes.oneOf(['default', 'glass', 'inline']),
    color: PropTypes.oneOf(['primary', 'accent', 'jaffa', 'buckthron']),
    rightAction: PropTypes.any,
    scrollerRef: PropTypes.func,
    unwrappedChildes: PropTypes.bool,
};

export default Tabs;
