import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import PropTypes from "prop-types";

import CheckAvailability from "../checkAvailability";
import Button from "../button";
import BreadCrumbs from '../breadCrumbs';

import {
    ADMIN,
    CLIENT,
    CLIENT_ADMIN,
    CONTENT_CREATOR, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR,
    LEAGUE_OWNER,
    MODERATOR,
    REPORTER,
    SCOUT_OPERATOR,
    SCOUT_TRADER,
    STREAMER, STREAMING_MANAGER, STREAMING_VIEWER
} from "../../../constants/userRoles";
import {
    EVENT_TYPES,
    FEDERATIONS_TABS,
    FEDERATIONS_TABS_TYPES, PRODUCT_TYPES,
    RIGHT_PANEL_ITEMS,
    UNIQUE_FEDERATION_TABS_TYPES
} from "../../../constants/uiConstants";
import {
    EVENTS_PAGE,
    REPORTING_PAGE,
    LEAGUES_PAGE,
    FEDERATIONS_PAGE,
} from "../../../constants/pageRoutes";
import {
    openPopup,
    openRightPanel,
    selectEventsType, selectFederationsTabType,
} from "../../../actions/sync-actions/uiActions";
import { popupConfig } from "../../../configs/popupConfig";

import usersDataService from "../../../services/usersDataService";
import scoutDataService from '../../../services/scoutDataService';

import ExportButton from "./parts/export";
import WatchListCount from './parts/watchListCount';
import FederationsActionButtons from "./parts/federationsActionButtons";

import Helpers from "../../../helpers/helperFunctions";
import { getProjectStatus, getViewerRestrictedRoutes } from "../../../helpers/uiHelpers";
import { getRoleIds } from "../../../helpers/userHelpers";
import { getBasePath, getSubPath } from '../../../helpers/routeHelper';
import { t } from '../../../helpers/translate';

import './topMenu.scss';

class TopMenu extends Component {
    static propTypes = {
        activeTabMenuItem: PropTypes.string,
        activeRightTabMenuItem: PropTypes.object,
        eventsType: PropTypes.string,
        isRightPanelOpen: PropTypes.bool,
        cartCount: PropTypes.number,
        isDemo: PropTypes.bool,
        isExportDisabled: PropTypes.bool,
        hideRules: PropTypes.bool
    };

    state = {
        scoutCartEventsCount: 0
    };

    componentDidMount() {
        this.subscription = scoutDataService.selectedScoutEventsList.subscribe(val => {
            this.setState({ scoutCartEventsCount: val[EVENT_TYPES.LIST]?.length || 0 });
        });
    }

    componentWillUnmount() {
        this.subscription && this.subscription.unsubscribe();
    }

    openEventsForm = () => {
        this.props.dispatch(openRightPanel({
            rightPanelKey: RIGHT_PANEL_ITEMS.EVENT_FORM,
            title: t('New Event Request'),
            subTitle: t('Create all the matches you want to provide streaming for.')
        }));
    };

    /**
     * @description handle events type change
     * @param type
     */
    handleEventsTypeChange = (type) => (e) => {
        if (type === this.props.eventsType) return;
        this.props.dispatch(selectEventsType(type));
    };

    /**
     * @description Toggle cart.
     */
    toggleCart = (event) => {
        if (event) { //Native call
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        this.props.dispatch(openRightPanel({ rightPanelKey: RIGHT_PANEL_ITEMS.USER_CART }));
    };

    getModeratorTabs = (showSubTabs) => {
        const { location: { pathname }, federationsTabType } = this.props;
        let tabsData = !showSubTabs && pathname.includes(FEDERATIONS_PAGE) ? FEDERATIONS_TABS : [];
        return Object.keys(tabsData).map((key) => (
            <li
                key={key}
                onClick={() => this.props.dispatch(selectFederationsTabType(key))}
                className={`${federationsTabType === key ? "active" : ""}`}
            >
                {t(tabsData[key])}
            </li>
        ));
    };

    render() {
        const { location: { pathname }, eventsType, cartCount, isExportDisabled, hideRules, federationsTabType,
            uniqueFederationTabType } = this.props;
        const { isDemo, isEnabled, freeTrialIsOver } = getProjectStatus();
        const disableBookingHistory = isDemo || !isEnabled || freeTrialIsOver;
        const showSubTabs = !!pathname.match(/\/\d+/);
        const cartEventsCount = getBasePath() === PRODUCT_TYPES.SCOUTING ? this.state.scoutCartEventsCount : cartCount;
        const viewerRestrictedRoutes = getViewerRestrictedRoutes();

        return (
            <ul className="top-menu-holder">
                <li>
                    <CheckAvailability availableRoles={[MODERATOR, ADMIN]}>
                        {showSubTabs && <BreadCrumbs type={getSubPath()}/>}
                    </CheckAvailability>
                    <CheckAvailability availableRoles={[CLIENT, CLIENT_ADMIN, STREAMING_MANAGER]}>
                        {hideRules && (
                            <Button
                                flexibility="content-size"
                                color="confirm"
                                size="medium"
                                icon="icon-plus"
                                appearance="light"
                                iconSize={20}
                                onClick={() => this.props.dispatch(openPopup('ruleCreation', popupConfig.ruleCreation))}
                            >
                                {t('Create AutoBooking Rule')}
                            </Button>
                        )}
                    </CheckAvailability>
                </li>
                <li>
                    <ul className="sub-header-nav">
                        <CheckAvailability restrictedRoles={[CONTENT_CREATOR]} availableRoutes={[EVENTS_PAGE]}>
                            <CheckAvailability
                                restrictedRoles={[STREAMER, REPORTER, STREAMING_VIEWER, SCOUT_TRADER]}
                                restrictedRoutes={viewerRestrictedRoutes}
                            >
                                <li className={`${eventsType === EVENT_TYPES.LIST ? "active" : ""}`}
                                    onClick={this.handleEventsTypeChange(EVENT_TYPES.LIST)}>
                                    {t('List')}
                                </li>
                            </CheckAvailability>
                            <CheckAvailability availableRoles={[LEAGUE_OWNER, MODERATOR, ADMIN, CP_GROUP_MANAGER, CP_ADMINISTRATOR, FEDERATION_GROUP_COORDINATOR]}>
                                <li className={`${eventsType === EVENT_TYPES.UPCOMING ? "active" : ""} ${isDemo ? 'disabled' : ''}`}
                                    onClick={this.handleEventsTypeChange(EVENT_TYPES.UPCOMING)}>
                                    {t('Upcoming')}
                                </li>
                                <li className={`${eventsType === EVENT_TYPES.HISTORY ? "active" : ""} ${isDemo ? 'disabled' : ''}`}
                                    onClick={this.handleEventsTypeChange(EVENT_TYPES.HISTORY)}>
                                    {t('History')}
                                </li>
                            </CheckAvailability>
                            <CheckAvailability
                                availableRoles={[CLIENT, CLIENT_ADMIN, SCOUT_OPERATOR, STREAMING_MANAGER, STREAMING_VIEWER, SCOUT_TRADER]}>
                                <li className={`${eventsType === EVENT_TYPES.BOOKED ? "active" : ""} ${disableBookingHistory ? 'disabled' : ''}`}
                                    onClick={this.handleEventsTypeChange(EVENT_TYPES.BOOKED)}>
                                    {t('Booked')}
                                </li>
                                <CheckAvailability
                                    restrictedRoles={[STREAMING_VIEWER, SCOUT_TRADER]}
                                    restrictedRoutes={viewerRestrictedRoutes}
                                >
                                    <li className={`${eventsType === EVENT_TYPES.HISTORY ? "active" : ""} ${disableBookingHistory ? 'disabled' : ''}`}
                                        onClick={this.handleEventsTypeChange(EVENT_TYPES.HISTORY)}>
                                        {t('History')}
                                    </li>
                                </CheckAvailability>
                            </CheckAvailability>
                            <CheckAvailability availableRoles={[STREAMER]}>
                                <li className={`${eventsType === EVENT_TYPES.UPCOMING ? "active" : ""} ${isDemo ? 'disabled' : ''}`}
                                    onClick={this.handleEventsTypeChange(EVENT_TYPES.UPCOMING)}>
                                    {getRoleIds(usersDataService.profile.getValue().roles).includes(CONTENT_CREATOR) ? t('Upcoming') : t('List')}
                                </li>
                                <li className={`${eventsType === EVENT_TYPES.HISTORY ? "active" : ""} ${isDemo ? 'disabled' : ''}`}
                                    onClick={this.handleEventsTypeChange(EVENT_TYPES.HISTORY)}>
                                    {t('History')}
                                </li>
                            </CheckAvailability>
                            <CheckAvailability availableRoles={[REPORTER]}>
                                <li className="active"
                                    onClick={this.handleEventsTypeChange(EVENT_TYPES.HISTORY)}>
                                    {t('History')}
                                </li>
                            </CheckAvailability>
                        </CheckAvailability>
                        <CheckAvailability availableRoles={[MODERATOR, ADMIN,CP_GROUP_MANAGER, CP_ADMINISTRATOR]}>
                            {this.getModeratorTabs(showSubTabs)}
                        </CheckAvailability>
                        {pathname === REPORTING_PAGE && <li className="active">{t('Video Streaming Dashboard')}</li>}
                        {pathname === LEAGUES_PAGE && <li className="active">{t('All Leagues')}</li>}
                    </ul>
                </li>
                <li>
                    <CheckAvailability
                        availableRoles={[CONTENT_CREATOR, LEAGUE_OWNER, MODERATOR, ADMIN]}
                        availableRoutes={[EVENTS_PAGE]}
                    >
                        <Button
                            onClick={this.openEventsForm}
                            disabled={isDemo}
                            icon="icon-plus"
                            color="confirm"
                            size="medium"
                            flexibility="content-size"
                            iconSize={20}
                            appearance="light"
                        >
                            {t('Add Event')}
                        </Button>
                        <div className="divider"/>
                    </CheckAvailability>
                    <CheckAvailability restrictedRoles={[SCOUT_OPERATOR, SCOUT_TRADER, STREAMING_VIEWER]}>
                        <>
                            {getBasePath() === '/streaming' && !!Helpers.arraysIntersectionMerge([EVENTS_PAGE, FEDERATIONS_TABS_TYPES.REPORTING, UNIQUE_FEDERATION_TABS_TYPES.REPORTING], [getSubPath(), federationsTabType, uniqueFederationTabType]).length && ( //TODO: remove getBasePath() === '/streaming' after scout has export
                                <ExportButton
                                    isDemo={isDemo}
                                    pathname={getSubPath()}
                                    dispatch={this.props.dispatch}
                                    uniqueFederationTabType={uniqueFederationTabType}
                                    federationsTabType={federationsTabType}
                                    eventsType={eventsType}
                                    isExportDisabled={isExportDisabled}
                                />
                            )}
                        </>
                    </CheckAvailability>
                    <CheckAvailability availableRoles={[CLIENT, CLIENT_ADMIN, SCOUT_OPERATOR, STREAMING_MANAGER]}>
                        {pathname !== REPORTING_PAGE && !!cartEventsCount && (
                            <>
                                <div className="divider"/>
                                <Button
                                    tooltip={t('My Cart')}
                                    icon="icon-shopping-bag"
                                    color="accent"
                                    badge={cartEventsCount}
                                    onClick={this.toggleCart}
                                    animation
                                />
                            </>
                        )}
                    </CheckAvailability>
                    <FederationsActionButtons
                        showSubTabs={showSubTabs}
                        federationsTabType={federationsTabType}
                        uniqueFederationTabType={uniqueFederationTabType}
                        pathname={pathname}
                        eventsType={this.props.eventsType}
                        dispatch={this.props.dispatch}
                    />
                    <CheckAvailability availableRoles={[MODERATOR, ADMIN]}>
                        <WatchListCount/>
                    </CheckAvailability>
                </li>
            </ul>
        );
    }
}

const mapStateToProps = (_, ownParams) => {
    return createSelector(
        [
            state => state.ui.activeTabMenuItem && state.ui.activeTabMenuItem[window.location.pathname],
            state => state.ui.activeRightTabMenuItem && state.ui.activeRightTabMenuItem[window.location.pathname],
            state => state.eventData?.selectedIds && state.eventData?.selectedIds[EVENT_TYPES.LIST]?.length,
            state => state.ui.eventsType,
            state => state.ui.operatorTabType,
            state => state.user && state.user.isDemo,
            state => state.ui && state.ui.isExportDisabled,
            state => state.autoBookingRules && state.autoBookingRules.hideRules,
            state => state.ui.federationsTabType,
            state => state.ui.uniqueFederationTabType
        ],
        (activeTabMenuItem, activeRightTabMenuItem, cartCount, eventsType, operatorTabType, isDemo, isExportDisabled, hideRules, federationsTabType, uniqueFederationTabType) => ({
            activeTabMenuItem: activeTabMenuItem,
            activeRightTabMenuItem: activeRightTabMenuItem,
            cartCount,
            eventsType,
            operatorTabType,
            isDemo,
            isExportDisabled,
            hideRules,
            federationsTabType,
            uniqueFederationTabType
        })
    );
};
export default withRouter(connect(mapStateToProps)(TopMenu));
