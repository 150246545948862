import socketIoClient from "socket.io-client";
import {connectionConfigs} from "../configs/socketConfig";
import {digest} from "json-hash";
import Config from "../configs/mainConfig";
import { BehaviorSubject, fromEvent, Subject } from "rxjs";
import {share} from "rxjs/operators";
import { SUBSCRIBE } from '../constants/socket';

class Socket {
    constructor () {
        this.socket = null;
        this._isConnected = new BehaviorSubject(false);
        this._connect = new Subject();
        this._connectors = {};
        this.subscriptions = {};

        const subscription = this._connect.subscribe(() => {
            this.socket = socketIoClient(Config.main.socketServerURL, connectionConfigs);
            this.socket.on('connect', () => {
                this._isConnected.next(true);

                Object.keys(this.subscriptions).forEach((key) => {
                    
                    // TODO REMOVE / 1. FIX FROM BACKEND 2. AFTER FIX, REMOVE << myBalance lines >>
                    if (key === 'myBalance') {
                        this.sendRequest('myBalance', {});
                    } else {
                        this.sendRequest(SUBSCRIBE, this.subscriptions[key]);
                    }
                });
                console.log("websocket is connected");
            });

            this.socket.on('disconnect', () => {
                this._isConnected.next(false);
                console.log("websocket is disconnected");
            });
            subscription.unsubscribe();
        })
    }

    /**
     * @description
     * Initializes connection
     */
    connect () {
        // Destroy current connection if it exists
        this._connect.next(true);
        const isConnected = this._isConnected.getValue();
        return new Promise((resolve, reject) => isConnected ? resolve() : reject());
    }

    /**
     * @description listen event and call callback when responsed
     * @param command
     * @param onUpdate
     * @param subscriptionKey
     */
    onEvent (command, onUpdate, subscriptionKey) {
        this.socket.on(command || subscriptionKey, (res) => {
            onUpdate && onUpdate(res);
            return res;
        });
    }

    getConnector (type, params) {
        if (this._connectors[type]) return this._connectors[type];

        const newConnector = fromEvent(this.socket, type).pipe(share());
        this._connectors[type] = newConnector;
        return newConnector;
    }

    /**
     * @description Send request with request.
     * @param {Object} request request for subscribe
     * @param {String} command request command
     * @returns {Object} promise
     */
    sendRequest (command, request) {

        if (command === SUBSCRIBE && request && Object?.keys(request)?.length) {
            this.subscriptions[digest(request)] = request;
        }

        // TODO REMOVE / 1. FIX FROM BACKEND 2. AFTER FIX, REMOVE << myBalance lines >>
        if (command === 'myBalance') {
            this.subscriptions.myBalance = 'myBalance';
        }

        try {
            if (this._isConnected.getValue()) {
                this.socket.emit(command, request);
            } else {
                this.connect().then(() => {
                    this.sendRequest(command, request);
                }).catch(e => e);
            }
            return Promise.resolve("request successfully sended");
        } catch (reason) {
            console.log('request rejected by reason:', reason);
            return Promise.reject(reason);
        }
    }

    close () {
        this.socket && this.socket.disconnect();
    }

    get connectionStatus () {
        return this._isConnected;
    }

    /**
     * @description  UnSubscribes from request or requests
     * @param {Object | Array} request  unSubscribe request
     * @returns {Promise} promise that will be resolved.
     */
    unsubscribe (request) {
        if (request === undefined) {
            console.warn("unsubscribe got undefined subscription id");
            return Promise.reject();
        }

        const command = 'unsubscribe',
            responses = [],
            successFn = () => this.socket.on(command, (res) => {
                delete this.subscriptions[digest(request)];
                console.log("successfully unsubscribe", res);
            }),
            errorFn = () => console.warn("cannot unsubscribe"),
            handleFn = request => {
                responses.push(this.sendRequest(command, request).then(successFn).catch(errorFn));
            };

        Array.isArray(request) ? request.forEach(request => handleFn(request)) : handleFn(request);

        return Promise.all(responses);
    }
}

export default new Socket();
