import { EVENT_TYPES } from './uiConstants';

// export const STATUSES = [
//     "Not Started",
//     "Started",
//     "Canceled",
//     "Finished"
// ];

// export const WITHDRAW_STATUSES = {
//     1: 'Pending',
//     2: 'Denied',
//     3: 'Approved'
// };

export const MATCH_STATUSES = {
    NOT_STARTED: 0,
    STARTED: 1,
    FINISHED: 2,
    CANCELED: 3
};

export const SCOUT_MATCH_STATUSES = {
    STARTED: 1,
    NOT_STARTED: 2,
    FINISHED: 3,
    CANCELED: 4
};

export const SCOUT_GAMES_STATUSES = {
    STARTED: 2,
    NOT_STARTED: 0,
    FINISHED: 9,
    CANCELED: 11
};

export const projectStatuses = {
    0: 'Demo',
    1: 'Pending',
    2: 'Live'
};

export const APPROVE_STATUSES = {
    0: 'Demo',
    1: 'Pending',
    2: 'Active'
};

// returns match statuses for filters, based on event type
export const matchStatusesByEventType = (eventType) => {
    if (eventType !== EVENT_TYPES.HISTORY) {
        return [
            {
                id: MATCH_STATUSES.NOT_STARTED,
                name: 'Not started'
            },
            {
                id: MATCH_STATUSES.STARTED,
                name: 'Live',
            }
        ];
    }

    return [
        {
            id: MATCH_STATUSES.FINISHED,
            name: 'Finished',
        },
        {
            id: MATCH_STATUSES.CANCELED,
            name: 'Canceled',
        }
    ]
};

export const scoutMatchStatusesByEventType = (eventType) => {
    if (eventType === EVENT_TYPES.LIST || eventType === EVENT_TYPES.BOOKED) {
        return [
            {
                id: SCOUT_MATCH_STATUSES.NOT_STARTED,
                name: 'PreMatch'
            },
            {
                id: SCOUT_MATCH_STATUSES.STARTED,
                name: 'Live',
            }
        ];
    }

    return [
        {
            id: SCOUT_MATCH_STATUSES.FINISHED,
            name: 'Finished',
        },
        {
            id: SCOUT_MATCH_STATUSES.CANCELED,
            name: 'Canceled',
        }
    ]
};
