import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";
import Collapser from 'react-styled-collapser';
import EventVideoPreview from '../../components/events/eventVideoPreview';
import Icon from '../../components/shared/icon';
import NoEvents from "../../components/shared/noData/noEvents";
import { CLIENT, CLIENT_ADMIN, STREAMING_MANAGER } from '../../constants/userRoles';
import bookingDataService from "../../services/bookingDataService";
import {getBookingEvents, setBookingEvents} from "../../actions/async-actions/bookingsAction";
import componentDataProvider from "../../hocs/componentDataProvider";
import paymentsDataService from '../../services/paymentsDataService';
import EventCountryPrices from "./parts/eventCountryPrices";
import Config from "../../configs/mainConfig";
import eventsDataService from "../../services/eventsDataService";
import Helpers from "../../helpers/helperFunctions";
import { closePopup, openPopup, registerToastMessage } from "../../actions/sync-actions/uiActions";
import { popupConfig } from "../../configs/popupConfig";
import {format} from "date-fns";
import {getProjectStatus} from "../../helpers/uiHelpers";
import Button from "../../components/shared/button";
import OverlayContent from "../../components/settings/rightPanel/overlayContent";
import {map} from 'rxjs/operators';
import {isArray} from "rxjs/internal-compatibility";
import {useCookies} from "react-cookie";
import { getEventDataByProduct, getProductsEventsData } from "../../helpers/eventsHelper";
import {EVENT_TYPES} from "../../constants/uiConstants";
import {VIDEO_STREAMING_PRODUCT_ID} from "../../constants/dataConstants";
import { useDispatch, useSelector } from "react-redux";
import { selectEventsType } from '../../helpers/selectors';
import { useHistory } from 'react-router-dom';
import { t } from "../../helpers/translate";

import './cart.scss';

const removeBlockedCountriesFromSelections = (events, prevSelections, activeTab) => {
    return events.reduce((acc, curr) => {
        if (prevSelections[curr.id]) {
            const productData = getEventDataByProduct(curr, activeTab)[0];
            acc[curr.id] = prevSelections[curr.id].filter(country => !productData.blocked_countries.includes(country));
            return acc;
        }
        return acc;
    }, {});
};

const UserCart = (props) => {
    const {
        userProfile,
        selectedEventsData
    } = props;

    const { springBME, consoleBetconstruct, currency } = Config.main;

    const [bookingEvents, updateBookingEvents] = useState({});
    const [selectedCountries, updateSelectedCountries] = useState({});
    const [events, updateEvents] = useState([]);
    const [cookies, setCookies] = useCookies(['cartCountrySelections']);
    const [isLoading, setIsLoading] = useState(false);
    const [eventCountriesInitialSelections, setEventCountriesInitialSelection] = useState(null);
    const [videoPreviewIds, setVideoPreViewIds] = useState({});

    const eventsType = EVENT_TYPES.LIST;
    const activeTab = VIDEO_STREAMING_PRODUCT_ID;
    const dispatch = useDispatch();
    const history = useHistory();
    const { eventsType: actualEventsType } = useSelector(selectEventsType);

    useEffect(() => {
        const subscription = bookingDataService.bookingEvents(activeTab).pipe(
            map(data => {
                if (data) {
                    return {
                        amount: data.amount,
                        items: Helpers.convertArrayToObjectByKey(data.items, "event_id")
                    };
                }
            })
        ).subscribe(updateBookingEvents);
        return () => {
            updateSelectedCountries(val => {
                const values = removeBlockedCountriesFromSelections(events, val, activeTab);
                setCookies('cartCountrySelections', values);
                return values;
            });
            subscription && subscription.unsubscribe();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const payload = getBookedPayload();
        payload.length ? dispatch(getBookingEvents({items: payload}, activeTab)) : updateBookingEvents({});
    }, [selectedCountries]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedEventsData) {
            const {productsEventsData} = getProductsEventsData(selectedEventsData[EVENT_TYPES.LIST], true);
            updateEvents(productsEventsData[VIDEO_STREAMING_PRODUCT_ID]);
        }
    }, [selectedEventsData]);

    useEffect(() => {
        const newSelections = events?.reduce((acc, event) => {
            if (!selectedCountries[event.id]) {
                const preservedSelections = cookies['cartCountrySelections'];
                if (typeof preservedSelections === 'object' && !isArray(preservedSelections) && preservedSelections[event.id]) {
                    return {...acc, [event.id]: preservedSelections[event.id]};
                }
                const {default_activity_countries} = userProfile;
                const productData = getEventDataByProduct(event, activeTab)[0];
                const  boughtCountries = productData?.booking?.bought_countries;
                const {blocked_countries} = productData;
                const initialSelections = default_activity_countries?.filter(val => productData.countries_price[val] && !blocked_countries?.includes(val) && !boughtCountries?.includes(val)) || [];
                return {...acc, [event.id]: initialSelections};
            }
            return {...acc, [event.id]: selectedCountries[event.id]};
        }, {});
        updateSelectedCountries(newSelections)
        //Get new prices for events.
    }, [events]); // eslint-disable-line react-hooks/exhaustive-deps

    const onCountriesChange = (eventCountries) => {
        updateSelectedCountries(val => ({
            ...val,
            ...eventCountries
        }));
    };

    const getBookedPayload = () => {
        const payload = [];
        events && events.forEach(event => {
            const eventId = event.id;
            const productData = getEventDataByProduct(event, activeTab)[0];
            if (selectedCountries[eventId]?.length) {
                payload.push({
                    event_id: eventId,
                    contents: [{
                        id: productData.id,
                        product_id: productData.product_id,
                        countries: selectedCountries[eventId]
                    }]
                });
            }
        });
        setIsLoading(!payload.length);
        return payload;
    };

    const removeCartEvent = (eventId) => () => {
        eventsDataService.toggleSelectedEventsByIds(eventId, eventsType);
    };

    const bookEvents = () => {
        const {organization} = userProfile;
        const {isDemo, freeTrialIsOver, isEnabled} = getProjectStatus();

        if (isDemo || freeTrialIsOver) {
            return dispatch(openPopup("message", {
                ...popupConfig.autoBookingRestrictionForDemo,
                handleCallBack: () => {
                    window.open(`${springBME}/organizations/${organization.partner_id}`, '_blank');
                }
            }));
        }

        if (getProjectStatus().isWalletPayment && paymentsDataService.walletBalance.getValue() < bookingEvents.amount) {
            const userHaveFullPermission = Helpers.checkRole(CLIENT, CLIENT_ADMIN);
            return dispatch(openPopup('message', {
                ...popupConfig.lowBalance,
                ...(userHaveFullPermission ? {
                    primaryButton: {
                        name: "Deposit",
                        color: 'accent',
                    },
                } : {}),
                ...(Helpers.checkRole(STREAMING_MANAGER) ? {
                    message: t('You have not enough funds to continue the current operation'),
                } : {}),
                handleCallBack: () => {
                    dispatch(closePopup())
                    if (userHaveFullPermission) {
                        setTimeout(() => {
                            history.push(`#wallet`);
                            dispatch(openPopup("settings", {
                                closeOnClickOutside: false,
                            }));
                        });
                    }
                }
            }));
        }

        if (!isEnabled) {
            return dispatch(openPopup("message", {
                ...popupConfig.autoBookingRestrictionForDisable,
                handleCallBack: () => {
                    window.open(`${consoleBetconstruct}/products/friendShip/overview`, '_blank');
                }
            }));
        }

        const payload = getBookedPayload();
        payload.length && setIsLoading(true);
        payload.length && dispatch(setBookingEvents({items: payload}, actualEventsType, () => {
            setIsLoading(false);
            setCookies('cartCountrySelections', {});
            updateSelectedCountries({});
            const eventsForRemove = payload.map(val => val.event_id);
            eventsDataService.toggleSelectedEventsByIds(eventsForRemove, eventsType);
        }));
    };

    const getCountriesWithPrices = (productData, countries) => {
        if (productData.default_price) return countries;

        return countries.reduce((acc, curr) => {
            if (productData.countries_price[curr]) {
                return [...acc, curr];
            }
            return acc;
        }, []);
    };

    const setAllCountriesPrices = (countryPrices, currentEventId) => {
        const sameCountries = Object.keys(selectedCountries).every(key => JSON.stringify(selectedCountries[currentEventId].sort()) === JSON.stringify(selectedCountries[key].sort()));
        if (events.length > 1 && !Helpers.isEqual(eventCountriesInitialSelections || {}, countryPrices || {}) && !sameCountries && !Helpers.isEqual(cookies?.cartCountrySelections?.[currentEventId], Object.keys(countryPrices || {}))) {
            dispatch(registerToastMessage({
                title: "Successfully saved",
                message: "Do you want to update all events in your cart?",
                mode: 'persistent',
                applyText: "Apply to all",
                onApply: () => {
                    events.forEach(event => {
                        const productData = getEventDataByProduct(event, activeTab)[0];
                        const notBlockedCountries = selectedCountries[currentEventId].filter(country => !productData.blocked_countries.includes(country));
                        const notBookedCountries = notBlockedCountries.filter(country => !(productData?.booking?.bought_countries || []).includes(country));
                        const countriesWithPrices = getCountriesWithPrices(productData, notBookedCountries);
                        onCountriesChange({[event.id]: countriesWithPrices});
                    });
                }
            }));
        }
        updateSelectedCountries(val => {
            const values = removeBlockedCountriesFromSelections(events, val, activeTab);
            setCookies('cartCountrySelections', values);
            return values;
        });
    };

    const handleEventPreview = (eventId) => {
        if (videoPreviewIds.hasOwnProperty(eventId)) {
            const newIds = Object.keys(videoPreviewIds).reduce((acc, curr) => {
                if (Number(curr) === eventId) return acc;
                return { ...acc, [curr]: videoPreviewIds[curr]};
            }, {});
            setVideoPreViewIds(newIds);
        } else {
            setVideoPreViewIds({
                ...videoPreviewIds,
                [eventId]: !videoPreviewIds[eventId],
            });
        }
    };

    return (
        events?.length ? <OverlayContent
            title={t('Your cart')}
            subTitle={t('Please make sure to select countries for booking')}
            footerTitle={`${currency}${Helpers.amountFormat(Helpers.toFixed(bookingEvents.amount  || 0, 2))}`}
            footerSubTitle={t('Total price')}
            primaryButton={{
                color: 'confirm',
                disabled: isLoading,
                onClick: bookEvents,
                name: t('Book')
            }}
            secondaryButton={{
                name: t('Clear All'),
                onClick: () => {
                    eventsDataService.removeAllSelectedEvents(eventsType)
                },
            }}
        >
            <div className="form-grid">
                {events.map((event) => {
                    const eventId = event.id;
                    const baseKey = `${eventId}/${event.league_id}`;
                    const eventPrices = (bookingEvents.items && bookingEvents.items[eventId]) || {};
                    const {contents = []} = eventPrices;
                    let eventPrice = selectedCountries[eventId]?.length ? eventPrices.price : 0;
                    const productData = getEventDataByProduct(event, activeTab)[0];
                    const fixedAmount = Helpers.amountFormat(Helpers.toFixed(eventPrice || 0, 2));


                    return (
                        <div key={baseKey} className="cart-item-holder">
                            <div className="card-item-content">
                                <div className="cart-item-close-holder">
                                    <Button
                                        icon="icon-clear"
                                        appearance="minimal"
                                        color="peach"
                                        onClick={removeCartEvent(eventId)}
                                    />
                                </div>
                                <ul className="cart-teams">
                                    <li>
                                        <div className="ellipsis-text">{event["team_1_name"]}</div>
                                    </li>
                                    <li>
                                        <small>{t('vs')}</small>
                                    </li>
                                    <li>
                                        <div className="ellipsis-text">{event["team_2_name"]}</div>
                                    </li>
                                </ul>
                                <div className="cart-location-info">
                                    <span>{`${event["region_name"]} - ${event["league_name"]}`}</span>
                                </div>
                                <ul className="cart-price-details-holder">
                                    <li>
                                        <div title={`${currency}${fixedAmount}`} className="cart-price">
                                            {`${currency}${fixedAmount}`}
                                        </div>
                                    </li>
                                    <li>
                                        {productData.countries_price && (
                                            <div className="cart-dropdown-holder">
                                                <EventCountryPrices
                                                    onCountriesChange={onCountriesChange}
                                                    countryPrices={contents[0] && contents[0].countries}
                                                    eventId={eventId}
                                                    onOpen={(initialSelections) => setEventCountriesInitialSelection(initialSelections)}
                                                    onClose={(selections) => {
                                                        setAllCountriesPrices((contents[0] && contents[0].countries) || Object.keys(selections), eventId)
                                                    }}
                                                    productData={productData}
                                                    selectedCountries={selectedCountries[eventId] || []}
                                                />
                                            </div>
                                        )}
                                    </li>
                                    <li>
                                        <div className="cart-details-holder">
                                            <div>
                                                <strong>{format(Helpers.getTimeZoneTime(event["start_date"]), Helpers.getTimeFormat())}</strong>
                                            </div>
                                            <div>
                                                {format(Helpers.getTimeZoneTime(event["start_date"]), "d MMMM yyyy")}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className='live-preview-holder'>
                                    <div
                                        onClick={() => handleEventPreview(event.id)}
                                        className={classnames(
                                            `live-preview-title`,
                                            {
                                                'disabled': productData.on_air_status !== 1,
                                            }
                                        )}
                                    >
                                        <Icon
                                            size={20}
                                            type='icon-play'
                                        />
                                        <p>{t('Live demo preview')}</p>
                                    </div>
                                    <Collapser
                                        duration={150}
                                        collapsed={!(videoPreviewIds.hasOwnProperty(event.id))}
                                    >
                                        {videoPreviewIds.hasOwnProperty(event.id) && (
                                            <EventVideoPreview
                                                event={event}
                                                isPlaying={Object.keys(videoPreviewIds).length === 1}
                                                onClose={handleEventPreview}
                                            />
                                        )}
                                    </Collapser>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </OverlayContent> : <NoEvents/>
    );
};

UserCart.propTypes = {
    userProfile: PropTypes.object,
    selectedEventsData: PropTypes.object,
};

UserCart.requiredProps = {
    userProfile: PropTypes.object,
    selectedEventsData: PropTypes.object,
};

export default componentDataProvider(UserCart);
