import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Input from "../input";

const RevenueSharingFields = ({ onChange, federationShareAmount, ...restProps }) => {
    const [feedConstructShare, setFeedConstructShare] = useState(100 - federationShareAmount);
    const [federationShare, setFederationShare] = useState(federationShareAmount);

    const resetSharePercentages = () => {
        setFeedConstructShare('');
        setFederationShare('');
        onChange(null);
    };

    const setSharePercentage = (key) => (e) => {
        if (e.target.value === '') {
            resetSharePercentages();
            return;
        }

        if (/^\d*$/.test(e.target.value)) {
            const percentage = Number(e.target.value);
            if (percentage > 100) return;

            setFeedConstructShare(key === 'feedconstruct' ? `${percentage}` : `${100 - percentage}`);
            setFederationShare(key === 'federation' ? `${percentage}` : `${100 - percentage}`);
            onChange(key === 'feedconstruct' ? 100 - percentage : percentage);
        }
    };

    return (
        <div className="form-grid col-2">
            <Input
                label='Feedconstruct share'
                required
                type='text'
                placeholder='Enter share'
                value={feedConstructShare}
                onChange={setSharePercentage('feedconstruct')}
                labelAppearance="title"
                {...restProps}
            />
            <Input
                label="Federation share"
                required
                type='text'
                placeholder='Enter share'
                value={federationShare}
                onChange={setSharePercentage('federation')}
                labelAppearance="title"
                {...restProps}
            />
        </div>
    );
};

RevenueSharingFields.propTypes = {
    onChange: PropTypes.func,
    federationShareAmount: PropTypes.number
};

RevenueSharingFields.defaultProps = {
    onChange: () => {}
};

export default RevenueSharingFields;
