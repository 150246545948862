import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isSameDay } from "date-fns";
import { closeRightPanel, registerToastMessage } from '../../../../actions/sync-actions/uiActions';
import { BUSINESS_MODEL_OPTIONS } from '../../../../constants/dataConstants';
import { TOAST_TYPES } from '../../../../constants/uiConstants';
import Helpers from '../../../../helpers/helperFunctions';
import leagueDataService from '../../../../services/leagueDataService';
import operatorsDataService from '../../../../services/operatorsDataService';
import Loader from '../../../reusableUIComponents/loader';
import OverlayContent from '../../../settings/rightPanel/overlayContent';
import Tab from '../../../shared/tab';
import Tabs from '../../../shared/tabs';
import { t } from "../../../../helpers/translate";
import FinancialSectionSetup from './financialSectionSetup';

const OperatorBusinessModelSetup = ({ data }) => {
    const dispatch = useDispatch();
    const [freePackageDuration, setFreePackageDuration] = useState();
    const [initialSportList, setInitialSportList] = useState([]);
    const [sportList, setSportList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFreeChosen, setIsFreeChosen] = useState(false);

    useEffect(() => {
        setLoading(true);
        leagueDataService.getSportsByParams({ without_pagination: 1 })
            .then(val => {
                setInitialSportList(val.data);
                setSportList(val.data);
                setLoading(false);
            })
            .catch(() => {});
    }, []);

    const initialValues = {
        ...(data.business_model === BUSINESS_MODEL_OPTIONS.FREE ? {
            business_model: data.business_model,
            future_business_model: data.future_business_model,
        } : {
            future_business_model: data.business_model,
        }),
        monthly_fee: data.monthly_fee ?? '',
        business_model_update_date: Helpers.toTimeStamp((typeof data.business_model_update_date === 'string' ? data.business_model_update_date.replace(/-/g, "/") : data.business_model_update_date) || new Date()),
        fixed_sports_leagues: data.fixed_sports_leagues?.map(el => ({ ...el, id: `${Date.now()}_${el.sport_id}` })) || [],
        freePackageDuration: 'forever',
    };

    const handleBusinessModelUpdate = (values) => {
        switch (true) {
            case isFreeChosen && values.business_model === BUSINESS_MODEL_OPTIONS.FREE:
                if (freePackageDuration === 'custom' && values.future_business_model === BUSINESS_MODEL_OPTIONS.FIXED) {
                    values = {
                        ...values,
                        fixed_sports_leagues: null,
                    };
                } else if (freePackageDuration === 'forever') {
                    values = {
                        business_model: values.business_model,
                        business_model_update_date: Helpers.toTimeStamp(new Date()),
                        future_business_model: null,
                        fixed_sports_leagues: null,
                    };
                }
                break;
            case values.future_business_model === BUSINESS_MODEL_OPTIONS.PPM:
                values = Helpers.removeKeyFromObject(values, 'monthly_fee', 'business_model');
                values = {
                    ...values,
                    fixed_sports_leagues: null,
                };
                break;
            case values.future_business_model === BUSINESS_MODEL_OPTIONS.FIXED:
                values = Helpers.removeKeyFromObject(values, 'business_model');
                values = {
                    ...values,
                    fixed_sports_leagues: null,
                };
                break;
            case values.future_business_model === BUSINESS_MODEL_OPTIONS.MIXED:
                values = Helpers.removeKeyFromObject(values, 'business_model');
                break;
            default:
                break;
        }

        if (values.fixed_sports_leagues?.length) {
            values = {
                ...values,
                fixed_sports_leagues: values.fixed_sports_leagues.map(({ sport_id, league_ids }) => ({ sport_id, league_ids })),
            };
        }

        values = Helpers.removeKeyFromObject(values, 'freePackageDuration');

        operatorsDataService.updateOperatorById(data.id, values)
            .then(() => {
                if (isSameDay(new Date(values.business_model_update_date * 1000), new Date())) {
                    dispatch(registerToastMessage({
                        message: 'Selected business model will be applied within one hour.',
                        timeout: 8000,
                    }));
                } else {
                    dispatch(registerToastMessage({
                        message: 'Successfully saved',
                    }));
                }
                dispatch(closeRightPanel());
            })
            .catch(() => {
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"}));
            });
    };

    return (
        <>
            <Formik
                onSubmit={() => {}}
                initialValues={initialValues}
                render={({ values, initialValues, setFieldValue, isValid, setTouched, touched, errors }) => (
                    <OverlayContent
                        title={t('Finance and Legal Information')}
                        appearance="fit"
                        primaryButton={{
                            name: t('Save'),
                            disabled: !!Object.keys(errors).length || !isValid,
                            onClick: () => handleBusinessModelUpdate(values),
                            color: 'confirm',
                        }}
                        secondaryButton={{
                            name: t('Cancel'),
                            onClick: () => dispatch(closeRightPanel()),
                        }}
                    >
                        <Tabs appearance='inline'>
                            <Tab title={t('Finance')}>
                                <FinancialSectionSetup
                                    values={values}
                                    initialValues={initialValues}
                                    setFieldValue={setFieldValue}
                                    setFreePackageDuration={setFreePackageDuration}
                                    freePackageDuration={freePackageDuration}
                                    sportList={sportList}
                                    initialSportList={initialSportList}
                                    setSportList={setSportList}
                                    setFreeMode={setIsFreeChosen}
                                    setTouched={setTouched}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Tab>
                            {/*<Tab title={t('Legal Information')} >
                                <div>

                                </div>
                            </Tab>*/}
                        </Tabs>
                        {loading && <Loader />}
                    </OverlayContent>
                )}
            />
        </>
    )
};

export default OperatorBusinessModelSetup;
