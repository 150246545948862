import React from "react";

import { openPopup, openRightPanel } from "../../../../actions/sync-actions/uiActions";
import CheckAvailability from "../../checkAvailability";
import Button from "../../button";

import { ADMIN, CP_ADMINISTRATOR, MODERATOR } from "../../../../constants/userRoles";
import {
    FEDERATIONS_TABS_TYPES,
    RIGHT_PANEL_ITEMS,
    UNIQUE_FEDERATION_TABS_TYPES
} from "../../../../constants/uiConstants";

import { federationsActionInvisibility, getProjectStatus } from '../../../../helpers/uiHelpers';
import { t } from "../../../../helpers/translate";

const federationButtonContent = {
    [FEDERATIONS_TABS_TYPES.PROVIDERS]: "Add New Federation",
    [FEDERATIONS_TABS_TYPES.TEMPLATES]: "Create A New Template",
    [FEDERATIONS_TABS_TYPES.GROUP_FEDERATIONS]: "Add New Group",
    [UNIQUE_FEDERATION_TABS_TYPES.LEAGUES]: "Attach new league",
    [UNIQUE_FEDERATION_TABS_TYPES.USER_MANAGEMENT]: "Create User",
    [UNIQUE_FEDERATION_TABS_TYPES.MATCHES]: "Add Event",
};

const FederationsActionButtons = ({
                                      federationsTabType,
                                      uniqueFederationTabType,
                                      pathname,
                                      showSubTabs,
                                      eventsType,
                                      dispatch
                                  }) => {
    const { isDemo, isPending } = getProjectStatus();

    const assignNewLeague = () => {
        dispatch(openPopup('assignNewLeague', {
            title: t("Attach new league"),
            closeOnClickOutside: false
        }));
    };

    const addEvent = () => {
        dispatch(openRightPanel({ rightPanelKey: RIGHT_PANEL_ITEMS.EVENT_FORM }));
    };

    const openFederationSetup = () => {
        dispatch(openRightPanel({ rightPanelKey: RIGHT_PANEL_ITEMS.FEDERATION_SETUP }));
    };

    const openFederationGroupSetup = () => {
        dispatch(openRightPanel({ rightPanelKey: RIGHT_PANEL_ITEMS.EDIT_FEDERATION_GROUP }));
    };

    const createNewTemplate = () => {
        dispatch(openPopup('templateCreation', {
            'title': t("Template Creation"),
            closeOnClickOutside: false
        }));
    };

    const createUser = () => {
        dispatch(openRightPanel({ rightPanelKey: 'createUserForm', adminsCreation: false }));
    };

    const action = {
        [FEDERATIONS_TABS_TYPES.PROVIDERS]: openFederationSetup,
        [FEDERATIONS_TABS_TYPES.GROUP_FEDERATIONS]: openFederationGroupSetup,
        [FEDERATIONS_TABS_TYPES.TEMPLATES]: createNewTemplate,
        [UNIQUE_FEDERATION_TABS_TYPES.LEAGUES]: assignNewLeague,
        [UNIQUE_FEDERATION_TABS_TYPES.USER_MANAGEMENT]: createUser,
        [UNIQUE_FEDERATION_TABS_TYPES.MATCHES]: addEvent,
    };

    if (federationsActionInvisibility(pathname, eventsType)) return null;

    const type = showSubTabs ? uniqueFederationTabType : federationsTabType;
    const disableButton = (type === UNIQUE_FEDERATION_TABS_TYPES.MATCHES) && (isDemo || isPending);

    return (
        <CheckAvailability availableRoles={[MODERATOR, ADMIN, CP_ADMINISTRATOR]}>
            {federationButtonContent[type] && (
                <Button
                    flexibility="content-size"
                    color="confirm"
                    size="medium"
                    icon="icon-plus"
                    appearance="light"
                    iconSize={20}
                    onClick={action[type]}
                    disabled={disableButton}
                >
                    {t(federationButtonContent[type])}
                </Button>
            )}
        </CheckAvailability>
    );
};

export default FederationsActionButtons;
