export const SERVICE_DATA_TYPES = {
    EVENTS_DATA: 'eventsData',
    SELECTED_EVENTS_DATA: 'selectedEventsData',
    USERS_DATA: 'usersData',
    STREAMERS_LIST: 'streamersList',
    USER_PROFILE: 'userProfile',
    SPORT_LIST: 'sportList',
    SPORT_DATA_LIST: 'sportDataList',
    LEAGUES_LIST: 'leaguesList',
    FILTER_LEAGUES_LIST: 'filterLeaguesList',
    REGIONS_LIST: 'regionsList',
    COUNTRIES_LIST: 'countriesList',
    COUNTRIES_GROUPED_LIST: 'countriesGroupedList',
    TEAMS_LIST: 'teamsList',
    PAYMENTS_DATA: 'paymentsData',
    PAYMENT_HISTORY: 'paymentHistory',
    BANK_INFO: 'bankInfo',
    WITHDRAW_HISTORY: 'withdrawHistory',
    BALANCE_HISTORY: 'balanceHistory',
    BALANCE_TOTALS: 'balanceTotals',
    CURRENT_INVOICE_DATA: 'currentInvoiceData',
    REVENUE_SHARING_MODEL: 'revenueSharingModel',
    CURRENT_INVOICE_EVENTS_DATA: 'currentInvoiceEventsData',
    INVOICE_HISTORY: 'invoiceHistory',
    AUTO_BOOKING_RULES_DATA: 'autoBookingRulesData',
    REPORTING_COUNTRIES_LIST: 'reportingCountriesList',
    REPORTING_SPORT_LIST: 'reportingSportList',
    REPORTING_LEAGUES_LIST: 'reportingLeaguesList',
    REPORTING_DEVICES_LIST: 'reportingDevicesList',
    REPORTING_BROWSERS_LIST: 'reportingBrowsersList',
    REPORTING_EMAILS_LIST: 'reportingEmailsList'
};
