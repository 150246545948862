import React from 'react';
import PropTypes from 'prop-types';

import NoData from '../noData';

import './nestedTable.scss';

const NestedTable = props => {
    const {
        children,
    } = props;

    return (
        <div className="st-sub-table-holder">
            <div className="st-sub-table">
                {children ? children : <NoData />}
            </div>
        </div>
    )
};

NestedTable.propTypes = {
    children: PropTypes.any.isRequired,
};

export default NestedTable;
