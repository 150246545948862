import {LOGGED_IN, LOGIN_FAILED, LOGOUT, SET_USER_INIT_DATA} from "../actions/action-types";

const UserReducer = (state = {loggedIn: false}, action = {}) => {
    switch (action.type) {
        case LOGGED_IN:
            return {
                ...state,
                loggedIn: action.payload
            };
        case LOGIN_FAILED:
            return {
                ...state,
                loggedIn: false
            };
        case LOGOUT:
            return {
                ...state,
                loggedIn: false
            };
        case SET_USER_INIT_DATA:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default UserReducer;