import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {createSelector} from "reselect";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { t } from "../../../helpers/translate";

import Notification from "../notification";

import './toaster.scss';

const Toaster = ({ toast }) => {
    const [toastsList, updateToastsList] = useState([]);
    const ref = useRef();

    const timeout = 8000;

    const addToastMessage = (toast) => {
        updateToastsList(arr => [{...toast, show: true}, ...arr]);
    };

    const hideToastMessage = (id, time = timeout) => {
        setTimeout(() => {
            updateToastsList(arr => arr.map(val => val.id === id ? {...val, show: false} : val));
        }, time);
    };

    const removeToastMessage = (id) => {
        setTimeout(() => {
            updateToastsList(arr => arr.filter(val => val.id !== id));
        }, 1000);
    };

    useEffect(() => {
        toast && addToastMessage({
            ...toast,
            title: t(toast?.title),
            message: t(toast?.message),
            applyText: t(toast?.applyText)
        });
    }, [toast]);

    return !!toastsList.length && ReactDOM.createPortal((
        <ul className="toaster-holder" ref={ref}>
            {toastsList.map((notification) => {
                return (
                    <Notification
                        key={notification.id}
                        data={notification}
                        onMessageHide={hideToastMessage}
                        onClose={removeToastMessage}
                    />
                )
            })}
        </ul>
        ), document.body
    )
};

Toaster.propTypes = {
    toasts: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = () => {
    return createSelector(
        [
            state => state.toast
        ],
        (toast) => ({toast})
    );
};

export default connect(mapStateToProps)(Toaster);
