import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import classnames from "classnames";
import Helpers from '../../../helpers/helperFunctions';
import { selectEventsType } from '../../../helpers/selectors';
import { getRoleIds } from "../../../helpers/userHelpers";
import { hideSubHeader } from '../../../helpers/uiHelpers';
import { getAvailableRoutes, getBasePath, getSubPath } from '../../../helpers/routeHelper';
import { ADMIN, CLIENT, CLIENT_ADMIN, MODERATOR, STREAMING_MANAGER } from "../../../constants/userRoles";
import { EVENT_TYPES, PRODUCT_TYPES } from '../../../constants/uiConstants';
import usersDataService from "../../../services/usersDataService";
import bookingDataService from "../../../services/bookingDataService";
import TopMenu from "../topMenu";
import RulesHorizontalSlide from "../rulesHorizontalSlide";

const SubHeader = () => {
    const { eventsType } = useSelector(selectEventsType);
    const [autoBookingRulesCount, setAutoBookingRulesCount] = useState(0);
    const availableRoutes = getAvailableRoutes(getBasePath());
    const pathname = getSubPath();
    const showTopMenu = pathname && availableRoutes[pathname] && (availableRoutes[pathname].showTopMenu === undefined || availableRoutes[pathname].showTopMenu);
    const userProfile = usersDataService.profile.getValue();
    const showAutoBookingRules = userProfile && userProfile.roles && Helpers.checkRole(CLIENT, CLIENT_ADMIN, STREAMING_MANAGER) && getBasePath() === PRODUCT_TYPES.STREAMING &&
        pathname && availableRoutes[pathname] && (availableRoutes[pathname].showAutobookingRules === undefined || availableRoutes[pathname].showAutobookingRules);
    const moderatorTopMenu = userProfile && userProfile.roles && (getRoleIds(userProfile.roles).includes(MODERATOR) || getRoleIds(userProfile.roles).includes(ADMIN)) && pathname.match(/[^/a-zA-Z]+/g);

    useEffect(() => {
        const subscription = bookingDataService.autoBookingRulesData.subscribe((data) => setAutoBookingRulesCount(data ? data.length : 0));
        return () => subscription.unsubscribe();
    }, []);

    if (hideSubHeader(pathname)) return null;
    return (
        <div className="sub-header-holder">
            <div className="sub-header">
                {(showTopMenu || moderatorTopMenu) && <TopMenu/>}
                {
                    (eventsType === EVENT_TYPES.BOOKED || eventsType === EVENT_TYPES.LIST) &&
                    <div
                        className={classnames('subheader-footer-holder', {
                            'active': autoBookingRulesCount && showAutoBookingRules
                        })}
                    >
                        {showAutoBookingRules && <RulesHorizontalSlide/>}
                    </div>
                }
            </div>
        </div>
    );
};

export default SubHeader;
