import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openPopup, registerToastMessage } from '../actions/sync-actions/uiActions';
import { popupConfig } from '../configs/popupConfig';
import { SUBSCRIBE } from '../constants/socket';
import { MATCH_STATUSES } from '../constants/statuses';
import { EVENT_TYPES, TOAST_TYPES } from '../constants/uiConstants';
import { getContentProvidersData } from '../helpers/eventsHelper';
import eventsDataService from '../services/eventsDataService';
import streamingService from '../services/streamingService';

const useStreamActions = (streamsList, eventData, streamData, setStreamData = () => {}, setStreamInfo = () => {}) => {
    const [newStream, setNewStream] = useState(null);

    const { on_air_status } = getContentProvidersData(eventData);
    const { organization_id: organizationId, id: eventId } = eventData;
    const streamStarted = on_air_status === 1;

    const dispatch = useDispatch();

    const selectStream = (item) => {
        if(!Object.keys(item).length) return;

        // Unsubscribe from prev stream
        streamingService.unsubscribeFromStreamUpdates(streamData.id);
        const newData = streamsList.filter(dataItem => dataItem?.id === item?.id)[0];
        setStreamData(streamsList.filter(dataItem => dataItem?.id === item?.id)[0]);
        setStreamInfo({});
        streamingService.subscribeToStreamUpdates(SUBSCRIBE, newData.id, eventData.id);
    };

    const applyStreamNameChange = (cb) => {
        const { id, organization_id } = eventData;
        streamingService.changeStreamId(id, organization_id, newStream.id)
            .then(() => {
                selectStream(newStream);
                typeof cb === 'function' && cb(newStream);
            })
            .finally(() => setNewStream(null));
    };

    const handleStreamSelect = (item, cb) => {
        const streamStarted = on_air_status === 1;
        typeof cb === 'function' && cb();
        streamStarted ? setNewStream(streamData.name !== item.name ? item : null) : selectStream(item);
    };

    const deleteStream = (ev, streamId, cb) => {
        ev.stopPropagation();
        typeof cb === 'function' && cb();
        const { id, organization_id } = eventData;

        dispatch(openPopup('message', {
            ...popupConfig.deleteStream,
            handleCallBack: () => {
                streamingService.deleteStreamId(id, organization_id, streamId)
                    .then(() => dispatch(registerToastMessage({ message: "Successfully Deleted" })))
                    .catch(() => dispatch(registerToastMessage({
                        type: TOAST_TYPES.ERROR,
                        message: 'Unable to delete the stream'
                    })));
            }
        }));
    };

    const handleEventListUpdate = (removeEvent = false) => {
        eventsDataService.getEventsByIdForUpdate(eventData.id, EVENT_TYPES.UPCOMING, removeEvent);
    }

    const updateEvent = (params, callback) => {
        const { id, organization_id } = eventData;
        eventsDataService.updateEvent(id, organization_id, params)
            .then(() => {
                const removeEvent = params.match_status === MATCH_STATUSES.FINISHED || params.match_status === MATCH_STATUSES.CANCELED;
                handleEventListUpdate(removeEvent);
                removeEvent && callback();
            });
    };

    const changeMatchStatus = (status, callback) => (e) => {
        if ((status === MATCH_STATUSES.FINISHED || status === MATCH_STATUSES.CANCELED) && (on_air_status === 1)) {
            typeof stopStream === 'function' && stopStream();
        }
        updateEvent({ match_status: status }, callback);
    };

    const stopStream = (callback) => {
        const { id: streamId } = streamData;
        const { id: eventId, organization_id: organizationId } = eventData;

        streamingService.endStream(eventId, streamId, organizationId)
            .then(() => {
                handleEventListUpdate()
                typeof callback === 'function' && callback();
            })
            .catch(()=>{});
    };

    const startStream = (streamData) => {
        streamingService.onAirStream(eventId, streamData.id, organizationId)
            .then(() => {
                handleEventListUpdate();
            })
            .catch(() => {});
    };

    return {
        stopStream,
        deleteStream,
        handleStreamSelect,
        applyStreamNameChange,
        changeMatchStatus,
        streamStarted,
        newStream,
        setNewStream,
        startStream,
    };
};

export default useStreamActions;
