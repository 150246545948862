import { getQueryParams, setQueryParams } from "../constants/queryParams";
import { SCOUT_DATA } from '../constants/reducerKeys';

import { SET_FILTERS_DATA, SET_MULTIPLE_FILTERS_DATA } from "../actions/action-types";
import { updateEventsService } from "../actions/async-actions/eventActions";
import { getUsersList } from "../actions/async-actions/userAction";
import { SetFiltersChange, SetLastFilters } from "../actions/sync-actions/filters";
import { getWidgetData } from "../actions/async-actions/reporting";
import { resetScrollOffset } from "../actions/sync-actions/uiActions";
import {
    getWithdrawHistory,
    getBalanceHistory,
    getClientCurrentInvoice,
    getBalanceTotals, getRevenueSharingModelPrediction
} from "../actions/async-actions/paymentsActions";
import Helpers from "../helpers/helperFunctions";

import operatorsDataService from "../services/operatorsDataService";
import federationsDataService from "../services/federationsDataService";
import templatesDataService from "../services/templatesDataService";
import scoutDataService from '../services/scoutDataService';


/**
 * @description Helper function to catch filter key or parent key to process direct data fetch.
 * @param store
 * @param key
 * @param parentKey
 * @param queryParams
 */
const processDataUpdate = (store, key, parentKey, queryParams, reset) => {
    if (queryParams.start && queryParams.end) {
        queryParams = {
            ...queryParams,
            start: Helpers.toTimeStamp(Helpers.convertDateByTimeZone(queryParams.start * 1000)),
            end: Helpers.toTimeStamp(Helpers.convertDateByTimeZone(queryParams.end * 1000))
        };
    }
    switch (parentKey || key) {
        case "events":
            store.dispatch(updateEventsService(queryParams, key, reset));
            break;
        case "users":
        case "moderatorUsers":
            store.dispatch(getUsersList(queryParams));
            break;
        case "reports":
            store.dispatch(getWidgetData(queryParams, parentKey ? parseInt(key) : ""));
            break;
        case "account":
            store.dispatch(getWithdrawHistory({ start: queryParams.start, end: queryParams.end }));
            break;
        case "balanceHistory":
            store.dispatch(getBalanceHistory({ start: queryParams.start, end: queryParams.end }));
            break;
        case "financialReport":
            const params = Object.keys(queryParams).reduce((acc, curr) => {
                return !["order_by", "includes", "order_direction", "offset", "limit"].includes(curr)
                    ? { ...acc, [curr]: queryParams[curr] }
                    : acc;
            }, {});
            store.dispatch(getBalanceHistory(queryParams));
            store.dispatch(getBalanceTotals(params));
            store.dispatch(getRevenueSharingModelPrediction(params));
            break;
        case "clientCurrentInvoice":
            store.dispatch(getClientCurrentInvoice({ start: queryParams.start, end: queryParams.end }));
            break;
        case "operators":
            operatorsDataService.getOperators(queryParams);
            break;
        case "bookedMatches":
            operatorsDataService.getBookedMatches(undefined, queryParams).catch(err => console.log(err));
            break;
        case "federations":
            federationsDataService.getFederationsList(queryParams);
            break;
        case"groupFederations":
            federationsDataService.getFederationsGroup(queryParams);
            break;
        case "templates":
            templatesDataService.getTemplatesList(queryParams);
            break;
        case SCOUT_DATA.EVENTS:
            scoutDataService.getGames(key, queryParams, reset);
            break;
        default:
            break;
    }
};

/**
 * @description Function to get last filters.
 * @param state
 * @param action
 * @returns {{}}
 */
const getLastFilters = (state, action) => {
    const { parentKey, key } = action,
        filters = (parentKey ? state.filters[parentKey] && state.filters[parentKey].subKeys &&
            state.filters[parentKey].subKeys[key] : state.filters[key]) || {};
    if (action.type === SET_FILTERS_DATA) {
        return {
            ...filters,
            [action.meta]: action.payload
        };
    } else {
        return {
            ...filters,
            ...action.payload
        };
    }
};

/**
 * @description Get only parent filters without subKeys or limit offset.
 * @param parentFilters
 * @param withoutOffset
 * @returns {*}
 */
export const getParentParams = (parentFilters, withoutOffset = false) => {
    let parentParams;
    parentFilters && (parentParams = Object.keys(parentFilters).reduce((acc, val) => {
        if (val === "subKeys" || (withoutOffset && (val === "limit" || val === "offset"))) return acc;
        return {
            ...acc,
            [val]: parentFilters[val]
        };
    }, {}));

    return parentParams;
};

/**
 * @description Helper middleware function to catch filters updates and handle params to process direct data fetch.
 * @param store
 * @returns {function(*): Function}
 * @constructor
 */
const FiltersMiddleware = store => next => action => {
    switch (action.type) {
        case SET_MULTIPLE_FILTERS_DATA:
        case SET_FILTERS_DATA: {
            const state = store.getState(),
                { parentKey, key } = action,
                filtersParams = (parentKey ? state.filtersParams[parentKey] &&
                    state.filtersParams[parentKey].subKeys && state.filtersParams[parentKey].subKeys[key]
                    : state.filtersParams[key]) || {},
                filters = getLastFilters(state, action);
            // Get parent filters params without subKeys.
            let parentParams = getParentParams(state.filtersParams[parentKey]),
                queryParams = getQueryParams(filters, filtersParams, parentKey || key, key); //Get queryParams
            if (parentKey && parentParams) {
                queryParams = {
                    ...parentParams,
                    ...queryParams
                };
            }

            if (action.resetOffset) {
                const defaultParams = setQueryParams({}, parentKey || key, key);
                queryParams.offset = defaultParams.offset;
                queryParams.limit = defaultParams.limit;

                filters.offset = defaultParams.offset;
                filters.limit = defaultParams.limit;

                //Dispatch reset scroll.
                next(resetScrollOffset(parentKey || key, true));
            }

            //Direct data fetch.
            processDataUpdate(store, key, parentKey, queryParams, action.resetOffset);

            //Dispatch filters change.
            store.dispatch(SetFiltersChange(key, queryParams, parentKey));
            //Dispatch last filters.
            next(SetLastFilters(key, filters, parentKey, action.resetOffset));
            return;

        }
        default:
    }
    next(action);
};

export default FiltersMiddleware;
