import React, {useState, useEffect, useRef, memo} from 'react';
import Input from "../../shared/input";
import { t } from '../../../helpers/translate';

const InputWithDelay = ({initialValue, onChange, delay = 0, labelAppearance, ...restProps}) => {
    const [inputValue, setInputValue] = useState(initialValue);
    let timer = useRef(0);

    useEffect(() => {
        if (initialValue !== inputValue) {
            setInputValue(initialValue);
        }
    }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInputChange = (e) => {
        const val = e.target.value;
        setInputValue(val);
        if (typeof onChange === 'function') {
            timer.current && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                onChange(val);
                timer.current = 0;
                }, delay);
        }
    };

    return (
        <Input
            value={inputValue}
            onChange={handleInputChange}
            label={t('Search')}
            placeholder={t('Type to search')}
            labelAppearance={labelAppearance || 'title'}
            {...restProps}
            type="search"
        />

    )
};

export default memo(InputWithDelay);
