import { BehaviorSubject } from 'rxjs';
import Config from "../configs/mainConfig";
import usersDataService from './usersDataService';

/*export const defaultOptions = {
    mode: 'cors',
    //baseHost: "http://10.25.13.172/api/"
    //baseHost: Config.main.baseHost
};*/

export class DataFetcher {
    constructor(props) {
        this._data = new BehaviorSubject({});
        this.defaultOptions = {
            mode: 'cors',
            credentials: 'include',
            baseHost: Config.main.baseHost,
            ...(props || {})
        };
    }

    queryParams = (params) => {
        return Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    };

    setupRequestOptions = (options = {}, overrideMethod) => {
        const organization_id = Number(sessionStorage.getItem('organizationId')) || usersDataService.userProfile.getValue()?.organization_id;
        let finalOptions = {
            ...this.defaultOptions,
            ...options,
            headers: {
                ...this.defaultOptions.headers,
                ...(organization_id ? { 'x-organization-id': organization_id } : {}),
                ...options.headers
            },
            method: overrideMethod
        };

        if (finalOptions.params && typeof finalOptions.params === "object") {
            finalOptions.body = options.bodyNotStringify ? finalOptions.params : JSON.stringify(finalOptions.params);
        }
        return finalOptions;
    };

    getOkAndJsonBody = res =>
        res.json()
            .then(data => {
                return { ok: res.ok, body: data, statusCode: res.status };
            }).catch(e => Promise.reject(e));

    checkOk = res => res.ok ? res : Promise.reject(res);
    unwrapBody = res => res.body;

    fetchWithResponse = (url, options, overrideMethod) => {
        options = this.setupRequestOptions(options, overrideMethod);
        return new Promise((resolve, reject) => {
            fetch(options.baseHost + url, options)
                .then(val => {
                        this._data.next(val);
                        resolve(val);
                    }
                )
                .catch(reject);
        });
    };

    fetchData = (url, options = {}, overrideMethod, getPureResponse) => {
        let headers = {
            ...(options.bodyNotStringify ? {} : { "Content-Type": "application/json" }),
            ...(options.headers || {}),
        };

        if (Object.keys(headers).length) {
            options.headers = headers;
        }

        if (options.queryParams) {
            url += (url.indexOf('?') === -1 ? '?' : '&') + this.queryParams(options.queryParams);
            delete options.queryParams;
        }

        const fetchWithResponse = this.fetchWithResponse(url, options, overrideMethod);
        return getPureResponse ? fetchWithResponse : fetchWithResponse
            .then(this.getOkAndJsonBody)
            .then(this.checkOk)
            .then(this.unwrapBody)
            .catch(e => Promise.reject(e));
    };

    getJson = (url, options, getPureResponse) => this.fetchData(url, options, 'GET', getPureResponse);
    postJson = (url, options) => this.fetchData(url, options, 'POST', null);
    putJson = (url, options) => this.fetchData(url, options, 'PUT');
    deleteJson = (url, options) => this.fetchData(url, options, 'DELETE');

    get data() {
        return this._data;
    }
}

export default new DataFetcher();
