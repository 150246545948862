import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Icon from "../icon";

import './menu.scss';
import { buttonConfig } from '../../../configs/uiConfig';

const Menu = props => {
    const { data, onMenuItemClick, onMouseLeave } = props;

    return (
        <ul className="popover-menu" onMouseLeave={onMouseLeave} onClick={e => e.stopPropagation()}>
            {data.map(({ onClick, name, className, type, icon, disabled, color }, index) => (
                <li
                    key={index}
                    onClick={(e) => {
                        e.stopPropagation();
                        onMenuItemClick && onMenuItemClick();
                        onClick(e);
                    }}
                    className={classnames(
                        `c-hover-${color}`,
                        className,
                        {
                            'h-divider': type === 'divider',
                            'popover-menu-item': type !== 'divider',
                            disabled
                        }
                    )}
                >
                    {icon && (
                        <Icon type={icon}/>
                    )}
                    <span className="ellipsis-text">{name}</span>
                </li>
            ))}
        </ul>
    )
};

Menu.propTypes = {
    icons: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func,
            name: PropTypes.string,
            className: PropTypes.string,
            type: PropTypes.oneOf(['item', 'divider']),
            color: PropTypes.oneOf(buttonConfig.color),
            icon: PropTypes.string,
            disabled: PropTypes.bool,
        })
    ).isRequired,
};

export default Menu;
