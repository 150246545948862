import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from "react-router-dom";

import Icon from "../icon";

import { buttonConfig } from "../../../configs/uiConfig";
import { openPopup } from '../../../actions/sync-actions/uiActions';

import './button.scss';

const Button = React.forwardRef((props, ref) => {
    const {
        active,
        animation,
        appearance,
        badge,
        children,
        className,
        color,
        cornerRadius,
        flexibility,
        icon,
        iconSize,
        itemsDirection,
        size,
        tag,
        tooltip,
        tooltipPosition,
        type,
        disabled,
        showConfirmation,
        dispatch,
        onClick,
        ...restProps
    } = props;

    const showConfirmationPopup = () => {
        typeof dispatch === 'function' && dispatch(openPopup('message', {
            ...showConfirmation,
            handleCallBack: onClick
        }));
    };

    const sharedProps = {
        'data-tip': tooltip,
        'data-tip-place': tooltipPosition,
        className: classnames(
            'btn',
            className,
            `s-${size}`,
            `c-${color}`,
            `a-${appearance}`,
            `f-${flexibility}`,
            `cr-${cornerRadius}`,
            `id-${itemsDirection}`,
            {
                animation,
                'active': active,
                'c-icon': !!icon && !children,
                'c-one': children && children.toString().length === 1,
            }
        ),
        type,
        ref,
        ...restProps
    };

    const content = () => (
        <>
            {icon && <Icon type={icon} size={iconSize} />}
            {!!children && (
                <span className="ellipsis-text">{children}</span>
            )}
            {!!badge && <span className="badge-count">{badge}</span>}
        </>
    );

    switch (tag) {
        case 'a':
            return (
                <a
                    rel="noopener noreferrer"
                    {...sharedProps}
                >
                    {content()}
                </a>
            );
        case 'NavLink':
            return (
                <NavLink {...sharedProps}>
                    {content()}
                </NavLink>
            );
        default:
            return (
                <button { ...sharedProps} disabled={disabled} onClick={showConfirmation ? showConfirmationPopup : onClick}>
                    {content()}
                </button>
            )
    }
});

Button.defaultProps = {
    active: false,
    animation: false,
    appearance: buttonConfig.appearance[0],
    color: buttonConfig.color[0],
    cornerRadius: buttonConfig.cornerRadius[0],
    flexibility: buttonConfig.flexibility[0],
    iconSize: Icon.defaultProps.size,
    itemsDirection: buttonConfig.itemsDirection[0],
    size: buttonConfig.size[2],
    tag: buttonConfig.tag[0],
    type: buttonConfig.type[0],
    tooltipPosition: buttonConfig.tooltipPosition[0],
    disabled: false
};

Button.propTypes = {
    showConfirmation: PropTypes.object,
    active: PropTypes.bool,
    animation: PropTypes.bool,
    appearance: PropTypes.oneOf(buttonConfig.appearance),
    badge: PropTypes.number,
    children: PropTypes.any,
    className: PropTypes.string,
    color: PropTypes.oneOf(buttonConfig.color),
    cornerRadius: PropTypes.oneOf(buttonConfig.cornerRadius),
    flexibility: PropTypes.oneOf(buttonConfig.flexibility),
    icon: PropTypes.string,
    iconSize: Icon.propTypes.size,
    itemsDirection: PropTypes.oneOf(buttonConfig.itemsDirection),
    size: PropTypes.oneOf(buttonConfig.size),
    tag: PropTypes.oneOf(buttonConfig.tag),
    type: PropTypes.oneOf(buttonConfig.type),
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    tooltipPosition: PropTypes.oneOf(buttonConfig.tooltipPosition),
};

export default Button;
