import React, { useEffect, useState } from "react";
import ModalContent from '../../shared/modal/parts/content';
import templatesDataService from '../../../services/templatesDataService';
import Tag from '../../shared/tag';
import { t } from "../../../helpers/translate";

const PerLeagueTemplates = ({ popupParams: {leagues} }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const templateIds = leagues
            .filter(val => val.template_id)
            .map(val => val.template_id)
            .join(',');

        templatesDataService.getTemplatesByParams({ids: templateIds})
            .then(res => {
                const val = res.data.map(el => {
                    const league = leagues.find(league => league.template_id === el.id);

                    return {
                        ...league,
                        template_name: el.name,
                    }
                });

                if (!res.data.length) {
                    setData(leagues);
                    return;
                }

                setData(val);
            }).catch(e => e);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <ModalContent>
        <div className={`modal-section`}>
            <div className="ms-head">
                {t("Leagues")}
            </div>
            <div className="ms-body">
                {data.map((item, index) => (
                    <Tag
                        name={`${item.name} ${item.template_name ? `- ${item.template_name}` : ''}`}
                        key={index}
                        clipped
                    />
                ))}
            </div>
        </div>
    </ModalContent>
};

export default PerLeagueTemplates;
