import paymentsDataService from "../../services/paymentsDataService";
import {registerToastMessage} from "../sync-actions/uiActions";
import {dataLoadDone, dataLoadFail, dataLoadStart} from "./index";
import {PAYMENTS} from "../../constants/reducerKeys";
import {TOAST_TYPES} from "../../constants/uiConstants";

export function pay (params, key) {
    return (dispatch) => {
        dispatch(dataLoadStart(key));
        paymentsDataService.pay(params)
            .then(res => {
                dispatch(dataLoadDone(key, res));
                if (res.data && res.data.method && res.data.method.action) {
                    window.location.href = res.data.method.action;
                }
            })
            .catch(err => {
                dispatch(dataLoadFail(key, err));
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Purchase Failed"}));
            });
    };
}

export function getWithdrawHistory (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(PAYMENTS.WITHDRAW));
        paymentsDataService.getWithdrawHistory(params)
            .then(res => {
                dispatch(dataLoadDone(PAYMENTS.WITHDRAW, res));
            })
            .catch(err => {
                dispatch(dataLoadFail(PAYMENTS.WITHDRAW, err));
            });
    };
}

export function getBalanceHistory (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(PAYMENTS.BALANCE_HISTORY));
        paymentsDataService.getBalanceHistory(params)
            .then(res => {
                dispatch(dataLoadDone(PAYMENTS.BALANCE_HISTORY, res));
            })
            .catch(err => {
                dispatch(dataLoadFail(PAYMENTS.BALANCE_HISTORY, err));
            });
    };
}

export function getClientCurrentInvoice (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(PAYMENTS.CLIENT_CURRENT_INVOICE));
        paymentsDataService.getCurrentInvoice(params)
            .then(res => {
                dispatch(dataLoadDone(PAYMENTS.CLIENT_CURRENT_INVOICE, res));
            })
            .catch(err => {
                dispatch(dataLoadFail(PAYMENTS.CLIENT_CURRENT_INVOICE, err));
            });
    };
}

export function getClientCurrentInvoiceEvents (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(PAYMENTS.CLIENT_CURRENT_INVOICE_EVENTS));
        paymentsDataService.getCurrentInvoiceEvents(params)
            .then(res => {
                dispatch(dataLoadDone(PAYMENTS.CLIENT_CURRENT_INVOICE_EVENTS, res));
            })
            .catch(err => {
                dispatch(dataLoadFail(PAYMENTS.CLIENT_CURRENT_INVOICE_EVENTS, err));
            });
    };
}

export function getBalanceTotals (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(PAYMENTS.BALANCE_TOTALS));
        paymentsDataService.getBalanceTotals(params)
            .then(res => {
                dispatch(dataLoadDone(PAYMENTS.BALANCE_TOTALS, res));
            })
            .catch(err => {
                dispatch(dataLoadFail(PAYMENTS.BALANCE_TOTALS, err));
            });
    };
}

export function getRevenueSharingModelPrediction (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(PAYMENTS.BALANCE_TOTALS));
        paymentsDataService.getRevenueSharingModelPrediction(params)
            .then(res => {
                dispatch(dataLoadDone(PAYMENTS.BALANCE_TOTALS, res));
            })
            .catch(err => {
                dispatch(dataLoadFail(PAYMENTS.BALANCE_TOTALS, err));
            });
    };
}
