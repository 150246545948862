import {
    SET_PRESET_DATA,
    CLEAR_PRESET_DATA,
    SET_MULTIPLE_PRESET_DATA,
    SET_APPLIED_PRESET_ID,
    CLEAR_All_PRESET_DATA, RESET_APPLIED_PRESET_IDS
} from "../action-types";

export const SetPresetData = (payload, meta, eventType) => {
    return {
        type: SET_PRESET_DATA,
        payload,
        meta,
        eventType
    };
};

export const SetMultiplePresetData = (payload, key) => {
    return {
        type: SET_MULTIPLE_PRESET_DATA,
        payload,
        key
    };
};

export const ClearPresetData = (key) => {
    return {
        type: CLEAR_PRESET_DATA,
        key
    };
};

export const ClearAllPresetData = (key) => {
    return {
        type: CLEAR_All_PRESET_DATA,
        key
    };
};

export const SetAppliedPresetId = (payload, key) => {
    return {
        type: SET_APPLIED_PRESET_ID,
        payload,
        key
    };
};

export const ResetAppliedPresetId = (payload) => {
    return {
        type: RESET_APPLIED_PRESET_IDS,
        payload
    };
};

