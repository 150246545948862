import React, {Component, createRef} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Input from "../../shared/input";
import Config from "../../../configs/mainConfig";
import Button from "../../shared/button";
import { t } from '../../../helpers/translate';

import './inputWithAccept.scss';

export class InputWithAccept extends Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        callBackFunction: PropTypes.func,
        input: PropTypes.object,
        indexKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        editable: PropTypes.bool,
    };
    constructor (props) {
        super(props);
        this.itemClickHandler = this.itemClickHandler.bind(this);
        this.inputBlurHandler = this.inputBlurHandler.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.acceptBtnClickHandler = this.acceptBtnClickHandler.bind(this);
        this.rejectBtnClickHandler = this.rejectBtnClickHandler.bind(this);
        this.keyUpHandler = this.keyUpHandler.bind(this);
        this.resetState = this.resetState.bind(this);
        this.inputField = createRef();
        this.state = {
            hidden: true,
            initialValue: this.props.value,
            value: (this.props.value && this.props.value),
            dirty: false
        };
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        let newState = (nextProps.value !== prevState.initialValue ? {initialValue: nextProps.value} : {});
        if (nextProps.value !== prevState.initialValue && nextProps.value !== prevState.value) {
            newState = {...newState, value: !isNaN(nextProps.value) && nextProps.value};
        }
        return newState;
    }

    itemClickHandler (e) {
        this.setState({hidden: false}, () => {
            //this.inputField.current.focus();
            if (this.state.value) {
                this.inputField.current.selectionStart = this.inputField.current.selectionEnd = this.state.value.toString().length;
            }
        });
    }
    inputBlurHandler (e) {
        (this.state.value === this.props.value || !this.state.dirty) && this.resetState();
    }
    inputChangeHandler (e) {
        if (!isNaN(e.target.value)) {
            this.setState({value: e.target.value, dirty: true});
        }

    }
    acceptBtnClickHandler (e) {
        e.preventDefault();
        this.props.callBackFunction && this.props.callBackFunction({key: this.props.indexKey, value: this.state.value});
        this.resetState();
    }
    keyUpHandler (e) {
        if (e.which === 13) {
            this.props.callBackFunction && this.props.callBackFunction({key: this.props.indexKey, value: this.state.value});
            this.resetState();

        } else if (e.which === 27) {
            this.resetState();
        }
    }
    resetState () {
        this.setState({hidden: true, dirty: false, value: this.props.value});
    }

    rejectBtnClickHandler (e) {
        e.preventDefault();
        this.resetState();
    }

    render () {
        const {
            value,
            editable = true,
        } = this.props;
        const {
            dirty,
        } = this.state;
        const readOnly = this.state.hidden || !editable;
        const inputValue = readOnly && value && Number(value) ? Config.main.currency + value : dirty || (value === this.state.value && Number(this.state.value)) ? this.state.value : '';

        return (
            <div
                className={classnames('input-with-accept-holder', {
                    'readonly': readOnly,
                })}
            >
                <div className="input-with-accept-input-holder">
                    <Input
                        value={inputValue}
                        ref={this.inputField}
                        onBlur={this.inputBlurHandler}
                        onChange={this.inputChangeHandler}
                        onKeyUp={this.keyUpHandler}
                        onClick={editable ? this.itemClickHandler : undefined}
                        readOnly={readOnly}
                        appearance={readOnly ? 'minimal' : 'outline'}
                        labelAppearance="none"
                        size="small"
                        cornerRadius="smooth"
                        placeholder={t('Set price')}
                    />
                </div>
                <div
                    className={classnames('input-with-accept-button-holder', {
                        'active': !readOnly && this.state.value !== value && dirty,
                    })}
                >
                    <Button
                        onClick={this.acceptBtnClickHandler}
                        disabled={readOnly && this.state.value !== value}
                        icon="icon-check"
                        size="medium"
                        appearance="minimal"
                        color="confirm"
                    />
                </div>
                <div
                    className={classnames('input-with-accept-button-holder', {
                        'active': !readOnly && dirty,
                    })}
                >
                    <Button
                        onClick={this.rejectBtnClickHandler}
                        icon="icon-clear-thick"
                        size="medium"
                        appearance="minimal"
                        color="danger"
                    />
                </div>
            </div>
        );
    }
}
