import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../icon';

import { moduleTitleConfig } from '../../../configs/uiConfig';

import './moduleTitle.scss';

const ModuleTitle = props => {
    const {
        size,
        title,
        position,
        children,
        className,
        description,
        cornerRadius,
        titleIcon,
        ...restProps
    } = props;

    return (
        <ul
            className={classnames(
                `module-header`,
                `cr-${cornerRadius}`,
                `p-${position}`,
                `s-${size}`,
                className,
            )}
            {...restProps}
        >
            <li className="left-content">
                <div className="left-inner">
                    {description ? (
                        <div className="module-description-title">
                            <div className="m-title ellipsis-text">{title}</div>
                            <div className="m-description ellipsis-text">{description}</div>
                        </div>
                    ) : (
                        <div className="module-title ellipsis-text">{title}</div>
                    )}
                    <div className="module-title-decoration"/>
                </div>
                {titleIcon && (
                    <div className="left-inner-icon">
                        <Icon type={titleIcon} />
                    </div>
                )}
            </li>
            <li className="right-content">
                {children}
            </li>
        </ul>
    )
};

ModuleTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf(moduleTitleConfig.size),
  className: PropTypes.string,
  position: PropTypes.oneOf(moduleTitleConfig.position),
  cornerRadius: PropTypes.oneOf(moduleTitleConfig.cornerRadius),
  children: PropTypes.node,
  titleIcon: PropTypes.string,
};

ModuleTitle.defaultProps = {
  size: moduleTitleConfig.size[3],
  position: moduleTitleConfig.position[0],
  cornerRadius: moduleTitleConfig.cornerRadius[0],
};

export default ModuleTitle;
