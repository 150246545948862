import React from "react";
import {Field} from "formik";
import Input from "../../../shared/input";
import DropDown from "../../../shared/dropDown";
import leagueDataService from "../../../../services/leagueDataService";
import DropDownItemWithDescription from "../../../shared/dropDown/pieces/dropDownItemWithDescription";
import federationsDataService from '../../../../services/federationsDataService';
import { t } from "../../../../helpers/translate";

const PartnerInfo = ({ values, errors, setFieldValue, organizationId }) => {
    return (
        <div className="form-grid col-2">
            <Field
                name="name"
                render={({ field: { name } }) => (
                    <Input
                        label={t("Federation Name")}
                        required
                        type="text"
                        placeholder={t("Enter federation name")}
                        value={values[name]}
                        onChange={(e) => {
                            setFieldValue(name, e.target.value);
                        }}
                        labelAppearance="title"
                    />
                )}
            />
            <Field
                name="email"
                render={({ field: { name } }) => {
                    return (
                        <Input
                            label="Email"
                            required
                            type="text"
                            placeholder="Enter email"
                            value={values[name]}
                            errorText={errors[name] && errors[name] !== 'Required' ? errors[name] : ''}
                            onChange={(e) => {
                                setFieldValue(name, e.target.value);
                            }}
                            labelAppearance="title"
                        />
                    );
                }}
            />
            <Field
                name="sport_ids"
                render={({ field: { name } }) => (
                    <DropDown
                        initialRequestParams={values[name].length ? { ids: values[name].join(',') } : null}
                        service={leagueDataService.getSportsByParams}
                        labelAppearance="title"
                        label={t("Sport")}
                        required
                        multiSelect
                        placeholder={t("Select sports")}
                        onChange={sports => {
                            setFieldValue(name, sports.map(val => val.id));
                        }}
                    />
                )}
            />
            <Field
                name="region_ids"
                render={({ field: { name } }) => (
                    <DropDown
                        initialRequestParams={values[name].length ? { ids: values[name].join(',') } : null}
                        service={leagueDataService.getRegionsByParams}
                        labelAppearance="title"
                        label={t("League Region")}
                        required
                        multiSelect
                        placeholder={t("Select region")}
                        onChange={regions => {
                            setFieldValue(name, regions.map(val => val.id));
                        }}
                    />
                )}
            />
            <Field
                name="league_ids"
                render={({ field: { name } }) => (
                    <DropDown
                        key={`${values.sport_ids && values.region_ids}`}
                        disabled={(!values.sport_ids?.length)}
                        requestParams={{
                            ...(values.sport_ids.length ? { sport_ids: values.sport_ids.join(',') } : {}),
                            ...(values.region_ids.length ? { region_ids: values.region_ids.join(',') } : {}),
                            ...{ includes: "sport,region", sportFields: "name,alias", regionFields: "name" },
                            ...(organizationId && { organization_ids: organizationId }),
                        }}
                        initialRequestParams={values.league_ids.length ? {
                            ...(values.league_ids.length && { ids: values.league_ids.join(',') }),
                            ...{ includes: "sport,region", sportFields: "name,alias", regionFields: "name" },
                            ...(organizationId && { organization_ids: organizationId }),
                        } : null}
                        service={federationsDataService.getModeratorLeagues}
                        labelAppearance="title"
                        label={t("Leagues")}
                        multiSelect
                        required
                        iconKey="sport.alias"
                        rowRenderer={(item) => <DropDownItemWithDescription item={item}/>}
                        placeholder={t("Select leagues")}
                        onChange={leagues => {
                            setFieldValue(name, leagues.map(val => val.id));
                        }}
                    />
                )}
            />
            <Field
                name="country_code"
                render={({ field: { name } }) => (
                    <DropDown
                        service={leagueDataService.getCountriesByParams}
                        labelAppearance="title"
                        label="Federation Country"
                        keys={{ key: 'code', value: 'name' }}
                        multiSelect={false}
                        placeholder="Select country"
                        initialRequestParams={values.country_code ? { codes: values.country_code } : null}
                        onChange={country => {
                            setFieldValue(name, country.code);
                        }}
                    />
                )}
            />
            <Field
                name="address"
                render={({ field }) => (
                    <Input
                        type="text"
                        label="Address"
                        placeholder="Enter address"
                        labelAppearance="title"
                        value={values[field.name]}
                        onChange={(e) => {
                            setFieldValue(field.name, e.target.value);
                        }}
                    />
                )}
            />
            <Field
                name="phone"
                render={({ field: { name } }) => (
                    <Input
                        value={values[name]}
                        type="text"
                        onChange={(e) => {
                            if (!e.target.value || /^[0-9 ()+-]+$/g.test(e.target.value)) {
                                setFieldValue(name, e.target.value);
                            }
                        }}
                        placeholder="Enter phone number"
                        labelAppearance="title"
                        label="Phone Number"
                    />
                )}
            />
        </div>
    );
};

export default PartnerInfo;
