import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../../helpers/translate';
import leagueDataService from '../../../../services/leagueDataService';
import DropDownItemWithDescription from '../../../shared/dropDown/pieces/dropDownItemWithDescription';
import DynamicSelect from '../../../shared/dynamicSelect';

const FixedSportsLeagues = (props) => {
    const {
        data,
        name,
        sportList,
        setFieldValue,
        initialSportList,
    } = props;

    const leagueParams = useMemo(() => ({
        includes: "sport,region",
        sportFields: "name,alias",
        regionFields: "name",
    }), []);

    return (
        <DynamicSelect
            data={data}
            className="span-2"
            onDelete={(id) => setFieldValue(name, data.filter(el => el.id !== id))}
            allowDelete
            allowAll
            interactive
            primaryDropdown={{
                placeholder: 'Select sport',
                onChange: (sport, value) => {
                    setFieldValue(name, data.map(el => el.id === value.id ? { ...value, sport_id: (sport.sport_id || sport.id), league_ids: [] } : el));
                },
                externalData: (value) => {
                    const selectedSport = initialSportList.find(sport => sport.id === value.sport_id);
                    return [...(selectedSport ? [selectedSport] : []), ...sportList];
                },
                initialSelections: (value) => initialSportList.find(el => el.id === value.sport_id),
                withLazyLoad: false,
                labelAppearance: 'title',
                label: t('Sport'),
            }}
            secondaryDropdown={{
                key: 'sport_id',
                keyForAllSelect: 'league_ids',
                placeholder: 'Select leagues',
                onChange: (leagues, value) => {
                    setFieldValue(name, data.map(el => el.id === value.id ? { ...value, league_ids: leagues.map(league => league.id) } : el));
                },
                initialRequestParams: (value) => value.league_ids.length ? ({
                    ...leagueParams,
                    ...(value.league_ids.length ? { ids: value.league_ids.join(',') } : {}),
                }) : undefined,
                requestParams: (value) => ({
                    ...leagueParams,
                    ...(value.sport_id ? { sport_ids: value.sport_id } : {}),
                }),
                labelAppearance: 'title',
                requestOnOpen: true,
                label: t('League'),
                service: leagueDataService.getLeaguesByParams,
                multiSelect: true,
                iconKey: 'sport.alias',
                rowRenderer: (item) => <DropDownItemWithDescription item={item} />,
            }}
        />
    );
};

FixedSportsLeagues.defaultProps = {
    data: [],
    name: '',
    sportList: [],
    initialSportList: [],
    setFieldValue: () => {},
};

FixedSportsLeagues.propTypes = {
    data: PropTypes.array,
    name: PropTypes.string,
    sportList: PropTypes.array,
    setFieldValue: PropTypes.func,
    initialSportList: PropTypes.array,
};

export default FixedSportsLeagues;
