import {
    CLEAR_All_PRESET_DATA,
    CLEAR_PRESET_DATA,
    SET_MULTIPLE_PRESET_DATA,
    SET_PRESET_DATA
} from "../actions/action-types";

const getPresetDataState = (data) => {
    // remove keys from data which aren't containing data anymore
    return Object.keys(data).reduce((acc, curr) => {
        if (!Array.isArray(data[curr]) || (Array.isArray(data[curr]) && data[curr].length)) {
            if((typeof data[curr] === "string" && !data[curr].trim().length)) return acc;
            acc[curr] = data[curr];
        }
        return acc;
    }, {});
};

const PresetReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_PRESET_DATA:
            const newDataByType = {
                ...state[action.eventType],
                [action.meta.key]: action.payload
            };

            return {
                ...state,
                [action.eventType]: {
                    ...getPresetDataState(newDataByType)
                }
            };
        case SET_MULTIPLE_PRESET_DATA:
            return {
                ...state,
                [action.key]: action.payload
            };
        case CLEAR_PRESET_DATA:
            return {
                ...state,
                [action.key]: null
            };
        case CLEAR_All_PRESET_DATA:
            return {};
        default:
            return state;
    }
};

export default PresetReducer;
