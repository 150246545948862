import DataFetcher from "./dataFetcherService";
import { STREAMS } from "../constants/api-resources";
import Socket from "../helpers/socket";
import { SUBSCRIBE, UNSUBSCRIBE } from "../constants/socket";
import { BehaviorSubject } from "rxjs";
import Helpers from "../helpers/helperFunctions";
import {
    ADMIN,
    CP_ADMINISTRATOR,
    CP_GROUP_MANAGER,
    FEDERATION_GROUP_COORDINATOR,
    MODERATOR
} from "../constants/userRoles";

const getFetchURL = (mainURL, organizationID) => {
    const isModerator = Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR);
    return isModerator ? `${STREAMS.MODERATOR_STREAMING_PREFIX.URL}/${organizationID}/${mainURL}` : mainURL;
};

class StreamingService {
    constructor() {
        this._streamData = new BehaviorSubject({});
        this._streamConnector = new BehaviorSubject({});
        this._streamEventConnector = new BehaviorSubject({});
    }

    setData(subjectKey, eventId, newData) {
        this[subjectKey].next({ ...this[subjectKey].getValue(), [eventId]: newData });
    }

    stop(eventId) {
        this.setData('_streamData', eventId, null);
        this.setData('_streamConnector', eventId, null);
        this.setData('_streamEventConnector', eventId, null);
    }

    /**
     * @description Connect to socket by command
     * @param command
     * @param streamId
     * @param eventId
     * @returns {*}
     */
    subscribeToStreamUpdates(command = SUBSCRIBE, streamId, eventId) {
        return streamId && Socket.sendRequest(command, {'events': [`stream:${streamId}`]})
            .then(res => {
                if (command !== UNSUBSCRIBE) {
                    Socket.onEvent('streams', (res) => {
                        this.setData('_streamConnector', eventId, res[streamId]);
                    });
                }
            }).catch(e => e);
    }

    unsubscribeFromStreamUpdates(streamId) {
        streamId && Socket.unsubscribe({'events': [`stream:${streamId}`]});
    }

    subscribeToProviderUpdates(command = SUBSCRIBE, providerId, eventId) {
        providerId && Socket.sendRequest(command, {'events': [`content:${providerId}`]})
            .then(() => {
                if (command !== UNSUBSCRIBE) {
                    Socket.onEvent('content', (res) => {
                        this.setData('_streamEventConnector', eventId, res);
                    })
                }
            }).catch(e => e);
    }

    unsubscribeFromProviderUpdates(providerId) {
        providerId && Socket.unsubscribe({'events': [`content:${providerId}`]});
    }

    setupStream(streamId, organizationID, eventId) {
        const request = {order_direction: 'desc', order_by: 'id', is_on_air: 0, without_pagination: 1};
        const url = getFetchURL(STREAMS.SETUP.URL, organizationID);
        return DataFetcher.getJson(url, {queryParams: request})
            .then(res => {
                this.setData('_streamData', eventId, res.data);
                this.subscribeToStreamUpdates(SUBSCRIBE, streamId, eventId);
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    changeStreamId(eventId, organizationID, streamId) {
        const url = getFetchURL(STREAMS.CHANGE_STREAM_ID.URL, organizationID);
        return DataFetcher.postJson(`${url}/${eventId}/change-stream-id`, {params: {stream_id: streamId}});
    }

    deleteStreamId(eventId, organizationID, streamId, callback) {
        const url = getFetchURL(STREAMS.DELETE.URL, organizationID);
        return DataFetcher.deleteJson(`${url}/${streamId}`, {})
            .then(res => {
                const data = this._streamData.getValue()[eventId] || [];
                const newData = data.filter(item => item.id !== streamId);

                this.setData('_streamData', eventId, newData);

                callback && (typeof callback === "function") && callback(res);
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    onAirStream(eventId, streamId, organizationId, callback) {
        const url = getFetchURL(STREAMS.ON_AIR.URL, organizationId);
        return DataFetcher.postJson(`${url}/${eventId}/onair`, {params: {stream_id: streamId}})
            .then(res => {
                callback && (typeof callback === "function") && callback(res);
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    endStream(eventId, streamId, organizationId, callback) {
        const url = getFetchURL(STREAMS.ON_AIR.URL, organizationId);
        return DataFetcher.postJson(`${url}/${eventId}/end`, {params: {stream_id: streamId, only_streaming: 1}})
            .then(res => {
                callback && (typeof callback === "function") && callback(res);
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    generateNewStream(streamId, eventId, organizationId) {
        const url = getFetchURL(STREAMS.CREATE.URL, organizationId);
        return DataFetcher.postJson(url)
            .then(res => {
                this.unsubscribeFromStreamUpdates(streamId);
                const data = this._streamData.getValue()[eventId];
                this.setData('_streamData', eventId, [res.data, ...data]);
                this.subscribeToStreamUpdates(SUBSCRIBE, res.data.id, eventId);
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    getPubLocations(params, serviceParams = {}) {
        return new Promise((resolve, reject) => {
            const url = getFetchURL(STREAMS.PUB_LOCATIONS.URL, serviceParams.organizationId);
            DataFetcher.getJson(url, {queryParams: {...params}})
                .then(res => {
                    if (res && res.data) {
                        const data = res.data.map(val => ({
                            ...val,
                            host: val.options.credentials[0] && val.options.credentials[0].host
                        }));
                        resolve({...res, data});
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    createPubLocation(id, name, eventId, organizationId) {
        // TODO CHECK eventID ARGUMENT
        const url = getFetchURL(STREAMS.CREATE.URL, organizationId);
        return DataFetcher.postJson(url, {params: {pub_location_id: id, stream_name: name}})
            .then(res => {
                const data = this._streamData.getValue()[eventId] || [];
                this.setData('_streamData', eventId, [res.data, ...data]);
            }).catch(err => Promise.reject(err));
    }

    streamReceivingOperators(organizationId, eventID) {
        return new Promise((resolve, reject) => {
            const url = `${STREAMS.MODERATOR_STREAMS.URL}/${organizationId}/events/${eventID}/${STREAMS.STREAM_RECEIVING_OPERATORS.URL}`;

            DataFetcher.getJson(url, {})
                .then(res => resolve(res))
                .catch(err => reject(err));
        });
    }

    streamerInfo(organizationId, eventID) {
        return new Promise((resolve, reject) => {
            const url = `${STREAMS.MODERATOR_STREAMS.URL}/${organizationId}/events/${eventID}/${STREAMS.STREAMER_INFO.URL}`;
            DataFetcher.getJson(url, {})
                .then(res => resolve(res))
                .catch(err => reject(err));
        });
    }

    get streamData() {
        return this._streamData;
    }

    get streamConnector() {
        return this._streamConnector;
    }

    get streamEventConnector() {
        return this._streamEventConnector;
    }

}

export default new StreamingService();
