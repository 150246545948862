import React, {Component} from "react";
import {connect} from "react-redux";
import {getServiceConnectorByName} from "../helpers/serviceHelper";
import {SERVICE_DATA_TYPES} from "../constants/serviceConstants";

function componentDataProvider (WrappedComponent) {
    class appData extends Component {

        constructor (props) {
            super(props);
            this.handleSubscriptions = this.handleSubscriptions.bind(this);
            const requiredKeys = Object.keys(WrappedComponent.requiredProps || {}); // eslint-disable-line react/forbid-foreign-prop-types
            this.state = Object.keys(SERVICE_DATA_TYPES).reduce((acc, key) => ({
                ...acc,
                ...(requiredKeys.includes(SERVICE_DATA_TYPES[key]) ? {[SERVICE_DATA_TYPES[key]]: null} : {})
            }), {});
        }

        componentDidMount () {
            //Subscribe all required services
            Object.keys(this.state).forEach(name => {
                const connector = getServiceConnectorByName(name);
                if (connector) {
                    this.subscription
                        ? this.subscription.add(connector.subscribe(data => this.handleSubscriptions({type: name, data})))
                        : this.subscription = connector.subscribe(data => this.handleSubscriptions({type: name, data}));
                }
            });
        }

        componentWillUnmount () {
            this.subscription && this.subscription.unsubscribe();
        }

        handleSubscriptions (val) {
            // TODO CHANGE
            val.data && this.setState({[val.type]: val.data});
            // if (val.data && !Helpers.isEqual(this.state[val.type], val.data)) {
            //     this.setState({[val.type]: val.data});
            // }
        }

        render () {
            const newProps = this.state;
            if (Object.keys(newProps).length && Object.keys(newProps).some(key => newProps[key] === null || newProps[key] === undefined)) {
                return null;
            }
            return <WrappedComponent {...this.props} {...newProps} />;
        }
    }

    return connect()(appData);
}

export default componentDataProvider;
