import React, { useState } from 'react';
import ModalContent from './content';
import DropDown from '../../dropDown';
import usersDataService from '../../../../services/usersDataService';
import { updateUser } from '../../../../actions/async-actions/userAction';
import { t } from "../../../../helpers/translate";

const ChangeStreamer = ({ closePopup, popupParams: {streamerId}, dispatch }) => {
    const [id, setId] = useState(streamerId);

    return <ModalContent
        primaryButton={{
            name: t('Apply'),
            disabled: streamerId === id,
            onClick: () => {
                dispatch(updateUser(streamerId, {active: 0, switch_to_user_id: id}, false, closePopup))
            }
        }}
        secondaryButton={{
            name: t('Discard'),
            onClick: closePopup,
        }}
    >
        <DropDown
            labelAppearance="none"
            placeholder={t('Choose Streamer')}
            service={usersDataService.getStreamersByParams}
            keys={{key: "id", value: "first_name,last_name"}}
            onChange={(streamer) => setId(streamer.id)}
            initialRequestParams={streamerId ? {ids: streamerId} : null}
            multiSelect={false}
        />
    </ModalContent>
};

export default ChangeStreamer;
