import {
    APP_DATA_UPDATE_DONE,
    APP_DATA_UPDATE_FAIL,
    APP_DATA_UPDATE_START,
    LOGOUT,
    RESET_INITIAL_STORE,
    SET_USER_INIT_DATA,
    DATA_LOAD_START,
    DATA_LOAD_DONE,
    DATA_LOAD_FAIL
    // GET_SERVICE_DATA
} from "../actions/action-types";
import { CLIENT, CLIENT_ADMIN, STREAMING_MANAGER } from "../constants/userRoles";
import {stopLeagueService} from "../actions/async-actions/leagueActions";
import {initEventsService, stopEventsService} from "../actions/async-actions/eventActions";
import {stopUsersService} from "../actions/async-actions/userAction";
import {FILTER_LEAGUES, LEAGUES, EVENTS, PRICES, USERS, COUNTRIES} from "../constants/reducerKeys";
import Helpers from "../helpers/helperFunctions";
import { getBasePathForInitialLoad } from '../helpers/routeHelper';
import {getDefaultEventType} from "../helpers/uiHelpers";
import {getAutoBookingRules, stopAutobooking} from "../actions/async-actions/bookingsAction";
import {checkCartEventsExistence, initSelectionsDataWatcher} from "../actions/sync-actions/cartData";

const ServiceController = store => next => action => {
    let currentState = store.getState();
    const serviceKeys = [EVENTS, FILTER_LEAGUES, COUNTRIES, LEAGUES, PRICES, ...Object.keys(USERS)];

    switch (action.type) {
        case SET_USER_INIT_DATA:
            if (action.payload.roles.length) {
                next(initEventsService(action.payload.roles, getDefaultEventType(action.payload.roles, getBasePathForInitialLoad(action.payload.roles))));
                Helpers.checkRole(CLIENT, CLIENT_ADMIN, STREAMING_MANAGER) && next(getAutoBookingRules());
                Helpers.checkRole(CLIENT, CLIENT_ADMIN, STREAMING_MANAGER) && next(checkCartEventsExistence());
                next(initSelectionsDataWatcher());
            }
            break;
        case LOGOUT: {
            if (currentState.user.loggedIn && !action.payload) {
                next(stopEventsService());
                next(stopLeagueService());
                next(stopUsersService());
                next(stopAutobooking());
                next({
                    type: RESET_INITIAL_STORE
                });
            }
            break;
        }
        case DATA_LOAD_START && action.reducerKey in serviceKeys:
            next({ type: APP_DATA_UPDATE_START });
            break;
        case DATA_LOAD_DONE && action.reducerKey in serviceKeys:
            next({ type: APP_DATA_UPDATE_DONE });
            break;
        case DATA_LOAD_FAIL && action.reducerKey in serviceKeys:
            next({
                type: APP_DATA_UPDATE_FAIL,
                payload: action.payload
            });
            break;
        default:
            break;
    }
    next(action);
};

export default ServiceController;
