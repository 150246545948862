import React, {forwardRef, useEffect, useState} from 'react';
import DropDownItem from "./dropDownItem";
import PropTypes from 'prop-types';
import VirtualList from "../../../reusableUIComponents/virtualList";
import {DROP_DOWN_CONFIG} from "../../../../configs/uiConfig";
import {CellMeasurer, CellMeasurerCache} from "react-virtualized";

const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 46
});

const DropDownContent = forwardRef((props, fwdRef) => {
    const {
        data,
        rowCount,
        suggestionMode,
        searchKeyword,
        multiSelect,
        onSelectAll,
        keys,
        selections,
        onSelectionChange,
        onLoadMore,
        withLazyLoad,
        allowSelectAll,
        allowDeselect,
        iconKey,
        rowRenderer,
        rowHasAction,
    } = props;

    const [initialSelectionIds, updateInitialSelectionIds] = useState((multiSelect && selections && Object.keys(selections)) || []);
    const [allSelected, updateAllSelected] = useState(false);
    const [filteredData, updateFilteredData] = useState([]);

    useEffect(() => {
        if (multiSelect) {
            const newSelectionIds = Object.keys(selections);
            if (newSelectionIds.length < initialSelectionIds.length) {
                updateInitialSelectionIds(newSelectionIds);
            }
            if (allowSelectAll) {
                if (withLazyLoad) {
                    updateAllSelected(!!selections['all']);
                } else {
                    updateAllSelected(newSelectionIds.length === data.length);
                }
            }
        }
    }, [selections, multiSelect]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!withLazyLoad && searchKeyword) {
            updateFilteredData(data.filter(val => val[keys.value].toString()?.toLowerCase()?.includes(searchKeyword?.toLowerCase()) && !initialSelectionIds.includes(val[keys.key].toString())));
        } else {
            updateFilteredData(data.filter(val => !(initialSelectionIds.includes(val[keys.key].toString()) || (searchKeyword && val[keys.key] === -1))));
        }
    }, [withLazyLoad, searchKeyword, data, initialSelectionIds]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {(suggestionMode && searchKeyword) && (
                <DropDownItem
                    text={searchKeyword}
                    multiSelect={multiSelect}
                    suggestion={true}
                    onSelect={onSelectionChange}
                />
            )}
            {multiSelect && (
                <>
                    {(!searchKeyword && allowSelectAll) && (
                        <DropDownItem
                            text={allSelected? 'Unselect All' : 'Select All'}
                            className="sticky"
                            multiSelect
                            active={allSelected}
                            onSelect={onSelectAll}
                        />
                    )}
                    {initialSelectionIds.map(itemKey => {
                        const item = !searchKeyword || selections[itemKey]?.[keys.value]?.toLowerCase()?.includes(searchKeyword?.toLowerCase()) ? selections?.[itemKey] : null;
                        return !!item && (
                            <DropDownItem
                                key={item[keys.key]}
                                itemKey={keys.key}
                                item={item}
                                icon={iconKey && item ? `si-${iconKey.split('.').reduce((acc, key) => (acc[key] || {}), item).toLowerCase()}` : null}
                                text={item ? keys.value.split(',').map(val => item[val]).join(' ') : ''}
                                multiSelect={multiSelect}
                                active={Boolean(multiSelect ? (allSelected || selections[item[keys.key]]) : item[keys.key] === selections[keys.key])}
                                onSelect={onSelectionChange}
                                rowRenderer={rowRenderer}
                            />
                        )
                    })}
                    {!!initialSelectionIds.length && (
                        <div className="divider" />
                    )}
                </>
            )}
            <VirtualList
                data={filteredData || []}
                ref={fwdRef}
                onloadMore={onLoadMore}
                withLazyLoad={withLazyLoad}
                rowCount={(rowCount && rowCount <= Object.keys(data).length ? filteredData.length : rowCount) ?? DROP_DOWN_CONFIG.loadLimit}
                cache={cache}
            >
                {({data, index, style, key, parent, ...props}) => {
                    const item = data[index];
                    return (
                        <CellMeasurer
                            key={key}
                            cache={cache}
                            parent={parent}
                            columnIndex={0}
                            rowIndex={index}
                        >
                            <DropDownItem
                                itemKey={keys.key}
                                item={item}
                                icon={iconKey && item ? `si-${iconKey.split('.').reduce((acc, key) => (acc[key] || ''), item).toLowerCase()}` : null}
                                text={item ? keys.value.split(',').map(val => item[val]).join(' ') : ''}
                                multiSelect={multiSelect}
                                allowDeselect={allowDeselect}
                                active={Boolean(item && (multiSelect ? (allSelected || selections[item[keys.key]]) : item[keys.key] === selections[keys.key]))}
                                onSelect={onSelectionChange}
                                skeleton={!item}
                                className="virtualized"
                                style={style}
                                rowHasAction={rowHasAction}
                                rowRenderer={rowRenderer}
                                {...props}
                            />
                        </CellMeasurer>
                    )
                }}
            </VirtualList>
        </>
    )
});

DropDownContent.propTypes = {
    data: PropTypes.array,
    suggestionMode: PropTypes.bool,
    searchKeyword: PropTypes.string,
    multiSelect: PropTypes.bool,
    onSelectAll: PropTypes.func,
    keys: PropTypes.object,
    selections: PropTypes.object,
    onSelectionChange: PropTypes.func,
    withLazyLoad: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    allowDeselect: PropTypes.bool,
    iconKey: PropTypes.string,
    rowRenderer: PropTypes.func,
    rowHasAction: PropTypes.bool,
};

export default DropDownContent;
