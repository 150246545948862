import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import {
    CLIENT,
    CLIENT_ADMIN,
    SCOUT_OPERATOR,
    SCOUT_TRADER,
    STREAMING_MANAGER,
    STREAMING_VIEWER
} from '../../../../../constants/userRoles';
import Helpers from '../../../../../helpers/helperFunctions';
import CheckAvailability from '../../../../shared/checkAvailability';
import Switch from '../../../../shared/switcher';
import Title from '../../../../shared/title';
import { t } from '../../../../../helpers/translate';

const ClientRolesTab = ({ setSwitchState, setFieldValue, values }) => {
    return (
        <div className="form-grid">
            <CheckAvailability availableRoles={[CLIENT]}>
                <ul className='form-grid'>
                    <li>
                        <Title label={t('General')} />
                    </li>
                    <li>
                        <Field
                            name="roles"
                            render={({field}) => (
                                <Switch
                                    checked={!!Helpers.existValueInArray(field.value, 'id', CLIENT_ADMIN)}
                                    onChange={() => setSwitchState({id: CLIENT_ADMIN}, values, setFieldValue)}
                                    label={t('Client Admin')}
                                    description={t('This role has full accesses.')}
                                />
                            )}
                        />
                    </li>
                </ul>
            </CheckAvailability>
            <ul className='form-grid col-2'>
                <li className='span-2'>
                    <Title label={t('Streaming')} />
                </li>
                <li>
                    <Field
                        name="roles"
                        render={({field}) => (
                            <Switch
                                checked={!!Helpers.existValueInArray(field.value, 'id', STREAMING_MANAGER)}
                                onChange={() => setSwitchState({id: STREAMING_MANAGER}, values, setFieldValue)}
                                label={t('Streaming Manager')}
                                description={t('This role has accesses to manage events.')}
                            />
                        )}
                    />
                </li>
                <li>
                    <Field
                        name="roles"
                        render={({field}) => (
                            <Switch
                                checked={!!Helpers.existValueInArray(field.value, 'id', STREAMING_VIEWER)}
                                onChange={() => setSwitchState({id: STREAMING_VIEWER}, values, setFieldValue)}
                                label={t('Streaming Viewer')}
                                description={t('This role can only view content.')}
                            />
                        )}
                    />
                </li>
            </ul>
            {process.env.REACT_APP_MODE !== 'production' && (
                <ul className='form-grid col-2'>
                    <li className='span-2'>
                        <Title label={t('Scouting')} />
                    </li>
                    <li>
                        <Field
                            name="roles"
                            render={({field}) => (
                                <Switch
                                    checked={!!Helpers.existValueInArray(field.value, 'id', SCOUT_OPERATOR)}
                                    onChange={() => setSwitchState({id: SCOUT_OPERATOR}, values, setFieldValue)}
                                    label={t('Scouting Manager')}
                                    description={t('This role has accesses to manage matches.')}
                                />
                            )}
                        />
                    </li>
                    <li>
                        <Field
                            name="roles"
                            render={({field}) => (
                                <Switch
                                    checked={!!Helpers.existValueInArray(field.value, 'id', SCOUT_TRADER)}
                                    onChange={() => setSwitchState({id: SCOUT_TRADER}, values, setFieldValue)}
                                    label={t('Scouting Viewer')}
                                    description={t('This role can only view the matches.')}
                                />
                            )}
                        />
                    </li>
                </ul>
            )}
        </div>
    );
};

ClientRolesTab.propTypes = {
    setSwitchState: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
};

export default ClientRolesTab;
