import React, {useCallback, useMemo, useState} from "react";
import {setHours, setMinutes, setSeconds, startOfDay} from "date-fns";
import Helpers from "../../../helpers/helperFunctions";
import PropTypes from "prop-types";
import classnames from 'classnames';
import DatePickerInput from "../../shared/datePickerInput";
import TimePicker from "../../shared/timePicker";
import Input from "../../shared/input";
import Collapser from "react-styled-collapser";

const convertTimeToDate = (time, date) => {
    const times = time.split(' : ');
    return setSeconds(setMinutes(setHours(date, times[0]), times[1]), 0);
};

const DateTimePicker = props => {
    const {
        appearance,
        className,
        cornerRadius,
        form,
        initialDate,
        itemsDirection,
        labelAppearance,
        onChange,
        required,
        size,
        timeZone
    } = props;

    const [date, setDate] = useState(initialDate || Helpers.getTimeZoneTime(parseInt(Date.now() / 1000), timeZone));
    const [time, setTime] = useState(Helpers.getTime(date.getHours(), date.getMinutes()));
    const minDate = useMemo(() => startOfDay(Helpers.getTimeZoneTime(null, form.values.timeZone)), [form.values.timeZone]);

    const handleDateChange = useCallback(changedDate => {
        onChange(parseInt(Helpers.convertDateByTimeZone(convertTimeToDate(time, changedDate), form.values.timeZone) / 1000));
        setDate(changedDate);
    }, [form.values.timeZone, onChange, time]);

    const sharedProps = {
        appearance,
        cornerRadius,
        itemsDirection,
        labelAppearance,
        required,
        size,
    };

    return (
        <Collapser
            collapsed={!form.values.timeZone}
            className={classnames('full-width', className)}
        >
            <div className="form-grid col-2">
                <DatePickerInput
                    type="calendar"
                    date={date}
                    minDate={minDate}
                    onChange={handleDateChange}
                    disabled={!form.values.timeZone}
                    {...sharedProps}
                />
                <TimePicker
                    value={time}
                    onChange={(selectedTime) => {
                        onChange(parseInt(Helpers.convertDateByTimeZone(convertTimeToDate(selectedTime, date), form.values.timeZone) / 1000));
                        setTime(selectedTime);
                        setDate(convertTimeToDate(selectedTime, date));
                    }}
                    disabled={!form.values.timeZone}
                    {...sharedProps}
                />
            </div>
        </Collapser>
    );
};

DateTimePicker.defaultProps = {
    appearance: Input.defaultProps.appearance,
    className: Input.defaultProps.className,
    cornerRadius: Input.defaultProps.cornerRadius,
    form: null,
    itemsDirection: Input.defaultProps.itemsDirection,
    labelAppearance: Input.defaultProps.labelAppearance,
    onChange: null,
    required: Input.defaultProps.required,
    size: Input.defaultProps.size,
};

DateTimePicker.propTypes = {
    appearance: Input.propTypes.appearance,
    className: Input.propTypes.className,
    cornerRadius: Input.propTypes.cornerRadius,
    form: PropTypes.object,
    itemsDirection: Input.propTypes.itemsDirection,
    labelAppearance: Input.propTypes.labelAppearance,
    onChange: PropTypes.func,
    required: Input.propTypes.required,
    size: Input.propTypes.size,
};


export default DateTimePicker;
