import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import PropTypes from "prop-types";

import OperatorBusinessModelSetup from '../../operators/operatorAccountInfo/parts/operatorBusinessModelSetup';
import OperatorSetup from '../../operators/operatorSetup';

import FederationsGroupForm from '../../federations/federationsGroupForm';
import FederationSetup from "../../federations/federationSetup";
import BusinessModelSetup from '../../federations/businessModelSetup';

import EventForm from "../formTemplates/eventForm";
import UserForm from "../formTemplates/userForm";
import PartnerRequestForm from "../formTemplates/partnerRequestForm";
import LeagueForm from '../formTemplates/leagueForm';

import {closeRightPanel} from "../../../actions/sync-actions/uiActions";
import { PRODUCT_TYPES, RIGHT_PANEL_ITEMS } from "../../../constants/uiConstants";
import CountriesTree from "../../shared/countriesTree";
import { getBasePath } from '../../../helpers/routeHelper';

import UserCart from "../../../containers/userCart";
import ScoutUserCart from '../../../containers/scoutUserCart';

import './overlay.scss';

const RightPanel = () => {
    const ref = useRef();

    const dispatch = useDispatch();
    const { options = {} } = useSelector(createSelector(
        [state => state.ui?.rightPanel],
        (options) => ({options})
    ));

    const {rightPanelKey, isOpen, ...restProps} = options;

    useEffect(() => {
        const handleEvent = (e) => {
            const root = document.getElementById('root');
            if (ref.current && !ref.current.contains(e.target) && root.contains(e.target)) {
                dispatch(closeRightPanel());
            }
        };
        document.addEventListener('mousedown', handleEvent);
        return () => document.removeEventListener('mousedown', handleEvent);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    if (!isOpen) return null;

    return (
        ReactDOM.createPortal((
            <div className="overlay-c-holder" ref={ref}>
                <div className={`modal-backdrop animation-fadeIn t-${rightPanelKey.toLowerCase()}`} onClick={() => (rightPanelKey === RIGHT_PANEL_ITEMS.USER_CART || rightPanelKey === RIGHT_PANEL_ITEMS.COUNTRIES_TREE ) && dispatch(closeRightPanel())} />
                <div className={`overlay-holder transformReveal t-${rightPanelKey.toLowerCase()}`}>
                    {(() => {
                        switch (rightPanelKey) {
                            case RIGHT_PANEL_ITEMS.CREATE_USER_FORM:
                            case RIGHT_PANEL_ITEMS.EDIT_USER_FORM:
                                return <UserForm key={rightPanelKey} type={rightPanelKey} {...restProps} />;
                            case RIGHT_PANEL_ITEMS.EVENT_FORM:
                                return <EventForm />;
                            case RIGHT_PANEL_ITEMS.CREATE_LEAGUE_FORM:
                                return <LeagueForm />;
                            case RIGHT_PANEL_ITEMS.PARTNER_REQUEST_FORM:
                                return <PartnerRequestForm />;
                            case RIGHT_PANEL_ITEMS.USER_CART:
                                return getBasePath() === PRODUCT_TYPES.STREAMING ? <UserCart /> : <ScoutUserCart/>;
                            case RIGHT_PANEL_ITEMS.COUNTRIES_TREE:
                                return <CountriesTree {...restProps} />;
                            case RIGHT_PANEL_ITEMS.FEDERATION_SETUP:
                                return <FederationSetup dispatch={dispatch} {...restProps} />;
                            case RIGHT_PANEL_ITEMS.BUSINESS_MODEL_SETUP:
                                return <BusinessModelSetup dispatch={dispatch} {...restProps} />;
                            case RIGHT_PANEL_ITEMS.OPERATOR_SETUP:
                                return <OperatorSetup {...restProps} />;
                            case RIGHT_PANEL_ITEMS.OPERATOR_BUSINESS_MODEL_SETUP:
                                return <OperatorBusinessModelSetup {...restProps} />;
                            case RIGHT_PANEL_ITEMS.EDIT_FEDERATION_GROUP:
                                return <FederationsGroupForm dispatch={dispatch} {...restProps} />
                            default:
                                return null;
                        }
                    })()}
                </div>
            </div>
        ), document.body)
    );
};

RightPanel.propTypes = {
    options: PropTypes.object,
};

export default RightPanel;
