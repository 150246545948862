import React from 'react';
import Tag from '../components/shared/tag';
import Helpers from "./helperFunctions";
import {
    ODDS_FEED_PRODUCT_ID,
    SCOUTING_PRODUCT_ID,
    STATISTICS_PRODUCT_ID,
    VIDEO_STREAMING_PRODUCT_ID
} from "../constants/dataConstants";
import usersDataService from "../services/usersDataService";
import { IFRAME_URLS, STREAMS } from "../constants/api-resources";
import Config from "../configs/mainConfig";
import { EVENT_TYPES } from '../constants/uiConstants';
import { ADMIN, MODERATOR } from '../constants/userRoles';
import { t } from "./translate";

/**
 * @description Get event content data
 * @param event
 * @return {Array}
 */
export const getContentProvidersData = (event) => {
    const contentProviders = Helpers.convertArrayToObjectByKey(event.content_providers, "product_id");
    return contentProviders[VIDEO_STREAMING_PRODUCT_ID] || (event.content_providers && event.content_providers.length && event.content_providers[0]) || { event_id: event.id };
};

/**
 * @name getDataFromEventByKey
 * @param {object} event
 * @param {object} leagueData
 * @param {string} key
 * @description The function return data from event or league by key
 * @return {any}
 * */
export const getDataFromEventByKey = (event, leagueData, key) => {
    return getContentProvidersData(event)[key] || leagueData[key];
};

/**
 * @name getSelectedCountries
 * @param {array} blockedCountries
 * @param {array} countriesList
 * @description get blocked countries from countriesList
 * @return {array}
 */
export const getBlockedCountries = (blockedCountries, countriesList) => {
    if (!(blockedCountries && countriesList)) return [];
    return countriesList.filter(country => blockedCountries.includes(country.iso3_code)); //Filtering countries from countries list by blocked countries iso3 code
};

/**
 * @description Get country price from provider object.
 * @param provider
 * @returns {*}
 */
export function getCountryPrice(provider) {
    let { countries_price: countriesPrices, blocked_countries: blockedCountries } = provider;
    let countriesCodes = countriesPrices && Object.keys(countriesPrices);

    if (countriesCodes && blockedCountries && blockedCountries.length) { //Remove blockedCountries from countries_price
        countriesCodes = countriesCodes.filter(countryCode => countriesPrices[countryCode] && !blockedCountries.includes(countryCode));
    }
    return countriesCodes;
}

/**
 * @description Check if product already booked.
 * @param productId
 * @param provider
 * @returns {boolean}
 */
export const productIsBooked = (productId, provider) => {
    if (productId !== VIDEO_STREAMING_PRODUCT_ID && provider.booking) {
        return true;
    }

    const countryPrice = getCountryPrice(provider);
    return !!(countryPrice && provider.booking && countryPrice.every(country => provider.booking.bought_countries.includes(country)));
};

/**
 * @description Get product book status.
 * @param productId
 * @param provider
 * @returns {object}
 */
export const getProductBookStatus = (productId, provider) => {
    const statuses = {
        blocked: {
            className: 'blocked',
            status: 'Blocked',
        },
        booked: {
            className: 'booked',
            status: 'Booked for all countries',
        },
        partlyAvailable: {
            className: 'partly-available',
            status: 'Book more countries',
        },
        available: {
            className: 'available',
            status: 'Available',
        },
    };

    if (!!(provider.booking && provider.booking.is_blocked)) {
        return statuses.blocked
    }

    const countryPrice = getCountryPrice(provider);

    if (countryPrice && provider.booking) {
        return countryPrice.every(country => provider.booking.bought_countries.includes(country))
            ? statuses.booked
            : statuses.partlyAvailable;
    }

    return statuses.available;
};
/**
 * @description Get selected events data per products.
 * @param eventsData
 * @param withBooked
 * @param eventsRemovedProduct
 * @returns {{productsEventsData, bookedEvents: Array}}
 */
export const getProductsEventsData = (eventsData, withBooked = false, eventsRemovedProduct) => {
    const productsEventsData = {}, bookedEvents = [], removedEvents = [];
    eventsData && eventsData.forEach(event => {
        let allProductsIsBooked = true,
            allProductsIsRemoved = true;
        event.content_providers.forEach(provider => {
            let productIsNotBooked, productIsNotRemoved;
            if (!productIsBooked(provider.product_id, provider)) { //Check if product already bought
                productIsNotBooked = true;
                allProductsIsBooked = false;
            }
            if (productIsNotBooked && !(eventsRemovedProduct && eventsRemovedProduct[event.id] && eventsRemovedProduct[event.id].includes(provider.product_id))) { //Check if product already removed
                productIsNotRemoved = true;
                allProductsIsRemoved = false;
            }

            if (productIsNotBooked && productIsNotRemoved) { //Add to available data if product not bought or not removed
                productsEventsData[provider.product_id] = productsEventsData[provider.product_id] || [];
                productsEventsData[provider.product_id].push(event);
            }
        });
        allProductsIsBooked && bookedEvents.push(event.id);
        allProductsIsRemoved && removedEvents.push(event.id);
    });
    if (withBooked) return { productsEventsData, bookedEvents, removedEvents };

    return productsEventsData;
};

/**
 * @description Get events products iframe url
 * @param productType
 * @param eventData
 * @returns {*}
 */
export const getProductIframeURL = (productType, eventData) => {
    return new Promise((resolve, reject) => {
        usersDataService.refreshSignature()
            .then(res => {
                const { signature } = res.data || usersDataService.profile.getValue();
                const { creation_id: creationId, id } = eventData;
                const isModerator_isAdmin = Helpers.checkRole(MODERATOR, ADMIN);
                const { organization_id } = getContentProvidersData(eventData) || {};

                switch (true) {
                    case isModerator_isAdmin && productType === VIDEO_STREAMING_PRODUCT_ID:
                        resolve(`${Config.main.baseHost}${STREAMS.MODERATOR_STREAMS.URL}/${organization_id}/events/${id}/preview?signature=${signature}`);
                        break;
                    case productType === VIDEO_STREAMING_PRODUCT_ID:
                        resolve(`${Config.main.baseHost}${IFRAME_URLS.STREAMING.URL}/${creationId}?signature=${signature}`);
                        break;
                    case productType === ODDS_FEED_PRODUCT_ID:
                        resolve(`${Config.main.baseHost}${IFRAME_URLS.ODDS_FEED.URL}/${creationId}?signature=${signature}`);
                        break;
                    case productType === STATISTICS_PRODUCT_ID:
                        resolve(`${Config.main.baseHost}${IFRAME_URLS.STATISTICS.URL}/${creationId}?signature=${signature}`);
                        break;
                    case productType === SCOUTING_PRODUCT_ID:
                        resolve(`${Config.main.baseHost}${IFRAME_URLS.SCOUTING.URL}/${creationId}?signature=${signature}`);
                        break;
                    default:
                        reject();
                }
            });
    });
};

export const getBookedEventsCategoriesParams = (currentProductBookingEvents = [], productType) => {
    const events = [], blockParams = [], unBlockParams = [];

    for (let event of currentProductBookingEvents) {
        let product = Helpers.findDataByKey(event.content_providers, 'product_id', productType);

        product && events.push(product);

        if (product.match_status === 0 && (product.booking ? !product.booking.is_blocked : true)) {
            blockParams.push({ event_id: product.event_id, content_ids: [product.id] });
        }

        if ((product.match_status === 0 || product.match_status === 1) && (product.booking ? product.booking.is_blocked : true)) {
            unBlockParams.push({ event_id: product.event_id, content_ids: [product.id] });
        }
    }

    return { events, blockParams, unBlockParams };
};

export const calculateResultingCountriesPrices = (countriesPrice, regionsPrice, defaultPrice, countriesList, withZeros = false) => {
    return countriesList?.reduce((acc, country) => {
        const price = countriesPrice[country['iso3_code']] ?? regionsPrice[country['region_code']] ?? defaultPrice ?? null;
        if (!isNaN(price) && (withZeros || Number(price))) {
            return {
                ...acc,
                [country['iso3_code']]: Number(price)
            };
        }
        return acc;
    }, {});
};

export const getEventDefaultKeyValue = (events, userProfile, key) => {
    const firstEvent = (events[0]?.content_providers?.filter(provider => provider.product_id === VIDEO_STREAMING_PRODUCT_ID)[0] || events[0]);
    const firstEventLeague = events[0]?.league || {};
    const value = firstEvent[key] || firstEventLeague[key];
    const defaultValue = key === 'streamer' ? value || userProfile : value;

    return events.every(event => {
        const eventDefaultByKey = (event.content_providers?.filter(provider => provider.product_id === VIDEO_STREAMING_PRODUCT_ID)[0] || {})[key];
        const leagueDefaultByKey = (event.league || event || {})[key];
        const mixedDefaultByKey = eventDefaultByKey || leagueDefaultByKey || userProfile || null;
        return key === 'streamer' ? defaultValue?.id === mixedDefaultByKey?.id : defaultValue === mixedDefaultByKey;
    }) ? defaultValue : null;
};

export const calculateEventDetails = (eventsData, countriesList, userProfile, eventsType) => {
    const events = eventsData && Array.isArray(eventsData) ? eventsData : [eventsData];
    const allEventsDefaultPrice = getEventDefaultKeyValue(events, userProfile, 'default_price');
    const allEventsStreamer = getEventDefaultKeyValue(events, userProfile, 'streamer');

    const details = events.reduce((acc, event) => {
        const {
            blocked_countries: eventBlockedCountries,
            countries_price: eventCountriesPrice,
            regions_price: eventRegionsPrice
        } = event.content_providers?.filter(provider => provider.product_id === VIDEO_STREAMING_PRODUCT_ID)[0] || {};
        const {
            blocked_countries: leagueBlockedCountries,
            countries_price: leagueCountriesPrice,
            regions_price: leagueRegionsPrice
        } = (eventsType !== EVENT_TYPES.UPCOMING && event.league) || event || {};

        const mixedBlockedCountries = Helpers.arraysDifferentialMerge(eventBlockedCountries, leagueBlockedCountries);
        const mixedCountriesPrice = { ...(leagueCountriesPrice || {}), ...(eventCountriesPrice || {}) };
        const mixedRegionsPrice = { ...(leagueRegionsPrice || {}), ...(eventRegionsPrice || {}) };

        return {
            ...acc,
            blockedCountries: acc.blockedCountries ? Helpers.arraysIntersectionMerge(acc.blockedCountries, mixedBlockedCountries) : mixedBlockedCountries,
            countriesPrice: acc.countriesPrice ? Helpers.objectsIntersectionMerge(acc.countriesPrice, mixedCountriesPrice) : mixedCountriesPrice,
            regionsPrice: acc.regionsPrice ? Helpers.objectsIntersectionMerge(acc.regionsPrice, mixedRegionsPrice) : mixedRegionsPrice,
            eventsDetailsById: {
                ...acc.eventsDetailsById,
                [event.id]: {
                    organizationId: event.organization_id
                }
            }
        };
    }, {
        blockedCountries: null,
        countriesPrice: null,
        regionsPrice: null,
        defaultPrice: allEventsDefaultPrice,
        streamer: allEventsStreamer,
        eventsDetailsById: {}
    });

    const resultingCountryPrices = calculateResultingCountriesPrices(details.countriesPrice, details.regionsPrice, details.defaultPrice, countriesList);

    return {
        ...details,
        resultingCountryPrices,
        countryWithPricesCount: Object.keys(resultingCountryPrices).length,
        blockedCountriesCount: details.blockedCountries.length
    };
};

export const prepareEventsForUpcoming = (eventsData, userProfile) => {
    const errors = {
        countryPrices: 0,
        approved: 0
    };
    return eventsData.reduce((acc, val) => {
        const countryPrices = getDataFromEventByKey(val, val.league, "countries_price");

        if (!val.approved || !val['creation_id']) {
            errors.approved += 1;
            acc.upcomingReject.push(val.id);
        }

        const filteredCountryPrices = countryPrices ? Object.keys(countryPrices).reduce((collected, country) => {
            if (countryPrices[country] !== "" && Number(countryPrices[country])) collected[country] = countryPrices[country];
            return collected;
        }, {}) : {};
        const {
            blocked_countries: eventBlockedCountries,
            content_providing_user_id: eventStreamerId
        } = getContentProvidersData(val);
        const { streamer: leagueStreamer, blocked_countries: leagueBlockedCountries } = val.league;
        const blockedCountries = eventBlockedCountries || leagueBlockedCountries || [];
        const existsCountryIsNotBlocked = Object.keys(filteredCountryPrices).some(item => !blockedCountries.includes(item));
        if (!countryPrices || !Object.keys(filteredCountryPrices).length || !existsCountryIsNotBlocked) {
            errors.countryPrices += 1;
            acc.upcomingReject.push(val.id);
        } else {
            val.approved && acc.upcomingApproved.push({
                event_id: val.id,
                countries_price: filteredCountryPrices,
                blocked_countries: blockedCountries,
                content_providing_user_id: eventStreamerId || (leagueStreamer || {}).id || userProfile.id,
                organization_id: val.organization_id
            });
        }

        return acc;
    }, { upcomingApproved: [], upcomingReject: [], errors });
};

export const processSelectedEventsData = (ids, itemsData, currentSelections = [], idKey = 'id') => {
    const newIds = Array.isArray(ids) ? ids : [ids];
    const newItemsData = itemsData && Array.isArray(itemsData) ? itemsData : [itemsData];
    //const currentEventsData = this._eventList.getValue()[key].data || [];
    let tempIds = [];
    const filteredSelections = currentSelections.filter(val => {
        if (newIds.includes(val[idKey])) {
            tempIds.push(val[idKey]);
            return false;
        }
        return true;
    });
    let missingIds = newIds.filter(val => !tempIds.includes(val));

    if (itemsData) {
        let tempData = [];
        missingIds = missingIds.filter(id => !newItemsData.some(item => {
            if (item[idKey] === id) {
                tempData.push(item);
                return true;
            }
            return false;
        }));
        return { selections: [...filteredSelections, ...tempData], missingIds };
    }
    return { selections: filteredSelections, missingIds };
};

export const getEventDataByProduct = (event, productId) => {
    return (event.content_providers || []).filter(provider => provider.product_id === productId);
};

export const getCountriesTags = (data, countriesList, color, mode = 'pricing', limit = 5, viewMore) => {
    const countriesCodes = mode === 'pricing' ? Object.keys(data) : data;
    const slicedCountries = countriesCodes.length > limit ? countriesCodes.slice(0, limit) : countriesCodes;
    const additionalInfoTag = countriesCodes.length > limit && (
        <Tag
            clipped
            key="info"
            color={color}
            onClick={viewMore}
            name={viewMore ? t(`View {${countriesCodes.length - limit}} more`) : t(`+{${countriesCodes.length - limit}} countries`)}
        />
    );

    let countriesTags;

    if (mode === 'pricing') {
        const fistCountryPrice = data[slicedCountries[0]];

        if (countriesCodes.length > 1 && countriesCodes.every(item => data[item] === fistCountryPrice)) {
            return (
                <Tag color={color} name={`${fistCountryPrice} ${Config.main.currency} per country`} />
            );
        }

        countriesTags = slicedCountries.map(item => {
            const country = Helpers.findDataByKey(countriesList, "iso3_code", item);
            return (
                <Tag color={color} name={`${country?.name} - ${data[item] + Config.main.currency}`} key={item} />
            );
        });
    } else {
        countriesTags = getBlockedCountries(slicedCountries, countriesList).map(country => (
            <Tag color="danger" key={country.iso3_code} name={country?.name} />
        ));
    }

    return countriesTags ? [...countriesTags, additionalInfoTag] : countriesTags;
}

export const getNotBookedCountriesPrices = (countriesPrices, defaultCountries, boughtCountries = [], blockCountries = [], product) => {
    const defaultCountriesCodes = (defaultCountries || []).filter(country => !boughtCountries.includes(country));

    const data = Object.keys(countriesPrices).reduce((acc, el) => {
        if (getCountryPrice(product).includes(el)) {
            return {...acc, [el]: countriesPrices[el]}
        }
        return acc;
    }, {});

    const notBoughtNotDefaultCountries = Object.keys(data).reduce((acc, curr) => {
        if (!(boughtCountries.includes(curr)) && !defaultCountriesCodes.includes(curr)) {
            acc[curr] = data[curr];
        }
        return acc;
    }, {});

    const notBoughtDefaults = Object.keys(data).reduce((arr, curr) => {
        !boughtCountries.includes(curr) && (arr[curr] = data[curr]);
        return arr;
    }, {});

    return { ...notBoughtDefaults, ...notBoughtNotDefaultCountries };
};

export const getBookedCountries = (bookedCountries, countryPrices, countriesList) => {
    const bookedCountriesList = bookedCountries.reduce((arr, curr) => {
        arr[curr] = countryPrices[curr];
        return arr;
    }, {});
    return getCountriesTags(bookedCountriesList, countriesList, 'danger');
};
