import {
    addDays,
    addHours,
    addMonths,
    addYears,
    differenceInDays,
    differenceInHours,
    differenceInMonths,
    differenceInYears,
    format
} from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";
import { Cookies } from "react-cookie";
import { listTimeZones } from "timezone-support/dist/index";
import { openPopup, registerToastMessage } from "../actions/sync-actions/uiActions";
import Config from '../configs/mainConfig';
import { popupConfig } from "../configs/popupConfig";
import { TOAST_TYPES } from '../constants/uiConstants';
import {
    CLIENT,
    CLIENT_ADMIN,
    SCOUT_OPERATOR,
    SCOUT_TRADER,
    STREAMING_MANAGER,
    STREAMING_VIEWER,
} from '../constants/userRoles';
import DataFetcher from "../services/dataFetcherService";
import usersDataService from "../services/usersDataService";
import { getBasePath } from './routeHelper';

const Helpers = (function () {
    return {
        redirectToLogout: function () {
            const origin = window.location.origin;
            //const url = `https://accounts.feedconstruct.com/logout?redirectTo=https://accounts.feedconstruct.com/#/login?redirectTo=${origin}`;
            const url = "https://accounts.feedconstruct.com/logout?redirectTo=";
            //const url = "http://dev.feedconstruct.com:3000/logout?redirectTo=";
            const redirectUrl = encodeURIComponent(`${Config.main.baseHost}feed-sso/login?redirect_url=${origin}`);
            setTimeout(() => window.location.replace(url + redirectUrl), 0);
        },

        /**
         * @name cloneDeep
         * @description clone object by losing ref
         * @param data
         */
        cloneDeep: function (data) {
            return JSON.parse(JSON.stringify(data));
        },

        /**
         * @name getCookieParam
         * @param {string} type
         * @description Get from cookie data by type
         * @return {any}
         * */
        getCookieParam: function (type) {
            const cookies = new Cookies();
            return cookies.get(type);
        },

        /**
         * @name isEqual
         * @description Checks if two object or Arrays is equal.
         * @param {object, array} obj1
         * @param {object, array} obj2
         * @returns {boolean}
         */
        isEqual: function (obj1, obj2) {
            if (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object' &&
                obj1.constructor === Object && obj2.constructor === Object) {
                return JSON.stringify(obj1) === JSON.stringify(obj2);
            } else if (Array.isArray(obj1) && Array.isArray(obj2)) {
                if (obj1.length !== obj2.length) {
                    return false;
                }
                return JSON.stringify(obj1) === JSON.stringify(obj2);
            }
            return obj1 === obj2;
        },

        toFixed: (num = 0, precisionPoint = 0) => {
            const strNum = num.toString();
            const [leftSide, rightSide] = strNum.split(".");
            const rSide = rightSide ? `.${rightSide.slice(0, precisionPoint)}` : "";
            return Number(`${leftSide}${rSide}`);
        },

        deepEqual: function (obj1, obj2) {
            if ((typeof obj1 === 'object' && obj1 !== null && obj1.constructor === Object)
                && (typeof obj2 === 'object' && obj2 !== null && obj2.constructor === Object)) {
                if (Object.keys(obj1).length !== Object.keys(obj2).length) {
                    return false;
                }
                for (const prop in obj1) {
                    if (!obj2.hasOwnProperty(prop) || ((obj2.hasOwnProperty(prop) && !Helpers.deepEqual(obj1[prop], obj2[prop])))) {
                        return false;
                    }
                }
                return true;
            }
            return (!isNaN(obj1) && !isNaN(obj2)) ? Number(obj1) === Number(obj2) : obj1 === obj2;
        },

        sortArrayByKey: function (source, key, direction = true) {
            let sourceCopy = this.cloneDeep(source);
            direction = direction ? 1 : -1;

            return sourceCopy.sort((a, b) => {
                const value1 = a[key].toString().toLowerCase();
                const value2 = b[key].toString().toLowerCase();
                return (value1 > value2 ? direction : value1 < value2 ? -direction : 0);
            });
        },

        /**
         * @description Sort object keys and get sorted array
         * @param source
         * @param direction
         */
        sortObjectKeys: function (source, direction = true) {
            const sourceCopy = Object.keys(this.cloneDeep(source)),
                ordered = {};
            direction = direction ? 1 : -1;

            //Sort object key by direction and copy new array
            sourceCopy.sort((a, b) => {
                return (a > b ? direction : a < b ? -direction : 0);
            }).forEach(key => {
                ordered[key] = source[key];
            });

            return ordered;
        },

        /**
         * @description sort array or object.
         * @param source
         * @param direction
         * @param key
         * @return {*}
         */
        sortData: function (source, direction = true, key) {
            if (typeof source !== "object" || source === null) return source;
            return Array.isArray(source) ? this.sortArrayByKey(source, key, direction) : this.sortObjectKeys(source, direction);
        },

        convertArrayToObjectByKey: (sourceArray = [], key = "") => {
            if (!sourceArray.length) return {};
            return Object.assign({}, ...sourceArray.map(val => ({ [val[key]]: val })));
            /* return sourceArray.reduce((collected, current) => {
                return {
                    ...collected,
                    [current[key]]: current
                };
            }, {});*/
        },

        /**
         * @name findDataByKey
         * @description Find data by key and value and return that data
         * @param {array} data
         * @param {string} key
         * @param {*} value
         * @returns {*}
         */
        findDataByKey: (data, key, value) => {
            const dataLength = data.length;
            for (let i = 0; i < dataLength; i++) {
                if (typeof data[i] !== "object") return;

                if (data[i][key] === value) {
                    return data[i];
                }
            }
        },

        /**
         * @name getQueryStringValue
         * @description Returns location query param
         * @param {String} name of the param
         * @param {String} url to get the param form given string default is current location
         * @returns {String}
         */

        getQueryStringValue: function (name, url) {
            if (!url) {
                url = window.location.href;
            }
            name = name.replace(/[[\]]/g, "\\$&");
            let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },

        paginationButtonsBuilder: (currentPage, totalPages) => {
            const offset = 2;
            const maxNumberOfButtons = 5;
            const delta = maxNumberOfButtons - offset;

            let middlePages = [];

            if (totalPages - offset < delta) {
                for (let i = offset; i < totalPages; i++) {
                    middlePages.push(i);
                }
            } else {
                const page = currentPage < delta ? delta : currentPage + delta > totalPages ? totalPages - offset : currentPage;
                middlePages = [page - 1, page, page + 1];
            }

            return [
                1,
                ...(totalPages > maxNumberOfButtons && currentPage > delta ? ["..."] : []),
                ...middlePages,
                ...(totalPages > maxNumberOfButtons && currentPage + delta <= totalPages ? ["..."] : []),
                ...(totalPages !== 1 ? [totalPages] : [])
            ];
        },

        calculateArraySum: (arr, value) => {
            let sum = 0;
            for (let i = 0; i < arr.length; i++) {
                value && (sum += arr[i].value);
                !value && (sum += arr[i]);
            }
            return sum;
        },

        getMultiSelectValue: (payload, data, optionsValue = "id", disableExclusion = false) => {
            /*let payloadLength = payload.length, dataLength = data.length, value, exclude = false;
            if (disableExclusion || !payloadLength || payloadLength < (dataLength / 2)) {
                value = payload;
            } else if (payloadLength === dataLength) {
                value = undefined;
                exclude = true;
            } else {
                value = data.filter(item => !payload.includes(item[optionsValue].toString())).map(item => item[optionsValue].toString());
                exclude = true;
            }*/ //@TODO Remove after prove that exclude not needed anymore.
            return { value: payload, exclude: false };
        },

        /**
         * @description Function will get selected filter values from data by filterValue.
         * @param filterValue
         * @param data
         * @param key
         * @param getObj
         * @returns {any[]}
         */
        getFilterValues: (filterValue, key = "id", data = [], getObj) => {
            if (getObj) return data.filter((obj) => obj[key].toLowerCase().includes(filterValue.toLowerCase()));
            if (!getObj) {
                if (!filterValue || (filterValue && filterValue.value && !filterValue.value.length)) return [];

                if (!filterValue.value) return data.map(item => item[key]);

                return !filterValue.exclude ? filterValue.value.map(item => !isNaN(item) ? parseInt(item) : item)
                    : data.filter(item => !filterValue.value.includes(item[key].toString())).map(item => item[key]);
            }
        },

        /**
         * @description Get filters not empty values and filter some conditions
         * @param filters
         * @param withoutOffset
         * @param includeOrganization
         */
        getFiltersActualValues: (filters, withoutOffset = false, includeOrganization) => {
            let notEmptyValues = {};
            for (let fKey in filters) {
                const filter = filters[fKey];

                if (((withoutOffset && fKey === "limit") || fKey === "offset" || fKey === "type" || (!includeOrganization && fKey === "organization_ids")) || !filter ||
                    (typeof filter === "object" && filter.value && !filter.value.length)) continue;

                notEmptyValues[fKey] = filters[fKey];
            }
            return notEmptyValues;
        },

        fillEmptyDateRanges: (data = [], dateKey, valueKey, value, dateFormat) => {
            return data.reduce((acc, val, index) => {
                let newData = [];
                let daysDiff = index && data[index - 1] && Math.abs(differenceInDays(new Date(val[dateKey]), new Date(data[index - 1][dateKey])));
                if (daysDiff > 1) {
                    for (let i = 1; i < daysDiff; i++) {
                        newData.push({
                            ...val,
                            [dateKey]: format(addDays(new Date(data[index - 1][dateKey]), i), dateFormat),
                            [valueKey]: value
                        });
                    }
                }
                return [...acc, ...newData, val];
            }, []);
        },

        findMaxValue: (data, key) => {
            let arr = [];
            for (let i = 0; i < data.length; i++) {
                arr.push(Number(data[i][key]));
            }
            return Math.max.apply(null, arr);
        },

        toggleArrayValue: (arr = [], value) => {
            const newArray = arr.filter(val => !isNaN(value) ? Number(val) !== Number(value) : val !== value);
            newArray.length === arr.length && newArray.push(!isNaN(value) ? Number(value) : value);
            return newArray;
        },

        addOrUpdateArrayOfObjectsValue: (arr = [], value, selector) => {
            const index = arr.findIndex(val => val?.[selector] === value?.[selector]);
            if (index !== -1) {
                return [...arr.slice(0, index), value, ...arr.slice(index + 1)];
            }
            return [...arr, value];
        },

        updateDataInArrayOfObjects: (array = [], newData = {}, type = 'id') => {
            const contains = array.some(item => item[type] === newData[type]);
            if (contains) {
                return array.map(item => item[type] === newData[type] ? { ...item, ...newData } : item);
            }
            return [...array, newData];
        },

        removeElementFromArray: (array, element) => {
            let index = array.indexOf(element);
            if (index > -1) array.splice(index, 1);
            return array;
        },

        removeObjFromArray: (arr, obj) => {
            let newArr = [];
            if (obj) {
                for (let i = 0; i < arr.length; i++) {
                    for (let j in obj) {
                        if (arr[i][j] !== obj[j]) {
                            newArr.push(arr[i]);
                            break;
                        }
                    }
                }
            }
            return newArr;
        },

        mergeArrayElements: (data = [], newData = [], uniqueKey) => {
            return data.filter(item => !newData.find(newItem => item[uniqueKey] === newItem[uniqueKey])).concat(newData);
        },
        //Merge two arrays and return only values that contain both arrays
        arraysIntersectionMerge: (arr1, arr2) => (arr1 || []).filter(val => (arr2 || []).includes(val)),
        //Merge two arrays and return uniq elements from both arrays
        arraysDifferentialMerge: (arr1, arr2) => [...new Set([...(arr1 || []), ...(arr2 || [])])],
        //Merge two objects and returns new object with same key and value in both objects
        objectsIntersectionMerge: (obj1 = {}, obj2 = {}) => Object.keys(obj1).reduce((acc, key) => obj1[key] === obj2[key] ? {
            ...acc,
            [key]: obj1[key]
        } : acc, {}),

        replaceArrayElements: (data = [], newData = [], uniqueKey = 'id') => {
            const oldData = Helpers.cloneDeep(data);
            for (let newItem of newData) {
                const foundIndex = oldData.findIndex(item => item[uniqueKey] === newItem[uniqueKey]);
                oldData[foundIndex] = newItem;
            }
            return oldData;
        },

        getByObjPath: (obj, key) => {
            if (!key.includes('.')) return obj[key];
            let keys = key.split('.');
            for (let i = 0; i < keys.length; i++) {
                obj = (obj && obj[keys[i]]) ? obj[keys[i]] : null;
            }
            return obj;
        },

        /**
         * @name buildStringByData
         * @param obj
         * @param str
         * @returns {string}
         */
        buildStringByData: (obj, str) => {
            if (str.includes('{') || str.includes('}')) {
                const findKeys = str.match(/{([^}]+)}/g);
                for (let i = 0; i < findKeys.length; ++i) {
                    const cleanKey = findKeys[i].replace('{', '').replace('}', '');
                    str = str.replace(findKeys[i], Helpers.getByObjPath(obj, cleanKey) ?? '');
                }
                return str;
            }
            return obj[str];
        },

        buildObjByObjPaths: (obj, objPrototype) => {
            let newObj = {};
            for (let i in objPrototype) {
                if (objPrototype.hasOwnProperty(i)) {
                    if (typeof objPrototype[i] === 'string') {
                        newObj[i] = Helpers.getByObjPath(obj, objPrototype[i]);
                    } else if (typeof objPrototype[i] === 'object') {
                        newObj[i] = Helpers.buildObjByObjPaths(obj, objPrototype[i]);
                    }
                }
            }
            return newObj;
        },

        /**
         * @description Export data from url by current queryParams
         * @param url
         * @param queryParams
         * @param fileName
         * @param dispatch
         * @param callBack
         * @param useTimerNotification
         */
        exportDataByQueryParams: (url, queryParams, fileName = url, dispatch, callBack = () => 0, useTimerNotification = false) => {
            let timerID;
            if (useTimerNotification) {
                timerID = setTimeout(() => {
                    dispatch(registerToastMessage({
                        message: "This may take a few minutes",
                        type: TOAST_TYPES.INFO,
                        timeout: 6000,
                    }));
                }, 5000);
            }
            //Download csv.
            DataFetcher.getJson(url, { queryParams, headers: { 'Content-Type': 'text/csv' } }, true)
                .then(response => response.blob()).then(blob => {
                callBack();
                //Download csv file
                if (blob.type === "text/csv" || blob.type === "text/csv;charset=utf-8") {
                    const csvURL = window.URL.createObjectURL(blob);
                    const tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', `${fileName}.csv`);
                    tempLink.click();
                    timerID && clearTimeout(timerID);
                } else {
                    dispatch(openPopup(
                        "message",
                        popupConfig.noDataExport
                    ));
                }
            }).catch(e => e);
        },

        /**
         * @description Checked Dates Diff
         * @param startTime
         * @param endTime
         * @returns {{years: number, months: number, days: number}}
         */
        checkDatesDiff: (startTime, endTime) => {
            const start = new Date(startTime * 1000), end = new Date(endTime * 1000);
            let years, months, days;
            years = differenceInYears(end, start);
            addYears(start, years);
            months = differenceInMonths(end, start);
            addMonths(start, months);
            days = differenceInDays(end, start);
            return { years, months, days };
        },

        /**
         * @description Checked value in Array by key
         * @param array
         * @param key
         * @param value
         * @returns {*}
         */
        existValueInArray: (array = [], key, value) => {
            if (!array) return;
            for (let i = 0; i < array.length; i++) {
                if (array[i][key] && value && array[i][key] === value) {
                    return array[i];
                }
            }
        },

        /**
         * @name getTimeZoneTime
         * @param {number} timeStamp
         * @param {string} tz
         * @description get the time in selected time zone
         * @return {object}
         */
        getTimeZoneTime: (timeStamp, tz = '') => {
            const { time_zone: timeZone } = usersDataService.profile.getValue();
            const tZone = listTimeZones().includes(timeZone) ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

            return convertToTimeZone(
                timeStamp
                    ? new Date(timeStamp * 1000)
                    : new Date(),
                { timeZone: tz || tZone });
        },

        /**
         * @name convertDateByTimeZone
         * @param {number} timeStamp
         * @param {string} tz
         * @description convert selected timestamp by selected time zone or in default time zone
         * @return {object}
         */
        convertDateByTimeZone: function (timeStamp, tz) {
            return addHours(timeStamp, differenceInHours(this.getTimeZoneTime(null, tz), new Date()) * -1);
        },

        /**
         * @description set time format
         * @returns {String}
         */
        getTimeFormat: () => {
            const timeFormat = usersDataService.profile.getValue() && usersDataService.profile.getValue().time_format;
            return timeFormat === "12" ? "hh:mm a" : "HH:mm";
        },

        getPresetFiltersCount: (presetData) => {
            return Object.keys(presetData).reduce((acc, curr) => {
                if ((Array.isArray(presetData[curr]) && presetData[curr].length) || (!Array.isArray(presetData[curr]) && presetData[curr])) {
                    return ++acc;
                }
                return acc;
            }, 0);
        },

        getArrayIndex: (data = [], item = {}, uniqueKey = '') => {
            for (let i = 0; i < data.length; i++) {
                if (data[i][uniqueKey] === item[uniqueKey]) return i;
            }
        },

        checkRole: (...roleIds) => {
            const { roles = [] } = usersDataService.userProfile.getValue() || {};
            return roles.some((val) => roleIds.filter((id) => val.id === id).length);
        },

        checkElementClass: (className = "", action, element = document.documentElement) => {
            const hasClass = Array.from(element.classList).includes(className);
            switch (action) {
                case 'add':
                    !hasClass && element.classList.add(className);
                    break;
                case 'remove':
                    hasClass && element.classList.remove(className);
                    break;
                default:
                    return hasClass;
            }
            return hasClass;
        },

        constructTime: (time) => {
            return ('0' + (typeof time === "string" ? time.trim() : time)).slice(-2);
        },

        getTime: (hours, minutes) => {
            return `${Helpers.constructTime(hours)} : ${Helpers.constructTime(minutes)}`;
        },

        regExpReplacer: (str = '', from = '', to = '') => {
            if (Array.isArray(from)) {
                for (let f of from) {
                    str = str.replace(new RegExp(f, "g"), to);
                }
                return str;
            } else {
                return str.replace(new RegExp(from, "g"), to);
            }
        },

        toTimeStamp: (strDate) => Math.floor(Date.parse(strDate) / 1000),

        yearsGenerator: (yearsCount = 100) => {
            const startYear = new Date().getFullYear();
            const years = [];
            for (let i = 0; i < yearsCount; ++i) {
                years.push(startYear - i);
            }
            return years;
        },

        multiFilter: (data = [], filterKeys = [], value = '', byOrder) => {
            if (!value) return data;
            value = value.toLowerCase();
            return data.filter((item) => {
                if (filterKeys.length) {
                    return filterKeys.some((key) => {
                        const string = item[key] ? item[key].toString().toLowerCase() : '';
                        return byOrder ? (string.indexOf(value) === 0 && value) : (string.includes(value) && value);
                    });
                } else {
                    const string = item.toString().toLowerCase();
                    return byOrder ? (string.indexOf(value) === 0 && value) : (string.includes(value) && value);
                }
            });
        },

        mailTo: ({ email = '', subject = '', bodyMessage = '' }) => {
            window.location.href = `mailto:${email}?subject=${subject}&body=${bodyMessage}`;
        },

        amountFormat: (num) => {
            num = num || 0;
            const num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        },

        flipObject: (obj) => {
            return Object.keys(obj).reduce((acc, key) => {
                acc[obj[key]] = key;
                return acc;
            }, {});
        },

        openNewWindow: (route = '/', target = '', title, callBack) => {
            const width = 969;
            const height = 748;
            const left = (window.screen.width - width) / 2;
            const top = (window.screen.height - height) / 2;
            const newWindow = window.open(route, target, 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top + 'toolbar=no,scrollbars=yes,resizable=no');
            if (title) setTimeout(() => newWindow.document.title = title, 500);
            callBack && callBack();
        },

        capitalizeFirstLetters: (string = '') => string.toLowerCase().split(' ').map((word) => word[0].toUpperCase() + word.substr(1)).join(' '),

        createArrInObjByKey: (obj = {}, placedKey = '', newArrKey = '') => {
            const tryParse = (value) => {
                try {
                    return JSON.parse(value);
                } catch (e) {
                    return value;
                }
            };
            const newObj = { [newArrKey]: [{}] };

            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (key.includes(placedKey)) {
                        newObj[newArrKey][0][key.replace(placedKey, '')] = tryParse(obj[key]);
                    } else {
                        newObj[key] = tryParse(obj[key]);
                    }
                }
            }
            return newObj;
        },

        setLastActiveProject: (path = getBasePath()) => {
            const isValidPath = path === '/streaming' || path === '/scouting';
            const isClient = Helpers.checkRole(CLIENT, CLIENT_ADMIN, STREAMING_VIEWER, STREAMING_MANAGER, SCOUT_TRADER, SCOUT_OPERATOR);
            if (!isValidPath) path = '/streaming';

            if (isClient) {
                const cookies = new Cookies();
                const userProfile = usersDataService.profile.getValue();
                cookies.set(`project_${userProfile.id}`, path);
            }
        },

        getPercentageChange: (oldNumber, newNumber) => {
            if (!oldNumber) return 0;

            const decreaseValue = newNumber - oldNumber;
            return (decreaseValue / oldNumber) * 100;
        },

        doCopy: (element, callBack) => {
            const { current } = typeof element === "object" ? element : typeof element === "string" && { current: { ownerDocument: document.getElementById(element) } };
            const { ownerDocument } = current;
            if (ownerDocument.queryCommandSupported("copy") || ownerDocument.queryCommandEnabled('copy')) {
                const textArea = ownerDocument.createElement("textarea");
                try {
                    textArea.textContent = current.innerHTML;
                    textArea.style.position = "fixed";
                    ownerDocument.body.appendChild(textArea);
                    textArea.focus();
                    textArea.setSelectionRange(0, 99999999);
                    ownerDocument.execCommand("copy");
                    callBack && callBack({ msg: "copied", type: "success" });
                } catch (err) {
                    callBack && callBack({ msg: "Copy failed", type: "failed" });
                } finally {
                    ownerDocument.body.removeChild(textArea);
                }
            } else callBack && callBack({ msg: "Your device doesn't support copy functionality", type: "failed" });
        },

        removeKeyFromObject: (obj, ...keys) => {
            return Object.keys(obj).reduce((acc, curr) => ({
                ...acc,
                ...(!keys.includes(curr) ? { [curr]: obj[curr] } : {}),
            }), {});
        },
    };
})();

export default Helpers;
