import Helpers from '../../helpers/helperFunctions';
import eventDataService from "../../services/eventsDataService";
import {dataLoadStart, dataLoadDone, dataLoadFail, dataLoadReset} from "./index";
import {EVENTS} from "../../constants/reducerKeys";
import {closeRightPanel, registerToastMessage} from "../sync-actions/uiActions";
import {
    CLIENT,
    CLIENT_ADMIN,
    STREAMING_MANAGER,
    STREAMING_VIEWER
} from "../../constants/userRoles";
import {EVENT_TYPES, TOAST_TYPES} from "../../constants/uiConstants";
import {eventsLeagueDefaultParams} from "../../constants/queryParams";

/**
 * @description Get init data for events by key
 * @param roles
 * @param key
 * @returns {function(): function(*=): Promise<any>}
 */
export function initEventsService (roles, key) {
    return function (dispatch) {
        dispatch(dataLoadStart(key));
        eventDataService.init(!!Helpers.arraysIntersectionMerge([CLIENT, CLIENT_ADMIN, STREAMING_VIEWER, STREAMING_MANAGER], roles.map(role => role.id)).length && "myGames", key)
            .then(data => {
                dispatch(dataLoadDone(key, data));
            })
            .catch(error => {
                dispatch(dataLoadFail(key, error));
            });
    };
}

/**
 * @description Update events by key.
 * @param params
 * @param key
 * @returns {Function}
 */
export function updateEventsService (params, key, reset) {
    return function (dispatch) {
        dispatch(dataLoadStart(key));
        eventDataService.update(params, key, reset)
            .then(data => {
                dispatch(dataLoadDone(key, data));
            })
            .catch(error => {
                dispatch(dataLoadFail(key, error));
            });
    };
}

export function createEvents (events, organizationId, key = EVENT_TYPES.LIST) {
    return (dispatch) => {
        dispatch(dataLoadStart(key));
        let promises = events.map(event => eventDataService.createEvent({...event, ...eventsLeagueDefaultParams}, organizationId));
        Promise.all(promises)
            .then(res => {
                dispatch(dataLoadDone(key, res));
                dispatch(registerToastMessage({
                    message: "Your requested matches are forwarded to our Content Creation department. Once approved, they will be ready for you to stream.",
                    timeout: 5000
                }));
                dispatch(closeRightPanel());
            })
            .catch(err => {
                dispatch(dataLoadFail(key, err));
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"}));
            });
    };
}

/**
 * @description Update selected events by params
 * @param key
 * @param params
 * @param toastMessage
 * @param callback
 * @return {Function}
 */
export function updateSelectedEvents (key, params, toastMessage = 'Saved', callback) {
    return (dispatch) => {
        eventDataService.updateSelectedEvents(key, params)
            .then(res => {
                dispatch(registerToastMessage({ message: toastMessage }));
                callback && (typeof callback === "function") && callback(res);
            })
            .catch(err => {
                dispatch(registerToastMessage({ type: TOAST_TYPES.ERROR, message: "Oops something went wrong" }));
            });
    };
}

export function rollbackEvent (eventId, key) {
    return function (dispatch) {
        dispatch(dataLoadStart('eventRollBack'));
        eventDataService.rollBackEvent(eventId, key)
            .then(data => {
                dispatch(registerToastMessage({ message: "Successfully Reversed"}));
                dispatch(dataLoadDone('eventRollBack', data));
            })
            .catch((error = {}) => {
                const {body = {}} = error;
                dispatch(registerToastMessage({ type: TOAST_TYPES.ERROR, message: body?.message ||  "Oops something went wrong" }));
                dispatch(dataLoadFail('eventRollBack', error));
            });
    };
}

export function stopEventsService () {
    return (dispatch) => {
        eventDataService.stop();
        dispatch(dataLoadReset(EVENTS));
    };
}
