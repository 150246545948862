import React from 'react';
import {Field, Formik} from "formik";
import {closeRightPanel} from "../../../../actions/sync-actions/uiActions";
import {useDispatch} from "react-redux";
import leagueDataService from "../../../../services/leagueDataService";
import Input from "../../../shared/input";
import DropDown from "../../../shared/dropDown";
import OverlayContent from "../../rightPanel/overlayContent";
import DatePickerInput from '../../../shared/datePickerInput';
import { nextDateShortcuts } from '../../../../constants/dateRanges';
import Helpers from '../../../../helpers/helperFunctions';
import { addYears, endOfDay, startOfDay } from "date-fns";
import { createLeague } from '../../../../actions/async-actions/leagueActions';
import { t } from '../../../../helpers/translate';

const validate = (values) => {
    return Object.keys(values).reduce((acc, curr) => {
        switch (curr) {
            case "name":
            case "league_id":
            case "region_id":
                !values[curr] && (acc[curr] = t('This field is required'));
                return acc;
            default:
                return acc;
        }
    }, {});
};

const initialValues = {
    name: '',
    region_id: null,
    region_name: null,
    sport_id: null,
    sport_name: null,
    season: {
        start_date: Math.floor(startOfDay(new Date()).valueOf()) / 1000,
        end_date: Math.floor(endOfDay(new Date()).valueOf()) / 1000,
    }

};

const LeagueForm = () => {
    const dispatch = useDispatch();

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnChange
            validateOnBlur
            onSubmit={() => {}}
            validate={validate}
            render={({ values, touched, errors, setFieldValue, setValues, setTouched, isValid }) => (
                <OverlayContent
                    title={t('Create New League')}
                    subTitle=""
                    secondaryButton={{
                        name: t('Cancel'),
                        onClick: () => dispatch(closeRightPanel())
                    }}
                    primaryButton={{
                        name: t('Create League'),
                        color: 'confirm',
                        disabled: !isValid,
                        onClick: () => {
                            const data = Object.keys(values).reduce((acc, curr) => {
                                return { ...acc, [curr]: values[curr] };
                            }, {});
                            dispatch(createLeague(data));
                        }
                    }}
                >
                    <div className="form-grid col-2">

                        <Field
                            name="name"
                            render={({field}) => (
                                <Input
                                    label={t('Name')}
                                    required
                                    type='text'
                                    placeholder={t('Enter name here')}
                                    value={values[field.name]}
                                    errorText={touched[field.name] && errors[field.name]}
                                    labelAppearance="title"
                                    onChange={(e) => {
                                        setFieldValue(field.name, e.target.value);
                                        setTouched({ ...touched, [field.name]: true })
                                    }}
                                />
                            )}
                        />

                        <Field
                            name="sport_id"
                            render={({form, field: {name}}) => (
                                <DropDown
                                    label={t('Sport')}
                                    labelAppearance="title"
                                    required
                                    service={leagueDataService.getSportsByParams}
                                    placeholder={t('Select sport')}
                                    requestParams={{ all: 1 }}
                                    onChange={sport => form.setValues({
                                        ...values,
                                        [name]: sport.id,
                                        sport_name: sport.name
                                    })}
                                />
                            )}
                        />

                        <Field
                            name="region_id"
                            render={({form, field: {name}}) => (
                                <DropDown
                                    label={t('Region')}
                                    labelAppearance="title"
                                    required
                                    service={leagueDataService.getRegionsByParams}
                                    placeholder={t('Select region')}
                                    onChange={region => form.setValues({
                                        ...values,
                                        [name]: region.id,
                                        region_name: region.name
                                    })}
                                />
                            )}
                        />

                        <Field
                            name="season"
                            render={({field: {name}}) => {
                                return (
                                    <DatePickerInput
                                        dateRange={{
                                            startDate: new Date(values[name].start_date * 1000),
                                            endDate: new Date(values[name].end_date * 1000),
                                        }}
                                        staticRanges={nextDateShortcuts(Helpers.getTimeZoneTime(), true)}
                                        onChange={({startDate, endDate}) => {
                                            setFieldValue(name, {
                                                start_date: Math.floor(startOfDay(startDate) / 1000),
                                                end_date: Math.floor(endOfDay(endDate) / 1000)
                                            })
                                        }}
                                        label={t('Season start & end dates')}
                                        labelAppearance="title"
                                        minDate={new Date()}
                                        maxDate={(addYears(startOfDay(new Date()), 100))}
                                    />
                                )
                            }}
                        />
                    </div>
                </OverlayContent>
            )}>
        </Formik>
    );
};

export default LeagueForm;
