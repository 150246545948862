import React, { useEffect, useState } from "react";
import ModalContent from '../../../shared/modal/parts/content';
import Tag from '../../../shared/tag';
import templatesDataService from '../../../../services/templatesDataService';
import { PARTNERSHIP_TYPES_LIST } from '../../../../constants/dataConstants';
import { PARTNERSHIP_TYPES } from '../../../../constants/uiConstants';
import { format } from "date-fns";
import Helpers from '../../../../helpers/helperFunctions';
import RevenueSharingFields from '../../../shared/revenueSharingFields';
import { t } from "../../../../helpers/translate";

const SECTION_TITLES = {
    "type": "Type",
    "amount": "Amount",
    "created_at": "Created date",
    "share": "Revenue sharing model",
    "cost": "Cost Coverage",
    "related_organizations": "Federations"
};

const TemplateDetails = ({ popupParams: { info } }) => {

    const [template, setTemplate] = useState(info);

    useEffect(() => {
        templatesDataService.getTemplateById(info.id)
            .then(res => setTemplate(res.data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getContentByType = (dataType) => {
        switch (dataType) {
            case "type":
                return PARTNERSHIP_TYPES_LIST.find(el => el.id === template[dataType]).name;
            case "created_at":
                return format(Helpers.getTimeZoneTime(typeof template[dataType] === "number"
                    ? template[dataType] : Math.floor(new Date(template[dataType] + 'Z').getTime()) / 1000), `d MMMM yyyy ${Helpers.getTimeFormat()}`);
            default:
                return template[dataType];
        }
    };

    return (
        <ModalContent>
            <div className="form-grid">
                {Object.keys(template).map((dataType, index) => {
                    if (!SECTION_TITLES[dataType]
                        || (template.type === PARTNERSHIP_TYPES.FIXED && ["cost", "share"].includes(dataType))
                        || (template.type === PARTNERSHIP_TYPES.SHARED && dataType === "amount")
                        || (Array.isArray(template[dataType]) && !template[dataType].length)) return null;

                    return (
                        <div className={`modal-section t-`} key={index}>
                            <div className="ms-head">
                                {t(SECTION_TITLES[dataType])}
                            </div>
                            <div className="ms-body">
                                {(dataType === "share" && (
                                    <RevenueSharingFields
                                        federationShareAmount={template[dataType]}
                                        readOnly
                                        required={false}
                                    />
                                )) || (
                                    dataType === "related_organizations" ? (
                                        template[dataType].map((el, key) => (
                                            <Tag
                                                key={key}
                                                name={el.name}
                                                clipped
                                            />
                                        ))
                                    ) : (
                                        <Tag
                                            name={getContentByType(dataType)}
                                            clipped
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </ModalContent>
    )
};

export default TemplateDetails;
