import {
    SET_FILTERS_DATA,
    SET_MULTIPLE_FILTERS_DATA,
    SET_FILTERS_CHANGE,
    SET_LAST_FILTERS,
    RESET_FILTERS_DATA,
    RESET_FILTERS_BY_PARENT_KEY,
} from "../action-types";

export const SetFiltersValue = (key, meta, payload, parentKey, resetOffset, reset) => {
    return {
        type: SET_FILTERS_DATA,
        key,
        meta,
        payload,
        parentKey,
        resetOffset,
        reset,
    };
};

export const SetMultipleFiltersValue = (key, payload, parentKey, resetOffset) => {
    return {
        type: SET_MULTIPLE_FILTERS_DATA,
        key,
        payload,
        parentKey,
        resetOffset,
    };
};

export const SetFiltersChange = (key, payload, parentKey) => {
    return {
        type: SET_FILTERS_CHANGE,
        key,
        payload,
        parentKey
    };
};
/**
 * @description Set last actual filters.
 * @param key
 * @param payload
 * @param parentKey
 * @param resetOffset
 * @returns {{type: string, key: *, payload: *, parentKey: *, resetOffset: *}}
 * @constructor
 */
export const SetLastFilters = (key, payload, parentKey, resetOffset) => {
    return {
        type: SET_LAST_FILTERS,
        key,
        payload: payload,
        parentKey,
        resetOffset
    };
};

export const ResetFiltersValue = (key, parentKey, resetOffset) => {
    return {
        type: RESET_FILTERS_DATA,
        key,
        parentKey,
        resetOffset
    };
};

export const ResetFiltersByParentKey = (key) => {
    return {
        type: RESET_FILTERS_BY_PARENT_KEY,
        key,
    };
};
