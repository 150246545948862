import {
    TOGGLE_FILTERS_DATA,
    TOGGLE_MULTIPLE_FILTERS_DATA,
    DELETE_FILTERS_DATA,
    TOGGLE_FILTER_DATA_BY_KEY,
    DELETE_ALL_FILTERS_DATA,
} from "../action-types";

/**
 * @param key
 * @param payload
 * @returns {{type: string, key: *, payload: *}}
 * @constructor
 */
export const ToggleFiltersData = (key, payload) => {
    return {
        type: TOGGLE_FILTERS_DATA,
        key,
        payload
    };
};

export const ToggleFiltersDataByKey = (key, payload) => {
    return {
        type: TOGGLE_FILTER_DATA_BY_KEY,
        key,
        payload
    };
};

export const ToggleMultipleFiltersData = payload => {
    return {
        type: TOGGLE_MULTIPLE_FILTERS_DATA,
        payload
    };
};

export const DeleteFiltersData = (key, parentKey) => {
    return {
        type: DELETE_FILTERS_DATA,
        key,
        parentKey,
    };
};

export const DeleteAllFiltersData = (payload) => {
    return {
        type: DELETE_ALL_FILTERS_DATA,
        payload,
    };
};

