import React, {useState} from 'react';
import streamingService from "../../../../services/streamingService";
import Input from "../../input";
import ModalContent from "./content";
import DropDown from "../../dropDown";
import Helpers from '../../../../helpers/helperFunctions';
import { registerToastMessage } from '../../../../actions/sync-actions/uiActions';
import { TOAST_TYPES } from '../../../../constants/uiConstants';
import { t } from '../../../../helpers/translate';

const CreateStream = ({ closePopup, popupParams = {}, dispatch }) => {
    const { organizationId, eventId } = popupParams;
    const [selectedStream, setSelectedStream] = useState({});
    const [value, setValue] = useState('');

    const streamNameOnChange = (e) => {
        const { value: nameValue } = e.target;
        if (!value.trim() && !nameValue.trim()) return;
        setValue(nameValue);
    };

    const handleSave = () => {
        const streamData = streamingService._streamData.getValue();
        const duplicateName = streamData[eventId] && Helpers.existValueInArray(streamData[eventId], 'stream_name', value);

        if (!value.trim()) {
            return dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Stream name is required"}));
        }
        if (duplicateName) {
            return dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: `The name "${value}" already exists.`}));
        }
        selectedStream.id && streamingService.createPubLocation(selectedStream.id, value, eventId, organizationId).then(() => {
            closePopup();
            dispatch(registerToastMessage({type: TOAST_TYPES.SUCCESS, message: "Stream Created"}));
        }).catch(e => e);

    };

    return (
        <ModalContent
            primaryButton={{
                name: t('Create'),
                color: 'confirm',
                onClick: handleSave,
                disabled: !value || !selectedStream.id
            }}
            secondaryButton={{
                name: t('Cancel'),
                onClick: closePopup,
            }}
        >
            <div className="form-grid col-2">
                <DropDown
                    service={streamingService.getPubLocations}
                    onChange={setSelectedStream}
                    multiSelect={false}
                    keys={{ key: 'id', value: 'prefix' }}
                    initialRequestParams={selectedStream?.id ? {ids: selectedStream.id} : null}
                    serviceAdditionalParams={{ organizationId }}
                    placeholder={t('Select stream')}
                    label={t('Stream')}
                    labelAppearance="title"
                    rowRenderer={(item) => (
                        <ul className="d-option d-option-vertical">
                            <li className="ellipsis-text">{`${item?.options?.credentials[0].name} - ${item.prefix}`}</li>
                            <li className="option-description ellipsis-text">{`${item?.options?.credentials[0].host}`}</li>
                        </ul>
                    )}
                />
                <Input
                    value={value}
                    onChange={streamNameOnChange}
                    placeHolder={t('Name the stream')}
                    label={t('Name')}
                    labelAppearance="title"
                />
            </div>
        </ModalContent>
    )
};

export default CreateStream
