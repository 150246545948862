import Button from '../button';
import Config from '../../../configs/mainConfig';
import React, { useEffect, useState } from 'react';
import paymentsDataService from '../../../services/paymentsDataService';
import { openPopup } from '../../../actions/sync-actions/uiActions';
import Helpers from '../../../helpers/helperFunctions';
import { SCOUT_OPERATOR, STREAMING_MANAGER } from '../../../constants/userRoles';
import { useHistory } from 'react-router-dom';

const WalletBalance = ({ dispatch }) => {
    const [walletBalance, setWalletBalance] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const subscription = paymentsDataService.getWalletBalance().subscribe(setWalletBalance);
        return () => subscription && subscription.unsubscribe();
    }, []);

    const handleDeposit = () => {
        history.push(`#wallet`);
        dispatch(openPopup("settings", {
            closeOnClickOutside: false
        }));
    };

    return (
        <>
            <Button
                icon='icon-wallet'
                appearance='minimal'
                color='default'
                flexibility='content-width'
                onClick={handleDeposit}
                className={Helpers.checkRole(SCOUT_OPERATOR, STREAMING_MANAGER) ? 'pointer-events-none' : ''}
            >
                {`${Config.main.currency} ${Helpers.amountFormat(walletBalance)}`}
            </Button>
            <div className="divider" />
        </>
    );
};

export default WalletBalance;
