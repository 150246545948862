import React from "react";

import Button from "../../shared/button";

import notFound from '../../../assets/media/not-found.svg';

import {t} from '../../../helpers/translate';

import './notFound.scss';

const ErrorNotFounded = (props) => {
    const { title, text } = props;

    return (
        <main>
            <div className="not-found-holder">
                <div className="not-found">
                    <img src={notFound} alt="" />
                    <h2>{title}</h2>
                    <h3>{text}</h3>
                    <div className="button-holder">
                        <Button
                            size="big"
                            to="/"
                            tag="NavLink"
                        >
                            {t('Go back home')}
                        </Button>
                    </div>
                </div>
            </div>
        </main>
    )
};

ErrorNotFounded.defaultProps = {
    title: '404',
    text: 'Requested page not found'
};

export default ErrorNotFounded;
