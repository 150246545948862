import classnames from 'classnames';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Cookies } from "react-cookie";
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { getUserPartnersList, setUserPartner } from "../../../actions/async-actions/userAction";
import { clearAllSelectedEvents } from "../../../actions/sync-actions/eventData";
import { projectStatuses } from "../../../constants/statuses";
import { EVENT_TYPES } from "../../../constants/uiConstants";
import Helpers from "../../../helpers/helperFunctions";
import { t } from "../../../helpers/translate";
import componentDataProvider from "../../../hocs/componentDataProvider";
import usersDataService from "../../../services/usersDataService";
import Loader from "../../reusableUIComponents/loader";
import Input from '../input';
import NoData from '../noData';

import './userPartnersList.scss';

class UserPartnersList extends Component {
    static propTypes = {
        userPartnersList: PropTypes.object,
        userProfile: PropTypes.object
    };

    static requiredProps = {
        userPartnersList: PropTypes.object,
        userProfile: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.handlePartnerChange = this.handlePartnerChange.bind(this);
        this.handlePartnerListSubscription = this.handlePartnerListSubscription.bind(this);
        this.state = {
            partnersList: null, // partners list grouped by roles
            flattenedPartnersList: null, // partners list without grouping
            searchKeyword: '',
        };
    }

    componentDidMount() {
        this.subscription = usersDataService.userPartnersList.pipe(
            map(list => {
                return {
                    partnersList: list && this.assemblePartnersListByRoles(list),
                    flattenedPartnersList: list,
                };
            }),
            debounceTime(500),
            distinctUntilChanged(),
        ).subscribe(this.handlePartnerListSubscription);
    }

    componentWillUnmount() {
        this.subscription && this.subscription.unsubscribe();
    }

    assemblePartnersListByRoles = (list) => {
        const sortedList = Helpers.sortArrayByKey(list, 'name');
        return sortedList.reduce((acc, val) => {
            const type = Number(val.partnership_type);
            const roles = { 1: "clients", 2: "federations", 3: "moderators" };
            acc[roles[type]].push(val);
            return acc;
        }, { clients: [], federations: [], moderators: [] });
    };

    handlePartnerListSubscription(values) {
        if (!values.partnersList) {
            this.props.dispatch(getUserPartnersList());
        } else {
            this.setState(values);
        }
    }

    handlePartnerChange = (partnerId) => (e) => {
        this.props.dispatch(clearAllSelectedEvents(EVENT_TYPES.LIST, { skipToggle: true }));
        sessionStorage.setItem('organizationId', partnerId);
        if (partnerId !== this.props.userProfile.organization_id) {
            const cookies = new Cookies();
            this.props.dispatch(setUserPartner({ organization_id: partnerId }, partnerId, () => {
                Promise.resolve().then(() => cookies.get("leftMenuPath", { path: "/" }) && cookies.remove("leftMenuPath", { path: "/" }));
                window.location.href = "/";
            }));
        }

    };

    listItem = (partner, userProfile, showStatus) => (
        <ul
            title={partner.name}
            key={partner.id}
            onClick={this.handlePartnerChange(partner.id)}
            className={classnames(
                'project-row',
                `t-${projectStatuses[partner.approve_status].toLowerCase()}`,
                {
                    'active': userProfile.organization_id === partner.id,
                }
            )}
        >
            <li className="ellipsis-text">{partner.name}</li>
            <li>
                {(!!projectStatuses[partner.approve_status] && showStatus) && (
                    <small>{t(projectStatuses[partner.approve_status])}</small>
                )}
            </li>
        </ul>
    );

    onSearch = (e) => {
        const { flattenedPartnersList } = this.state;
        const searchKeyword = e.target.value.toLowerCase();

        if (searchKeyword.length >= 100) return;

        const list = flattenedPartnersList.filter(el => {
            const possibleMatches = [el.name.toLowerCase(), el.id?.toString(), el.partner_id?.toString()];
            return possibleMatches.some(val => val && val?.indexOf(searchKeyword) !== -1);
        });

        this.setState({
            searchKeyword,
            partnersList: this.assemblePartnersListByRoles(list)
        });
    };

    render() {
        const { userProfile } = this.props, { partnersList, searchKeyword } = this.state;
        const { clients = [], federations = [], moderators = [] } = partnersList || {};
        return (
            partnersList ? (
                <div className="partners-list-container">
                    <div className="partners-list-search">
                        <Input
                            value={searchKeyword}
                            placeholder={t('Search')}
                            onChange={this.onSearch}
                            labelAppearance="none"
                            icon="icon-search"
                            autoFocus={true}
                        />
                    </div>
                    {Object.keys(partnersList).every(partner => !partnersList[partner].length) ? (
                        <NoData
                            transparent
                            size="small"
                        />
                    ) : (
                        <div className="partners-list-holder">
                            {!!moderators.length && (
                                <>
                                    <p>{t('Moderator')}</p>
                                    <div className="partners-list">
                                        {moderators.map(partner => this.listItem(partner, userProfile))}
                                    </div>
                                </>
                            )}
                            {!!clients.length && (
                                <>
                                    <p>{t('Operators')}</p>
                                    <div className="partners-list">
                                        {clients.map(partner => this.listItem(partner, userProfile, true))}
                                    </div>
                                </>
                            )}
                            {!!federations.length && (
                                <React.Fragment>
                                    <p>{t('Federations')}</p>
                                    <div className="partners-list">
                                        {federations.map(partner => this.listItem(partner, userProfile, true))}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <Loader/>
            )
        );
    }
}

export default componentDataProvider(UserPartnersList);
