import {
    SET_EVENT_HOVER_DATA,
    CLEAR_ALL_SELECTED_EVENTS,
    UPDATE_SELECTED_EVENTS
} from "../action-types";

/**
 * @description Update selected events.
 * @param payload
 * @param key
 * @returns {{payload: Array, key: String}}
 */
export const updateSelectedEvents = (payload, key) => {
    return {
        type: UPDATE_SELECTED_EVENTS,
        payload,
        key
    };
};
/**
 * @description remove all selected events for key
 * @param key
 * @param meta
 * @returns {{type: string, key: *}}
 */
export const clearAllSelectedEvents = (key, meta = {}) => {
    return {
        type: CLEAR_ALL_SELECTED_EVENTS,
        key,
        meta
    };
};

export const setEventHoverData = (payload) => {
    return {
        type: SET_EVENT_HOVER_DATA,
        payload
    };
};
