import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";

import Checkbox from "../../checkbox";
import Icon from "../../icon";
import { t } from "../../../../helpers/translate";

import './dropDownItem.scss';

const DropDownItem = props => {
    const {
        item,
        itemKey,
        multiSelect,
        suggestion,
        active,
        icon,
        text,
        className,
        onSelect,
        skeleton,
        style,
        rowRenderer,
        allowDeselect,
        rowHasAction,
    } = props;

    const handleClick = (e) => {
        e.preventDefault();
        if (typeof onSelect === 'function') {
            suggestion ? onSelect(text, true) : onSelect(item)
        }
    };

    const isCustomElement = typeof rowRenderer === 'function';

    return (
        <label
            className={classnames('dropdown-item', className,
                {
                    suggestion,
                    active,
                    'deselect': active && allowDeselect,
                    'multi-select': multiSelect,
                    'with-action': rowHasAction,
                    'pointer-events-none': skeleton,
                }
            )}
            onClick={handleClick}
            style={style}
        >
            <ul>
                <li>
                    <ul className="option-holder">
                        {icon && (
                            <li className={classnames('option-icon', 'si-color', icon)}/>
                        )}
                        <li
                            className={classnames(`option-text ellipsis-text`, {
                                'flexible': isCustomElement,
                            })}
                        >
                            {skeleton ? (
                                <div className="dropdown-skeleton-line"/>
                            ) : (
                                <>
                                    {isCustomElement ? (
                                        rowRenderer(item)
                                    ) : (
                                        t(text || item[itemKey])
                                    )}
                                </>
                            )}
                        </li>
                    </ul>
                </li>
                <li>
                    {!skeleton && (
                        suggestion ? (
                            <Icon type="icon-plus"/>
                        ) : (
                            multiSelect ? (
                                <Checkbox checked={active} onChange={() => {
                                }}/>
                            ) : (
                                <Icon type="icon-check"/>
                            )
                        )
                    )}
                </li>
            </ul>
        </label>
    );
};

DropDownItem.propTypes = {
    item: PropTypes.object,
    itemKey: PropTypes.string,
    multiSelect: PropTypes.bool,
    suggestion: PropTypes.bool,
    active: PropTypes.bool,
    icon: PropTypes.any,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    onSelect: PropTypes.func,
    skeleton: PropTypes.bool,
    style: PropTypes.any,
    rowRenderer: PropTypes.func,
    allowDeselect: PropTypes.bool,
    rowHasAction: PropTypes.bool,
};

export default DropDownItem;
