import { useCallback, useEffect, useState } from 'react';

const useHorizontalScroll = (scrollerRef, loading) => {
    const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
    const [isDragging, setDragging] = useState(false);
    const [scrollBoxWidth, setScrollBoxWidth] = useState(0);
    const [left, setLeft] = useState(0);

    const handleScroll = useCallback(() => {
        if (!scrollerRef) return;
        const { scrollLeft, scrollWidth, offsetWidth } = scrollerRef.current
        let newLeft = (scrollLeft / scrollWidth) * offsetWidth;
        setLeft(newLeft);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleThumbMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setScrollThumbPosition(e.clientX);
        setDragging(true);
    };

    const handleDocumentMouseMove = useCallback((e) => {
        if (isDragging) {
            e.preventDefault();
            e.stopPropagation();
            const { scrollLeft, scrollWidth, offsetWidth } = scrollerRef.current;
            let deltaX = e.clientX - lastScrollThumbPosition;
            let percentage = deltaX * (scrollWidth / offsetWidth);

            setScrollThumbPosition(e.clientX);

            setLeft((scrollLeft / scrollWidth) * offsetWidth);
            scrollerRef.current.scrollLeft = Math.min(
                scrollLeft + percentage,
                scrollWidth - offsetWidth
            );
        }
    }, [isDragging, lastScrollThumbPosition, scrollBoxWidth, left]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDocumentMouseUp = useCallback((e) => {
        if (isDragging) {
            e.preventDefault();
            setDragging(false);
        }
    }, [isDragging]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const { clientWidth, scrollWidth } = scrollerRef.current;
        if (clientWidth !== scrollWidth && !loading) {
            const scrollThumbPercentage = clientWidth / scrollWidth;
            const scrollThumbWidth = Math.max(scrollThumbPercentage * clientWidth, 20);
            setScrollBoxWidth(scrollThumbWidth);
        }
    }, [loading, scrollerRef]);

    useEffect(() => {
        const scrollHostElement = scrollerRef.current;
        scrollHostElement.addEventListener("scroll", handleScroll);
        return function cleanup() {
            scrollHostElement.removeEventListener("scroll", handleScroll);
        };
    }, [scrollerRef]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.addEventListener("mousemove", handleDocumentMouseMove);
        document.addEventListener("mouseup", handleDocumentMouseUp);
        document.addEventListener("mouseleave", handleDocumentMouseUp);
        return function cleanup() {
            document.removeEventListener("mousemove", handleDocumentMouseMove);
            document.removeEventListener("mouseup", handleDocumentMouseUp);
            document.removeEventListener("mouseleave", handleDocumentMouseUp);
        };
    }, [handleDocumentMouseMove, handleDocumentMouseUp]);

    return { handleThumbMouseDown, left, scrollBoxWidth }
};

export default useHorizontalScroll;
