import React, {Component} from "react";
import ErrorNotFounded from "../../components/application/routeNotFound";
import { t } from '../../helpers/translate';

/**
 * @description Component will handle errors which are
 */
class ErrorBoundary extends Component {
    //Initial state
    state = {
        hasError: false
    };

    static getDerivedStateFromError (error) {
        return {hasError: true};
    }

    componentDidCatch (error, info) {
        const slackHookUrl = `https://hooks.slack.com/services/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`;
        fetch(slackHookUrl, {
            method: 'POST',
            body: JSON.stringify({
                text: `Error is--${error}. Information about error-${JSON.stringify(info).replace(/\\n/g, ' ')}`
            })
        });
    }

    render () {
        if (this.state.hasError) {
            return (
                <ErrorNotFounded
                    title={t('Oops')}
                    text={t('Something went wrong')}
                />
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
