import React, {useEffect, useState} from "react";
import ModalContent from "../../shared/modal/parts/content";
import leagueDataService from "../../../services/leagueDataService";
import DropDownItemWithDescription from "../../shared/dropDown/pieces/dropDownItemWithDescription";
import DropDown from "../../shared/dropDown";
import federationsDataService from "../../../services/federationsDataService";
import LeagueSetupRow from "../../shared/leagueSetupRow";
import {Formik} from "formik";
import {startOfDay, endOfDay, addYears} from "date-fns";
import { registerToastMessage } from '../../../actions/sync-actions/uiActions';
import { t } from '../../../helpers/translate';

const initialValues = {leagues: []};

const AssignNewLeague = ({ closePopup, pathname, dispatch }) => {
    const [selectedLeagues, setSelectedLeagues] = useState([]);
    const [federationData, setFederationData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const federationId = pathname.match(/\d+/)?.[0];

    useEffect(() => {
        if (federationId) {
            const federationsList = federationsDataService.federationsList.getValue();
            const data = federationsList.data?.find(federation => federation.id === Number(federationId));
            if (!data) {
                setIsLoading(true);
                federationsDataService.getFederationsByIds(federationId)
                    .then(res => {
                        setFederationData(res.data[0]);
                        setIsLoading(false);
                    })
            } else {
                setFederationData(data);
            }
        }
    }, [federationId]);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnChange
            validateOnBlur
            onSubmit={() => {}}
            render={({ values, setValues }) => (
                <ModalContent
                    primaryButton={{
                        name: t('Attach'),
                        disabled: isLoading || !values.leagues.length || (!(federationData || {}).template_id && !values.leagues.every(league => Object.keys(league).every(key => league[key]))),
                        onClick: () => {
                            setIsLoading(true);
                            federationsDataService.attachLeaguesToFederation(federationId, values.leagues, selectedLeagues)
                                .then(() => {
                                    setIsLoading(false);
                                    dispatch(registerToastMessage({message: "Successfully attached"}));
                                    closePopup();
                                });
                        },
                        color: 'confirm',
                    }}
                    secondaryButton={{
                        name: t('Close'),
                        onClick: closePopup,
                    }}
                >
                    <div className='form-grid'>
                        <DropDown
                            requestParams={{
                                ...{includes: "sport,region", sportFields: "name,alias", regionFields: "name"},
                                unattached: 1
                            }}
                            service={leagueDataService.getModeratorLeaguesByParams}
                            labelAppearance="title"
                            label={t('League')}
                            multiSelect
                            iconKey="sport.alias"
                            rowRenderer={(item) => <DropDownItemWithDescription item={item} />}
                            placeholder={t('Select leagues')}
                            onChange={(leagues) => {
                                setSelectedLeagues(leagues);
                                setValues({
                                    leagues: leagues.map((league) => {
                                        const selectedLeague = values.leagues.find(val => val.league_id === league.id);
                                        const startDate = selectedLeague?.start_date ? new Date(selectedLeague.start_date) : new Date();
                                        const endDate = selectedLeague?.end_date ? new Date(selectedLeague.end_date) : new Date();

                                        return {
                                            league_id: league.league_id || league.id,
                                            template_id: selectedLeague?.template_id || federationData.template_id,
                                            payment_type: selectedLeague?.payment_type,
                                            start_date: (startOfDay(startDate)).toISOString(),
                                            end_date: (addYears(endOfDay(endDate), 1)).toISOString(),
                                            tier: 1
                                        };
                                    })
                                });
                            }}
                        />
                        {!!selectedLeagues.length && !federationData.template_id && (
                            <>
                                {selectedLeagues.map((league, index) => (
                                    <LeagueSetupRow
                                        key={league.id}
                                        index={index}
                                        league={league}
                                        values={values}
                                        setValues={setValues}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </ModalContent>
            )} />
    )
};

export default AssignNewLeague;
