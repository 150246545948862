import { COUNTRIES, LEAGUES, SPORTS, TEAMS } from "../constants/api-resources";
import DataFetcher from "./dataFetcherService";
import Helpers from "../helpers/helperFunctions";
import webWorker from "../webWorker";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { singleObjectToArrayConverter, loadMoreDataHandler } from "../helpers/dataHandlers";
import { SERVICE_DATA_TYPES } from "../constants/serviceConstants";
import { GridConfig } from "../configs/gridConfig";
import {
    ADMIN,
    CP_ADMINISTRATOR,
    CP_GROUP_MANAGER,
    FEDERATION_GROUP_COORDINATOR,
    MODERATOR
} from "../constants/userRoles";
import { getServiceUrl } from "../helpers/uiHelpers";
import eventsDataService from './eventsDataService';
import { EVENT_TYPES } from '../constants/uiConstants';

class LeagueDataService {
    constructor() {
        this._sportList = new BehaviorSubject({});
        this._sportDataList = this._sportList.pipe(
            map(sportList => Helpers.convertArrayToObjectByKey(sportList.data || [], "id"))
        );
        this._regionList = new BehaviorSubject({});
        this._filterLeagueList = new BehaviorSubject({});
        this._leagueList = new BehaviorSubject({});
        this._leagueDataList = this._leagueList.pipe(
            map(leagueList => Helpers.convertArrayToObjectByKey(leagueList, "id"))
        );
        this._teamList = new BehaviorSubject([]);
        this._countryList = new BehaviorSubject([]);
        this._countriesGroupedList = new BehaviorSubject({});

        this._activeServicies = {};
    }

    checkAndActivate(name) {
        if (!this._activeServicies[name]) {
            this._activeServicies[name] = true;
            switch (name) {
                case SERVICE_DATA_TYPES.LEAGUES_LIST:
                    this.getLeagues();
                    break;
                case SERVICE_DATA_TYPES.SPORT_LIST:
                    this.getSports();
                    break;
                case SERVICE_DATA_TYPES.COUNTRIES_GROUPED_LIST:
                    this.getGroupedCountries();
                    break;
                case SERVICE_DATA_TYPES.COUNTRIES_LIST:
                    this.getCountries();
                    break;
                case SERVICE_DATA_TYPES.FILTER_LEAGUES_LIST:
                    this.getFilterLeagues();
                    break;
                case SERVICE_DATA_TYPES.SPORT_DATA_LIST:
                    this.getSports();
                    break;
                case SERVICE_DATA_TYPES.REGIONS_LIST:
                    this.getRegions({ without_pagination: 1 });
                    break;
                default:
                    break;
            }
        }
    }

    getGroupedCountries() {
        DataFetcher.getJson(COUNTRIES.COUNTRIES_GROUPED.URL, { queryParams: { order_by: "name" } })
            .then(res => {
                this._countriesGroupedList.next(singleObjectToArrayConverter(res.data));
            }).catch(e => e);
    }

    getCountries() {
        DataFetcher.getJson(COUNTRIES.ALL_COUNTRIES.URL, { queryParams: { without_pagination: true } })
            .then(res => {
                this._countryList.next(Helpers.sortArrayByKey(singleObjectToArrayConverter(res.data), "name"));
            }).catch(e => e);
    }

    getLeagues() {
        if (Helpers.checkRole(ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR)) return;
        DataFetcher.getJson(this.leaguesUrl, {
            queryParams: { offset: 0, limit: GridConfig.loadLimit }
        })
            .then(res => {
                //res.total_count && actionCallBack && actionCallBack(res.total_count);
                this._leagueList.next(res);
            }).catch(e => e);
    }

    updateLeague(leagueId, params) {
        return DataFetcher.putJson(`${this.leaguesUrl}/${leagueId}`, {
            headers: { "Content-Type": "application/json" },
            params
        })
            .then(res => {
                const leaguesData = this._leagueList.getValue();
                const index = leaguesData.data?.findIndex(val => val.id === leagueId);
                this._leagueList.next({
                    ...leaguesData,
                    data: [
                        ...leaguesData.data.slice(0, index),
                        { ...leaguesData.data[index], ...res.data },
                        ...leaguesData.data.slice(index + 1),
                    ]
                });

                const selectedEvents = eventsDataService.selectedEventsData.getValue();

                if (selectedEvents[EVENT_TYPES.LIST]?.length) {
                    const updatedData = selectedEvents[EVENT_TYPES.LIST].map(event => {
                        if (event.league_id === leagueId) {
                            return {
                                ...event,
                                league: {
                                    ...event.league,
                                    ...res.data,
                                }
                            };
                        }
                        return event;
                    });
                    eventsDataService.selectedEventsData.next({
                        ...selectedEvents,
                        [EVENT_TYPES.LIST]: updatedData,
                    });
                }
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    }

    createLeague(params) {
        const createLeagueUrl = Helpers.checkRole(MODERATOR, ADMIN) ? LEAGUES.MODERATOR_CREATE_LEAGUE.URL : LEAGUES.CREATE_LEAGUE.URL;
        return DataFetcher.postJson(createLeagueUrl, { params })
            .then(res => {
                // TODO CHANGE
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    }

    /**
     * @param params
     * @param loadMore
     * @returns {Promise<void> | Promise | Promise<T> | Promise<T | void> | *}
     */
    getSports(params = {}, loadMore) {
        if (params.removeData) return Promise.resolve(this._sportList.next({}));
        return DataFetcher.getJson(SPORTS.SPORTS_LIST.URL, { queryParams: params })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._sportList.getValue());
                this._sportList.next({ total_count: res.total_count, data });
                return Promise.resolve(true);
            })
            .catch(err => Promise.reject(err));
    }

    /**
     * @param params
     * @param loadMore
     * @returns {Promise<void> | Promise | Promise<T> | Promise<T | void> | *}
     */
    getRegions(params = {}, loadMore) {
        if (params.removeData) return Promise.resolve(this._regionList.next({}));
        return DataFetcher.getJson(COUNTRIES.REGIONS.URL, { queryParams: params })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._regionList.getValue(), true);
                this._regionList.next({ total_count: res.total_count, data });
                return Promise.resolve(true);
            })
            .catch(err => Promise.reject(err));
    }

    /**
     * @param params
     * @param loadMore
     * @returns {Promise<void> | Promise | Promise<T> | Promise<T | void> | *}
     */
    getFilterLeagues(params, loadMore) {
        return DataFetcher.getJson(LEAGUES.FILTER_LEAGUES_LIST.URL, { queryParams: params })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._filterLeagueList.getValue());
                this._filterLeagueList.next({ total_count: res.total_count, data });
                return Promise.resolve(true);
            })
            .catch(err => Promise.reject(err));
    }

    getLeagueByOffset = (queryParams, reset) => {
        return DataFetcher.getJson(this.leaguesUrl, { queryParams })
            .then(res => {
                if (reset) {
                    this._leagueList.next(res);
                } else {
                    // const nextData = workerService.newTask("mergeData", this._leagueList.getValue(), res);
                    // nextData.subscribe(val => {
                    //     this._leagueList.next(val);
                    // });
                    webWorker.open(
                        'helperWorker',
                        'mergeData',
                        [this._leagueList.getValue(), res],
                        (resp) => this._leagueList.next(resp)
                    );
                }
                return Promise.resolve(res);
            }).catch(e => e);
    };

    getTeamsByParams(params) {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(TEAMS.TEAMS_LIST.URL, { queryParams: params })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getSportsByParams(params) {
        return DataFetcher.getJson(SPORTS.SPORTS_LIST.URL, { queryParams: params });
    }

    getRegionsByParams(params) {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(COUNTRIES.REGIONS.URL, { queryParams: params })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    unattachLeagueFromFederation(organizationId, leagueId, organizationName) {
        return new Promise((resolve, reject) => {
            DataFetcher.deleteJson(`${LEAGUES.UNATTACH_LEAGUE.URL}/${organizationId}/leagues/${leagueId}`)
                .then((res) => {
                    const leaguesList = this._leagueList.getValue();
                    const totalCount = leaguesList.total_count - 1;
                    if (organizationName) {
                        this._leagueList.next({
                            ...leaguesList, data: leaguesList.data.map(item => {
                                    if (item.id === leagueId) {
                                        return {
                                            ...item,
                                            organization_id: null,
                                            organization_name: null
                                        };
                                    } else return item;
                                }
                            )
                        });
                    } else {
                        this._leagueList.next({
                            ...leaguesList,
                            data: leaguesList.data.filter(league => Number(leagueId) !== league.id),
                            total_count: totalCount < 0 ? totalCount : totalCount,
                        });
                    }

                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    getCountriesByParams(params) {
        return DataFetcher.getJson(COUNTRIES.ALL_COUNTRIES.URL, { queryParams: params });
    }

    getLeaguesByParams(params) {
        return DataFetcher.getJson(LEAGUES.STANDART_LEAGUE.URL, { queryParams: params });
    }

    getModeratorLeaguesByParams(params) {
        return DataFetcher.getJson(LEAGUES.MODERATOR_LEAGUES.URL, { queryParams: params });
    }

    getMyLeaguesByParams = (params) => {
        return DataFetcher.getJson(this.leaguesUrl, { queryParams: params });
    };

    stop() {
        this._sportList.next({});
        this._regionList.next({});
        this._filterLeagueList.next({});
        this._leagueList.next({});
        this._teamList.next([]);
        this._countryList.next([]);
        this._countriesGroupedList.next({});

        this._activeServicies = {};
    }

    get leaguesUrl() {
        return Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR) ? getServiceUrl(LEAGUES.STANDART_LEAGUE.URL, LEAGUES.MODERATOR_LEAGUES.URL) : LEAGUES.LEAGUES_LIST.URL;
    }

    /*****************************Getters*****************************/
    get sportList() {
        return this._sportList;
    }

    get regionsList() {
        this.checkAndActivate(SERVICE_DATA_TYPES.REGIONS_LIST);
        return this._regionList;
    }

    get teamsList() {
        return this._teamList;
    }

    get sportDataList() {
        this.checkAndActivate(SERVICE_DATA_TYPES.SPORT_DATA_LIST);
        return this._sportDataList;
    }

    get filterLeaguesList() {
        return this._filterLeagueList;
    }

    get leaguesList() {
        this.checkAndActivate(SERVICE_DATA_TYPES.LEAGUES_LIST);
        return this._leagueList;
    }

    get countriesList() {
        this.checkAndActivate(SERVICE_DATA_TYPES.COUNTRIES_LIST);
        return this._countryList;
    }

    get countriesGroupedList() {
        this.checkAndActivate(SERVICE_DATA_TYPES.COUNTRIES_GROUPED_LIST);
        return this._countriesGroupedList;
    }
}

export default new LeagueDataService();
