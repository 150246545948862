export const SUBSCRIBE = "subscribe";
export const UNSUBSCRIBE = "unsubscribe";
export const GET_ALL_GAMES = "allGames";
export const GET_MY_GAMES = "myGames";
export const DEMO_STREAMS = "stream:demo";

export const SOCKET_EVENTS = {
    EVENT: 'event',
    NEW_EVENT: 'newEvent'
};
