import { useEffect, useState } from 'react';
import braintreeLib from 'braintree-web';
import paymentsDataService from '../services/paymentsDataService';

const useVaultManager = () => {
    const [authorization, setAuthorization] = useState(null);
    const [vaultManager, setVaultManager] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);

    useEffect(() => {
        paymentsDataService.getPaymentClientToken()
            .then(res => setAuthorization(res.clientToken));
    }, []);

    useEffect(() => {
        if (authorization) {
            braintreeLib.vaultManager.create({
                authorization
            }).then(setVaultManager);
        }
    }, [authorization]);

    useEffect(() => {
        if (vaultManager) {
            vaultManager.fetchPaymentMethods()
                .then(val => {
                    setPaymentMethods(val
                        // .filter(el => el.type !== 'PayPalAccount')
                        .map(el => ({
                            default: el.default,
                            nonce: el.nonce,
                            type: el.type,
                            ...el.details
                        }))
                    );
                });
        }
    }, [vaultManager]);

    const deletePaymentMethod = (nonce) => {
        return vaultManager.deletePaymentMethod(nonce)
            .then(() => {
                setPaymentMethods(paymentMethods.filter((method) => method.nonce !== nonce))
                return Promise.resolve(nonce);
            })
            .catch(() => Promise.reject(false));
    };

    const addPaymentMethod = (newPaymentMethod) => {
        setPaymentMethods([...paymentMethods, newPaymentMethod]);
    };

    return { paymentMethods, setPaymentMethods, authorization, deletePaymentMethod, addPaymentMethod };
};

export default useVaultManager;
