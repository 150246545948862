import { lazy } from 'react';
import {
    CLIENT,
    CONTENT_CREATOR,
    LEAGUE_OWNER,
    REPORTER,
    STREAMER,
    MODERATOR,
    ADMIN,
    SCOUT_OPERATOR,
    SCOUT_TRADER,
    CLIENT_ADMIN,
    STREAMING_MANAGER,
    STREAMING_VIEWER,
    CP_ADMINISTRATOR,
    CP_GROUP_MANAGER,
    FEDERATION_GROUP_COORDINATOR,
} from "../constants/userRoles";
const ModeratorHomePage = lazy(() => import('../containers/moderatorHomePage'));
const EventsWrapper = lazy(() => import('../containers/eventsWrapper'));
const FederationsWrapper = lazy(() => import('../containers/federationsWrapper'));
const LeaguesWrapper = lazy(() => import('../containers/leaguesWrapper'));
const WatchListWrapper = lazy(() => import('../containers/watchListWrapper'));
const DemoHomePage = lazy(() => import('../components/application/demoHomePage'));
const ReportingDashboard= lazy(() => import("../containers/reportingDashboard"));
const SettingsWrapper = lazy(() => import("../containers/settingsWrapper"));
const StreamerView = lazy(() => import("../containers/streamerView"));
const OperatorsWrapper = lazy(() => import("../containers/operatorsWrapper"));
const ScoutWrapper = lazy(() => import("../containers/scoutWrapper"));
const UserManagement = lazy(() => import("../components/settings/userManagement"));
const AccountDefaults = lazy(() => import("../components/settings/accountDefaults"));
const ApiIntegration = lazy(() => import("../components/settings/api_integration"));
const FinancialReport = lazy(() => import("../components/settings/financialReport"));
const Wallet = lazy(() => import("../components/wallet"));
const MyAccount = lazy(() => import("../components/myAccount"));

const routerConfig = {
    "/": {
        allowed: "*",
        showTopMenu: false,
        showAutobookingRules: false,
        showInDemo: true,
        component: DemoHomePage
    },
    "/scouting": {
        allowed: process.env.REACT_APP_MODE === 'production' ? [] : [CLIENT, SCOUT_OPERATOR, SCOUT_TRADER, CLIENT_ADMIN],
        defaultRoute: {
            "/events": "*", // default route for everyone else
        },
        routes: {
            "/events": {
                allowed: process.env.REACT_APP_MODE === 'production' ? [] : [LEAGUE_OWNER, CONTENT_CREATOR, CLIENT, STREAMER, CP_ADMINISTRATOR, CP_GROUP_MANAGER, MODERATOR, ADMIN, SCOUT_OPERATOR, SCOUT_TRADER, CLIENT_ADMIN],
                showInTopMenu: true,
                showAutobookingRules: false,
                showInDemo: true,
                component: ScoutWrapper,
                name: "Events"
            },
            "/account": {
                allowed: process.env.REACT_APP_MODE === 'production' ? [] : "*",
                name: "My Account",
                icon: "icon-user",
                showInDemo: true,
                showTopMenu: false,
                showAutobookingRules: false,
                component: MyAccount,
            },
        },
    },
    "/streaming": {
        allowed: "*",
        showInDemo: true,
        defaultRoute: {
            "/events": "*", // default route for everyone else
            "/home": [MODERATOR, ADMIN]
        },
        routes: {
            "/home": {
                allowed: [MODERATOR, ADMIN],
                showInTopMenu: true,
                showAutobookingRules: false,
                showTopMenu: false,
                showInDemo: true,
                name: "Home",
                component: ModeratorHomePage
            },
            "/federations": {
                allowed: [MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR],
                showInTopMenu: true,
                showAutobookingRules: false,
                showInDemo: true,
                name: "Federations",
                component: FederationsWrapper,
                path: ["/federations/group/:id" , "/federations/:id", "/federations",]
            },
            "/operators": {
                allowed: [MODERATOR, ADMIN],
                showInTopMenu: true,
                showTopMenu: true,
                showAutobookingRules: false,
                showInDemo: true,
                name: "Operators",
                component: OperatorsWrapper,
                path: ['/operators/:id', '/operators']
            },
            "/leagues": {
                allowed: [LEAGUE_OWNER,MODERATOR,ADMIN, REPORTER, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR],
                showInTopMenu: true,
                showAutobookingRules: false,
                showInDemo: true,
                name: "Leagues",
                component: LeaguesWrapper
            },
            "/events": {
                allowed: [LEAGUE_OWNER, CONTENT_CREATOR, CLIENT, STREAMER, MODERATOR, ADMIN, CLIENT_ADMIN,
                    STREAMING_MANAGER, STREAMING_VIEWER, REPORTER, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR],
                showInTopMenu: true,
                showAutobookingRules: true,
                showInDemo: true,
                name: "Events",
                component: EventsWrapper,
            },

            "/reporting": {
                allowed: [LEAGUE_OWNER, CLIENT, CLIENT_ADMIN, REPORTER],
                showInTopMenu: true,
                showAutobookingRules: false,
                showInDemo: true,
                name: "Reporting",
                component: ReportingDashboard,
                routeProps: {
                    strict: true
                }
            },
            "/streamer": {
                allowed: [LEAGUE_OWNER, CLIENT, STREAMER, MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR],
                showInDemo: true,
                pathParams: '/:eventId',
                component: StreamerView,
                path: '/streamer/:eventId'
            },

            "/watchlist": {
                allowed: [MODERATOR, ADMIN],
                showInTopMenu: true,
                showTopMenu: false,
                showAutobookingRules: false,
                showInDemo: false,
                name: "Watchlist",
                component: WatchListWrapper
            },
            "/account": {
                allowed: "*",
                name: "My Account",
                icon: "icon-user",
                showInDemo: true,
                showTopMenu: false,
                showAutobookingRules: false,
                component: MyAccount,
            },
        },
    },
    "/settings": { // use can use allowedForStreaming or allowedForScouting keys for specifying separate permissions for shared routes
        allowed: "*",
        showTopMenu: false,
        showInDemo: true,
        component: SettingsWrapper,
        showInPopover: true,
        routes: {
            "/users": {
                allowed: [CLIENT, CLIENT_ADMIN,LEAGUE_OWNER, MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER],
                name: "User Management",
                icon: "icon-address-book",
                showInDemo: false,
                component: UserManagement
            },
            "/defaults": {
                allowed: "*",
                name: "Settings",
                icon: "icon-drafting-compass",
                showInDemo: true,
                component: AccountDefaults

            },
            "/API_integration": {
                allowed: [CLIENT, CLIENT_ADMIN],
                name: "API Integration",
                icon: "icon-infinity",
                showInDemo: true,
                showOnlyInIntegration: true,
                component: ApiIntegration
            },
            "/payments": {
                allowed: [CLIENT, CLIENT_ADMIN, LEAGUE_OWNER, REPORTER],
                name: "Financial Report",
                icon: "icon-euro-sign",
                showInDemo: false,
                component: FinancialReport
            },
            "/wallet": {
                allowed: [CLIENT, CLIENT_ADMIN],
                allowedPaymentType: 1,
                name: "Wallet",
                icon: "icon-wallet",
                showInDemo: false,
                component: Wallet,
            }
        }
    }
};

export default routerConfig;
