import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './title.scss';

const Title = props => {
    const {
        label,
        className,
    } = props;

    return (
        <ul
            className={classnames(
                `title-component-holder`,
                className,
            )}
        >
            <li>{label}</li>
            <li>
                <hr />
            </li>
        </ul>
    );
};

Title.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.any,
};

export default Title;
