import React, { useEffect, useState } from 'react';
import { registerToastMessage } from '../../../actions/sync-actions/uiActions';
import { TOAST_TYPES } from '../../../constants/uiConstants';
import usersDataService from '../../../services/usersDataService';
import Button from "../button";
import Popover from "../popover";
import { t } from '../../../helpers/translate';

import './bcApps.scss';

const BcApps = (props) => {
    const [appsData, setAppsData] = useState([]);
    const { email, partnerId, dispatch, name } = props;

    useEffect(() => {
        const subscription = !usersDataService.userProfile.subscribe(({ is_betconstruct_user: isBetUser }) => {

            const applicationsURL = isBetUser ? 'https://api.accounts-bc.com/v1/applications' : 'https://static.fansites.club/fs/ott-configs/apps.json';
            fetch(applicationsURL)
                .then(response => response.json())
                .then(data => {
                    const newData = data.filter(item => item.platform !== "OTT").map((item, id) => item.hasOwnProperty('platform') ? {
                        id,
                        name: item.platform,
                        url: item.link,
                        icon: item.icon_url
                    } : item);

                    if (email === 'grigor.korkotyan@vivaromedia.am') {
                        if (partnerId) {
                            newData.push({
                                "name": "OTT",
                                "icon": "https://static.fansites.club/fs/ott-configs/icons/ott_icon.svg",
                                "url": `https://accounts.fansites.club/#/wl-setup/limited-setup?hideHeader=true&projectId=${partnerId}&projectName=${encodeURIComponent(name)}`,
                            });
                        } else {
                            newData.push({
                                "name": "OTT",
                                "icon": "https://static.fansites.club/fs/ott-configs/icons/ott_icon.svg",
                                error: () => {
                                    dispatch(registerToastMessage({
                                        type: TOAST_TYPES.ERROR,
                                        message: 'You do not have a project in SpringBME, as soon you create it you will be able to build and manage you OTT website',
                                        timeout: 4000
                                    }));
                                }
                            });
                        }
                    }
                    setAppsData(newData);
                });
        });
        return () => {
            subscription && subscription.unsubscribe();
        };
    }, []);

    return !!appsData.length && (
        <Popover
            selfSizing
            position="bottom"
            content={
                <div className="apps-popover">
                    {appsData.map(app => {
                        const appNameCleaned = app.name.toLowerCase().replace(' ', '');
                        return appNameCleaned !== 'friendship' && (
                            partnerId || app.name !== 'OTT' ? <a
                                    key={app.id}
                                    href={app.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={`app-icon icon-${appNameCleaned}`}/>
                                    <p className="ellipsis-text">
                                        {app.name}
                                    </p>
                                </a>
                                :
                                <a
                                    key={app.id}
                                    onClick={app.error}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={`app-icon icon-${appNameCleaned}`}/>
                                    <p className="ellipsis-text">
                                        {app.name}
                                    </p>
                                </a>
                        );
                    })}
                </div>
            }
        >
            <Button
                tooltip={t('More Products')}
                icon="icon-apps"
                appearance="minimal"
                color="default"
            />
        </Popover>
    );
};

export default BcApps;
