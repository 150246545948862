export const DROP_DOWN_CONFIG = {
    loadLimit: 50
};

export const inputConfig = {
    type: [
        'text',
        'file',
        'search',
        'color',
        'number',
        'password',
        'textarea',
    ],
    appearance: [
        'minimal',
        'outline',
        'light',
        'inline',
    ],
    size: [
        'small',
        'big',
        'default',
    ],
    itemsDirection: [
        'start',
        'end',
    ],
    cornerRadius: [
        'round',
        'smooth',
    ],
    labelAppearance: [
        'swap',
        'title',
        'none',
    ],
};

export const popoverConfig = {
    position: [
        'auto-start',
        'auto',
        'auto-end',
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right',
        'right-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'left-end',
        'left',
        'left-start'
    ],
    destination: [
        'portal',
        'wrapper',
    ],
    animation: [
        'fadeIn',
        'none',
    ],
};

export const buttonConfig = {
    appearance: [
        'default',
        'outline',
        'minimal',
        'grayscale',
        'light',
    ],
    size: [
        'small',
        'medium',
        'default',
        'big',
    ],
    color: [
        'primary',
        'hero',
        'accent',
        'brand',
        'danger',
        'confirm',
        'warning',
        'default',
        'peach',
        'jaffa',
        'contrast',
    ],
    flexibility: [
        'full-width',
        'content-size',
        'content-width',
        'default',
    ],
    itemsDirection: [
        'start',
        'end',
    ],
    cornerRadius: [
        'round',
        'smooth',
    ],
    tag: [
        'button',
        'a',
        'NavLink',
    ],
    type: [
        'button',
        'submit',
        'reset',
    ],
    tooltipPosition: [
        'bottom',
        'top',
        'left',
        'right',
    ],
};

export const notificationConfig = {
    type: [
        'success',
        'info',
        'warning',
        'error',
    ],
    mode: [
        'default',
        'persistent',
    ],
};

export const switcherConfig = {
    color: [
        'primary',
        'confirm',
    ],
    size: [
        'small',
        'big',
    ],
    labelPosition: [
        'right',
        'left',
        'top',
        'bottom',
    ],
    labelAlignment: [
        'start',
        'center',
        'end',
    ],
};

export const detailRowConfig = {
    appearance: [
        'wide',
        'compact',
    ],
    theme: [
        'default',
        'gradient',
        'danger',
    ]
};

export const moduleTitleConfig = {
    cornerRadius: [
        'position-radius',
        'no-radius',
        'full-radius',
    ],
    position: [
        'top',
        'bottom',
    ],
    size: [
        'small',
        'medium',
        'big',
        'extra-big',
    ]
};
