import {checkIsStaging} from "../helpers/userHelpers";

const apiHost = (window.location.host.includes('vstream') || window.location.host.includes('friendship')) ? window.location.host.replace(/vstream|friendship/, 'vstreamapi') : 'vstreamapi-dev.feedconstruct.com';

const apiProtocol = 'https:';
const wsProtocol = apiProtocol === 'https:' ? 'wss:' : 'ws:';

export const apiUrl = `${apiProtocol}//${apiHost}`;
const wsUrl = `${wsProtocol}//${apiHost}`;

const Config = {
    main: checkIsStaging(window.location.host) ? {
        currency: "€",
        ssoLogin: `${apiUrl}/api/sso/login`,
        ssoRegistration: "https://www.accounts-bc.com/registration",
        betSsoProfile: "https://www.accounts-bc.com/profile",
        feedSsoProfile: "https://accounts.feedconstruct.com/profile/general-information",
        baseHost: `${apiUrl}/api/`,
        scoutDataBaseHost: "https://scoutdata.feedconstruct.com/index.php",
        socketServerURL: wsUrl,
        googleAnalytics: 'UA-134457709-1',
        springBME: 'https://console.springbme.com',
        consoleBetconstruct: 'https://console.betconstruct.com'
    } : {
        currency: "€",
        ssoLogin: `${apiUrl}/api/sso/login`,
        ssoRegistration: "https://stage.accounts-bc.com/registration",
        betSsoProfile: "https://www.accounts-bc.com/profile",
        feedSsoProfile: "https://accounts.feedconstruct.com/profile/general-information",
        baseHost: `${apiUrl}/api/`,
        scoutDataBaseHost: "https://scoutdata-stage.feedconstruct.com/index.php", //"https://scoutdatastage.feedconstruct.com/index.php",//"http://devmgfdsdata.betconstruct.int/",//https://scoutdatastage.megafeed.com/", //"https://scoutdatastage.megafeed.com/",  "https://devmgfdsdata.betconstruct.int/"
        socketServerURL: wsUrl,
        springBME: 'http://devbmefront.betconstruct.int',
        consoleBetconstruct: 'http://devbmefront.betconstruct.int'
    },
};

export default Config;
