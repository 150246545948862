import React, {Component} from "react";
import PropTypes from "prop-types";
import componentDataProvider from "../../../hocs/componentDataProvider";
import bookingDataService from "../../../services/bookingDataService";
import {createSelector} from "reselect";
import {connect} from "react-redux";
import {format} from "date-fns";
import {selectAutobookingRule, toggleAutobookingRulesSection, setAutobookingRuleEditId} from "../../../actions/sync-actions/autoBooking";
import {openPopup, resetStyle} from "../../../actions/sync-actions/uiActions";
import { popupConfig } from "../../../configs/popupConfig";
import {SetMultipleFiltersValue} from "../../../actions/sync-actions/filters";
import {AUTOBOOKING_RULES_IDS, EVENTS} from "../../../constants/reducerKeys";
import {ToggleFiltersData} from "../../../actions/sync-actions/filtersData";
import Button from "../button";

import './horizontalSlider.scss';
import Popover from "../popover";
import Menu from "../menu";
import { t } from '../../../helpers/translate';

class RulesHorizontalSlide extends Component {
    static propTypes = {
        selectedRuleIds: PropTypes.array,
        hideRules: PropTypes.bool,
        eventsType: PropTypes.string,
        userProfile: PropTypes.object
    };

    static requiredProps = {
        autoBookingRulesData: PropTypes.array,
    };

    static defaultProps = {
        selectedRuleIds: []
    };

    constructor (props) {
        super(props);
        this.state = {
            rules: [],
            expand: false,
            openedRuleMenu: null,
            lastScrollPosition: 0
        };
        this.toggleSelectedRule = this.toggleSelectedRule.bind(this);
        this.handleSlideClick = this.handleSlideClick.bind(this);
        this.handleAutoBookingRules = this.handleAutoBookingRules.bind(this);
        this.handleRuleDelete = this.handleRuleDelete.bind(this);
        this.handleRuleFreeze = this.handleRuleFreeze.bind(this);
        this.handleRuleEdit = this.handleRuleEdit.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);

        this.slidesRef = React.createRef();
    }

    static getDerivedStateFromProps (nextProps) {
        // open rules slide if at least one rule exists
        if (nextProps.autoBookingRulesData && ((nextProps.autoBookingRulesData.length && nextProps.hideRules) || (!nextProps.hideRules && !nextProps.autoBookingRulesData.length))) {
            nextProps.dispatch(toggleAutobookingRulesSection());
        }

        return null;
    }

    /**
     * @description Handles setting rules data to state
     * @param data
     */
    handleAutoBookingRules (data) {
        data && this.setState({rules: data});
    }

    toggleExpand () {
        this.setState({expand: !this.state.expand});
    }

    handleSlideClick (ruleId, ruleName, isRuleOutDated) {
        if (isRuleOutDated) return;
        const { eventsType, selectedRuleIds } = this.props;
        return () => this.toggleSelectedRule(selectedRuleIds, {id: ruleId, name: ruleName}, eventsType);
    }

    /**
     * @description Handles rule edit
     * @param rule
     * @returns {Function}
     */
    handleRuleEdit (rule) {
        return (e) => {
            e.stopPropagation();
            this.setState({ openedRuleMenu: null });
            this.props.dispatch(openPopup('message', {
                ...popupConfig.ruleEdit,
                handleCallBack: () => {
                    this.props.dispatch(resetStyle(" "));
                    this.props.dispatch(setAutobookingRuleEditId(rule.id));
                    setTimeout(() => {
                        this.props.dispatch(openPopup('ruleCreation', {
                            ...popupConfig.ruleCreation,
                            title: t('Update Autobooking rule')
                        }));
                    });
                }
            }));
        };
    }

    /**
     * @description Handles rule freeze and unfreeze
     * @param rule
     * @returns {Function}
     */
    handleRuleFreeze (rule) {
        return (e) => {
            const { dispatch } = this.props;
            e.stopPropagation();
            this.setState({ openedRuleMenu: null });
            const frozen = rule.is_frozen | 0,
                message = !frozen ? `${t('You are about to freeze already autobooked matches. The freezing will not work on matches that are already live.')}`
                    : `${t('You are about to unfreeze the autobooked matches. The unfreezing will also work on matches that are already live.')}`,
                title = !frozen ? t('Freeze the Rule') : t('Unfreeze the Rule');

            dispatch(openPopup("message", {
                ...popupConfig.rulefreeze,
                title,
                message,
                handleCallBack: () => {
                    bookingDataService.updateAutobookingRule(rule.id, {is_frozen: !frozen}, () => {
                        // TODO CHANGE !!!
                        // dispatch(SetMultipleFiltersValue(eventsType, {[AUTOBOOKING_RULES_IDS]: { value: [rule.id], excludes: false }}, "events"));
                    });
                }
            }));
        };
    }

    toggleSelectedRule (selectedRuleIds, rule, eventsType) {
        const dataForRequest = {
            value: selectedRuleIds.includes(rule.id)
                ? selectedRuleIds.filter(id => id !== rule.id)
                : [...selectedRuleIds, rule.id],
            excludes: false
        };

        this.props.dispatch(SetMultipleFiltersValue(eventsType, {[AUTOBOOKING_RULES_IDS]: dataForRequest}, "events", true));
        this.props.dispatch(selectAutobookingRule(eventsType, rule.id));
        this.props.dispatch(ToggleFiltersData(`${EVENTS}_${eventsType}_${AUTOBOOKING_RULES_IDS}`, {id: rule.id, name: rule.name}));
    }

    /**
     * @description Handles rule deletion
     * @param rule
     * @returns {Function}
     */
    handleRuleDelete (rule) {
        return (e) => {
            e.stopPropagation();
            this.setState({ openedRuleMenu: null });

            const {selectedRuleIds, eventsType, dispatch} = this.props, frozen = rule.is_frozen | 0,
                cbFn = () => {
                    this.toggleSelectedRule(selectedRuleIds, rule, eventsType);
                };

            dispatch(openPopup("message",
                {...(!frozen ? popupConfig.ruleDelete : popupConfig.frozenRuleDelete),
                    handleCallBack: () => !frozen ? bookingDataService.deleteRule(rule.id, {keep: 0}, selectedRuleIds.includes(rule.id) && cbFn) : null,
                    handleCloseCallBack: () => bookingDataService.deleteRule(rule.id, !frozen ? {keep: 1} : {}, selectedRuleIds.includes(rule.id) && cbFn)
                }));
        };
    }

    /**
     * @description Handles showing rule details
     * @param rule
     * @returns {Function}
     */
    showDetails (rule) {
        return (e) => {
            e.stopPropagation();
            this.setState({ openedRuleMenu: null });
            this.props.dispatch(openPopup("ruleDetails", {title: rule.name, rule}));
        };
    }

    onWheel = (e) => {
        e.preventDefault();
        const container = this.slidesRef.current;
        if (container) {
            const maxScrollPosition = container.scrollWidth;
            let delta = ((e.deltaX || -e.wheelDelta || e.detail) >> 10) || 1;
            delta = delta * (-300);
            const newPosition = this.state.lastScrollPosition - delta;
            if (container.contains(e.target) && newPosition <= maxScrollPosition && newPosition >= 0) {
                container.scrollTo({
                    top: 0,
                    left: newPosition,
                    behaviour: 'smooth'
                });
                this.setState({lastScrollPosition: newPosition});
            }
        }
    };

    // @TODO fix
    //
    // componentDidMount() {
    //     this.slidesRef.current && this.slidesRef.current.addEventListener('wheel', this.onWheel);
    // }
    //
    // componentWillUnmount() {
    //     this.slidesRef.current && this.slidesRef.current.removeEventListener('wheel', this.onWheel);
    // }

    render () {
        const {selectedRuleIds, autoBookingRulesData, hideRules} = this.props;

        if (hideRules) return null;

        return (
            <div className="r-slides-holder">
                <div className="r-slides" ref={this.slidesRef} >
                    <ul>
                        <li>
                            <Button
                                icon="icon-plus"
                                color="confirm"
                                onClick={() => this.props.dispatch(openPopup('ruleCreation',  popupConfig.ruleCreation))}
                                tooltip={t('Add AutoBooking rule')}
                                tooltipPosition="right"
                            />
                        </li>
                        {autoBookingRulesData && autoBookingRulesData.map(rule => {
                            const startDate = rule['valid_date_period'].start * 1000;
                            const endDate = rule['valid_date_period'].end * 1000;
                            const {id, name, sport, sport_id: sportId} = rule;
                            const dateNow = Date.now();
                            const isRuleOutDated = endDate ? endDate < dateNow : false;
                                // disableFreezeToggle = isRuleOutDated || (freeTrialIsOver && !isLive) ? 'disabled' : '',
                             const isAllSport = sportId === -1 || (!sportId && !sport);
                            return (
                                <li key={id} onClick={this.handleSlideClick(id, name, isRuleOutDated)}>
                                    <ul className={`r-item-holder ${selectedRuleIds.includes(id) ? "active" : ""} ${(rule.is_frozen | 0) ? "frozen" : ""} ${isRuleOutDated ? "outdated" : ""}`}>
                                        <li title={`${(startDate && format(new Date(startDate), 'dd MMM yyyy')) || t('No date')} - ${(endDate && format(new Date(endDate), 'dd MMM yyyy')) || t('No date')}`}>
                                            <div
                                                className={`ri-icon si-color si-background si-${isAllSport ? "allsport" : sport && sport.alias ? sport.alias.replace(/ /g, '').toLowerCase() : ""}`}
                                                title={isAllSport ? t('All Sports') : sport ? sport.name : ""}
                                            />
                                            <div className="ri-title ellipsis-text">{name}</div>
                                        </li>
                                        <li onClick={(e) => e.stopPropagation()}>
                                            <Popover
                                                selfSizing
                                                position="bottom-end"
                                                opened={this.state.openedRuleMenu === rule.id}
                                                onClose={() => this.setState({ openedRuleMenu: null})}
                                                content={
                                                    <Menu
                                                        data={[
                                                            {
                                                                onClick: this.showDetails(rule),
                                                                name: t('Details'),
                                                                icon: 'icon-view-doc',
                                                            },
                                                            {
                                                                onClick: this.handleRuleEdit(rule),
                                                                name: t('Edit'),
                                                                icon: 'icon-edit',
                                                                disabled: !!(rule.is_frozen | 0),
                                                            },
                                                            {
                                                                onClick: this.handleRuleFreeze(rule),
                                                                name: !!(rule.is_frozen | 0) ? t('Unfreeze') : t('Freeze'),
                                                                icon: !!(rule.is_frozen | 0) ? 'icon-composite' : 'icon-dynamic',
                                                                disabled: !!isRuleOutDated
                                                            },
                                                            {
                                                                type: 'divider',
                                                            },
                                                            {
                                                                onClick: this.handleRuleDelete(rule),
                                                                name: t('Delete'),
                                                                color: 'danger',
                                                                icon: 'icon-delete',
                                                            },
                                                        ]}
                                                    />
                                                }
                                            >
                                                <Button
                                                    onClick={(e) => {
                                                        this.setState({ openedRuleMenu: rule.id});
                                                        e.stopPropagation();
                                                    }}
                                                    appearance="minimal"
                                                    color="default"
                                                    size="medium"
                                                    icon="icon-more"
                                                />
                                            </Popover>
                                        </li>
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return createSelector(
        [
            state => state.ui && state.ui.eventsType,
            state => state.autoBookingRules && state.autoBookingRules[state.ui.eventsType] && JSON.stringify(state.autoBookingRules[state.ui.eventsType].selectedRuleIds),
            state => state.autoBookingRules && state.autoBookingRules.hideRules,
        ],
        (eventsType, selectedRuleIds, hideRules) => ({
            eventsType,
            selectedRuleIds: selectedRuleIds && JSON.parse(selectedRuleIds),
            hideRules
        })
    );
};

export default connect(mapStateToProps)(componentDataProvider(RulesHorizontalSlide));
