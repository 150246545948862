import {BehaviorSubject} from "rxjs";
import Helpers from "../helpers/helperFunctions";
import eventsDataService from "./eventsDataService";

class WatchListDataService {

    constructor () {
        this.initialized = false;
        this.storageKey = '';
        this._watchList = new BehaviorSubject([]);
        this._watchListLoading = new BehaviorSubject(false);
    }

    setWatchListStorageKey (organizationID) {
        if (this.storageKey) return;
        this.storageKey = `${organizationID}-watchListIds`;
        this._watchList.next(this.getFromStorage().map((id) => ({ id })));
    }

    init () {
        if (this.initialized) return;
        const watchListIds = this.getFromStorage();
        this.getEvents(watchListIds);
    }

    getFromStorage () {
        const watchListIdsStringify = localStorage.getItem(this.storageKey) || '[]';
        return JSON.parse(watchListIdsStringify);
    }

    setToStorage (eventIds) {
        localStorage.setItem(this.storageKey, JSON.stringify(eventIds));
    }

    removeFromStorage (eventId, removeAll) {
        if (removeAll) {
            localStorage.removeItem(this.storageKey);
        }
        const eventIds = this.getFromStorage() || [];
        return Helpers.removeElementFromArray(eventIds, eventId);
    }

    getEvents (ids) {
        if (!ids.length) return;
        if (!this.initialized) this.initialized = true;
        this._watchListLoading.next(true);
        eventsDataService.getEventsByIds(ids, {}, true).then((data) => {
            this._watchList.next(data);
            this._watchListLoading.next(false);
        }).catch((err) => {
            this._watchListLoading.next(false);
        });
    }

    addToWatchList (eventID) {
        if (this._watchListLoading.getValue()) return;

        const watchListIds = this.getFromStorage();
        const mergedIds = [...new Set([...watchListIds, eventID])];
        this.setToStorage(mergedIds);
        this.getEvents(mergedIds);
    }

    removeFromWatchList (eventID) {
        const watchList = this._watchList.getValue();
        const builtEventIds = this.removeFromStorage(eventID);
        this.setToStorage(builtEventIds);
        this._watchList.next(Helpers.removeObjFromArray(watchList, { id: eventID }));
        // this.getEvents(builtEventIds);
    }

    get watchList () {
        return this._watchList;
    }

    get watchListLoading () {
        return this._watchListLoading;
    }

}

export default new WatchListDataService();
