import {
    ADD_TO_UPCOMING,
    CHECK_CART_EVENTS_EXISTENCE, INIT_SELECTIONS_DATA_WATCHER
} from "../action-types";

export const checkCartEventsExistence = () => {
    return {
        type: CHECK_CART_EVENTS_EXISTENCE,
    };
};

export const initSelectionsDataWatcher = () => {
    return {
        type: INIT_SELECTIONS_DATA_WATCHER,
    };
};

export const addToUpcoming  = (callback) => {
    return {
        type: ADD_TO_UPCOMING,
        callback,
    };
};
