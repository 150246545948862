import React from "react";
import PropTypes from "prop-types";
import Input from "../../input";
import ModalContent from "./content";
import Tag from "../../tag";
import { t } from '../../../../helpers/translate';

const SECTION_TITLES = {
    "autobooking_rule_ids": "AutoBooking",
    "dateTime": "Date range",
    "sports": "Sports",
    "regions": "Regions",
    "filterLeagues": "Leagues",
    "booking_type": "Booking types",
    "content_providers-content_providing_user_ids": "Streamer"
};


function PresetInfo (props) {
    const {popupParams: {selectedPreset, applyPreset}, closePopup} = props;

    return (
        <ModalContent
            primaryButton={{
                name: t('Apply Preset'),
                onClick: () => {
                    applyPreset(selectedPreset)();
                    closePopup();
                },
                color: 'confirm',
            }}
            secondaryButton={{
                name: t('Close'),
                onClick: closePopup,
            }}
        >
            <div className="form-grid">
                {Object.keys(selectedPreset.data).map(dataType => {
                    if (dataType === "dateTime") return null;
                    if (dataType === "q") {
                        return (
                            <Input
                                key={dataType}
                                readOnly
                                itemsDirection="end"
                                icon="icon-search"
                                label={t('Search')}
                                labelAppearance="title"
                                value={selectedPreset.data[dataType]}
                            />
                        );
                    }
                    return (selectedPreset.data[dataType]).length ? (
                        <div key={dataType} className={`modal-section t-${dataType}`}>
                            <div className="ms-head">
                                {t(SECTION_TITLES[dataType])}
                            </div>
                            <div className="ms-body">
                                {(selectedPreset.data[dataType]).map((item, index) => (
                                    <Tag
                                        name={item.name}
                                        key={index}
                                        clipped
                                    />
                                ))}
                            </div>
                        </div>
                    ) : null;
                })}
            </div>
        </ModalContent>
    );
}

PresetInfo.propTypes = {
    popupParams: PropTypes.object,
    closePopup: PropTypes.func
};

export default PresetInfo;
