import { startOfHour } from 'date-fns';
import { listTimeZones } from "timezone-support";
import { convertToTimeZone } from "date-fns-timezone";
import { SCOUT_GAMES_STATUSES } from '../../constants/statuses';
import { EVENT_TYPES } from '../../constants/uiConstants';

const workerHelperFunctions = {
    addOrReplaceArrayElements: (data = [], newData = [], uniqueKey = 'id') => {
        const oldData = JSON.parse(JSON.stringify(data));
        for (const newItem of newData) {
            const foundIndex = data.findIndex(item => item[uniqueKey] === newItem[uniqueKey]);
            if (foundIndex !== -1) {
                oldData[foundIndex] = newItem;
            } else {
                oldData.push(newItem);
            }
        }
        return oldData;
    },

    mergeDataBySubKey: function (currentData, newData, key) {
        return {
            ...currentData,
            [key]: {
                ...(currentData[key] || {}), ...(newData || {}),
                data: workerHelperFunctions.addOrReplaceArrayElements((currentData[key] && currentData[key].data) || [], newData.data, 'id')
            }
        };
    },

    mergeData: function (currentData, newData) {
        return {
            ...currentData,
            data: workerHelperFunctions.addOrReplaceArrayElements(currentData.data, newData.data, 'id')
        };
    },

    getTimeZoneTime: (timeStamp, timeZone, tz = '') => {
        const tZone = listTimeZones().includes(timeZone) ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

        return convertToTimeZone(
            timeStamp
                ? new Date(timeStamp * 1000)
                : new Date(),
            { timeZone: tz || tZone });
    },

    groupEventsByTime: (data = [], timeZone) => {
        let lastDate;
        return data.reduce((acc, val) => {
            const groupKey = startOfHour(workerHelperFunctions.getTimeZoneTime(val.start_date, timeZone)).valueOf();
            const { groupedData, flatData } = acc;
            if (!groupedData[groupKey]) {
                groupedData[groupKey] = [val];
                const oldDate = new Date(lastDate);
                const date = new Date(groupKey);
                const date1 = `${oldDate.getFullYear()}-${oldDate.getMonth() + 1}-${oldDate.getDate()}`;
                const date2 = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                flatData.push({ id: groupKey, isGroup: true, showDate: date1 !== date2, sport_alias: val.sport_alias });
                lastDate = groupKey;
            } else {
                groupedData[groupKey].push(val);
            }
            flatData.push(val);
            return acc;
        }, { groupedData: {}, flatData: [] });
    },

    updateScoutEvents: (events, updatedItem, eventsType) => {
        if (events?.data?.some(val => val.game_id === updatedItem.game_id && val.game_status !== updatedItem.game_status)) {
            if ((eventsType === EVENT_TYPES.LIST || eventsType === EVENT_TYPES.BOOKED) && (!updatedItem.hasOwnProperty('game_status') || updatedItem.game_status === SCOUT_GAMES_STATUSES.STARTED || updatedItem.game_status === SCOUT_GAMES_STATUSES.NOT_STARTED)) {
                return {
                    ...events,
                    data: events.data.map(event => event.game_id === updatedItem.game_id ? { ...event, ...updatedItem } : event)
                };
            }

            if ((eventsType === EVENT_TYPES.LIST || eventsType === EVENT_TYPES.BOOKED) && (updatedItem.game_status === SCOUT_GAMES_STATUSES.CANCELED || updatedItem.game_status === SCOUT_GAMES_STATUSES.FINISHED)) {
                const newData = events.data.filter(event => event.game_id !== updatedItem.game_id);
                const total_count = events.data.length !== newData.length ? events.total_count - 1 : events.total_count;
                return {
                    ...events,
                    data: newData,
                    total_count
                };
            }
        }
        return null;
    },

    buildReportingMapData: (data = [], type = 'all') => {
        if (!data) return [];
        const { content = [], measures = [] } = data, obj = {};
        for (let k = 0; k < measures.length; k++) {
            obj[measures[k]['field']] = {};
            for (let i = 0; i < content.length; i++) {
                if (content[i]['country'] && Object.keys(content[i]['country']).length) {
                    obj[measures[k]['field']][[content[i]['country'].iso3_code]] = {
                        "value": content[i][measures[k]['field']],
                        "name": content[i]['country'].name,
                        "isoCode": content[i]['country'].iso3_code
                    };
                }
            }
        }
        return type !== 'all' ? obj[type] : obj;
    }
};

export default workerHelperFunctions;
