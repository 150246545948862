import {PARTNERSHIP_TYPES} from "./uiConstants";

export const ODDS_FEED_PRODUCT_ID = 1;
export const SCOUTING_PRODUCT_ID = 2;
export const VIDEO_STREAMING_PRODUCT_ID = 3;
export const STATISTICS_PRODUCT_ID = 4;
export const BOOKED_ID = 1;
export const AUTO_BOOKED_ID = 2;

export const ODDS_FEED_PRODUCT = "Odds feed";
export const SCOUTING_PRODUCT = "Live scouting";
export const VIDEO_STREAMING_PRODUCT = "Video streaming";
export const STATISTICS_PRODUCT = "Statistics";
export const BOOKED = "Manually Booked";
export const AUTO_BOOKED = "AutoBooked";

export const CONTENT_PROVIDERS_PRODUCTS_DATA = [
    {
        "id": ODDS_FEED_PRODUCT_ID,
        "name": ODDS_FEED_PRODUCT
    },
    {
        "id": SCOUTING_PRODUCT_ID,
        "name": SCOUTING_PRODUCT
    },
    {
        "id": VIDEO_STREAMING_PRODUCT_ID,
        "name": VIDEO_STREAMING_PRODUCT
    },
    {
        "id": STATISTICS_PRODUCT_ID,
        "name": STATISTICS_PRODUCT
    }
];

export const DAYS_OF_WEEK = [
    {
        "id": 2,
        "name": "Mon"
    },
    {
        "id": 3,
        "name": "Tue"
    },
    {
        "id": 4,
        "name": "Wed"
    },
    {
        "id": 5,
        "name": "Thu"
    },
    {
        "id": 6,
        "name": "Fri"
    },
    {
        "id": 7,
        "name": "Sat"
    },
    {
        "id": 1,
        "name": "Sun"
    }
];

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const CONTENT_PROVIDERS_BOOK_TYPE = [
    {
        "id": BOOKED_ID,
        "name": BOOKED
    },
    {
        "id": AUTO_BOOKED_ID,
        "name": AUTO_BOOKED
    }
];

export const SCOUT_DATA_COVER_TYPES = [
    {
        "id": 1,
        "name": 'TV'
    },
    {
        "id": 2,
        "name": 'Stadium'
    }
];

export const SCOUT_DATA_STATUS_TYPES = [
    {
        "id": 1,
        "name": 'LIVE'
    },
    {
        "id": 2,
        "name": 'Prematch'
    }
];

export const CONTENT_PROVIDERS_PRODUCTS = {
    [VIDEO_STREAMING_PRODUCT_ID]: VIDEO_STREAMING_PRODUCT,
    [ODDS_FEED_PRODUCT_ID]: ODDS_FEED_PRODUCT,
    [STATISTICS_PRODUCT_ID]: STATISTICS_PRODUCT,
    [SCOUTING_PRODUCT_ID]: SCOUTING_PRODUCT
};

export const CONTENT_PROVIDERS_TABS = [
    VIDEO_STREAMING_PRODUCT_ID, ODDS_FEED_PRODUCT_ID, STATISTICS_PRODUCT_ID, SCOUTING_PRODUCT_ID
];

export const DATA_STATES = {
    loading: false,
    loaded: false,
    failed: false
};

export const REPORTING_EMAIL_PERIODS = [
    {
        name: 'Monthly',
        id: 'monthly'
    },
    {
        name: 'Weekly',
        id: 'weekly'
    },
    {
        name: 'Yearly',
        id: 'yearly'
    }
];

export const PAYMENT_TYPES = {
    1: 'PPM (Pay Per Match)',
    2: 'Monthly Subscription'
};

export const PROJECT_TYPE = {
    0: 'WL/TK',
    1: 'API'
};

export const PARTNER_TYPE = {
    0: 'Real',
    1: 'Test',
};

export const PAYMENT_TYPE = {
    1: 'Wallet',
    2: 'Invoice',
};

export const FS_VIDEO_STATUS = {
    1: 'Trial',
    0: 'Inactive',
    2: 'Active',
};

export const PAYMENT_FREQUENCY = [
    {
        id: 1,
        name: 'Monthly'
    },
    {
        id: 2,
        name: 'Quarterly'
    },
    {
        id: 3,
        name: 'By season'
    }
];

export const AGREEMENT_STATUSES = [
    {
        id: 1,
        name: 'Yes'
    },
    {
        id: 2,
        name: 'No'
    },
    {
        id: 3,
        name: 'In process'
    }
];

export const PARTNERSHIP_TYPES_LIST = [
    {
        id: PARTNERSHIP_TYPES.FIXED,
        name: 'Fixed price'
    },
    {
        id: PARTNERSHIP_TYPES.SHARED,
        name: 'Revenue sharing'
    },
    {
        id: PARTNERSHIP_TYPES.MIXED,
        name: 'Combination of fixed and revenue sharing'
    }
];

export const LANGUAGES = [
    {id: 'eng', name: "English"}
];

export const APPROVE_STATUSES = [
    {
        id: 0,
        name: 'Trial'
    },
    {
        id: 1,
        name: 'Inactive'
    },
    {
        id: 2,
        name: 'Active'
    }
];

export const BUSINESS_MODEL_OPTIONS = {
    FREE: 'free',
    FIXED: 'fixed',
    MIXED: 'mixed',
    PPM: 'ppm',
};

export const BUSINESS_MODEL = [
    {
        id: BUSINESS_MODEL_OPTIONS.PPM,
        name: 'Pay Per Match',
    },
    {
        id: BUSINESS_MODEL_OPTIONS.FIXED,
        name: 'Fixed Monthly Fee',
    },
    {
        id: BUSINESS_MODEL_OPTIONS.MIXED,
        name: 'Mixed Package',
    },
    {
        id: BUSINESS_MODEL_OPTIONS.FREE,
        name: 'Free Package',
    },
];
