import React from 'react';
import classnames from 'classnames';
import Icon from "../icon";

import './checkbox.scss';

const Checkbox = props => {
    const {
        label,
        onChange,
        checked,
        reversed,
        className,
        readOnly,
        ...restProps
    } = props;

    return (
        <label
            className={classnames('checkbox-holder', className, {
                reversed,
                'read-only': readOnly,
            })}
        >
            <input
                type="checkbox"
                onChange={onChange}
                checked={checked}
                readOnly={readOnly}
                {...restProps}
            />
            <small>
                <Icon type="icon-check" size={18} className="not-animation-fadeInUp" />
            </small>
            {label && <div className="checkbox-label">{label}</div>}
        </label>
    );
};

export default Checkbox;
