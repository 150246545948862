import usersDataService from "../services/usersDataService";
import {EVENT_TYPES} from "../constants/uiConstants";
import { REPORTER, SCOUT_TRADER, STREAMER, STREAMING_VIEWER } from "../constants/userRoles";
import { EVENTS_PAGE, FEDERATIONS_PAGE } from "../constants/pageRoutes";
import Helpers from './helperFunctions';
import { getBasePath, getSubPath } from './routeHelper';
import { matchPath } from 'react-router-dom';
import { getRoleIds } from './userHelpers';

/**
 * @description get Project Statuses
 * @return {object}
 */
export const getProjectStatus = () => {
    const oneDayByTimeStamp = 86400000;
    const userProfile = usersDataService.profile.getValue();
    const {organization: {approve_status, trial_end, is_enabled, is_api_partner}, is_betconstruct_user} = userProfile;
    const trialEndByTimeStamp = trial_end ? new Date(trial_end * 1000).getTime() : 0;
    const fromTodayToTrialEndDateRest = new Date().getTime() - trialEndByTimeStamp;
    const isTrial = trial_end ? new Date().getTime() < new Date(trial_end * 1000).getTime() : null;
    const isDemo = approve_status === 0 && !isTrial && !!is_betconstruct_user;
    const isWalletPayment = userProfile.organization.payment_type !== 2;
    const onlyIsDemo = approve_status === 0 && !!is_betconstruct_user;
    const isPending = approve_status === 1 && !isTrial;
    const onlyIsPending = approve_status === 1;
    const isLive = approve_status === 2 || !is_betconstruct_user;
    const isEnabled = !!is_enabled || !is_betconstruct_user;
    const freeTrialIsOverForPopups = trial_end ? (new Date().getTime() > trialEndByTimeStamp && fromTodayToTrialEndDateRest < (oneDayByTimeStamp * 15)) && !isLive : null;
    const freeTrialIsOverForLivePopups = trial_end ? (new Date().getTime() > trialEndByTimeStamp && fromTodayToTrialEndDateRest < (oneDayByTimeStamp * 15)) && isLive : null;
    const freeTrialIsOver = trial_end ? new Date().getTime() > trialEndByTimeStamp && !isLive : null;
    const freeTrialIsOverForLive = trial_end ? new Date().getTime() > trialEndByTimeStamp && isLive : null;
    const lessThanDayLeft = trial_end && isTrial ? new Date().setDate(new Date().getDate() + 1) > new Date(trial_end * 1000).getTime() : null;
    const isApiPartner = is_api_partner;
    // console.info({ isTrial, isDemo, isPending, onlyIsPending, freeTrialIsOver, freeTrialIsOverForLive, isLive, isEnabled, onlyIsDemo, lessThanDayLeft, isApiPartner }, '------');
    return {
        isTrial,
        isDemo,
        isPending,
        onlyIsPending,
        freeTrialIsOver,
        freeTrialIsOverForLive,
        freeTrialIsOverForPopups,
        freeTrialIsOverForLivePopups,
        isLive,
        isEnabled,
        onlyIsDemo,
        lessThanDayLeft,
        isApiPartner,
        isWalletPayment
    };
};

/**
 * @description Get events default type by roles.
 * @param roles
 * @param basePath
 * @returns {string}
 */
export const getDefaultEventType = (roles = [], basePath = getBasePath()) => {
    switch (true) {
        case Helpers.checkRole(STREAMER):
            return EVENT_TYPES.UPCOMING;
        case basePath === '/streaming' && roles.some(role => role.id === STREAMING_VIEWER):
        case basePath === '/scouting' && roles.some(role => role.id === SCOUT_TRADER):
            return EVENT_TYPES.BOOKED;
        case Helpers.checkRole(REPORTER):
            return EVENT_TYPES.HISTORY;
        default:
            return EVENT_TYPES.LIST;
    }
};

export const getServiceUrl = (endpoint, moderatorEndpoint) => {
    const basePath = getBasePath();
    const subPath = getSubPath();

    const { id } = matchPath(window.location.pathname, {
        path: `${basePath}${subPath}/:id`,
        exact: true,
        strict: false
    })?.params || {};

    if (subPath === `/${FEDERATIONS_PAGE}` && id) {
        return `moderator/federations/${id}/${endpoint}`;
    }

    return moderatorEndpoint;
};

export const federationsActionInvisibility = (pathname, eventsType) => {
    switch (true) {
        case eventsType === EVENT_TYPES.HISTORY:
        case !pathname.includes(FEDERATIONS_PAGE):
            return true;
        default:
            break;
    }
};

export const hideSubHeader = (pathname) => {
    const basePath = getBasePath();
    const subPath = getSubPath();
    const { id } = matchPath(window.location.pathname, {
        path: `${basePath}${subPath}/:id`,
        exact: true,
        strict: false
    })?.params || {};

    const restrictedRoutes = ['/watchlist', '/streamer', '/reporting'];

    if (subPath === '/operators' && !id) restrictedRoutes.push('/operators');

    return restrictedRoutes.some((route) => pathname.includes(route));
};

export const getViewerRestrictedRoutes = () => {
    const roleIds = getRoleIds(usersDataService.profile.getValue().roles);
    if (getBasePath() === '/scouting') {
        return roleIds.includes(SCOUT_TRADER) ? [EVENTS_PAGE] : [];
    }

    return roleIds.includes(STREAMING_VIEWER) ? [EVENTS_PAGE] : [];
};
