import React from "react";
import PropTypes from "prop-types";
import componentDataProvider from "../../../hocs/componentDataProvider";
import DropDown from "../../../components/shared/dropDown";
import Helpers from "../../../helpers/helperFunctions";
import Input from "../../../components/shared/input";
import DropDownItemWithPrice from "../../../components/shared/dropDown/pieces/dropDownItemWithPrice";
import { t } from '../../../helpers/translate';

const EventCountryPrices = (props) => {
    const {
        eventId,
        selectedCountries,
        productData,
        onCountriesChange,
        countriesList,
        onClose,
        onOpen
    } = props;

    const  boughtCountries = productData?.booking?.bought_countries;
    const {blocked_countries, countries_price} = productData;

    const filteredCountriesList = countriesList
        .filter(country => countries_price[country["iso3_code"]] && !boughtCountries?.includes(country["iso3_code"]) && !blocked_countries?.includes(country["iso3_code"]))
        .map(val => ({...val, price: countries_price[val["iso3_code"]]}));

    const initialSelections = Helpers.convertArrayToObjectByKey(filteredCountriesList.filter(val => selectedCountries.includes(val["iso3_code"])), "iso3_code");
    const onUpdate = countries => {
        onCountriesChange({[eventId]: countries.map(val => val["iso3_code"])});
    };

    const handleOpen = () => {
        onOpen(filteredCountriesList.filter(val => selectedCountries.includes(val["iso3_code"])).reduce((acc, curr) => ({...acc, [curr.iso3_code]: curr.price}), {}));
    };

    return (
        filteredCountriesList?.length > 0 ? (
            <DropDown
                placeholder={t('Select countries to book for')}
                multiSelect
                keys={{key: 'iso3_code', value: 'name'}}
                onChange={onUpdate}
                onOpen={handleOpen}
                onClose={onClose}
                externalData={filteredCountriesList}
                withLazyLoad={false}
                initialSelections={initialSelections}
                labelAppearance="none"
                allowSelectAll
                rowRenderer={(item) => <DropDownItemWithPrice item={item} />}
            />
        ) : (
            <Input
                placeholder={t('No countries found with price')}
                readOnly
                labelAppearance="none"
            />
        )
    );
};

EventCountryPrices.propTypes = {
    eventId: PropTypes.number,
    countriesGroupedList: PropTypes.object,
    selectedCountries: PropTypes.array,
    boughtCountries: PropTypes.array,
    blockedCountries: PropTypes.array,
    countryPrices: PropTypes.object,
    onCountriesChange: PropTypes.func,
    countriesWithPrice: PropTypes.object,
    countriesList: PropTypes.array,
    onClose: PropTypes.func,
    onOpen: PropTypes.func
};
EventCountryPrices.requiredProps = {
    countriesList: PropTypes.array
};

export default componentDataProvider(EventCountryPrices);
