import { map, switchMap } from 'rxjs/operators';
import { SCOUT_PAGES } from '../constants/api-resources';
import { SCOUT_GAMES_STATUSES } from '../constants/statuses';
import { checkIsStaging } from '../helpers/userHelpers';
import scoutDataFetcher from './scoutDataFetcher';
import { BehaviorSubject, from, fromEvent, Subject } from 'rxjs';
import { scoutEventsDefaultParams } from '../constants/queryParams';
import { EVENT_TYPES } from '../constants/uiConstants';
import { processSelectedEventsData } from '../helpers/eventsHelper';
import { getProjectStatus } from '../helpers/uiHelpers';
import io from 'socket.io-client';

const convertScoutGameStatus = (status) => {
    switch (status) {
        case 0:
        case 1:
            return SCOUT_GAMES_STATUSES.NOT_STARTED;
        case 9:
            return SCOUT_GAMES_STATUSES.FINISHED;
        case 11:
            return SCOUT_GAMES_STATUSES.CANCELED;
        default:
            return SCOUT_GAMES_STATUSES.STARTED;
    }
};

const getData = (page, params = {}) => {
    return scoutDataFetcher.getJson('',
        {
            queryParams: {
                page,
                ...params,
            }
        }, false, true);
};

class ScoutDataService {
    constructor() {
        this._gamesData = new Subject();
        this._selectedScoutEventsList = new BehaviorSubject({});
        this._gameRequestId = 0;
        this._gameUpdatesConnector = new Subject();
        this._token = null;
    }

    _initSocketGamesUpdate() {
        if (!this._socket) {
            this.getToken().subscribe(val => {
                this._token = val.data;

                this._socket = io(process.env.REACT_APP_MODE === 'staging' || checkIsStaging(window.location.host) ? 'https://darkside.megafeed.com/' : 'https://testdarkside.megafeed.com/', {
                    query: `user_type=client&friendship_token=${this._token}`,
                    secure: true,
                    reconnect: false,
                    transports: ['websocket']
                });

                if (this._socket) {
                    fromEvent(this._socket, 'connect').pipe(
                        switchMap(() => {
                            return fromEvent(this._socket, 'new_event_status');
                        }),
                        map(val => ({
                            game_id: val?.game_id,
                            game_status: convertScoutGameStatus(val?.game_state?.match_status?.status)
                        }))
                    ).subscribe((val) => {
                            this._gameUpdatesConnector.next(val);
                        }
                    );
                }
            })
        } else {
            this._socket.connect();
        }
    }

    getSports(params) {
        return getData(SCOUT_PAGES.SPORTS, params);
    }

    getLeagues(params) {
        return getData(SCOUT_PAGES.LEAGUES, params);
    }

    getCountries(params) {
        return getData(SCOUT_PAGES.COUNTRIES, params);
    }

    toggleSelectedScoutEventsByIds(ids, key, itemsData) {
        const allSelections = this._selectedScoutEventsList.getValue();
        const currentSelections = allSelections[key] || [];
        const { selections, missingIds } = processSelectedEventsData(ids, itemsData, currentSelections, 'game_id');

        this._selectedScoutEventsList.next({ ...allSelections, [key]: selections });

        missingIds.length && getData(SCOUT_PAGES.GAMES_LIST_BY_IDS, {
            game_ids: missingIds.join(',')
        })
            .then(res => {
                const newData = res?.data;
                newData?.length && this._selectedScoutEventsList.next({
                    ...allSelections,
                    [key]: [...selections, ...(newData || [])]
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    removeAllSelectedScoutEvents(key) {
        const currentList = this._selectedScoutEventsList.getValue(),
            filteredList = { ...currentList, [key]: [] };
        this._selectedScoutEventsList.next(filteredList);
    }

    bookScoutEvents(ids) {
        return getData(SCOUT_PAGES.BOOK_GAMES, {
            game_ids: ids,
            is_wallet: getProjectStatus().isWalletPayment
        }).then(res => {
                if (res?.data === "Ok") {
                    ids.split(',').forEach(id => this._gameUpdatesConnector.next({
                        game_id: Number(id),
                        booked: 1
                    }));
                }
                return Promise.resolve(res)
            })
    }

    getFinancialReport(params = {}) {
        return from(getData(SCOUT_PAGES.FIN_REPORT, {
            ...params,
            is_wallet: getProjectStatus().isWalletPayment
        }));
    }

    getToken () {
        return from(getData(SCOUT_PAGES.GET_TOKEN));
    }

    getGames(eventsType, params = {}, reset) {
        const newParams = { ...scoutEventsDefaultParams(eventsType), ...params };
        let type = "";

        switch (eventsType) {
            case EVENT_TYPES.LIST:
                type = SCOUT_PAGES.LIST_GAMES;
                break;
            case EVENT_TYPES.BOOKED:
                type = SCOUT_PAGES.BOOKED_GAMES;
                break;
            case EVENT_TYPES.HISTORY:
                type = SCOUT_PAGES.HISTORY_GAMES;
                break;
            default:
                type = SCOUT_PAGES.LIST_GAMES;
        }
        reset && this._gamesData.next(null);
        const requestId = ++this._gameRequestId;
        getData(type, newParams)
            .then(res => {
                const data = res?.data.map(val => ({
                    ...val,
                    game_status: convertScoutGameStatus(val.game_status),
                    start_date: val.start_date_time,
                    sport_alias: val.sport_alias || val.sport_name
                })) || [];
                requestId === this._gameRequestId && this._gamesData.next({ ...res, data });
            });
    }

    stopSocketUpdates() {
        if (this._socket) {
            this._socket.disconnect();
        }
    }

    get gamesData() {
        return this._gamesData;
    }

    get selectedScoutEventsList() {
        return this._selectedScoutEventsList;
    }

    get gamesUpdates() {
        this._initSocketGamesUpdate();
        return this._gameUpdatesConnector;
    }

    get token () {
        return this._token;
    }
}

export default new ScoutDataService();
