import React, { useEffect, useRef, useState } from "react";
import Popover from "../popover";
import Input from "../input";
import InputWithDelay from "../../reusableUIComponents/inputWithDelay";
import NoData from "../noData";
import noSearchDataImage from '../../../assets/media/search.svg';
import PropTypes from 'prop-types';
import Helpers from "../../../helpers/helperFunctions";
import DropDownContent from "./pieces/dropDownContent";
import useDataFetcher from "../../../hooks/useDataFetcher";
import { DROP_DOWN_CONFIG } from "../../../configs/uiConfig";
import { t } from "../../../helpers/translate";

import './dropDown.scss';

const DropDown = props => {
    const {
        appearance,
        cornerRadius,
        rowRenderer,
        disabled,
        searchable,
        errorText,
        externalData,
        initialRequestParams,
        initialSelections,
        notInitialSelectAll,
        serviceAdditionalParams,
        allowSelectAll,
        allowDeselect,
        keys,
        label,
        labelAppearance,
        multiSelect,
        onChange,
        placeholder,
        requestParams,
        required,
        service,
        size,
        iconKey,
        suggestionMode,
        withLazyLoad,
        className,
        onClose,
        isOpen,
        onOpen,
        writeProtected,
        rowHasAction,
        requestOnOpen,
    } = props;

    const [searchKeyword, setSearchKeyword] = useState('');
    const [selections, updateSelections] = useState(initialSelections || {});
    const [data, updateData] = useState(externalData || null);
    const [scrollerRefReady, setScrollerRefReady] = useState(false);
    const [suggestion, setSuggestion] = useState(null);
    const scrollerRef = useRef(null);
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        updateData(externalData || null);
    }, [externalData]);

    useEffect(() => {
        isOpen && handleOpen(true);
        isOpen !== undefined && setIsOpened(isOpen);
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateSelections(initialSelections || {});
    }, [initialSelections]);

    const handleSelectionChange = (item, isSuggestion = false) => {
        if (isSuggestion) {
            const newItem = { [keys.key]: -1, [keys.value]: item };
            setSuggestion(newItem);
            updateSelections(newItem);
            setSearchKeyword('');
            onChange(newItem);
            setIsOpened(false);
            return;
        }
        if (multiSelect) {
            let currentSelections = Helpers.cloneDeep(selections);
            currentSelections['all'] && delete currentSelections['all'];
            if (selections[item[keys.key]]) {
                delete currentSelections[item[keys.key]];
            } else {
                currentSelections[item[keys.key].toString()] = item;
            }
            updateSelections(currentSelections);
            onChange(Object.keys(currentSelections).map(key => currentSelections[key]));
        } else {
            const val = selections[keys.key] === item[keys.key] ? {} : item;
            updateSelections(val);
            onChange(val);
            setIsOpened(false);
        }
    };

    const handleSelectAll = items => {
        if (withLazyLoad && multiSelect) {
            if (selections['all']) {
                updateSelections({});
                onChange([]);
            } else {
                updateSelections({ 'all': { [keys.key]: -1, [keys.value]: 'all' } });
                onChange([{ [keys.key]: -1, [keys.value]: 'all' }]);
            }
        } else {
            const selectedItems = Object.keys(selections);
            if (selectedItems.length !== data.length) {
                updateSelections(data.reduce((acc, val) => ({ ...acc, [val[keys.key].toString()]: val }), {}));
                onChange(data);
            } else {
                updateSelections({});
                onChange([]);
            }
        }
    };

    const handleInitialUpdate = (newData) => {
        if (multiSelect) {
            !notInitialSelectAll && updateSelections(newData.reduce((acc, val) => ({ ...acc, [val[keys.key].toString()]: val }), {}));
        } else {
            newData && newData.length === 1 && updateSelections(newData[0]);
        }
        !withLazyLoad && !externalData && typeof service === "function" && updateData(newData);
    };

    const [loadMore, rowCount] = useDataFetcher(
        service,
        { ...requestParams, ...(withLazyLoad && searchKeyword ? { q: searchKeyword.toLowerCase() } : {}) },
        updateData,
        {
            isLazy: withLazyLoad,
            isOpened,
            requestOnOpen,
            initialRequestParams,
            onInitialDataUpdate: handleInitialUpdate
        }, serviceAdditionalParams);

    const handleLoadMore = (range) => {
        if (range.stopIndex >= data?.length && data?.length >= DROP_DOWN_CONFIG.loadLimit && range.stopIndex < rowCount) {
            loadMore();
        }
    };

    const handleOpen = val => {
        setSearchKeyword('');
        setIsOpened(val);
        const shouldLoadMore = data && externalData && externalData.length === 1 && data.length === 1;
        (!data || shouldLoadMore) && withLazyLoad && loadMore(true);
        onOpen && onOpen();
    };

    const selectionsLength = Object.keys(selections).length;

    return (
        <Popover
            scrollerRef={ref => {
                scrollerRef.current = ref;
                setScrollerRefReady(!!ref);
            }}
            animation="none"
            maxItems={6}
            opened={isOpened}
            onOpen={handleOpen}
            onClose={() => onClose && onClose(selections)}
            itemHeight={46}
            selfSizing={appearance === 'inline'}
            className={appearance === 'inline' ? 'a-inline' : ''}
            header={
                searchable && (
                    <InputWithDelay
                        placeholder={t('Search')}
                        size="default"
                        labelAppearance="none"
                        initialValue={searchKeyword}
                        autoFocus
                        delay={300}
                        onChange={val => {
                            setSearchKeyword(val);
                        }}
                    />
                )
            }
            content={
                (scrollerRef.current && (!data || data.length)) || (data && !data.length && suggestionMode && searchKeyword) || (data && !data.length && selectionsLength && !searchKeyword) ? (
                    <DropDownContent
                        data={suggestion
                            ? [suggestion, ...(data || [])]
                            : !multiSelect && allowSelectAll ? [{
                                [keys.key]: -1,
                                [keys.value]: t('All')
                            }, ...(data || [])] : (data || [])
                        }
                        onSelectionChange={handleSelectionChange}
                        suggestionMode={suggestionMode}
                        searchKeyword={searchKeyword}
                        multiSelect={multiSelect}
                        rowCount={rowCount}
                        keys={keys}
                        iconKey={iconKey}
                        onSelectAll={handleSelectAll}
                        allowSelectAll={allowSelectAll}
                        scrollerRefReady={scrollerRefReady}
                        selections={selections}
                        onLoadMore={handleLoadMore}
                        withLazyLoad={withLazyLoad}
                        allowDeselect={allowDeselect}
                        ref={scrollerRef.current}
                        rowRenderer={rowRenderer}
                        rowHasAction={rowHasAction}
                    />
                ) : (
                    <NoData
                        transparent
                        image={noSearchDataImage}
                        size="extra-small"
                        text={t('And then there were none')}
                    />
                )
            }
        >
            <Input
                appearance={appearance}
                cornerRadius={cornerRadius}
                disabled={disabled}
                icon={(disabled || writeProtected) ? '' : 'icon-arrow-down-outline'}
                itemsDirection="end"
                labelAppearance={labelAppearance}
                placeholder={t(placeholder)}
                readOnly
                writeProtected={writeProtected}
                required={required}
                size={size}
                label={label}
                value={
                    selectionsLength ? multiSelect
                        ? `${selectionsLength && selectionsLength === 1 ? selections[Object.keys(selections)[0]][keys.value] : selectionsLength + ' selected'}` : keys.value.split(',').map(val => selections[val]).join(' ') : ''}
                className={className}
                errorText={errorText}
            />
        </Popover>
    );
};

DropDown.propTypes = {
    appearance: Input.propTypes.appearance,
    cornerRadius: Input.propTypes.cornerRadius,
    disabled: Input.propTypes.disabled,
    errorText: Input.propTypes.errorText,
    externalData: PropTypes.array,
    initialRequestParams: PropTypes.object,
    initialSelections: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    keys: PropTypes.object,
    label: Input.propTypes.label,
    labelAppearance: Input.propTypes.labelAppearance,
    loadMore: PropTypes.func,
    multiSelect: PropTypes.bool,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    service: PropTypes.func,
    placeholder: Input.propTypes.placeholder,
    readOnly: Input.propTypes.readOnly,
    required: Input.propTypes.required,
    selectedItem: PropTypes.object,
    size: Input.propTypes.size,
    suggestionMode: PropTypes.bool,
    withLazyLoad: PropTypes.bool,
    requestParams: PropTypes.object,
    searchable: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    iconKey: PropTypes.string,
    className: PropTypes.string,
    rowRenderer: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    allowDeselect: PropTypes.bool,
    writeProtected: PropTypes.bool,
    rowHasAction: PropTypes.bool,
    notInitialSelectAll: PropTypes.bool,
    requestOnOpen: PropTypes.bool
};

DropDown.defaultProps = {
    appearance: Input.defaultProps.appearance,
    cornerRadius: Input.defaultProps.cornerRadius,
    disabled: Input.defaultProps.disabled,
    keys: { key: 'id', value: 'name' },
    labelAppearance: Input.defaultProps.labelAppearance,
    multiSelect: false,
    placeholder: "Select Option",
    readOnly: Input.defaultProps.readOnly,
    required: Input.defaultProps.required,
    size: Input.defaultProps.size,
    suggestionMode: false,
    withLazyLoad: true,
    searchable: true,
    allowSelectAll: false,
    allowDeselect: false,
    writeProtected: false,
    rowHasAction: false,
    requestOnOpen: false
};
export default DropDown;
