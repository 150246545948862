import React, { useEffect, useState } from "react";
import { addYears, endOfDay, startOfDay } from "date-fns";
import { Formik, Field } from "formik";

import federationsDataService from "../../../services/federationsDataService";
import templatesDataService from "../../../services/templatesDataService";

import Helpers from "../../../helpers/helperFunctions";
import { t } from '../../../helpers/translate';

import Tab from "../../shared/tab";
import Tabs from "../../shared/tabs";
import LeagueSetupRow from "../../shared/leagueSetupRow";
import DropDown from "../../shared/dropDown";
import Button from '../../shared/button';
import DatePickerInput from "../../shared/datePickerInput";
import InformationMessage from '../../shared/informationMessage';

import { TOAST_TYPES } from '../../../constants/uiConstants';
import { nextDateShortcuts } from "../../../constants/dateRanges";
import { PAYMENT_FREQUENCY } from "../../../constants/dataConstants";

import { closePopup, closeRightPanel, openPopup, registerToastMessage } from "../../../actions/sync-actions/uiActions";
import OverlayContent from '../../settings/rightPanel/overlayContent';

const BusinessModelSetup = ({ federation, dispatch, callback, tab, data: leagueData }) => {
    const [selectedTab, setSelectedTab] = useState(tab ? tab : 'byFederation');
    const [leagues, setLeagues] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});

    useEffect(() => {
        if (!leagues.length && !leagueData) {
            federationsDataService.getFederationLeagues(federation.id, { without_pagination: 1 })
                .then(res => setLeagues(res.data)).catch(e => e);
        } else {
            setLeagues([leagueData]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const byFederationInitialValues = {
        seasonStartEnd: {
            start_date: Math.floor((federation.start_date ? new Date(federation.start_date.replace(/-/g, "/")) : startOfDay(new Date())) / 1000),
            end_date: Math.floor((federation.end_date ? new Date(federation.end_date.replace(/-/g, "/")) : addYears(endOfDay(new Date()), 1)) / 1000),
        },
        template_id: federation.template_id,
        payment_type: federation.payment_type,
    };

    const initialValues = selectedTab === "byFederation"
        ? byFederationInitialValues
        : {
            leagues: leagues.map(league => {
                const startDate = league?.start_date ? new Date(league.start_date) : new Date();
                const endDate = league?.end_date ? new Date(league.end_date) : new Date();

                return ({
                    league_id: league.league_id || league.id,
                    template_id: league?.template_id,
                    payment_type: league?.payment_type,
                    start_date: (startOfDay(startDate)).toISOString(),
                    end_date: endDate.toISOString(),
                    tier: 1,
                });
            })
        };

    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
    };

    const checkValuesValidity = (values) => {
        if (selectedTab === "byFederation") {
            for (let key in values) {
                if (values.hasOwnProperty(key)) {
                    if (typeof values[key] === "object" && values[key] !== null) {
                        checkValuesValidity(values[key]);
                    } else {
                        if (!values[key]) return false;
                    }
                }
            }
            return true;
        } else {
            return values.leagues.every(league => Object.keys(league).every(key => !!league[key]));
        }
    };

    const prepareDataForSubmit = (values) => {
        return Object.keys(values).reduce((acc, curr) => {
            if (curr === "seasonStartEnd") {
                return { ...acc, ...values[curr] };
            }
            return { ...acc, [curr]: values[curr] };
        }, {});
    };

    const createNewTemplate = () => {
        dispatch(openPopup('templateCreation', {
            'title': 'Template Creation',
            closeOnClickOutside: false
        }));
    };

    return (
        <Formik
            onSubmit={() => {
            }}
            initialValues={initialValues}
            key={JSON.stringify(initialValues)}
            enableReinitialize
            isInitialValid={false}
            render={({ values, setFieldValue, isValid, setValues }) => (
                <>
                    <OverlayContent
                        leftCloseButton={leagueData}
                        title={t('Business model & payments setup')}
                        subTitle={federation.name}
                        appearance="fit"
                        primaryButton={{
                            name: t('Assign & Save'),
                            disabled: !isValid || !checkValuesValidity(values) || Helpers.deepEqual(initialValues, values),
                            onClick: () => {
                                const data = selectedTab === "byFederation" ? prepareDataForSubmit(values) : values.leagues;
                                if (leagueData) {
                                    federationsDataService.attachLeaguesToFederation(federation.id, { league_id: leagueData.id }, leagueData.id, federation.name)
                                        .then((res) => {
                                            dispatch(registerToastMessage({ message: " Saved and Successfully attached" }));
                                        })
                                        .catch(() => {
                                            dispatch(registerToastMessage({
                                                type: TOAST_TYPES.ERROR,
                                                message: "This federation is disabled from Federation section"
                                            }));
                                        });
                                    federationsDataService.attachLeaguesToFederation(federation.id, data)
                                    dispatch(closePopup());
                                } else {
                                    dispatch(openPopup("message", {
                                        title: "Changing assignment type",
                                        message: "You are about to change the template assignment type. The previous inquiry will reset.",
                                        classification: "warning",
                                        handleCallBack: () => {
                                            (selectedTab === "byFederation"
                                                ? federationsDataService.attachBusinessModelToFederation(federation.id, data)
                                                : federationsDataService.attachLeaguesToFederation(federation.id, data))
                                                .then((val) => {
                                                    typeof callback === "function" && callback(selectedTemplate, val, data);
                                                    dispatch(registerToastMessage({ message: "Saved" }));
                                                    dispatch(closeRightPanel());
                                                }).catch(e => e);

                                        },
                                        primaryButton: {
                                            name: t('Continue'),
                                        }
                                    }));
                                }
                            },
                            color: 'confirm',
                        }}
                        secondaryButton={{
                            name: t('Cancel'),
                            onClick: () => dispatch(closeRightPanel()),
                        }}
                    >
                        <Tabs
                            appearance="inline"
                            selectedTabId={selectedTab}
                            onChange={handleTabChange}
                            rightAction={
                                <Button appearance="light" icon="icon-plus" color="confirm" onClick={createNewTemplate}
                                        iconSize={20}>
                                    New Template
                                </Button>
                            }
                        >
                            {!leagueData &&
                            <Tab title={t('By Federation')} id="byFederation">
                                <div className="form-grid col-2">
                                    <div className="span-2">
                                        <InformationMessage
                                            icon="icon-info"
                                            title={t('Make it easy!')}
                                            text={t('If you assign a template to the federation all its leagues will inherit it.')}
                                        />
                                    </div>
                                    <Field
                                        name="template_id"
                                        render={({ field: { name } }) => (
                                            <DropDown
                                                placeholder={t('Search for a template')}
                                                onChange={template => {
                                                    setFieldValue(name, template.id);
                                                    setSelectedTemplate(template);
                                                }}
                                                initialRequestParams={values[name] ? { ids: values[name] } : null}
                                                service={templatesDataService.getTemplatesByParams}
                                                keys={{ key: 'id', value: 'name' }}
                                                labelAppearance="title"
                                                label={t('Template')}
                                                className="span-2"
                                            />
                                        )}
                                    />
                                    <Field
                                        name="seasonStartEnd"
                                        render={({ field: { name } }) => {
                                            return (
                                                <DatePickerInput
                                                    dateRange={{
                                                        startDate: new Date(values[name].start_date * 1000),
                                                        endDate: new Date(values[name].end_date * 1000),
                                                    }}
                                                    staticRanges={nextDateShortcuts(Helpers.getTimeZoneTime(), true)}
                                                    onChange={({ startDate, endDate }) => {
                                                        setFieldValue(name, {
                                                            start_date: Math.floor(startOfDay(startDate) / 1000),
                                                            end_date: Math.floor(endOfDay(endDate) / 1000)
                                                        });
                                                    }}
                                                    label={t('Season start & end dates')}
                                                    labelAppearance="title"
                                                    minDate={new Date()}
                                                    maxDate={(addYears(startOfDay(new Date()), 100))}
                                                    writeProtected={false}
                                                />
                                            );
                                        }}
                                    />
                                    <Field
                                        name="payment_type"
                                        render={({ field: { name } }) => (
                                            <DropDown
                                                label={t('Payment frequency')}
                                                labelAppearance="title"
                                                placeholder={t('Select payment frequency')}
                                                onChange={(val) => setFieldValue(name, val.id)}
                                                externalData={PAYMENT_FREQUENCY}
                                                initialSelections={values[name] ? PAYMENT_FREQUENCY.find(item => values[name] === item.id) : null}
                                                withLazyLoad={false}
                                                multiSelect={false}
                                                searchable={false}
                                            />
                                        )}
                                    />
                                </div>
                            </Tab>}
                            <Tab title={t('Per League')} id="perLeague">
                                <div className="form-grid">
                                    {leagues.map((league, index) => (
                                        <LeagueSetupRow
                                            key={league.id}
                                            index={index}
                                            league={league}
                                            values={values}
                                            setValues={setValues}
                                            writeProtected={false}
                                        />
                                    ))}
                                </div>
                            </Tab>
                        </Tabs>
                    </OverlayContent>
                </>
            )}
        />
    );
};

export default BusinessModelSetup;
