import React, { useEffect, useRef, useState } from "react";
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { format } from "date-fns";
import { useDispatch } from 'react-redux';

import Icon from "../../shared/icon";
import Button from "../../shared/button";
import NoData from "../../shared/noData";
import Tag from "../../shared/tag";
import Popover from "../../shared/popover";
import Menu from "../../shared/menu";
import ModuleTitle from '../../shared/moduleTitle';
import NestedTable from '../../shared/nestedTable';
import Pagination from "../../shared/pagination";

import { PER_PAGE_COUNT } from "../../../constants/queryParams";
import { MATCH_STATUSES } from "../../../constants/statuses";

import { registerToastMessage } from '../../../actions/sync-actions/uiActions';
import useHorizontalScroll from '../../../hooks/useHorizontalScroll';
import Config from "../../../configs/mainConfig";
import InputWithDelay from "../inputWithDelay";

import Helpers from "../../../helpers/helperFunctions";
import { t } from '../../../helpers/translate';

import './simpleTable.scss';

const classNameCleaner = key => typeof key !== 'function' ? Helpers.regExpReplacer(key, ['{', '}', ' ', Config.main.currency], '').toLowerCase() : '';

const buildArrayByModifiedDataKey = (string = '') => {
    const values = [];
    const regExp = new RegExp(/{(.*?)}/, 'g');
    let matches;

    while ((matches = regExp.exec(string))) {
        values.push(matches[1]);
    }
    return values;
};

const filterTableKeysByItem = (data = [], tableKeys = {}) => {
    if (!data.length) return Object.keys(tableKeys).reduce((acc, key) => ({ ...acc, [key]: '' }), {});
    const filteredTableKeys = {};
    data.forEach(item => {
        Object.keys(tableKeys).forEach(key => {
            if (typeof tableKeys[key] === 'function' || (tableKeys.hasOwnProperty(key) && buildArrayByModifiedDataKey(tableKeys[key]).every((x) => item.hasOwnProperty(x)))) {
                filteredTableKeys[key] = tableKeys[key];
            }
        });
    });
    return filteredTableKeys;
};

const SimpleTable = props => {
    const {
        cta,
        data,
        title,
        onSort,
        search,
        loading,
        uniqueKey,
        tableKeys,
        onRowClick,
        pagination,
        rowActions,
        tableHeader,
        rowClassName,
        tableActions,
        actionButton,
        placeholders,
        NoDataComponent,
        renderNestedTable,
        searchInitialValue,
        initialExpandedRows,
    } = props;

    const filteredTableKeys = filterTableKeysByItem(data, tableKeys);
    const [expandedRows, setExpandedRows] = useState(initialExpandedRows);
    const [searchValue, setSearchValue] = useState(searchInitialValue);
    const [sortValues, setSortValues] = useState({});
    const [activeColumn, setActiveColumn] = useState('');
    const [menuStateId, setMenuStateId] = useState(null);
    const scrollerRef = useRef(null);
    const dispatch = useDispatch();
    const { localSearchKeys, onSearch } = search;
    const withSearch = Object.keys(search).length;
    const hasSubTable = typeof renderNestedTable === "function";
    const colSpan = Object.keys(filteredTableKeys).length + (hasSubTable || onRowClick ? 1 : 0);
    const width = `${100 / Object.keys(filteredTableKeys).length}%`;
    const filteredData = !localSearchKeys ? data : Helpers.multiFilter(data, localSearchKeys, searchValue);

    const { handleThumbMouseDown, left, scrollBoxWidth, } = useHorizontalScroll(scrollerRef, loading);

    useEffect(() => {
        if (!Helpers.isEqual(initialExpandedRows, expandedRows)) {
            setExpandedRows(initialExpandedRows);
        }
    }, [initialExpandedRows]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (typeof searchInitialValue === 'string' && !searchInitialValue && searchInitialValue !== searchValue) {
            setSearchValue('');
        }
    }, [searchInitialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubTableExpansion = key => () => {
        setExpandedRows({
            ...expandedRows,
            [key]: !expandedRows[key],
        });
    };

    const handleRowClick = (row, index) => {
        hasSubTable && handleSubTableExpansion(row[uniqueKey] || index);
        return () => {
            onRowClick && onRowClick(row, expandedRows[row[uniqueKey] || index]);
            hasSubTable && handleSubTableExpansion(row[uniqueKey] || index)();
        };
    };

    const handleCopyText = (text) => (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(text);
        dispatch(registerToastMessage({ message: 'Copied', timeout: 1000 }));
    };

    const getCellContent = (row, headerKey) => {
        // TODO CHANGE (ASAP)
        if (typeof headerKey === 'function') return headerKey(row);
        switch (headerKey) {
            case 'is_free_trial':
            case 'is_enabled':
                return row[headerKey] ? t('Yes') : t('No');
            case 'status':
                return MATCH_STATUSES[row.status];
            case 'frequency':
            case 'streams_count':
                return (
                    <div className="st-c-holder">
                        <Tag name={row[headerKey]} color="primary"/>
                    </div>
                );
            case 'roles':
                return Array.isArray(row[headerKey]) ? row[headerKey].map((role) => role.name).toString() : '';
            case 'created_at':
            case 'start_date':
            case 'created':
            case 'date':
            case 'time':
                return format(Helpers.getTimeZoneTime(row[headerKey]), `d MMMM yyyy ${Helpers.getTimeFormat()}`);
            case 'last_login':
                return row[headerKey] ? format(Helpers.getTimeZoneTime(row[headerKey]), `d MMMM yyyy ${Helpers.getTimeFormat()}`) : '';
            case 'active':
                return row[headerKey] ? t('Active') : t('Inactive');
            case 'organizationName':
                return row?.organization?.name;
            case 'userName':
                return `${row?.user?.first_name} ${row?.user?.last_name}`;
            case 'amount':
                return `${Config.main.currency} ${Helpers.amountFormat(Number(row[headerKey]).toFixed(2))}`;
            case 'is_test':
                return row[headerKey] ? t('Test') : t('Real');
            default:
                return Helpers.buildStringByData(row, headerKey);
        }
    };

    return (
        <div
            className={classnames(
                `simple-table-container`,
                {
                    'splash': !!title && !tableHeader,
                    'empty': !filteredData.length,
                }
            )}
        >
            {title && (
                <ModuleTitle
                    title={title}
                >
                    {cta}
                </ModuleTitle>
            )}
            {(withSearch || actionButton || tableActions) && (
                <ul className="table-actions">
                    <li>
                        {!!withSearch && (
                            <div className="ct-search">
                                <InputWithDelay
                                    delay={500}
                                    labelAppearance="none"
                                    initialValue={searchValue}
                                    onChange={(value) => {
                                        setSearchValue(value);
                                        typeof onSearch === "function" && onSearch(value);
                                    }}
                                />
                            </div>
                        )}
                        {!!tableActions && (
                            <div className="ct-actions-holder">
                                {tableActions}
                            </div>
                        )}
                    </li>
                    <li>
                        {!!actionButton && (
                            <Button
                                icon={actionButton.icon}
                                color={actionButton.color}
                                size="big"
                                appearance={actionButton.appearance}
                                className={actionButton.className}
                                onClick={actionButton.onClick}
                                disabled={actionButton.disabled}
                            >
                                {actionButton.name}
                            </Button>
                        )}
                    </li>
                </ul>
            )}
            {tableHeader && (
                <div className="table-header">
                    {tableHeader}
                </div>
            )}
            <div
                ref={scrollerRef}
                className="s-table-holder"
            >
                <table className="table">
                    <thead>
                    <tr>
                        {(hasSubTable || onRowClick) && (
                            <th className="table-action-holder">
                                <div className="table-head"/>
                            </th>
                        )}
                        {Object.keys(filteredTableKeys).map((th, index) => {
                            const sortDirection = sortValues[th];
                            const filteredTableKey = typeof filteredTableKeys[th] !== 'function' && filteredTableKeys[th].includes('_valueWithUnit') ? filteredTableKeys[th].replace('_valueWithUnit', '') : filteredTableKeys[th];
                            return (
                                <th key={index} style={{ width }} className={`t-${classNameCleaner(filteredTableKey)}`}
                                    onClick={() => {
                                        if (onSort) {
                                            const orderDirection = sortDirection ? 'asc' : 'desc';
                                            setActiveColumn(th);
                                            setSortValues({
                                                ...sortValues,
                                                [th]: !sortDirection
                                            });
                                            onSort(filteredTableKey, orderDirection);
                                        }
                                    }}
                                >
                                    <div
                                        className={classnames('table-head', {
                                            'active': activeColumn === th,
                                            'clickable': onSort,
                                        })}
                                    >
                                        <p className="ellipsis-text">{th}</p>
                                        {onSort && (
                                            <div className="sorting-icons-st">
                                                <Icon
                                                    size={18}
                                                    type="icon-sorting-up"
                                                    className={classnames({
                                                        'active': sortDirection && activeColumn === th,
                                                    })}
                                                />
                                                <Icon
                                                    size={18}
                                                    type="icon-sorting-down"
                                                    className={classnames({
                                                        'active': !sortDirection && activeColumn === th,
                                                    })}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                        {rowActions && (
                            <th className="table-action-holder">
                                <div className="table-head"/>
                            </th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {(filteredData.length && !loading) ? filteredData.map((row, index) => (
                        <React.Fragment key={index}>
                            <tr
                                onClick={handleRowClick(row, index)}
                                className={classnames(typeof rowClassName === 'function' ? rowClassName(row) : rowClassName, {
                                    'clickable': hasSubTable || onRowClick,
                                    'odd': index % 2 !== 0,
                                    'active': expandedRows[row[uniqueKey] || index],
                                })}
                            >
                                {(hasSubTable || onRowClick) && (
                                    <td className="table-action-holder">
                                        {hasSubTable && (
                                            <Icon
                                                type="icon-arrow-down-outline"
                                                className={classnames({
                                                    'active': expandedRows[row[uniqueKey] || index],
                                                })}
                                            />
                                        )}
                                        {!hasSubTable && onRowClick && (
                                            <Icon type="icon-arrow-right-outline"/>
                                        )}
                                    </td>
                                )}
                                {Object.keys(filteredTableKeys).map((headerKey, i) => {
                                    const cellContent = getCellContent(row, filteredTableKeys[headerKey]);
                                    const copyButton = filteredTableKeys[headerKey] === 'api_key';
                                    return (
                                        <td key={i} style={{ width }}
                                            className={`t-${classNameCleaner(filteredTableKeys[headerKey])}`}>
                                            <div className="table-cell ellipsis-text"
                                                 title={typeof cellContent !== "object" ? cellContent : ''}>
                                                {
                                                    (placeholders && !cellContent) ?
                                                        <div className="empty-placeholder"/> :
                                                        <div className="api-key-input">
                                                            {cellContent}
                                                            {copyButton && <Button
                                                                onClick={handleCopyText(cellContent)}
                                                                icon="icon-clone"
                                                                appearance="minimal"
                                                                color="confirm"
                                                            />}
                                                        </div>
                                                }
                                            </div>

                                        </td>
                                    );
                                })}
                                {rowActions && (
                                    <td className="table-action-holder">
                                        <div className="row-actions">
                                            {rowActions(row).length > 1 ? (
                                                <Popover
                                                    selfSizing
                                                    opened={menuStateId === row.id}
                                                    onClose={() => setMenuStateId(null)}
                                                    position="bottom-end"
                                                    content={
                                                        <Menu
                                                            data={rowActions(row, () => setMenuStateId(null))}
                                                        />
                                                    }
                                                >
                                                    <Button
                                                        icon="icon-more"
                                                        appearance="minimal"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setMenuStateId(row.id);
                                                        }}
                                                    />
                                                </Popover>
                                            ) : (
                                                rowActions(row).map((button, index) => (
                                                    <Button
                                                        key={index}
                                                        icon={button.icon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            button.onClick(row);
                                                        }}
                                                        appearance="minimal"
                                                        color={button.color}
                                                    />
                                                ))
                                            )}
                                        </div>
                                    </td>
                                )}
                            </tr>
                            {(!!expandedRows[row[uniqueKey] || index] && typeof renderNestedTable === "function") && (
                                <tr>
                                    <td colSpan={colSpan}>
                                        <NestedTable>
                                            {renderNestedTable(row)}
                                        </NestedTable>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    )) : (
                        loading ? (
                            Array(PER_PAGE_COUNT).fill('').map((_, index) => (
                                <tr
                                    key={index}
                                    className={classnames({
                                        'odd': index % 2 !== 0,
                                    })}
                                >
                                    {(hasSubTable || onRowClick) && (
                                        <td className="table-action-holder">
                                            <div className="sp-skeleton circle animation-fadeIn"/>
                                        </td>
                                    )}
                                    {Object.keys(filteredTableKeys).map((headerKey, index) => (
                                        <td key={index}
                                            className={`t-${classNameCleaner(filteredTableKeys[headerKey])}`}>
                                            <div className="table-cell">
                                                <div className="sp-skeleton animation-fadeIn"/>
                                            </div>
                                        </td>
                                    ))}
                                    {rowActions && (
                                        <td className="table-action-holder">
                                            <div className="sp-skeleton circle animation-fadeIn"/>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={colSpan}>
                                    {NoDataComponent || <NoData size="medium"/>}
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </div>
            {!!filteredData.length && (
                <>
                    {pagination && (
                        <Pagination
                            key={pagination?.totalCount || filteredData.length}
                            data={{
                                total: pagination?.totalCount || filteredData.length,
                                perPage: PER_PAGE_COUNT,
                                selectedPage: pagination?.selectedPage
                            }}
                            onUpdate={pagination?.onPaginationUpdate}
                        />
                    )}
                    {(scrollerRef.current && !!scrollBoxWidth) && (
                        <div className="fake-scrollbar">
                            <button
                                style={{ width: scrollBoxWidth, left: left }}
                                className="fake-scrollbar-thumb"
                                onMouseDown={handleThumbMouseDown}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

SimpleTable.defaultProps = {
    data: [],
    tableKeys: {},
    search: {},
    searchInitialValue: '',
    initialExpandedRows: {},
};

SimpleTable.propTypes = {
    cta: PropTypes.any,
    data: PropTypes.array,
    tableKeys: PropTypes.object,
    initialExpandedRows: PropTypes.object,
    subTableData: PropTypes.object, // TODO REMOVE
    uniqueKey: PropTypes.string, // TODO REMOVE
    renderNestedTable: PropTypes.func,
    subTableContent: PropTypes.any,
    onSort: PropTypes.func,
    onRowClick: PropTypes.func,
    placeholders: PropTypes.bool,
    search: PropTypes.object,
    rowActions: PropTypes.func, // {onClick: PropTypes.func, icon: PropTypes.string, color: Button.propTypes.color}
    tableHeader: PropTypes.any,
    tableActions: PropTypes.any,
    title: PropTypes.any,
    rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    searchInitialValue: PropTypes.string,
    actionButton: PropTypes.shape({
        icon: Icon.propTypes.type,
        color: Button.propTypes.color,
        appearance: Button.propTypes.appearance,
        className: PropTypes.string,
        name: PropTypes.string,
        onClick: PropTypes.func,
    }),
};

export default SimpleTable;
