import { LOGGED_IN, LOGIN_FAILED, LOGOUT, SET_USER_INIT_DATA, SESSION_PROLONGED } from "../action-types";

export const Logout = () => {
    return {
        type: LOGOUT
    };
};

export const LoggedIn = (payload) => {
    return {
        type: LOGGED_IN,
        payload
    };
};

export const LoginFailed = (payload) => {
    return {
        type: LOGIN_FAILED,
        payload: payload
    };
};

export const SetUserInitData = (payload) => {
    return {
        type: SET_USER_INIT_DATA,
        payload
    };
};

export const SessionProlonged = (payload) => {
    return {
        type: SESSION_PROLONGED,
        payload
    };
};
