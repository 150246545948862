import { createSelector } from "reselect";
import {getRelativeFilters} from "../configs/filterConfig";
import { SCOUT_DATA } from '../constants/reducerKeys';
import { EVENTS_PAGE } from '../constants/pageRoutes';
import { getSubPath } from './routeHelper';

export const GetEventsGridSelector = (state, ownParams) => createSelector(
    [
        state => state.ui.eventsType && state.dataStates[state.ui.eventsType] && JSON.stringify(state.dataStates[state.ui.eventsType]),
        state => state.filtersParams?.events?.subKeys && JSON.stringify(state.filtersParams.events.subKeys[state.ui.eventsType]),
        state => state.eventData?.selectedIds && state.eventData.selectedIds[state.ui.eventsType] && JSON.stringify(state.eventData.selectedIds[state.ui.eventsType]),
        state => state.cart?.selectedIds && JSON.stringify(state.cart.selectedIds),
        state => state.ui?.eventsType,
        state => state.ui.activeRightTabMenuItem && state.ui.activeRightTabMenuItem[ownParams.pathname],
        state => JSON.stringify((state.user && state.user.roles) || [])
    ],
    (dataState, filtersParams, selectedIds, cartIds, eventsType, activeRightTabMenuItem, roles) => {
        return {
            dataState: dataState ? JSON.parse(dataState) : {},
            filtersParams: filtersParams && JSON.parse(filtersParams),
            selectedIds: selectedIds && JSON.parse(selectedIds),
            cartIds: cartIds && JSON.parse(cartIds),
            eventsType,
            activeRightTabMenuItem,
            roles: JSON.parse(roles)
        };
    }
);

export const GetScoutEventsGridSelector = () => createSelector(
    [
        state => state.ui.eventsType && state.dataStates[state.ui.eventsType] && JSON.stringify(state.dataStates[state.ui.eventsType]),
        state => state.filtersParams[SCOUT_DATA.EVENTS] && state.filtersParams[SCOUT_DATA.EVENTS].subKeys && JSON.stringify(state.filtersParams[SCOUT_DATA.EVENTS].subKeys[state.ui.eventsType]),
        state => state.ui && state.ui.eventsType,
    ],
    (dataState, filtersParams, eventsType) => {
        return {
            dataState: dataState ? JSON.parse(dataState) : {},
            filtersParams: filtersParams && JSON.parse(filtersParams),
            eventsType,
        };
    }
);

export const GetUsersGridSelector = (state, ownParams) => {
    return createSelector(
        [
            state => state.dataStates.profile || {},
            state => state.filtersParams && JSON.stringify(state.filtersParams[ownParams.reducerKey || "users"])
        ],
        (dataState, filtersParams) => ({
            dataState,
            filtersParams: filtersParams && JSON.parse(filtersParams)
        })
    );
};

export const GetOperatorFilterParams = createSelector(
    [
        state => state.filtersParams && JSON.stringify(state.filtersParams.operators || {})
    ],
    (filtersParams) => ({
        filtersParams: filtersParams && JSON.parse(filtersParams)
    })
);

export const GetEventDetailsData = createSelector(
    [
        state => state.eventData.hoveredEvent,
        state => state.user && state.user.roles
    ],
    (event, userRoles) => {
        return {
            event,
            userRoles
        };
    }
);

/**
 * @description Get map of selectors for relative filters values.
 * @param reducerKey
 * @param reducerSubKey
 * @param filterName
 * @returns {*}
 * @constructor
 */
export const GetRelativeFilters = ({reducerKey, reducerSubKey, filterName}) => {
    const relativeFilters = getRelativeFilters(filterName);
    return relativeFilters.map(relativeFName => {
        return createSelector(
            [
                state => state.filtersParams[reducerKey] && state.filtersParams[reducerKey][relativeFName],
                state => state.filtersParams[reducerKey] && state.filtersParams[reducerKey].subKeys &&
                    state.filtersParams[reducerKey].subKeys[reducerSubKey] && state.filtersParams[reducerKey].subKeys[reducerSubKey][relativeFName]
            ],
            (parentFilter, subFilter) => {
                return {
                    [relativeFName]: subFilter || parentFilter
                };
            }
        );
    });
};

export const getFilterDataByFilterKey = (state, ownParams) => {
    const {reducerKey, reducerSubKey, filterKey} = ownParams;
    return createSelector(
        [
            state => state.filters[reducerKey] && state.filters[reducerKey][filterKey] && JSON.stringify(state.filters[reducerKey][filterKey]),
            state => state.filters[reducerKey] && state.filters[reducerKey].subKeys &&
                state.filters[reducerKey].subKeys[reducerSubKey] && JSON.stringify(state.filters[reducerKey].subKeys[reducerSubKey][filterKey])
        ],
        (parentFilter, subFilter) => {
            return {
                filterData: (subFilter && JSON.parse(subFilter)) || (parentFilter && JSON.parse(parentFilter))
            };
        }
    );
};

export const selectEventsType = createSelector(
    [
        state => state.ui.eventsType
    ],
    (eventsType) => ({ eventsType })
);

export const getDataForFilterWrapper = (ownParams) => {
    const {reducerKey, reducerSubKey, filterName} = ownParams,
        dataKey = `${reducerKey}${reducerSubKey ? '_' + reducerSubKey : ''}_${filterName}`;
    return createSelector(
        [
            state => state.filtersData[dataKey] && JSON.stringify(state.filtersData[dataKey]),
            state => state.dataStates[filterName] && JSON.stringify(state.dataStates[filterName]),
            state => state.ui && state.ui.eventsType,
            ...GetRelativeFilters(ownParams)
        ],
        (filterData, dataState, dataType, ...relativeFilters) => {
            let relativeFData = relativeFilters && relativeFilters.reduce((acc, currentV) => {
                const key = Object.keys(currentV)[0];
                if (currentV[key] === undefined) {
                    return acc;
                }
                return {...acc, ...currentV};
            }, {});
            return {
                filterData: filterData && JSON.parse(filterData),
                dataState: dataState && JSON.parse(dataState),
                dataType: getSubPath() === EVENTS_PAGE ? dataType : null,
                relativeFData,
                dataKey
            };
        }
    );
};

export const selectDataForPresets = createSelector(
    [
        state => JSON.stringify(state.presetData),
        state => state.ui && state.ui.appliedPresetIdsByKey && JSON.stringify(state.ui.appliedPresetIdsByKey)
    ],
    (currFiltersByType, appliedPresetIdsByKey) => ({
        currFiltersByType: currFiltersByType && JSON.parse(currFiltersByType),
        appliedPresetIdsByKey: (appliedPresetIdsByKey && JSON.parse(appliedPresetIdsByKey)) || {}
    })
);

export const selectUiDataForBreadCrumbs = createSelector([
        state => state.ui.federationsTabType,
        state => state.ui.uniqueFederationTabType,
        state => state.ui.eventsType,
        state => state.ui.operatorTabType,
        state => state.ui.breadcrumbBase,
    ],
    (federationsTabType, uniqueFederationTabType, eventsType, operatorTabType, breadcrumbBase) => ({
        federationsTabType,
        uniqueFederationTabType,
        eventsType,
        operatorTabType,
        breadcrumbBase,
    })
);

export const selectMathListDataForReset = createSelector([
    state => state.filters && state.filters.events && state.filters.events.subKeys && JSON.stringify(state.filters.events.subKeys[state.ui.eventsType]),
    state => state.autoBookingRules && state.autoBookingRules[state.ui.eventsType] && state.autoBookingRules[state.ui.eventsType].selectedRuleId,
    state => state.ui && state.ui.eventsType

], (currentFilters, selectedRuleId, eventsType) => ({
    currentFilters: currentFilters && JSON.parse(currentFilters),
    selectedRuleId: selectedRuleId && JSON.parse(selectedRuleId),
    eventsType
}));

export const selectOperatorsDataForReset = (ownProps) => {
    const { reducerKey } = ownProps;
    return createSelector([
        state => state.filters && state.filters[reducerKey] && JSON.stringify(state.filters[reducerKey]),
        state => state.ui?.appliedPresetIdsByKey?.bookedMatches,
        state => state.ui?.operatorTabType,
    ], (currentFilters, presetId, operatorTabType) => ({
        currentFilters: (currentFilters && JSON.parse(currentFilters)) || {},
        presetId,
        operatorTabType,
    }));
};

export const selectDataForLeaguesWrapper = createSelector(
    [
        state => state.ui.eventsType,
        state => JSON.stringify(state.dataStates.leagues || {}),
    ],
    (eventsType, leaguesState) => {
        return {
            eventsType,
            leaguesState: JSON.parse(leaguesState)
        };
    }
);

export const selectAutobookingRules = createSelector([
    state => state.autoBookingRules?.editingRuleId,
    state => state.ui?.eventsType,
    state => state.ui?.ruleCreatingButtonState
], (editingRuleId, eventsType, ruleCreatingButtonState) => ({
    editingRuleId,
    eventsType,
    ruleCreatingButtonState
}));
