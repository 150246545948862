import React from 'react';
import {Form, Field} from "formik";
import leagueDataService from "../../../../services/leagueDataService";
import {listTimeZones} from "timezone-support";
import CompetitorsDynamicSelect from "../../../reusableUIComponents/competitorsDynamicSelect/index";
import DateTimePicker from "../../../reusableUIComponents/dateTimePicker/index";
import Helpers from "../../../../helpers/helperFunctions";
import DropDown from "../../../shared/dropDown";
import DropDownItemWithDescription from "../../../shared/dropDown/pieces/dropDownItemWithDescription";
import Collapser from "react-styled-collapser";
import { getBasePath, getSubPath } from '../../../../helpers/routeHelper';
import { useRouteMatch } from "react-router-dom";
import { ADMIN, MODERATOR } from '../../../../constants/userRoles';
import { t } from '../../../../helpers/translate';

const EventFormFields = (props) => {
    const {values} = props;
    const {league_id, region_id, timeZone, start_date, sport_id} = values;
    const basePath = getBasePath();
    const subPath = getSubPath();
    const { id: federationId } = useRouteMatch(`${basePath}${subPath}/:id`)?.params || {};

    return (
        <Form className="form-grid col-2">
            <Field
                name="sport_id"
                render={({form, field: {name}}) => (
                    <DropDown
                        label={t('Sport')}
                        labelAppearance="title"
                        multiSelect={false}
                        service={leagueDataService.getSportsByParams}
                        placeholder={t('Select sport')}
                        initialRequestParams={sport_id ? {ids: sport_id} : null}
                        requestParams={{
                            ...(Helpers.checkRole(MODERATOR, ADMIN) && federationId ? {federation_id: federationId} : {}),
                        }}
                        onChange={sport => {
                            form.setValues({
                                ...values,
                                [name]: sport.id,
                                sport_name: sport.name,
                                league_id: sport.id !== values[name] ? null : league_id
                            });
                        }}
                    />
                )}
            />
            <Field
                name="timeZone"
                render={({form, field: {name}}) => (
                    <DropDown
                        placeholder={t('Select time zone')}
                        label={t('Time Zone')}
                        labelAppearance="title"
                        onChange={timeZone => {
                            form.setValues({
                                ...values, [name]: timeZone.name
                            });
                        }}
                        externalData={listTimeZones().sort().map((name, id) => ({id, name}))}
                        withLazyLoad={false}
                        multiSelect={false}
                        initialSelections={
                            values && listTimeZones().sort().indexOf(timeZone) !== -1
                                ? {
                                    id: listTimeZones().sort().indexOf(timeZone),
                                    name: timeZone
                                }
                                : null}
                    />
                )}
            />
            <Collapser collapsed={!sport_id} className="span-2">
                <div className="form-grid col-2">
                    <Field
                        name="region_id"
                        render={({form, field: {name}}) => (
                            <DropDown
                                label={t('Region')}
                                placeholder={t('Select region')}
                                labelAppearance="title"
                                service={leagueDataService.getRegionsByParams}
                                onChange={region => form.setValues({
                                    ...values,
                                    [name]: region.id,
                                    region_name: region.name
                                })}
                                initialRequestParams={region_id ? {ids: region_id} : null}
                                multiSelect={false}
                            />
                        )}
                    />
                    <Field
                        name="league_id"
                        render={({form, field: {name}}) => (
                            <DropDown
                                key={sport_id}
                                label={t('Leagues')}
                                labelAppearance="title"
                                requestParams={{
                                    includes: "sport,region",
                                    sportFields: "name,alias",
                                    regionFields: "name",
                                    ...(sport_id ? {sport_ids: sport_id} : {}),
                                }}
                                multiSelect={false}
                                suggestionMode={false}
                                iconKey="sport.alias"
                                rowRenderer={(item) => <DropDownItemWithDescription item={item} />}
                                disabled={!sport_id}
                                service={leagueDataService.getMyLeaguesByParams}
                                placeholder={t('Select league')}
                                onChange={league => {
                                    form.setValues({
                                        ...values,
                                        [name]: (Helpers.checkRole(MODERATOR, ADMIN) && league.league_id) || league.id,
                                        league_name: league.name
                                    });
                                }}
                                initialRequestParams={league_id ? {ids: league_id} : null}
                            />
                        )}
                    />
                </div>
            </Collapser>
            <Field
                name="start_date"
                render={({form, field: {name}}) => (
                    <DateTimePicker
                        key={timeZone}
                        labelAppearance="title"
                        timeZone={timeZone}
                        initialDate={start_date ? Helpers.getTimeZoneTime(start_date, timeZone) : null}
                        className="span-2"
                        form={form}
                        onChange={data => {
                            form.setValues({
                                ...(Helpers.cloneDeep(values)), [name]: data
                            });
                        }}
                    />
                )}
            />
            <Field
                name="teams"
                render={(fieldProps) =>
                    <CompetitorsDynamicSelect
                        {...fieldProps}
                        className="span-2"
                        useParams={false}
                        allowAll={false}
                    />
                }
            />
        </Form>
    );
};

export default EventFormFields;
