import {dataLoadStart, dataLoadDone, dataLoadFail} from "./index";
import {REPORTS} from "../../constants/reducerKeys";
import reportingDataService from "../../services/reportingDataService";
import {registerToastMessage} from "../sync-actions/uiActions";
import {TOAST_TYPES} from "../../constants/uiConstants";

export function getDashboard (federationId) {
    return function (dispatch) {
        dispatch(dataLoadStart(REPORTS.DASHBOARD));
        reportingDataService.getDashboard(federationId)
            .then(data => {
                dispatch(dataLoadDone(REPORTS.DASHBOARD, data));
            })
            .catch(error => {
                dispatch(dataLoadFail(REPORTS.DASHBOARD, error));
            });
    };
}

export function getWidgetData (params = {}, id) {
    return function (dispatch) {
        const meta = { dataStateByDeepKey: `${REPORTS.WIDGET}${id}` };
        dispatch(dataLoadStart(REPORTS.WIDGET, id, meta));
        reportingDataService.getWidgetData(params, id)
            .then(data => {
                dispatch(dataLoadDone(REPORTS.WIDGET, id, meta));
            })
            .catch(error => {
                dispatch(dataLoadFail(REPORTS.WIDGET, id, { ...error, ...meta }));
            });
    };
}

export function getAreaChartData (params = {}, id) {
    return function (dispatch) {
        dispatch(dataLoadStart(REPORTS.CHART, id));
        reportingDataService.getAreaChartData(params, id)
            .then(data => {
                dispatch(dataLoadDone(REPORTS.CHART, id));
            })
            .catch(error => {
                dispatch(dataLoadFail(REPORTS.CHART, id, error));
            });
    };
}

export function getReportingEMails (params = {}) {
    return function (dispatch) {
        dispatch(dataLoadStart(REPORTS.EMAILS));
        reportingDataService.getReportingEmails(params)
            .then(data => {
                dispatch(dataLoadDone(REPORTS.EMAILS));
            })
            .catch(error => {
                dispatch(dataLoadFail(REPORTS.EMAILS, error));
            });
    };
}

export function addReportingEMail (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(REPORTS.ADD_REPORTING_EMAIL));
        reportingDataService.addReportingEMail(params)
            .then(res => {
                dispatch(dataLoadDone(REPORTS.ADD_REPORTING_EMAIL, res));
                dispatch(registerToastMessage({message: "E-Mail address successfully added"}));
            })
            .catch(err => {
                dispatch(dataLoadFail(REPORTS.ADD_REPORTING_EMAIL, err));
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"}));
            });
    };
}

export function removeReportingEMail (id) {
    return (dispatch) => {
        dispatch(dataLoadStart(REPORTS.REMOVE_REPORTING_EMAIL));
        reportingDataService.removeReportingEMail(id)
            .then(res => {
                dispatch(dataLoadDone(REPORTS.REMOVE_REPORTING_EMAIL, res));
                dispatch(registerToastMessage({message: "E-Mail address successfully removed"}));
            })
            .catch(err => {
                dispatch(dataLoadFail(REPORTS.REMOVE_REPORTING_EMAIL, err));
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"}));
            });
    };
}
