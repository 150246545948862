import React, {useEffect, useState} from "react";
import ModalContent from "../../shared/modal/parts/content";
import federationsDataService from "../../../services/federationsDataService";
import Tag from "../../shared/tag";
import { combineLatest } from "rxjs";
import SimpleTable from "../../reusableUIComponents/simpleTable";
import {CONTENT_CREATOR, LEAGUE_OWNER, REPORTER, STREAMER} from "../../../constants/userRoles";
import {FEDERATIONS_PAGE} from "../../../constants/pageRoutes";
import { setBreadcrumbBase } from '../../../actions/sync-actions/uiActions';
import { t } from '../../../helpers/translate';

const FederationsDetails = ({ closePopup, popupParams: { federationId, title }, history, dispatch }) => {
    const [federationInfo, setFederationInfo] = useState({});

    useEffect(() => {
        federationsDataService.getFederationUsers(federationId);

        const subscription = combineLatest([federationsDataService.federationsList, federationsDataService.federationUsers])
            .subscribe(([federationsList, federationUsers]) => {
                const usersData = federationUsers.data?.reduce((acc, curr) => {
                    const data = {};
                    curr.roles.forEach((role) => {
                        const {first_name, last_name, email} = curr;
                        data[role.id] = first_name ? `${first_name} ${last_name || ''}`.trimEnd() : email;
                    });
                    acc.push(data);
                    return acc;
                }, []);

                const federation = federationsList.data?.find(el => el.id === federationId) || {};
                setFederationInfo({
                    leagues: [...(federation.only_five_leagues || [])].slice(0, 5),
                    users: usersData
                })
            });

        return () => subscription.unsubscribe();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ModalContent
            primaryButton={{
                name: t('View more'),
                onClick: () => {
                    history.push(`${FEDERATIONS_PAGE}/${federationId}`);
                    dispatch(setBreadcrumbBase(title));
                    closePopup();
                },
            }}
            secondaryButton={{
                name: t('Close'),
                onClick: closePopup,
            }}
        >
            <div className="form-grid">
                <div  className="modal-section">
                    <div className="ms-head">{t('Leagues')}</div>
                    <div className="ms-body">
                        {(federationInfo.leagues)?.map((item, index) => (
                            <Tag
                                name={item.name}
                                key={index}
                                clipped
                            />
                        ))}
                    </div>
                </div>
                <div className="m-fit-block">
                    <SimpleTable
                        data={federationInfo.users || []}
                        placeholders
                        tableKeys={{
                            [t('League owners')]: `${LEAGUE_OWNER}`,
                            [t('Streamers')]: `${STREAMER}`,
                            [t('Content Creators')]: `${CONTENT_CREATOR}`,
                            [t('Reporters')]: `${REPORTER}`
                        }}
                    />
                </div>
            </div>
        </ModalContent>
    );
};

export default FederationsDetails;
