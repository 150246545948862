import {openPopup} from "../actions/sync-actions/uiActions";
import { popupConfig } from "../configs/popupConfig";
import Config from "../configs/mainConfig";
import usersDataService from "../services/usersDataService";
import {getProjectStatus} from "./uiHelpers";
import Helpers from "./helperFunctions";

export const proceedToAutoBookingSubmit = (isDemo, freeTrialIsOver, isPending, isEnabled, dispatch) => {
    const userProfile = usersDataService.profile && usersDataService.profile.getValue();

    const demoAndFreeTrialIsOver = () => {
        dispatch(openPopup("message", {
            ...popupConfig.autoBookingRestrictionForDemo,
            handleCallBack: () => {
                window.open(`${Config.main.springBME}/organizations/${userProfile.organization.partner_id}`, '_blank');
            }
        }));
    };

    const pendingAndFreeTrialIsOver = () => {
        dispatch(openPopup("message", {
            ...popupConfig.autoBookingRestrictionForPending,
            handleCallBack: () => {
                window.open(`${Config.main.consoleBetconstruct}/projects`, '_blank');
            }
        }));
    };

    const isNotEnabled = () => {
        dispatch(openPopup("message", {
            ...popupConfig.autoBookingRestrictionForDisable,
            handleCallBack: () => {
                window.open(`${Config.main.consoleBetconstruct}/products/friendShip/overview`, '_blank');
            }
        }));
    };

    switch (true) {
        case isDemo && freeTrialIsOver: {
            demoAndFreeTrialIsOver();
            return false;
        }
        case isPending && freeTrialIsOver: {
            pendingAndFreeTrialIsOver();
            return false;
        }
        case !isEnabled: {
            isNotEnabled();
            return false;
        }
        default:
            return true;
    }
};

export const showPopupByPartnerStatus = (dispatch, userProfile) => {
    const {isTrial, isDemo, isPending, isLive, isEnabled, lessThanDayLeft, isApiPartner, freeTrialIsOverForPopups, freeTrialIsOverForLivePopups} = getProjectStatus();
    const {organization: {id, partner_id: partnerId}} = userProfile;

    let config = {};
    switch (true) {
        case !isEnabled && !Helpers.getCookieParam(`enableFriendship${id}`):
            config = {...popupConfig.enableFriendship, showOnlyOnce: `${popupConfig.enableFriendship.showOnlyOnce}${id}`};
            return dispatch(openPopup("message", {
                ...config,
                handleCloseCallBack: () => showPopupByPartnerStatus(dispatch, userProfile),
                handleCallBack: () => {
                    setTimeout(() => showPopupByPartnerStatus(dispatch, userProfile), 200);
                    window.open(`${Config.main.consoleBetconstruct}/products/friendShip/overview`, '_blank');
                }
            }));
        case !isLive && isEnabled && isTrial && !Helpers.getCookieParam(`friendshipIsEnabled${id}`): {
            config = {
                ...popupConfig.friendshipIsEnabled,
                classification: isApiPartner ? "info" : 'success',
                showOnlyOnce: `${popupConfig.friendshipIsEnabled.showOnlyOnce}${id}`,
                primaryButton: {
                    name: isApiPartner ? 'Contact Support' : "Go Live",
                    color: isApiPartner ? undefined : 'confirm'
                }
            };
            return dispatch(openPopup("message", {
                ...config,
                handleCloseCallBack: () => showPopupByPartnerStatus(dispatch, userProfile),
                handleCallBack: () => {
                    if (isApiPartner) {
                        Helpers.mailTo({
                            email: 'support@feedconstruct.com',
                            subject: 'Please update my project status'
                        });
                    } else {
                        window.open(`${Config.main.springBME}/dashboard`, '_blank');
                    }
                    setTimeout(() => showPopupByPartnerStatus(dispatch, userProfile), 200);
                }
            }));
        }
        case isLive && isEnabled && isTrial && !Helpers.getCookieParam(`friendshipIsEnabled${id}`):
            config = {...popupConfig.friendshipIsEnabledForLive, showOnlyOnce: `${popupConfig.friendshipIsEnabledForLive.showOnlyOnce}${id}`};
            return dispatch(openPopup("message", {
                ...config,
                handleCloseCallBack: () => showPopupByPartnerStatus(dispatch, userProfile),
                handleCallBack: () => {
                    setTimeout(() => showPopupByPartnerStatus(dispatch, userProfile), 200);
                    partnerId && window.open(`${Config.main.springBME}/dashboard`, '_blank');
                }
            }));
        case !isLive && lessThanDayLeft && !Helpers.getCookieParam(`freeTrialIsAlmostOver${id}`):
            config = {...popupConfig.freeTrialIsAlmostOver, showOnlyOnce: `${popupConfig.freeTrialIsAlmostOver.showOnlyOnce}${id}`};
            return dispatch(openPopup("message", {
                ...config,
                handleCloseCallBack: () => showPopupByPartnerStatus(dispatch, userProfile),
                handleCallBack: () => {
                    setTimeout(() => showPopupByPartnerStatus(dispatch, userProfile), 200);
                    window.open('https://www.feedconstruct.com/contact-us', '_blank');
                }
            }));
        case isLive && lessThanDayLeft && !Helpers.getCookieParam(`freeTrialIsAlmostOver${id}`):
            config = {...popupConfig.freeTrialIsAlmostOverForLive, showOnlyOnce: `${popupConfig.freeTrialIsAlmostOverForLive.showOnlyOnce}${id}`};
            return dispatch(openPopup("message", {
                ...config,
                handleCloseCallBack: () => showPopupByPartnerStatus(dispatch, userProfile),
                handleCallBack: () => {
                    setTimeout(() => showPopupByPartnerStatus(dispatch, userProfile), 200);
                    window.open('https://www.feedconstruct.com/contact-us', '_blank');
                }
            }));
        case (isPending || isDemo) && isEnabled && freeTrialIsOverForPopups && !Helpers.getCookieParam(`freeTrialIsOver${id}`):
            config = {...popupConfig.freeTrialIsOver, showOnlyOnce: `${popupConfig.freeTrialIsOver.showOnlyOnce}${id}`};
            return dispatch(openPopup("message", {
                ...config,
                handleCloseCallBack: () => showPopupByPartnerStatus(dispatch, userProfile),
                handleCallBack: () => {
                    setTimeout(() => showPopupByPartnerStatus(dispatch, userProfile), 200);
                    window.open('https://www.feedconstruct.com/contact-us', '_blank');
                }
            }));
        case isLive && isEnabled && freeTrialIsOverForLivePopups && !Helpers.getCookieParam(`freeTrialIsOver${id}`):
            config = {...popupConfig.freeTrialIsOverForLive, showOnlyOnce: `${popupConfig.freeTrialIsOverForLive.showOnlyOnce}${id}`};
            return dispatch(openPopup("message", {
                ...config,
                handleCloseCallBack: () => showPopupByPartnerStatus(dispatch, userProfile),
                handleCallBack: () => {
                    setTimeout(() => showPopupByPartnerStatus(dispatch, userProfile), 200);
                    window.open('https://www.feedconstruct.com/contact-us', '_blank');
                }
            }));
        case isPending && !isTrial && !Helpers.getCookieParam(`projectIsPending${id}`):
            config = {...popupConfig.projectIsPending, showOnlyOnce: `${popupConfig.projectIsPending.showOnlyOnce}${id}`};
            return dispatch(openPopup("message", {
                ...config,
                handleCloseCallBack: () => showPopupByPartnerStatus(dispatch, userProfile),
                handleCallBack: () => {
                    setTimeout(() => showPopupByPartnerStatus(dispatch, userProfile), 200);
                    window.open(`${Config.main.consoleBetconstruct}/projects`, '_blank');
                }
            }));
        default:
            break;
    }
};
