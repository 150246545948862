import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import RuleCreationForm from "../ruleCreationForm";
import bookingDataService from "../../../services/bookingDataService";
import usersDataService from "../../../services/usersDataService";
import componentDataProvider from "../../../hocs/componentDataProvider";
import {Formik} from "formik";
import {addYears, startOfDay} from 'date-fns';
import {VIDEO_STREAMING_PRODUCT_ID} from "../../../constants/dataConstants";
import {
    resetStyle,
    enableRuleCreationButton,
    openPopup, registerToastMessage
} from "../../../actions/sync-actions/uiActions";
import {useDispatch, useSelector } from "react-redux";
import {setAutobookingRuleEditId} from "../../../actions/sync-actions/autoBooking";
import Helpers from "../../../helpers/helperFunctions";
import { popupConfig } from "../../../configs/popupConfig";
import {getProjectStatus} from "../../../helpers/uiHelpers";
import {proceedToAutoBookingSubmit} from "../../../helpers/modalHelpers";
import {TOAST_TYPES} from "../../../constants/uiConstants";
import { selectAutobookingRules } from '../../../helpers/selectors';
import { t } from '../../../helpers/translate';

const initialValues = {
    "name": "",
    "product_ids": [3],
    "countries": [],
    "sport_id": null,
    "league_ids": [],
    "region_ids": [],
    "teams": [{
        "team_1_id": null,
        "team_2_id": null // null means all teams
    }],
    "valid_date_period": {
        "start": Math.floor(startOfDay(new Date()).valueOf() / 1000),
        "end": Math.floor(addYears(new Date(), 1).valueOf() / 1000)
    },
    "valid_time_period": {
        "start": "00:00", // Format hh:mm
        "end": "23:59" // null means the end of day
    },
    "valid_week_days": [2, 3, 4, 5, 6, 7, 1], // acceptable from 0 - 6, o is monday
    "time_zone": null,
};

const AutoBookingRulesTab = (props) => {
    const {autoBookingRulesData, closePopup} = props,
        userProfile = usersDataService.profile && usersDataService.profile.getValue(),
        initialData = {
            ...initialValues,
            countries: userProfile.default_activity_countries,
            time_zone: userProfile.time_zone,
        };

    const dispatch = useDispatch();
    const { editingRuleId, ruleCreatingButtonState } = useSelector(selectAutobookingRules);

    const getEditingRuleData = () => {
        const data = editingRuleId && autoBookingRulesData.find(rule => rule.id === editingRuleId);
        if (data && !data.sport && !data.sport_id) {
            data.sport_id = -1;
            data.sport = {name: "All", id: -1};
        }

        return data;
    };

    const editingRuleData = getEditingRuleData();

    const validate = useCallback((values) => {
        const errors = Object.keys(values).reduce((arr, curr) => {
            switch (curr) {
                case "name": {
                    if (!values[curr]) {
                        arr[curr] = t('Name is required');
                        return arr;
                    }
                    autoBookingRulesData.some(rule => ((!editingRuleData || rule.id !== editingRuleData.id) && rule[curr] === values[curr])) && (arr.sameNameError = t('A rule with this name already exists'));
                    return arr;
                }
                case "sport_id": {
                    !values[curr] && (arr[curr] = t('Sport is required'));
                    return arr;
                }
                case "product_ids": {
                    !values[curr].length && (arr[curr] = t('Product is required'));
                    return arr;
                }
                case "countries":
                    values.product_ids.length && (values.product_ids.includes(VIDEO_STREAMING_PRODUCT_ID) && !values[curr].length) && (arr[curr] = t('Countries is required'));
                    return arr;
                default:
                    return arr;
            }
        }, {});
        if (Object.keys(errors).length <= 1) {
            dispatch(enableRuleCreationButton(true));
        }
        if (!Object.keys(errors).length) {
            const newValuesInstance = {...values};
            delete newValuesInstance.name;
            delete newValuesInstance.valid_date_period;
            delete newValuesInstance.valid_time_period;
            autoBookingRulesData.some(rule => {
                if (!editingRuleData || rule.id !== editingRuleData.id) {
                    const ruleDate = Object.keys(newValuesInstance).reduce((arr, curr) => {
                        arr[curr] = rule[curr];
                        return arr;
                    }, {});
                    return Helpers.isEqual(ruleDate, newValuesInstance);
                }
                return false;
            }) && (errors.same_rule = t('You have a rule with the same criteria'));
           dispatch(enableRuleCreationButton(!!Object.keys(errors).length));
        }
        return errors;
    }, [autoBookingRulesData, editingRuleId]);  // eslint-disable-line react-hooks/exhaustive-deps

    const createRule = (values, formProps) => {
        if (values.sport_id === -1) {
            values.sport_id = null;
            values.sport = null;
        }
        const handleRuleCreate = () => {
            bookingDataService.createAutoBookingRule(values)
                .then((data) => {
                    dispatch(enableRuleCreationButton(true));
                    formProps.resetForm(initialData);
                    dispatch(resetStyle(" "));
                    dispatch(registerToastMessage({ message: "Rule Created" }));
                    closePopup();
                })
                .catch((err) => {
                    dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Can't create rule check your selections"}));
                });
        };

        !Helpers.getCookieParam("autoBookingCreate") ? dispatch(openPopup("message", {
            ...popupConfig.autoBookingCreate,
            handleCallBack: () => handleRuleCreate()
        })) : handleRuleCreate();
    };

    const editRule = (values, formProps) => {
        if (values.sport_id === -1) {
            values.sport_id = null;
            values.sport = null;
        }
        const handleRuleEdit = () => {
            bookingDataService.updateAutobookingRule(editingRuleId, values)
                .then(data => {
                    dispatch(enableRuleCreationButton(true));
                    formProps.resetForm(initialData);
                    dispatch(setAutobookingRuleEditId(null));
                    dispatch(registerToastMessage({message: "Rule Edited"}));
                    closePopup();
                })
                .catch((err) => {
                    dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Can't create rule check your selections"}));
                });
        };

        !Helpers.getCookieParam("autoBookingUpdate") ? dispatch(openPopup("message", {
            ...popupConfig.autoBookingUpdate,
            handleCallBack: () => handleRuleEdit()
        })) : handleRuleEdit();
    };

    const handleSubmit = (values, formProps) => {
        if (values.name && values.sport_id && values.product_ids.length && (!values.product_ids.includes(VIDEO_STREAMING_PRODUCT_ID) || values.countries.length)) {
            const {freeTrialIsOver, isEnabled, isDemo, isPending} = getProjectStatus();

            if (proceedToAutoBookingSubmit(isDemo, freeTrialIsOver, isPending, isEnabled, dispatch)) {
                const data = Object.keys(values).reduce((acc, curr) => {
                    if (curr === 'teams') {
                        return {...acc, teams: values[curr].reduce((collection, team) => {
                            delete team.id;
                            return [...collection, team];
                            }, [])}
                    }
                    return {...acc, [curr]: values[curr]};
                }, {});
                !editingRuleId ? createRule(data, formProps) : editRule(data, formProps);
            }
        }
    };

    return (
        <Formik
            initialValues={editingRuleData || initialData}
            validate={validate}
            onSubmit={() => {}}
            validateOnChange={true}
            validateOnBlur={true}
            isInitialValid={true}
            enableReinitialize
            render={
                (formProps) => (
                    <RuleCreationForm
                        ruleCreatingButtonState = {ruleCreatingButtonState}
                        formProps={formProps}
                        handleFormSubmit={handleSubmit}
                        editingRuleId={editingRuleId}
                        initialState={initialData}
                        closePopup={closePopup}
                        dispatch={dispatch}
                    />
                )
            }
        >
        </Formik>
    );
};

AutoBookingRulesTab.propTypes = {
    autoBookingRulesData: PropTypes.array,
    editingRuleId: PropTypes.any,
    eventsType: PropTypes.string,
    ruleCreatingButtonState: PropTypes.bool
};

AutoBookingRulesTab.requiredProps = {
    autoBookingRulesData: PropTypes.array,
};

export default componentDataProvider(AutoBookingRulesTab);

