import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { BUSINESS_MODEL, BUSINESS_MODEL_OPTIONS } from '../../../../constants/dataConstants';
import Helpers from '../../../../helpers/helperFunctions';
import { t } from '../../../../helpers/translate';
import DatePickerInput from '../../../shared/datePickerInput';
import DropDown from '../../../shared/dropDown';
import InformationMessage from '../../../shared/informationMessage';
import Input from '../../../shared/input';
import FixedSportsLeagues from './fixedSportsLeagues';

const freePackageDurationOptions = [{ name: 'Forever', id: 'forever' }, { name: 'Custom', id: 'custom' }];

const FinancialSectionSetup = (props) => {
    const {
        errors,
        values,
        touched,
        sportList,
        setTouched,
        setFreeMode,
        setSportList,
        initialValues,
        setFieldValue,
        initialSportList,
        freePackageDuration,
        setFreePackageDuration,
    } = props;

    const { monthly_fee, fixed_sports_leagues = [], future_business_model, business_model } = values;

    const [isFreeChosen, setIsFreeChosen] = useState(false);

    useEffect(() => {
        setFreeMode(isFreeChosen);

        if (isFreeChosen && business_model === BUSINESS_MODEL_OPTIONS.FREE && !freePackageDuration) {
            setFreePackageDuration('forever');
        }
    }, [isFreeChosen]); // eslint-disable-line

    useEffect(() => {
        if (!isFreeChosen && business_model === BUSINESS_MODEL_OPTIONS.FREE) {
            setFreePackageDuration(future_business_model ? 'custom' : 'forever');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fixed_sports_leagues?.length && sportList.length) {
            const sportIds = fixed_sports_leagues.filter(data => data.sport_id).map(data => data.sport_id);
            setSportList(sportList.filter(sport => !sportIds.includes(sport.id)));
        }
    }, [fixed_sports_leagues, sportList.length]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!initialValues.fixed_sports_leagues.length && (business_model === BUSINESS_MODEL_OPTIONS.FREE || future_business_model !== BUSINESS_MODEL_OPTIONS.MIXED)) {
            setFieldValue('fixed_sports_leagues', []);
        }
    }, [future_business_model]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (business_model === BUSINESS_MODEL_OPTIONS.FREE) {
            setIsFreeChosen(true);
        }
    }, [business_model]);

    const ppm = (
        <>
            <Field
                name="business_model_update_date"
                render={({ field: { name } }) => (
                    <DatePickerInput
                        type="calendar"
                        labelAppearance="title"
                        date={new Date(values[name] * 1000)}
                        minDate={new Date()}
                        onChange={(date) => setFieldValue(name, Helpers.toTimeStamp(date))}
                    />
                )}
            />
        </>
    );

    const fixedModel = (
        <>
            {future_business_model === BUSINESS_MODEL_OPTIONS.MIXED && (
                <Input
                    label={t('Fixed (Monthly)')}
                    labelAppearance="title"
                    value={(BUSINESS_MODEL.find(el => el.id === BUSINESS_MODEL_OPTIONS.FIXED) || {}).name}
                    placeholder={(BUSINESS_MODEL.find(el => el.id === BUSINESS_MODEL_OPTIONS.FIXED) || {}).name ?? ''}
                    readOnly
                />
            )}
            <Field
                name="monthly_fee"
                validate={() => values.monthly_fee === '' ? 'Monthly fee is required' : values.monthly_fee === 0 ? 'Monthly fee can not be 0' : ''}
                render={({ field: { name } }) => (
                    <Input
                        value={String(monthly_fee)}
                        labelAppearance="title"
                        placeholder={t('Monthly fee')}
                        errorText={touched[name] && errors[name]}
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                                setFieldValue(name, !e.target.value && typeof e.target.value === 'string' ? e.target.value : Number(e.target.value));
                                setTouched({ ...touched, [name]: true });
                            }
                        }}
                    />
                )}
            />
            {!isFreeChosen && (
                <Field
                    name="business_model_update_date"
                    render={({ field: { name } }) => (
                        <DatePickerInput
                            type="calendar"
                            labelAppearance="title"
                            date={new Date(values[name] * 1000)}
                            minDate={new Date()}
                            onChange={(date) => setFieldValue(name, Helpers.toTimeStamp(date))}
                        />
                    )}
                />
            )}
        </>
    );

    const mixedModel = (
        <>
            {fixedModel}
            <InformationMessage
                text={(
                    <>
                        <span>
                            Choose the sports and leagues that should be included in the <strong>Fixed / Monthly Package</strong>.
                        </span>
                        <span className='information-message-footnote'>
                            <strong>PLEASE NOTE:</strong> All other events that your partner books will be charged via per match fee.
                        </span>
                    </>
                )}
                buttonText={t('Add sport')}
                disabled={!(values.fixed_sports_leagues || []).every(el => !!el.sport_id)}
                onClick={() => {
                    setFieldValue('fixed_sports_leagues', [ ...values.fixed_sports_leagues, { id: Date.now(), sport_id: null, league_ids: [] } ])
                }}
                appearance="wide"
                icon="icon-info"
                className="span-2"
            />
            <Field
                name="fixed_sports_leagues"
                validate={() => (!fixed_sports_leagues.length || !fixed_sports_leagues.every(item => item.sport_id)) && 'Required'}
                render={({ field: { name } }) => (
                    <FixedSportsLeagues
                        name={name}
                        data={fixed_sports_leagues}
                        setFieldValue={setFieldValue}
                        sportList={sportList}
                        initialSportList={initialSportList}
                    />
                )}
            />
        </>
    );

    const freePackage = (
        <>
            <Field
                name="freePackageDuration"
                render={({ field: { name } }) => (
                    <DropDown
                        label={t('Free package duration')}
                        labelAppearance="title"
                        externalData={freePackageDurationOptions}
                        initialSelections={freePackageDurationOptions.find(item => item.id === freePackageDuration)}
                        onChange={(val) =>  {
                            setFreePackageDuration(val.id);
                            setFieldValue(name, val.id);
                        }}
                        withLazyLoad={false}
                        multiSelect={false}
                        searchable={false}
                    />
                )}
            />

            {freePackageDuration === 'custom' && (
                <>
                    <Field
                        name="business_model_update_date"
                        render={({ field: { name } }) => (
                            <DatePickerInput
                                type="calendar"
                                label={t('Free trial end date')}
                                labelAppearance="title"
                                date={new Date(values[name] * 1000)}
                                minDate={new Date()}
                                onChange={(date) => setFieldValue(name, Helpers.toTimeStamp(date))}
                            />
                        )}
                    />
                    <Field
                        name="future_business_model"
                        render={({ field: { name } }) => (
                            <DropDown
                                label={t('Business model after trial ends')}
                                labelAppearance="title"
                                placeholder={t('Select business model')}
                                onChange={(val) =>  setFieldValue(name, val.id)}
                                externalData={BUSINESS_MODEL.filter(el => ![BUSINESS_MODEL_OPTIONS.FREE].includes(el.id))}
                                initialSelections={values[name] ? BUSINESS_MODEL.find(item => values[name] === item.id) : null}
                                withLazyLoad={false}
                                multiSelect={false}
                                searchable={false}
                            />
                        )}
                    />
                </>
            )}
            {
                freePackageDuration === 'custom' && (future_business_model === BUSINESS_MODEL_OPTIONS.FIXED
                ? fixedModel
                : future_business_model === BUSINESS_MODEL_OPTIONS.MIXED
                    ? mixedModel
                    : null)
            }
        </>
    );

    const renderContentByBusinessModelType = () => {
        switch (true) {
            case (isFreeChosen && business_model === BUSINESS_MODEL_OPTIONS.FREE):
                return freePackage;
            case future_business_model === BUSINESS_MODEL_OPTIONS.PPM:
                return ppm;
            case future_business_model === BUSINESS_MODEL_OPTIONS.FIXED:
                return fixedModel;
            case future_business_model === BUSINESS_MODEL_OPTIONS.MIXED:
                return mixedModel;
            default:
                return null;
        }
    };

    return (
        <div className="form-grid col-2">
            <Field
                 name={business_model === BUSINESS_MODEL_OPTIONS.FREE ? "business_model" : "future_business_model"}
                 render={({ field: { name } }) => (
                     <DropDown
                         label={t('Business model')}
                         labelAppearance="title"
                         placeholder={t('Select business model')}
                         externalData={BUSINESS_MODEL}
                         onChange={(val) => {
                             if (val.id === BUSINESS_MODEL_OPTIONS.FREE) {
                                 setFieldValue('business_model', val.id);
                                 setFieldValue('future_business_model', BUSINESS_MODEL_OPTIONS.FIXED);
                                 setIsFreeChosen(true);
                             } else {
                                 setIsFreeChosen(false);
                                 setFieldValue('future_business_model', val.id);
                                 setFieldValue('business_model', initialValues.business_model);
                             }
                         }}
                         initialSelections={values[name] ? BUSINESS_MODEL.find(item => values[name] === item.id) : null}
                         withLazyLoad={false}
                         multiSelect={false}
                         searchable={false}
                     />
                 )}
            />
            {renderContentByBusinessModelType()}
        </div>
    );
};

export default FinancialSectionSetup;
