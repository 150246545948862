import { FEDERATIONS_TABS, OPERATOR_TABS, UNIQUE_FEDERATION_TABS } from '../constants/uiConstants';
import {
    selectEventsType,
    selectFederationsTabType,
    selectOperatorTabType,
    selectUniqueFederationTabType
} from '../actions/sync-actions/uiActions';
import { SetMultipleFiltersValue } from '../actions/sync-actions/filters';
import { getEventType } from '../constants/queryParams';
import { getBasePath } from './routeHelper';
import { t } from "../helpers/translate";

export const getFederationsSteps = (type, federationsTabType, breadcrumbBase, uniqueFederationTabType, eventsType, dispatch, history, federationId) => {
    const eventsListTypes = {list: "List", upcoming: "Upcoming", history: "History"};

    const handleFederationTabChange = (key) => {
        dispatch(selectFederationsTabType(key));
        history.push(getBasePath() + type);
    };

    const handleEventsTypeChange = (key, eventsType) => {
        if (key === eventsType) return;
        const payload = {
            type: getEventType(key),
            ...(federationId && {organization_ids: federationId}),
        };

        dispatch(selectEventsType(key));
        dispatch(SetMultipleFiltersValue(key, payload, "events"));
    };

    return [
        {
            name: t(FEDERATIONS_TABS[federationsTabType]),
            id: 'wrapper',
            children: Object.keys(FEDERATIONS_TABS).map(key => ({
                name: t(FEDERATIONS_TABS[key]),
                id: key,
                onClick: () => handleFederationTabChange(key),
                ...(federationsTabType === key && {selected: true})
            })),
        },
        {
            name: breadcrumbBase,
        },
        {
            name: t(UNIQUE_FEDERATION_TABS[uniqueFederationTabType]),
            id: 'sub',
            children: Object.keys(UNIQUE_FEDERATION_TABS).map(key => ({
                name: t(UNIQUE_FEDERATION_TABS[key]),
                id: key,
                onClick: () => dispatch(selectUniqueFederationTabType(key)),
                ...(uniqueFederationTabType === key && {selected: true})
            })),
        },
        ...(
            uniqueFederationTabType === "matches" ? [
                {
                    name: t(eventsListTypes[eventsType]),
                    current: true,
                    children: Object.keys(eventsListTypes).map(key => ({
                        name: t(eventsListTypes[key]),
                        id: key,
                        onClick: () => handleEventsTypeChange(key, eventsType),
                        selected: key === eventsType,
                    })),
                }
            ] : []
        ),
    ];
};

export const getOperatorsSteps = (type, breadcrumbBase, operatorTabType, dispatch, history) => {
    return [
        {
            name: t("Operators"),
            id: 'wrapper',
            onClick: () => history.push(getBasePath() + type),
        },
        {
            name: t(breadcrumbBase),
        },
        {
            name: t(OPERATOR_TABS[operatorTabType]),
            id: 'sub',
            children: Object.keys(OPERATOR_TABS).map(key => ({
                name: t(OPERATOR_TABS[key]),
                id: key,
                onClick: () => {
                    dispatch(selectOperatorTabType(key));
                },
                ...(operatorTabType === key && {selected: true})
            })),
        },
    ];
};
