import React from "react";
import { t } from '../../../../helpers/translate';

const NoEvents = () => (
    <div className="match-list-info">
        <div className="hover-cursor">
            <i className="far fa-mouse-pointer"/>
            <p>{t('Please add events')}</p>
        </div>
    </div>
);

export default NoEvents;
