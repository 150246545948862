import React  from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { closeRightPanel, registerToastMessage } from '../../../actions/sync-actions/uiActions';
import { APPROVE_STATUSES } from '../../../constants/dataConstants';
import { TOAST_TYPES } from '../../../constants/uiConstants';
import Helpers from '../../../helpers/helperFunctions';
import { t } from '../../../helpers/translate';
import operatorsDataService from '../../../services/operatorsDataService';
import FileInput from '../../federations/federationSetup/parts/fileInput';
import OverlayContent from '../../settings/rightPanel/overlayContent';
import DropDown from '../../shared/dropDown';
import Input from '../../shared/input';
import Title from '../../shared/title';

const partnerTypes = [{id: 0, name: 'Real'}, {id: 1, name: 'Test'}];

const OperatorSetup = (props) => {
    const {
        data,
        operatorId,
    } = props;

    const dispatch = useDispatch();

    const handleSubmit = (values, initialValues) => {
        const payload = Helpers.removeKeyFromObject(values, 'files');
        const initialPayload = Helpers.removeKeyFromObject(initialValues, 'files');
        const selectedFile = values.files.project_delivery_document;

        const createNewFile = (selectedFile && !data.files?.project_delivery_document?.id) || (selectedFile && selectedFile?.id !== data.files?.project_delivery_document?.id);
        const deleteFile = !createNewFile && !selectedFile && !!data.files?.project_delivery_document?.id;
        const updateOperator = Helpers.isEqual(payload, initialPayload);

        const formData = new FormData();
        createNewFile && formData.append(selectedFile?.name, new Blob([selectedFile], { type: selectedFile?.type }), selectedFile?.name || 'file');

        Promise.all([
            ...(!updateOperator ? [operatorsDataService.updateOperatorById(operatorId, payload)] : []),
            ...(deleteFile ? [operatorsDataService.deleteAttachment(operatorId)] : []),
            ...(createNewFile ? [operatorsDataService.createOperatorAttachment(operatorId, formData)] : []),
        ])
            .then(() => {
                dispatch(registerToastMessage({
                    title: "Operator Updated",
                }));
                dispatch(closeRightPanel());
            })
            .catch((err) => {
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: err.body?.message || "Oops something went wrong"}));
            });
    };

    return (
        <Formik
            initialValues={{
                is_test: data.is_test,
                sales_manager: data.sales_manager,
                project_manager: data.project_manager,
                account_manager: data.account_manager,
                files: {
                    project_delivery_document: data.files?.project_delivery_document,
                },
                ...(data.is_api_partner ? { approve_status: data.approve_status } : {}),
            }}
            onSubmit={() => {}}
            enableReinitialize
        >
            {({ isValid, errors, values, initialValues, setFieldValue }) => (
                <OverlayContent
                    title={t('Operator Setup')}
                    subTitle=""
                    appearance="default"
                    secondaryButton={{
                        name: t('Cancel'),
                        onClick: () => dispatch(closeRightPanel()),
                    }}
                    primaryButton={{
                        name: t('Save'),
                        color: 'primary',
                        disabled: !isValid || !!Object.keys(errors).length,
                        onClick: () => handleSubmit(values, initialValues),
                    }}
                >
                    <div className="form-grid col-2">
                        <Title
                            label='General'
                            className='span-2'
                        />
                        {!!data.is_api_partner ? (
                            <Field
                                name="approve_status"
                            >
                                {({ field: { name } }) => (
                                    <DropDown
                                        key={values[name]}
                                        label={t('FS Video Status')}
                                        labelAppearance="title"
                                        placeholder={t('Select FS video status')}
                                        externalData={APPROVE_STATUSES}
                                        initialSelections={APPROVE_STATUSES.find(item => values[name] === item.id)}
                                        onChange={(val) => setFieldValue(name, val.id)}
                                        withLazyLoad={false}
                                        multiSelect={false}
                                        searchable={false}
                                    />
                                )}
                            </Field>
                        ) : null}
                        <Field
                            name="sales_manager"
                        >
                            {({ field: { name } }) => (
                                <Input
                                    label={t("Sales Manager")}
                                    type='text'
                                    placeholder={t("Enter sales manager name")}
                                    value={values[name]}
                                    onChange={(e) => {
                                        setFieldValue(name, e.target.value);
                                    }}
                                    labelAppearance="title"
                                />
                            )}
                        </Field>
                        <Field
                            name="account_manager"
                        >
                            {({ field: { name } }) => (
                                <Input
                                    label={t("Account Manager")}
                                    type='text'
                                    placeholder={t("Enter account manager name")}
                                    value={values[name]}
                                    onChange={(e) => {
                                        setFieldValue(name, e.target.value);
                                    }}
                                    labelAppearance="title"
                                />
                            )}
                        </Field>
                        <Field
                            name="project_manager"
                        >
                            {({ field: { name } }) => (
                                <Input
                                    label={t("Project Manager")}
                                    type='text'
                                    placeholder={t("Enter project manager name")}
                                    value={values[name]}
                                    onChange={(e) => {
                                        setFieldValue(name, e.target.value);
                                    }}
                                    labelAppearance="title"
                                />
                            )}
                        </Field>
                        <Title
                            label='Partner Type'
                            className='span-2'
                        />
                        <Field
                            name="is_test"
                        >
                            {({ field: { name } }) => (
                                <>
                                    <DropDown
                                        key={values[name]}
                                        label={t('Partner Type')}
                                        labelAppearance="title"
                                        placeholder={t('Select partner type')}
                                        externalData={partnerTypes}
                                        initialSelections={partnerTypes.find(item => values[name] === item.id)}
                                        onChange={(val) => setFieldValue(name, val.id)}
                                        withLazyLoad={false}
                                        multiSelect={false}
                                        searchable={false}
                                    />
                                </>
                            )}
                        </Field>
                        <Title
                            label='Attachments'
                            className='span-2'
                        />
                        <Field
                            name="project_delivery_document"
                            render={({ field: { name } }) => (
                                <FileInput
                                    initialFile={values.files[name]}
                                    name={name}
                                    accepts={['.doc', '.docx', '.pdf']}
                                    label="Project Delivery Document"
                                    className='span-2'
                                    onChange={file => {
                                        setFieldValue('files', {
                                            [name]: file
                                        });
                                    }}
                                />
                            )}
                        />
                    </div>
                </OverlayContent>
            )}
        </Formik>

    );
};

OperatorSetup.propTypes = {
    operatorId: PropTypes.number,
    data: PropTypes.object,
};

export default OperatorSetup;
