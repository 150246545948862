import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import worldIconsSet from '../../../assets/fonts/world-icons/selection';

import '../../../assets/fonts/world-icons/style.css';
import './flag.scss';

const Flag = props => {
    const {
        name,
        size,
        color,
        className,
    } = props;
    const flagName = name
        .toLowerCase()
        .replace(/([ ]|[(]|[)]|[.]|[']|[-]|[,])/g, '')
        .replace(/é/g, 'e')
        .replace(/ç/g, 'c')
        .replace(/å/g, 'a')
        .replace(/ô/g, 'o')
    ;
    const isIcon = worldIconsSet.icons.filter((icon) => (icon.properties.name.split(', ').includes(flagName))).length;

    return (
        <div
            className={classnames(
                'flag-holder',
                `c-${color}`,
                `s-${size}`,
                className,
                {
                    [`flag flag-${flagName}`]: !isIcon,
                    [`wi-${flagName}`]: isIcon,
                },
            )}
        />
    )
};

Flag.defaultProps = {
    name: 'world',
    color: 'accent',
};

Flag.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['default', 'small', 'medium']),
    color: PropTypes.oneOf(['accent', 'default']),
};

export default Flag;
