import { useEffect, useCallback } from "react";

const defaultConfig = { attributes: true, childList: true, subtree: true };

const useMutationObserver = (ref, callback, config = defaultConfig) => {
    const handleObservation = useCallback((mutationsList) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                callback && callback();
            }
        }
    }, [callback, ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let observer;
        if (ref.current) {
            observer = new MutationObserver(handleObservation);
            observer.observe(ref.current, config);
        }
        return () => observer && observer.disconnect();
    }, [ref.current, handleObservation]); // eslint-disable-line react-hooks/exhaustive-deps

};

export default useMutationObserver;
