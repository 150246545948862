import React, { useEffect, useState } from "react";
import { Field } from "formik";

import federationsDataService from '../../../../../services/federationsDataService';

import DropDown from '../../../../shared/dropDown';
import Switch from "../../../../shared/switcher";
import CheckAvailability from "../../../../shared/checkAvailability";
import Title from '../../../../shared/title';

import {
    getRoleName,
    ADMIN,
    CONTENT_CREATOR,
    LEAGUE_OWNER,
    REPORTER,
    STREAMER,
    CLIENT,
    CLIENT_ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR
} from "../../../../../constants/userRoles";
import ClientRolesTab from './clientRolesTab';

import Helpers from "../../../../../helpers/helperFunctions";
import { t } from '../../../../../helpers/translate';

import './rolesTab.scss';

const RolesTab = ({ setSwitchState, values, setFieldValue, adminsCreation }) => {
    const [federationsGroupList, setFederationsGroupList] = useState();
    const isModeratorProject = Helpers.checkRole(ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER);

    useEffect(() => {
        if (isModeratorProject) {
            federationsDataService.getFederationsGroup()
                .then(res => {
                    setFederationsGroupList(res?.data);
                })
                .catch(e => e);
        }
    }, []);

    const adminCreationRenderer = () => {
        const selectedRoleId = values?.roles[0]?.id;
        return (
            <>
                <CheckAvailability availableRoles={[ADMIN]}>
                    <div className="form-grid col-1">
                        <Title
                            label={t('General')}
                            className="span-3"
                        />
                    </div>
                    <div className="role-switcher">
                        <Field
                            name="roles"
                            render={({ field }) => (
                                <Switch
                                    checked={!!Helpers.existValueInArray(field.value, 'id', ADMIN)}
                                    onChange={() => {
                                        setFieldValue(values.federation_groups, []);
                                        setSwitchState({ id: ADMIN }, values, setFieldValue);
                                    }}
                                    label={t('Administrator')}
                                    description={t('Full view access, full editing access')}
                                />
                            )}
                        />
                    </div>
                </CheckAvailability>
                <CheckAvailability availableRoles={[ADMIN, CP_ADMINISTRATOR]}>
                    <div className="form-grid col-1">
                        <Title
                            label={t('Content Provider')}
                            className="span-3"
                        />
                    </div>
                    <div className="role-switcher">
                        <Field
                            name="roles"
                            render={({ field }) => (
                                <Switch
                                    checked={!!Helpers.existValueInArray(field.value, 'id', CP_ADMINISTRATOR)}
                                    onChange={() => setSwitchState({ id: CP_ADMINISTRATOR }, values, setFieldValue)}
                                    label={getRoleName(CP_ADMINISTRATOR)}
                                    description={t('Full view access, full editing access for all pages except "Operators.')}
                                />
                            )}
                        />
                    </div>
                </CheckAvailability>
                <CheckAvailability availableRoles={[ADMIN, CP_ADMINISTRATOR]}>
                    <div className="role-switcher">
                        <Field
                            name="roles"
                            render={({ field }) => (
                                <Switch
                                    checked={!!Helpers.existValueInArray(field.value, 'id', CP_GROUP_MANAGER)}
                                    onChange={() => {
                                        setFieldValue('federation_groups', {});
                                        setSwitchState({ id: CP_GROUP_MANAGER }, values, setFieldValue);
                                    }}
                                    label={t('Federation Group Manager')}
                                    description={t('Full view access, full editing access for the leagues and matches of attached group.')}
                                />
                            )}
                        />
                        {selectedRoleId === CP_GROUP_MANAGER &&
                        <DropDown label="Federations Group"
                                  labelAppearance="title"
                                  initialSelections={values.federation_groups[0] || values.federation_groups || null}
                                  onChange={(group) => setFieldValue('federation_groups', {
                                      name: group.name,
                                      id: group.id,
                                  })}
                                  allowDeselect
                                  required
                                  externalData={federationsGroupList}
                                  withLazyLoad={false}
                                  keys={{ key: 'id', value: 'name' }}
                                  placeholder="Select Federations Group"
                                  className="role-switcher"/>
                        }
                    </div>
                </CheckAvailability>
                <CheckAvailability availableRoles={[ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER]}>
                    <div className="role-switcher">
                        <Field
                            name="roles"
                            render={({ field }) => (
                                <Switch
                                    checked={!!Helpers.existValueInArray(field.value, 'id', FEDERATION_GROUP_COORDINATOR)}
                                    onChange={() => {
                                        setFieldValue('federation_groups', {});
                                        setSwitchState({ id: FEDERATION_GROUP_COORDINATOR }, values, setFieldValue);
                                    }}
                                    label={t('Federation Group Coordinator')}
                                    description={t('Full view access, full editing access for the matches of attached group.')}
                                />
                            )}
                        />
                        {selectedRoleId === FEDERATION_GROUP_COORDINATOR &&
                        <DropDown label="Federations Group"
                                  labelAppearance="title"
                                  onChange={(group) => setFieldValue('federation_groups', {
                                      name: group.name,
                                      id: group.id,
                                  })}
                                  initialSelections={values.federation_groups[0] || values.federation_groups || null}
                                  externalData={federationsGroupList}
                                  withLazyLoad={false}
                                  required
                                  allowDeselect
                                  keys={{ key: 'id', value: 'name' }}
                                  placeholder="Select Federations Group"
                                  className="role-switcher"/>}
                    </div>
                </CheckAvailability>
                <CheckAvailability availableRoles={[ADMIN]}>
                    <div className="form-grid col-1">
                        <Title
                            label={t('Content Buyer')}
                            className="span-3"
                        />
                    </div>
                    <div className="role-switcher">
                        <Field
                            name="roles"
                            render={({ field }) => (
                                <Switch
                                    checked={!!Helpers.existValueInArray(field.value, 'id', CLIENT_ADMIN)}
                                    onChange={() => setSwitchState({ id: CLIENT_ADMIN }, values, setFieldValue)}
                                    label={t('CB Administrator')}
                                    description={t('Full view access, full editing access for "Operators" page.')}
                                />
                            )}
                        />
                    </div>
                </CheckAvailability>
            </>
        );
    };

    const defaultRenderer = () => {
        return (
            <div>
                <Field
                    name="roles"
                    render={({ field }) => (
                        <Switch
                            checked={!!Helpers.existValueInArray(field.value, 'id', LEAGUE_OWNER)}
                            label={t('Content Provider')}
                            onChange={() => setSwitchState({ id: LEAGUE_OWNER }, values, setFieldValue)}
                            description={t('This role has all necessary accesses to manage other accounts. While activating this role, the rest of the options will be disabled.')}
                        />
                    )}
                />
                <Field
                    name="roles"
                    render={({ field }) => (
                        <Switch
                            checked={!!Helpers.existValueInArray(field.value, 'id', STREAMER)}
                            label={getRoleName(STREAMER)}
                            onChange={() => setSwitchState({ id: STREAMER }, values, setFieldValue)}
                            description={t('This role can stream all events from My Events section. Streamer has also access to the live reporting results in the streaming page. While activating this role, only Admin will be disabled.')}
                        />
                    )}
                />
                <Field
                    name="roles"
                    render={({ field }) => (
                        <Switch
                            checked={!!Helpers.existValueInArray(field.value, 'id', CONTENT_CREATOR)}
                            label={getRoleName(CONTENT_CREATOR)}
                            onChange={() => setSwitchState({ id: CONTENT_CREATOR }, values, setFieldValue)}
                            description={t('This role can input only new match requests and add events to My Events for streaming. While activating this role, only Admin will be disabled.')}
                        />
                    )}
                />
                <Field
                    name="roles"
                    render={({ field }) => (
                        <Switch
                            checked={!!Helpers.existValueInArray(field.value, 'id', REPORTER)}
                            onChange={() => setSwitchState({ id: REPORTER }, values, setFieldValue)}
                            label={getRoleName(REPORTER)}
                            description={t('This role can view all reports and results. While activating this reporting, only Admin will be disabled.')}
                        />
                    )}
                />
            </div>
        );
    };

    switch (true) {
        case !!adminsCreation:
            return adminCreationRenderer();
        case !!Helpers.checkRole(CLIENT, CLIENT_ADMIN):
            return (
                <ClientRolesTab
                    values={values}
                    setSwitchState={setSwitchState}
                    setFieldValue={setFieldValue}
                />
            );
        default:
            return defaultRenderer();
    }
};

export default RolesTab;
