import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import OverlayContent from "../../settings/rightPanel/overlayContent";
import Tabs from "../../shared/tabs";
import Tab from "../../shared/tab";
import { closeRightPanel, openRightPanel, registerToastMessage } from "../../../actions/sync-actions/uiActions";
import PartnerInfo from "./parts/partnerInfo";
import federationsDataService from "../../../services/federationsDataService";
import { RIGHT_PANEL_ITEMS, TOAST_TYPES } from "../../../constants/uiConstants";
import { t } from '../../../helpers/translate';

const validate = (values) => {
    return Object.keys(values)
        .reduce((acc, curr) => {
            switch (curr) {
                case 'email': {
                    if (!values.email) {
                        acc[curr] = t('Required');
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        acc[curr] = t('Invalid email address');
                    }
                    return acc;
                }
                case 'name':
                case 'sport_ids':
                case 'region_ids':
                case 'league_ids': {
                    !values[curr].length && (acc[curr] = t('Required'));
                    return acc;
                }
                default:
                    return acc;
            }
        }, {});
};

const FederationSetup = (props) => {
    const { dispatch, federationId, callback, businessModelCb } = props;
    const [organizationId, setOrganizationId] = useState(null);
    const [initialValues, setInitialValues] = useState({
        name: '',
        region_ids: [],
        sport_ids: [],
        league_ids: [],
        email: '',
        address: '',
        phone: '',
        country_code: null,
    });

    useEffect(() => {
        if (federationId) {
            federationsDataService.getFederationsByIds(federationId)
                .then((federation) => {
                    const data = federation.data?.[0] || {};
                    setOrganizationId(data.id);

                    setInitialValues(values => ({
                        ...values,
                        name: data.name,
                        region_ids: data.region_ids || [],
                        sport_ids: data.sport_ids || [],
                        league_ids: data.league_ids || [],
                        email: data.email,
                        address: data.address ?? '',
                        phone: data.phone ?? '',
                        country_code: data.country_code,
                    }));
                });
        }
    }, [federationId]);

    const prepareDataForSubmit = (values) => {
        return Object.keys(values).reduce((acc, curr) => {
            if (federationId && curr === "email" && values[curr] === initialValues[curr]) return acc;
            return { ...acc, ...(values[curr] ? { [curr]: values[curr] } : {}) };
        }, {});
    };

    return (
        <Formik
            onSubmit={() => {
            }}
            initialValues={initialValues}
            enableReinitialize
            key={JSON.stringify(initialValues)}
            isInitialValid={false}
            validate={validate}
            render={({ values, setFieldValue, isValid, errors }) => (
                <OverlayContent
                    title={t('Federation Setup')}
                    subTitle=""
                    appearance="fit"
                    secondaryButton={{
                        name: t('Cancel'),
                        onClick: () => dispatch(closeRightPanel()),
                    }}
                    primaryButton={{
                        name: federationId ? t('Edit') : t('Create'),
                        color: federationId ? 'primary' : 'confirm',
                        disabled: !isValid || !!Object.keys(errors).length,
                        onClick: () => {
                            const data = prepareDataForSubmit(values);
                            (federationId
                                ? federationsDataService.updateFederation(federationId, data)
                                : federationsDataService.createFederation(data))
                                .then((res) => {
                                    typeof callback === "function" && callback(data);
                                    dispatch(registerToastMessage({
                                        title: federationId ? "Federation Updated" : "Federation Created",
                                        message: "Do you want to setup business model and payments?",
                                        applyText: "Business model & payments setup",
                                        mode: 'persistent',
                                        onApply: () => {
                                            dispatch(openRightPanel({
                                                rightPanelKey: RIGHT_PANEL_ITEMS.BUSINESS_MODEL_SETUP,
                                                federation: res,
                                                callback: businessModelCb,
                                            }));
                                        }
                                    }));
                                    dispatch(closeRightPanel());
                                })
                                .catch(err => {
                                    dispatch(registerToastMessage(
                                        err.body?.data?.length ?
                                            {
                                                type: TOAST_TYPES.ERROR,
                                                message: `This league is already attached to the other content provider, please contact Content Acquisition Team to clarify and detach from the previous one.
                                                ${err.body.data.map(item => `\n${item.league_name} : ${item.organization_name}`)}`,
                                                timeout: 15000
                                            }
                                            :
                                            {
                                                type: TOAST_TYPES.ERROR,
                                                message: err.body?.message || "Oops something went wrong"
                                            }));
                                });
                        }
                    }}
                >
                    <Form>
                        <Tabs appearance="inline">
                            <Tab title={t('Main Information')}>
                                <PartnerInfo
                                    values={values}
                                    organizationId={organizationId}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                />
                            </Tab>
                            <Tab title={t('Technical Information')}>
                                <div></div>
                            </Tab>
                        </Tabs>
                    </Form>
                </OverlayContent>
            )}
        />
    );
};

export default FederationSetup;
