import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import './loader.scss';

const Loader = (props) => {
    const {
        size,
        absoluteBlur,
        withPortal,
    } = props;

    const isAbsoluteBlur = props.hasOwnProperty('absoluteBlur');

    const loaderElement = () => (
        <div
            className={classnames('loader-holder', 'not-animation-fadeInUp not-animation-fadeIn', `s-${size}`, {
                'splash-blur-holder': isAbsoluteBlur,
                'active': absoluteBlur,
            })}
        >
            <div className="loader not-animation-fadeInUp not-animation-fadeIn">
                <div className="loader-item not-animation-fadeInUp not-animation-fadeIn loader-circle"/>
                <div className="loader-item not-animation-fadeInUp not-animation-fadeIn loader-icon icon-logo-p-1"/>
                <div className="loader-item not-animation-fadeInUp not-animation-fadeIn loader-icon icon-logo-p-2"/>
            </div>
        </div>
    );

    return (
        isAbsoluteBlur && withPortal ? (
            ReactDOM.createPortal(
                loaderElement(),
                document.getElementById('root')
            )
        ) : loaderElement()
    )
};

Loader.defaultProps = {
    size: 'default',
};

export default Loader;
