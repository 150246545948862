import {
    addDays,
    addMonths,
    addWeeks,
    endOfDay,
    endOfMonth,
    endOfWeek,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    subWeeks,
    endOfYear, startOfYear,
} from "date-fns";
import Helpers from "../helpers/helperFunctions";
import { t } from "../helpers/translate";

export const nextDateShortcuts = (tzDate, showFollowings, lastThreeMonths = false) => [
  { label: t('Today'), range: () => ({ startDate: startOfDay(tzDate), endDate: endOfDay(tzDate) }) },
  ...(showFollowings ? [{ label: t('Tomorrow'), range: () => ({ startDate: addDays(startOfDay(tzDate), 1), endDate: addDays(endOfDay(tzDate), 1) }) }] : []),
  { label: t('This week'), range: () => ({ startDate: startOfWeek(startOfDay(tzDate)), endDate: endOfWeek(tzDate) }) },
  ...(showFollowings ? [{ label: t('Following week'), range: () => ({ startDate: startOfWeek(addWeeks(tzDate, 1)), endDate: endOfWeek(addWeeks(tzDate, 1)) }) }] : []),
  { label: t('This month'), range: () => ({ startDate: startOfMonth(startOfDay(tzDate)), endDate: endOfMonth(endOfDay(tzDate)) }) },
  ...(showFollowings ? [{ label: t('Following month'), range: () => ({ startDate: startOfMonth(addMonths(tzDate, 1)), endDate: endOfMonth(addMonths(tzDate, 1)) }) }] : []),
  { label: t('This year'), range: () => ({ startDate: startOfYear(startOfDay(tzDate)), endDate: endOfYear(tzDate) }) },
    ...(lastThreeMonths ? [{ label: t('Last three months'), range: () => ({ startDate: startOfDay(addMonths(tzDate, -3)), endDate: endOfDay(tzDate) }) }] : [])
];

export const prevDateShortcuts = tzDate => [
  { label: t("Today"), range: () => ({ startDate: startOfDay(tzDate), endDate: endOfDay(tzDate) }) },
  { label: t("Yesterday"), range: () => ({ startDate: subDays(startOfDay(tzDate), 1), endDate: subDays(endOfDay(tzDate), 1) }) },
  { label: t("This week"), range: () => ({ startDate: startOfWeek(tzDate), endDate: endOfDay(tzDate) }) },
  { label: t("Last week"), range: () => ({ startDate: startOfWeek(subWeeks(tzDate, 1)), endDate: endOfWeek(subWeeks(tzDate, 1)) }) },
  { label: t("This month"), range: () => ({ startDate: startOfMonth(tzDate), endDate: endOfDay(tzDate) }) },
  { label: t("Last month"), range: () => ({ startDate: startOfMonth(subMonths(tzDate, 1)), endDate: endOfMonth(subMonths(tzDate, 1)) }) }
];

export const initialDateRange = (type = 'day') => {
    const timeByTimeZone = Helpers.getTimeZoneTime();
    switch (type) {
        case "day":
            return {
                start: Helpers.toTimeStamp(startOfDay(subMonths(timeByTimeZone, 1))),
                end: Helpers.toTimeStamp(endOfDay(timeByTimeZone)),
            };
        case 'month':
            return {
                start: Helpers.toTimeStamp(startOfMonth(timeByTimeZone)),
                end: Helpers.toTimeStamp(endOfMonth(timeByTimeZone)),
            };
        default:
            return {};
    }
};
