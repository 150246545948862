import React, { useState, useEffect } from 'react';
import NoEvents from "../../components/shared/noData/noEvents";
import Config from "../../configs/mainConfig";
import { popupConfig } from '../../configs/popupConfig';
import { CLIENT, CLIENT_ADMIN, SCOUT_OPERATOR } from '../../constants/userRoles';
import Helpers from "../../helpers/helperFunctions";
import { format } from "date-fns";
import Button from "../../components/shared/button";
import OverlayContent from "../../components/settings/rightPanel/overlayContent";
import { EVENT_TYPES, TOAST_TYPES } from "../../constants/uiConstants";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProjectStatus } from '../../helpers/uiHelpers';
import paymentsDataService from '../../services/paymentsDataService';
import scoutDataService from '../../services/scoutDataService';
import { closePopup, openPopup, registerToastMessage } from '../../actions/sync-actions/uiActions';
import { t } from '../../helpers/translate';

import '../userCart/cart.scss';


const ScoutUserCart = (props) => {
    const {
        dispatch,
    } = props;

    const [events, updateEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const eventsType = EVENT_TYPES.LIST;
    const eventPrice = 6.5;

    useEffect(() => {
        const subscription = scoutDataService.selectedScoutEventsList.subscribe(val => val && val[EVENT_TYPES.LIST] && updateEvents(val[EVENT_TYPES.LIST]));
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const removeCartEvent = (eventId) => () => {
        scoutDataService.toggleSelectedScoutEventsByIds(eventId, eventsType);
    };

    const bookEvents = () => {
        if (getProjectStatus().isWalletPayment && paymentsDataService.walletBalance.getValue() < events.length * eventPrice) {
            const userHaveFullPermission = Helpers.checkRole(CLIENT, CLIENT_ADMIN);
            return dispatch(openPopup('message', {
                ...popupConfig.lowBalance,
                ...(userHaveFullPermission ? {
                    primaryButton: {
                        name: "Deposit",
                        color: 'accent',
                    },
                } : {}),
                ...(Helpers.checkRole(SCOUT_OPERATOR) ? {
                    message: t('You have not enough funds to continue the current operation'),
                } : {}),
                handleCallBack: () => {
                    dispatch(closePopup());
                    if (userHaveFullPermission) {
                        setTimeout(() => {
                            history.push(`#wallet`);
                            dispatch(openPopup("settings", {
                                closeOnClickOutside: false
                            }));
                        });
                    }
                }
            }));
        }

        setIsLoading(true);
        scoutDataService.bookScoutEvents(events.map(val => val.game_id).join(','))
            .then(res => {
                if (res?.data === "Ok") {
                    dispatch(registerToastMessage({ message: "Booked" }));
                    setIsLoading(false);
                    scoutDataService.removeAllSelectedScoutEvents(eventsType);
                }
            })
            .catch(error => {
                dispatch(registerToastMessage({ type: TOAST_TYPES.ERROR, message: "Oops something went wrong" }));
                setIsLoading(false);
            })
    };

    return (
        events?.length ? <OverlayContent
            title={t('Your cart')}
            //subTitle=""
            footerTitle={`${Config.main.currency}${(events.length * eventPrice) || 0}`}
            footerSubTitle={t('Total price')}
            primaryButton={{
                color: 'confirm',
                disabled: isLoading,
                onClick: bookEvents,
                name: t('Book')
            }}
            secondaryButton={{
                name: t('Clear All'),
                onClick: () => {
                    scoutDataService.removeAllSelectedScoutEvents(eventsType);
                },
            }}
        >
            <div className="form-grid">
                {events.map((event) => {
                    const eventId = event.game_id;
                    const baseKey = `${eventId}/${event.league_id}`;

                    return (
                        <div key={baseKey} className="cart-item-holder">
                            <div className="card-item-content">
                                <div className="cart-item-close-holder">
                                    <Button
                                        icon="icon-clear"
                                        appearance="minimal"
                                        color="peach"
                                        onClick={removeCartEvent(eventId)}
                                    />
                                </div>
                                <ul className="cart-teams">
                                    <li>
                                        <div className="ellipsis-text">{event["home_team_name"]}</div>
                                    </li>
                                    <li>
                                        <small>{t('vs')}</small>
                                    </li>
                                    <li>
                                        <div className="ellipsis-text">{event["away_team_name"]}</div>
                                    </li>
                                </ul>
                                <div className="cart-location-info">
                                    <span>{`${event["sport_name"]} - ${event["league_name"]}`}</span>
                                </div>
                                <ul className="cart-price-details-holder">
                                    <li>
                                        <div className="cart-price">
                                            {Config.main.currency}{eventPrice || 0}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="cart-details-holder">
                                            <div>
                                                <strong>{format(Helpers.getTimeZoneTime(event["start_date"]), Helpers.getTimeFormat())}</strong>
                                            </div>
                                            <div>
                                                {format(Helpers.getTimeZoneTime(event["start_date"]), "d MMMM yyyy")}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    );
                })}
            </div>
        </OverlayContent> : <NoEvents/>
    );
};

export default connect()(ScoutUserCart);
