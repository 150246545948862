import React from "react";
import PropTypes from 'prop-types';
import Icon from "../icon";
import Button from "../button";

import './informationMessage.scss';

const InformationMessage = props => {
    const {
        title,
        text,
        icon,
        buttonUrl,
        buttonText,
        content,
        appearance,
        color,
        className,
        onClick,
        disabled,
    } = props;

    return (
        <ul className={`information-message-holder a-${appearance} c-${color} ${className ?? ''}`}>
            <li>
                {icon && (
                    <Icon
                        type={icon}
                        size={appearance === 'compact' ? 80 : 48}
                    />
                )}
            </li>
            <li>
                <h3>{title}</h3>
                <p>{text}</p>
            </li>
            <li>
                {content}
                {!!buttonUrl && (
                    <Button
                        tag="a"
                        href={buttonUrl}
                        flexibility={appearance === 'compact' ? 'full-width' : 'default'}
                        icon="icon-files-and-links"
                        size="big"
                        color="confirm"
                        target="_blank"
                    >
                        {buttonText}
                    </Button>
                )}
                {typeof onClick === 'function' && (
                    <Button
                        flexibility={appearance === 'compact' ? 'full-width' : 'default'}
                        icon="icon-plus"
                        color="confirm"
                        size="medium"
                        target="_blank"
                        onClick={onClick}
                        disabled={disabled}
                    >
                        {buttonText}
                    </Button>
                )}
            </li>
        </ul>
    )
};

InformationMessage.defaultProps = {
    appearance: 'wide',
    color: 'confirm',
};

InformationMessage.propTypes = {
    title: PropTypes.string,
    text: PropTypes.any,
    content: PropTypes.node,
    buttonUrl: PropTypes.string,
    buttonText: PropTypes.string,
    appearance: PropTypes.oneOf(['wide', 'compact']),
    color: PropTypes.oneOf(['confirm', 'jaffa']),
    icon: Icon.propTypes.type,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default InformationMessage;
