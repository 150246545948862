export const popupConfig = {
    "upcomingReject": {
        title: "Please note",
        classification: "warning",
        message: "Events you have chosen are not approved yet or they should have pricing to be added into the upcoming list.",
    },
    "upcoming": {
        title: "Add these events to the upcoming section?",
        message: "Review the pricing and the blocked countries for all the events and make sure they are correct. If you add these events to the upcoming section those would become available for the customers purchase.",
        dontShowAgainType: "upcomingPopup",
        classification: "info",
        primaryButton: {
            name: "Yes",
        },
    },
    "sessionTimeOut": {
        title: "Logged Out",
        message: "Your Session time out please login again",
        classification: "info",
        type: "logout"
    },
    "deletePreset": {
        title: "Delete saved filter preset",
        message: "Are you sure you want to delete this preset?",
        classification: "trash",
        primaryButton: {
            name: "Delete",
            color: 'danger',
        }
    },
    "eventsExportData": {
        message: "Here you can export only \"Live\" and \"Not Started\" matches for 1 month period at most.",
        title: "Export Data",
        dontShowAgainType: "exportDataConfirmation",
        classification: "info",
        primaryButton: {
            name: "Yes",
        },
    },
    "eventsExportHistoryData": {
        message: "Here you can export only finished/canceled match data for 1 month at most.",
        title: "Export Data",
        dontShowAgainType: "exportDataConfirmation",
        classification: "info",
        primaryButton: {
            name: "Yes",
        },
    },
    "reportsExportData": {
        message: "In case of global export you can only export reporting data for 1 month period at most. The reporting data will be exported based on date range you set: start date plus 1 month at most.",
        title: "Export Data",
        dontShowAgainType: "exportDataConfirmation",
        classification: "info",
        primaryButton: {
            name: "Yes",
        },
    },
    "noDataExport": {
        title: "Warning",
        message: "There is no data for the first month of the date range you set.",
        classification: "warning",
    },
    "assignStreamerReject": {
        title: "Assign Streamer",
        classification: "warning",
        message: "You can’t change the streamer when the event or streaming is already started.",
    },
    "blockBookedEvents": {
        classification: "info",
        title: null,
        message: null, // Message text is dynamic
        dontShowAgainType: "blockBookedEvents",
        primaryButton: {
            name: "Yes",
        },
    },
    "blockBookedEvent": {
        classification: "info",
        title: null,
        message: null, // Message text is dynamic
        dontShowAgainType: "blockBookedEvent",
        primaryButton: {
            name: "Yes",
        },
    },
    "rulefreeze": {
        primaryButton: {
            name: "Continue",
            color: "accent",
        },
        classification: "warning",
    },
    "ruleEdit": {
        title: "Edit the Rule",
        message: "Please note, your edit will affect all AutoBooked matches.",
        primaryButton: {
            name: "Continue",
            color: "accent",
        },
        classification: "warning",
    },
    "ruleDelete": {
        title: "Delete the Rule",
        message: "Do you want to delete the rule and already AutoBooked matches?",
        secondaryButton: {
            name: "Keep the matches",
        },
        primaryButton: {
            name: "Yes",
            color: 'danger',
        },
        functionOnClickOutside: false,
        classification: "trash",
    },
    "frozenRuleDelete": {
        title: "Delete the Rule",
        message: "Do you want to delete the rule?",
        primaryButton: {
            name: "Yes",
            color: 'danger',
        },
        classification: "trash",
    },
    "startStream": {
        title: "Start Stream",
        message: "Are you sure you want to start stream.",
        primaryButton: {
            name: "Start",
            color: 'confirm',
        },
        classification: "info",
    },
    "stopStream": {
        title: "Stop Stream",
        message: "Are you sure you want to stop stream.",
        primaryButton: {
            name: "Stop",
            color: 'danger',
        },
        classification: "warning",
    },
    "matchStart": {
        title: "Start match",
        message: "Are you sure you want to start the match?",
        primaryButton: {
            name: "Start",
            color: 'confirm',
        },
        classification: "info",
    },
    "matchEnd": {
        title: "End match",
        message: "Are you sure you want to end the match? Your streaming will end automatically.",
        primaryButton: {
            name: "Yes",
            color: 'danger',
        },
        classification: "warning",
    },
    "matchCancel": {
        title: "Cancel match",
        message: "Are you sure you want to cancel the match? Your streaming will end automatically.",
        primaryButton: {
            name: "Yes",
            color: 'danger',
        },
        classification: "warning",
    },
    "autoBookingCreate": {
        title: "Autobooking rule",
        message: "Are you sure you want to create this autoBooking rule? The matches will automatically be booked after your confirmation.",
        primaryButton: {
            name: "Continue",
        },
        dontShowAgainType: "autoBookingCreate",
        classification: "info",
    },
    "autoBookingUpdate": {
        title: "Autobooking rule",
        message: "Are you sure you want to update this autoBooking rule? The matches will automatically be updated after your confirmation.",
        primaryButton: {
            name: "Continue",
            color: 'accent',
        },
        dontShowAgainType: "autoBookingUpdate",
        classification: "warning",
    },
    "bookingRestriction": {
        title: "Enable Friendship",
        message: "In order to book matches you need to enable Friendship platform through Spring BME",
        primaryButton: {
            name: "Enable",
            color: 'confirm',
        },
        dontShowAgainType: null,
        classification: "info",
    },
    "autoBookingRestrictionForDisable": {
        title: "Enable Friendship",
        message: "In order to create autoBooking rules please enable Friendship platform through Spring BME",
        primaryButton: {
            name: "Enable",
            color: 'confirm',
        },
        dontShowAgainType: null,
        classification: "info",
    },
    "autoBookingRestrictionForDemo": {
        title: "Go Live",
        message: "You should be a LIVE partner to be able to create autoBooking rules. Please go LIVE using SpringBME or contact us.",
        primaryButton: {
            name: "Go Live",
            color: 'confirm',
        },
        dontShowAgainType: null,
        classification: "info",
    },
    "autoBookingRestrictionForPending": {
        title: "Check Status",
        message: "You should be a LIVE partner to be able to create autoBooking rules. Please check your project status using SpringBME or contact us.",
        primaryButton: {
            name: "Check status",
            color: 'accent',
        },
        dontShowAgainType: null,
        classification: "warning",
    },
    "enableFriendship": {
        title: "Enable Friendship",
        message: "In order to activate Friendship trial mode you need to enable it through Spring BME. Login to BME or simply follow this link.",
        primaryButton: {
            name: "Enable",
            color: 'confirm',
        },
        showOnlyOnce: 'enableFriendship',
        classification: "warning",
    },
    "friendshipIsEnabled": {
        title: "Friendship is Enabled",
        message: "Congrats! You have enabled Friendship trial. You have a free access to all our video streaming content for 10 days for free!. You can book the content and watch the streams directly in our platform. Go live and video streams will be also available on your betting website.",
        secondaryButton: {
            name: "OK",
        },
        primaryButton: {
            name: "Go Live"
        },
        showOnlyOnce: 'friendshipIsEnabled'
    },
    "freeTrialIsAlmostOver": {
        title: "Your free trial is almost over",
        message: "Your free trial will be over in less than a day. In order to use Friendship at its full capacity your project needs to be live. Navigate to Spring BME to go live. If you have already submitted documentation please contact your project manager to see the status.",
        secondaryButton: {
            name: "OK",
        },
        primaryButton: {
            name: "Contact Support",
            color: 'brand',
        },
        showOnlyOnce: 'freeTrialIsAlmostOver',
        classification: "warning",
    },
    "freeTrialIsOver": {
        title: "Your free trial is over",
        message: "Your free trial is over. In order to use Friendship at its full capacity your project needs to be live. Navigate to Spring BME to go live. P.S. If you have already submitted documentation please contact your project manager to see the status.",
        secondaryButton: {
            name: "OK",
        },
        primaryButton: {
            name: "Contact Support",
            color: 'brand',
        },
        showOnlyOnce: 'freeTrialIsOver',
        classification: "error",
    },
    "projectIsPending": {
        title: "Check Status",
        message: "You should be a LIVE partner to be able to book matches. Please check your project status using SpringBME or contact us.",
        primaryButton: {
            name: "Check status",
            color: 'accent',
        },
        showOnlyOnce: 'projectIsPending',
        classification: "warning",
    },
    "friendshipIsEnabledForLive": {
        title: "Friendship is Enabled",
        message: "Congrats! You have enabled Friendship trial. You have a free access to all our video streaming content for 10 days for free! You can book the content and watch the streams directly in our platform. After your trial is over, your video streams will be also available on your betting website.",
        secondaryButton: {
            name: "Cancel",
        },
        showOnlyOnce: 'friendshipIsEnabled',
        classification: "success",
    },
    "freeTrialIsAlmostOverForLive": {
        title: "Your free trial is almost over",
        message: "Your free trial will be over in less than a day. Once your free trial is over, your booked events will start appearing on your website. Please contact support in case of any questions.",
        secondaryButton: {
            name: "OK",
        },
        primaryButton: {
            name: "Contact Support",
            color: 'brand',
        },
        showOnlyOnce: 'freeTrialIsAlmostOver',
        classification: "warning",
    },
    "freeTrialIsOverForLive": {
        title: "Your free trial is over",
        message: "Your free trial is over. Starting from now on your booked matches will start appearing on your website. We also have freezed the autoBooking rules in case you didn't want to be charged for them. You can always unfreeze the rules later. Please contact support in case of any questions.",
        showOnlyOnce: 'freeTrialIsOver',
        classification: "error",
        secondaryButton: {
            name: "OK",
        },
        primaryButton: {
            name: "Contact Support",
        },
    },
    "autoBookingEditDate": {
        title: "Error",
        message: "You can’t choose end date that’s already passed.",
        classification: "error",
    },
    "skipTrial": {
        title: "Skip trial",
        message: "Are you sure you want to skip your trial? Once approved, the matches that you have booked will appear on your website. You will be charged automatically.",
        classification: "error",
        primaryButton: {
            name: "Skip the trial",
            color: 'danger',
        },
    },
    "goLiveForAPI": {
        title: "Go Live",
        message: "Our team will get back to you maximum in 24 hours",
        classification: "info",
        secondaryButton: {
            name: "Cancel",
        },
        primaryButton: {
            name: "Go Live",
        },
    },
    "ruleCreation": {
        title: "Create New AutoBooking Rule",
        closeOnClickOutside: false
    },
    "templateDelete": {
        title: "Deactivate template",
        message: "Are you sure you want to deactivate this template?",
        classification: "warning",
        primaryButton: {
            name: "Yes",
            color: 'accent',
        },
        secondaryButton: {
            name: "No",
        }
    },
    "rollBackEvent": {
        title: "Sending the event back to upcoming",
        message: "Are you sure you want to add the event back to upcoming section?",
        classification: "info",
        primaryButton: {
            name: "Yes",
            color: 'accent',
        },
        secondaryButton: {
            name: "No",
        }
    },
    "addToUpcoming": {
        title: "Adding events to upcoming",
        message: "Review the pricing and the blocked countries for all the events to make sure they are correct. If you add these events to the upcoming section those would become available for the customers purchase.",
        classification: "info",
        primaryButton: {
            name: "Add",
            color: 'accent',
        },
        secondaryButton: {
            name: "Cancel"
        }
    },
    "addToWatchList": {
        title: "Watchlist",
        message: "You can add maximum 6 games to the watchlist",
        classification: "warning",
        primaryButton: {
            name: "Go to watchlist",
            color: 'accent',
        },
        secondaryButton: {
            name: "Cancel"
        }
    },
    "streamerChangeWarning": {
        title: "Important",
        message: "The user you want to disable has streams assigned to . Do you want to proceed with disabling the user?",
        classification: "warning",
        primaryButton: {
            name: "Yes",
        },
        secondaryButton: {
            name: "No",
        },
    },
    "blockedUser": {
        title: "Blocked User",
        message: "You are disabled please contact your manager for more details.",
        secondaryButton: {
            name: "Log out",
        },
        primaryButton: {
            name: "Contact Support",
            color: 'brand',
        },
        classification: "warning",
        closeOnOk: false,
    },
    "deleteCard": {
        title: "Delete saved card",
        message: "Are you sure you want to delete this card?",
        classification: "trash",
        primaryButton: {
            name: "Delete",
            color: 'danger',
        }
    },
    "deleteStream": {
        title: "Delete Stream",
        message: "Are you sure you want to delete this stream?",
        classification: "trash",
        primaryButton: {
            name: "Delete",
            color: 'danger',
        }
    },
    "lowBalance": {
        title: "Low Balance",
        message: "You have not enough funds to continue the current operation. Please refill your balance to continue.",
        closeOnClickOutside: false,
        classification: "warning",
    },
    "leagueDetach": {
        title: "Detach league",
        message: "Are you sure you want to detach this league?",
        classification: "trash",
        primaryButton: {
            name: "Detach",
            color: 'danger',
        }
    },
    "leagueAttach": {
        title: "Attach league",
        message: "Are you sure you want to attach this league?",
        classification: "info",
        primaryButton: {
            name: "Attach",
            color: 'confirm',
        }
    },
    "storeLeagueStreams": {
        title: "Store league streams",
        message: "Are you sure you want to store all streams of this League?",
        classification: "info",
        primaryButton: {
            name: "Yes",
            color: "confirm",
        },
        secondaryButton: {
            name: "No",
        },
    },
    "forDisabledFederation" : {
        title: "Disabled federation",
        message:"This federation is disabled from Federation section",
        classification: "warning",
        primaryButton: {
            name: "Ok",
            color: "danger",
        }
    },

    "disableStoreLeagueStreams": {
        title: " Disabled store league streams",
        message: "Are you sure you DO NOT want to store all streams of this league anymore?",
        classification: "warning",
        primaryButton: {
            name: "Yes",
            color: "danger",
        },
        secondaryButton: {
            name: "No",
        },
    },
    "b2cBecomePartner": {
        classification: "info",
        title: "Become a Partner",
        message: "Do you want to become our partner? Great! Our Business Executive will contact you within 24 hours.",
        primaryButton: {
            name: "Contact Me",
            color: "confirm"
        },
    },
    "federationsGroupDelete": {
        title: "Delete federations group",
        classification: "trash",
        message: "By deleting the group all Federations and Users will be detached from it. Are you still sure you want to delete the Group?",
        primaryButton:{
            name: "Yes",
            color: "confirm"
        },
        secondaryButton:{
            name: "No",
        }
    },
    "federationsUserDelete": {
        title: "Remove the user from the group",
        classification: "trash",
        message: "The user will not be able to manage the federation content of this group any more. Are you sure you want to remove? ",
        primaryButton:{
            name: "Yes",
            color: "confirm"
        },
        secondaryButton:{
            name: "No",
        }
    }
};
