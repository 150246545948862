import React, { useState } from 'react';
import classnames from 'classnames';
import Helpers from "../../../helpers/helperFunctions";
import PropTypes from "prop-types";
import Button from "../button";

import './pagination.scss';

const Pagination = (props) => {
    const { data: { total, perPage, selectedPage }, onUpdate } = props;
    const [currentPage, setCurrentPage] = useState(selectedPage || 1);
    const pageButtons = Helpers.paginationButtonsBuilder(currentPage, Math.ceil(total / perPage));
    const pageFrom = (currentPage === 1) ? currentPage : (currentPage * perPage) - perPage;
    const pageTo = (currentPage === pageButtons.length) ? total : currentPage * perPage;
    const disabledPrev = (currentPage === 1);
    const disabledNext = (!pageButtons.length) || (currentPage === pageButtons[pageButtons.length - 1]);

    const changePage = (currentPage) => {
        return () => {
            onUpdate(currentPage);
            setCurrentPage(currentPage);
        };
    };

    const buttons = pageButtons.map((page, index) => {
        return (
            <Button
                active={page === currentPage}
                key={index}
                className={(page === '...' || page === currentPage) ? 'pointer-events-none' : ''}
                onClick={changePage(page)}
                appearance="minimal"
                flexibility="content-size"
                color={page === currentPage ? 'primary' : 'default'}
            >
                {page}
            </Button>
        );
    });

    const isPaginationAvailable = props.data && total >= perPage;

    return (
        <ul className='pagination-holder'>
            <li onClick={(e) => e.stopPropagation()}>
                {`${pageFrom} - ${Math.min(pageTo, total)} of ${total}`}
            </li>
            <li>
                <div
                    className={classnames('pagination', {
                        'pointer-events-none': !isPaginationAvailable,
                    })}
                >
                    <Button
                        color="default"
                        appearance="minimal"
                        icon="icon-arrow-left"
                        onClick={changePage(currentPage - 1, 'prev_1')}
                        disabled={disabledPrev}
                    />
                    <div className="divider"/>
                    {buttons}
                    <div className="divider"/>
                    <Button
                        color="default"
                        appearance="minimal"
                        icon="icon-arrow-right"
                        onClick={changePage(currentPage + 1, 'next_1')}
                        disabled={disabledNext}
                    />
                </div>
            </li>
        </ul>
    );
};

Pagination.propTypes = {
    data: PropTypes.object,
    onUpdate: PropTypes.func
};

export default Pagination;
