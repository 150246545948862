import React from "react";
import PropTypes from "prop-types";
import ModalContent from "../../../shared/modal/parts/content";
import {Formik, Form, Field} from "formik";
import Input from "../../../shared/input";
import DropDown from "../../../shared/dropDown";
import {PARTNERSHIP_TYPES_LIST} from "../../../../constants/dataConstants";
import {PARTNERSHIP_TYPES, TOAST_TYPES} from "../../../../constants/uiConstants";
import RevenueSharingFields from "../../../shared/revenueSharingFields";
import templatesDataService from "../../../../services/templatesDataService";
import Helpers from "../../../../helpers/helperFunctions";
import {registerToastMessage} from "../../../../actions/sync-actions/uiActions";
import { t } from '../../../../helpers/translate';

const TemplateCreationForm = ({ closePopup, popupParams: {templateData}, dispatch }) => {
    const initialValues = {
        name: templateData?.name ?? '',
        type: templateData?.type ?? PARTNERSHIP_TYPES.FIXED,
        share: templateData?.share ?? 0,
        amount: templateData?.amount ?? '',
        cost: templateData?.cost ?? ''
    };

    const validate = (values) => {
        return  Object.keys(values).reduce((acc, curr) => {
            switch (curr) {
                case 'name':
                case 'type':
                    if (!values[curr]) {
                        return { ...acc, [curr]: t('Required')};
                    }
                    return acc;
                case 'amount':
                    if ((values.type === PARTNERSHIP_TYPES.FIXED || values.type === PARTNERSHIP_TYPES.MIXED) && !values[curr]) {
                        acc[curr] = t('Required');
                    }
                    return acc;
                case 'share':
                    if ((values.type === PARTNERSHIP_TYPES.SHARED || values.type === PARTNERSHIP_TYPES.MIXED) && !values[curr] && values[curr] !== 0) {
                        acc[curr] = t('Required');
                    }
                    return acc;
                case 'cost':
                    if ((values.type === PARTNERSHIP_TYPES.SHARED || values.type === PARTNERSHIP_TYPES.MIXED) && !values[curr]) {
                        acc[curr] = t('Required');
                    }
                    return acc;
                default:
                    return acc;
            }
        }, {});
    };

    const handleSubmit = (values) => {
        const data = { ...values };
        if (data.type === PARTNERSHIP_TYPES.FIXED) {
            delete data.share;
            delete data.cost;
        }

        if (data.type === PARTNERSHIP_TYPES.SHARED) {
            delete data.amount;
        }

        (templateData
            ? templatesDataService.updateTemplate(templateData.id, data)
            : templatesDataService.createTemplate(data))
            .then(() => {
                closePopup();
                dispatch(registerToastMessage({message: "Saved"}));
            })
            .catch(res => dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: res?.body?.message || "Oops something went wrong"})));
    };

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={initialValues}
            isInitialValid={false}
            validate={validate}
            render={({ values, setValues, setFieldValue, errors, isValid }) => (
                <Form>
                    <ModalContent
                        primaryButton={{
                            name: t('Save'),
                            disabled: !isValid || (Helpers.deepEqual(values, initialValues) ? !Object.keys(errors).length : !!Object.keys(errors).length),
                            onClick: () => handleSubmit(values),
                            color: 'confirm',
                        }}
                        secondaryButton={{
                            name: t('Close'),
                            onClick: closePopup,
                        }}
                    >
                        <div className="form-grid col-2">
                            <Field
                                name="name"
                                render={({ field: { name } }) => (
                                    <Input
                                        label={t('Template Name')}
                                        required
                                        type='text'
                                        placeholder={t('Enter template name')}
                                        value={values[name]}
                                        onChange={(e) => setFieldValue(name, e.target.value)}
                                        labelAppearance="title"
                                    />
                                )}
                            />
                            <Field
                                name="type"
                                render={({ field: { name } }) => (
                                    <DropDown
                                        label={t('Partnership type')}
                                        labelAppearance="title"
                                        required
                                        multiSelect={false}
                                        withLazyLoad={false}
                                        externalData={PARTNERSHIP_TYPES_LIST}
                                        initialSelections={PARTNERSHIP_TYPES_LIST.find(item => values[name] === item.id)}
                                        onChange={(val) => {
                                            setValues({ ...initialValues, name: values.name, [name]: val.id });
                                        }}
                                    />
                                )}
                            />
                            {(values.type === PARTNERSHIP_TYPES.FIXED || values.type === PARTNERSHIP_TYPES.MIXED) ? (
                                <Field
                                    key={values.type}
                                    name="amount"
                                    render={({ field: { name } }) => (
                                        <Input
                                            label={values.type === PARTNERSHIP_TYPES.FIXED ? t('Amount') : t('Guaranteed amount')}
                                            required
                                            type='text'
                                            placeholder={t('Enter amount')}
                                            value={values[name]}
                                            onChange={(e) => /^\d*$/.test(e.target.value) && setFieldValue(name, e.target.value)}
                                            labelAppearance="title"
                                        />
                                    )}
                                />
                            ) : null}
                            {(values.type === PARTNERSHIP_TYPES.SHARED || values.type === PARTNERSHIP_TYPES.MIXED) ? (
                                <>
                                    <Field
                                        name="share"
                                        render={({ field: { name } }) => (
                                            <RevenueSharingFields
                                                key={values.type}
                                                federationShareAmount={values[name]}
                                                onChange={(val) => setFieldValue(name, val)}
                                            />
                                        )}
                                    />
                                    <Field
                                        name="cost"
                                        render={({ field: { name } }) => (
                                            <Input
                                                key={values.type}
                                                label={t('Cost Coverage')}
                                                required
                                                type='text'
                                                placeholder={t('Enter cost coverage')}
                                                value={values[name]}
                                                onChange={(e) => /^\d*$/.test(e.target.value) && setFieldValue(name, e.target.value)}
                                                labelAppearance="title"
                                            />
                                        )}
                                    />
                                </>
                            ) : null}
                        </div>
                    </ModalContent>
                </Form>
            )}
        />
    )
};

TemplateCreationForm.propTypes = {
    closePopup: PropTypes.func
};

export default TemplateCreationForm;
