import {
    SELECT_TAB_MENU_ITEM,
    RESET_SCROLL_OFFSET,
    OPEN_RIGHT_PANEL,
    CLOSE_RIGHT_PANEL,
    SELECT_RIGHT_TAB_MENU_ITEM,
    SELECT_EVENTS_TYPE,
    EXPORT_DATA,
    OPEN_POPUP,
    TOGGLE_UI_KEY,
    EXPORT_DISABLE,
    API_INTEGRATION_PAGE,
    ENABLE_CREATE_BUTTON,
    MAIN_LOADER,
    SWITCH_INTERCOM,
    SHOW_INTERCOM,
    SET_TOAST,
    USER_EDIT_ID,
    SELECT_OPERATOR_TAB_TYPE,
    SELECT_FEDERATIONS_TAB_TYPE,
    SELECT_UNIQUE_FEDERATION_TAB_TYPE,
    SET_BREADCRUMB_BASE, SET_CURRENT_PRODUCT,
} from "../action-types";

export const registerToastMessage = (payload) => {
    return {
        type: SET_TOAST,
        payload
    }
};

export const mainLoader = (payload) => {
    return {
        type: MAIN_LOADER,
        payload
    };
};

export const switchIntercomState = () => {
    return {
        type: SWITCH_INTERCOM,
    }
};

export const setIntercomState = (payload) => {
    return {
        type: SHOW_INTERCOM,
        payload
    }
};

/**
 * @name openPopup
 * @param {string} payload
 * @param {object} meta
 * @description Open popup by popup type
 * @return {object}
 * */
export const openPopup = (payload, meta = {}) => {
    return {
        type: OPEN_POPUP,
        payload,
        meta
    };
};

/**
 * @name closePopup
 * @param payload
 * @description Close popup
 * @return {object}
 * */
export const closePopup = (payload) => {
    return {
        type: "CLOSE_POPUP",
        payload
    };
};

/**
 * @param key
 * @param payload
 * @returns {{type: string, key: *, payload: *}}
 */
export const selectTabMenuItem = (key, payload) => {
    return {
        type: SELECT_TAB_MENU_ITEM,
        key,
        payload
    };
};

/**
 * @param key
 * @returns {{type: string, key: *, payload: *}}
 */
export const selectRightTabMenuItem = (key, payload) => {
    return {
        type: SELECT_RIGHT_TAB_MENU_ITEM,
        key,
        payload
    };
};

/**
 * @description Reset scroll to top.
 * @param key
 * @param payload
 * @returns {{type: string, key: *, payload: *}}
 */
export const resetScrollOffset = (key, payload) => {
    return {
        type: RESET_SCROLL_OFFSET,
        key,
        payload
    };
};

/**
 * @description Open settings right panel
 * @returns {{type: string}}
 */
export const openRightPanel = (payload) => ({
    type: OPEN_RIGHT_PANEL,
    payload
});

/**
 * @description Select events active type
 * @param payload
 * @returns {{type: string, payload: *}}
 */
export const selectEventsType = (payload) => ({
    type: SELECT_EVENTS_TYPE,
    payload
});

export const selectOperatorTabType = (payload) => ({
    type: SELECT_OPERATOR_TAB_TYPE,
    payload
});

export const selectFederationsTabType = (payload) => ({
    type: SELECT_FEDERATIONS_TAB_TYPE,
    payload
});

export const selectUniqueFederationTabType = (payload) => ({
    type: SELECT_UNIQUE_FEDERATION_TAB_TYPE,
    payload
});

/**
 * @description Close settings right panel
 * @returns {{type: string}}
 */
export const closeRightPanel = () => ({
    type: CLOSE_RIGHT_PANEL
});

/**
 * @description export data by route
 * @param payload
 * @returns {{type: string, payload: *}}
 */
export const exportData = (payload, meta, params) => {
    return {
        type: EXPORT_DATA,
        payload,
        meta,
        params
    };
};

export const disableApiIntegration = (payload) => {
    return {
        type: API_INTEGRATION_PAGE,
        payload
    };
};

/**
 * @description disables export button in top menu
 * @param payload
 * @return {{type: string, payload: *}}
 */
export const disableExportButton = (payload) => ({
    type: EXPORT_DISABLE,
    payload
});

/**
 * @description Toggle ui key.
 * @param key
 * @returns {{type: string, key: *}}
 */
export const toggleUiKey = (key) => {
    return {
        type: TOGGLE_UI_KEY,
        key
    };
};

/**
 * @name resetStyle
 * @param payload
 * @description style change
 * @return {object}
 * */
export const resetStyle = (payload) => {
    return {
        type: "CHANGE_COLOR",
        payload
    };
};

/**
 * @name enableRuleCreationButton
 * @param payload
 * @description toggle Create rule button
 * @return {object}
 * */
export const enableRuleCreationButton = (payload) => {
    return {
        type: ENABLE_CREATE_BUTTON,
        payload
    };
};

export const setUserEditId = (payload) => {
    return {
        type: USER_EDIT_ID,
        payload
    };
};

export const setBreadcrumbBase = (payload) => {
    return {
        type: SET_BREADCRUMB_BASE,
        payload
    };
};

export const setCurrentProduct = (payload) => {
    return {
        type: SET_CURRENT_PRODUCT,
        payload
    }
}
