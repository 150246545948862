import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './hostedField.scss';

const HostedField = props => {
    const {
        id,
        label,
        className,
    } = props;

    return (
        <div
            className={classnames(
                `hosted-field-holder`,
                className,
            )}
        >
            {label && <label htmlFor={id}>{label}</label>}
            <div
                id={id}
                className='hosted-field'
            />
        </div>
    );
};

HostedField.defaultProps = {
    placeholder: '',
};

HostedField.propTypes = {
    type: PropTypes.oneOf(['number', 'cvv', 'expirationDate']).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
};

export default HostedField;
