import React, { useEffect, useState, useRef } from "react";
import { DateRangePicker, Calendar } from 'react-date-range';
import Input from "../input";
import classnames from 'classnames';
import Popover from "../popover";
import PropTypes from "prop-types";
import { format, isSameDay, endOfDay } from "date-fns";
import { t } from '../../../helpers/translate';

import './datePicker.scss';

const constructShownDate = (date, pickerMode = "range", appearance) => {
    const formatter = appearance === 'inline' ? 'd MMM yyyy' : 'd MMM yyyy';
    if (pickerMode === "calendar") return `${format(date, formatter)}`;
    return `${format(date.startDate, formatter)}  -  ${format(date.endDate, formatter)}`;
};

const staticRangeHandler = {
    range: () => ({}),
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
    },
};

const DatePickerInput = props => {
    const {
        appearance,
        className,
        cornerRadius,
        date,
        dateRange,
        description,
        disabled,
        errorText,
        icon,
        itemsDirection,
        label,
        labelAppearance,
        maxDate,
        minDate,
        onChange,
        onClose,
        onOpen,
        placeholder,
        required,
        size,
        staticRanges,
        type,
        writeProtected,
        daysUpToToday,
        daysStartingToday,
    } = props;

    const [dateValue, setDateValue] = useState(type === "range" ? dateRange : date);
    const prevDate = useRef(dateValue);

    useEffect(() => {
        if (type === "range" && dateValue.startDate !== new Date(dateRange.startDate) && dateValue.endDate !== new Date(dateRange.endDate)) {
            setDateValue(dateRange);
        }
    }, [type, dateRange, dateValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const shownDate = constructShownDate(dateValue, type, appearance);

    const handleDateChange = (date) => {
        if (type === "calendar") {
            setDateValue(date);
            onChange && onChange(date);
        } else {
            const { startDate, endDate } = date.selection;
            if ((!isSameDay(dateValue.endDate, endDate) && endDate <= maxDate) || !isSameDay(dateValue.startDate, startDate)) {
                setDateValue({ startDate, endDate });
                onChange && onChange({
                    startDate,
                    endDate: endOfDay(endDate)
                });
            }
        }
    };

    return (
        <Popover
            animation="none"
            onClose={() => {
                if (JSON.stringify(prevDate.current) !== JSON.stringify(dateRange)) {
                    onClose(dateRange);
                    prevDate.current = dateRange;
                }
            }}
            onOpen={onOpen}
            selfSizing
            content={
                <>
                    {type === "calendar" ? (
                        <Calendar
                            date={dateValue}
                            onChange={handleDateChange}
                            minDate={minDate}
                            color='var(--primary)'
                        />
                    ) : (
                        <DateRangePicker
                            ranges={[{
                                startDate: new Date(dateValue.startDate),
                                endDate: new Date(dateValue.endDate),
                                key: 'selection',
                                autoFocus: true,
                                showDateDisplay: true
                            }]}
                            direction="horizontal"
                            months={2}
                            onChange={handleDateChange}
                            rangeColors={['var(--primary)', 'var(--accent)', 'var(--brand)']}
                            showDateDisplay={false}
                            minDate={minDate}
                            maxDate={maxDate}
                            className={classnames({
                                'disable-days-up-to-today': !daysUpToToday,
                                'disable-days-starting-today': !daysStartingToday,
                            })}
                            staticRanges={staticRanges.length ? staticRanges.map(range => ({ ...staticRangeHandler, ...range })) : []}
                        />
                    )}
                </>
            }
        >
            <Input
                appearance={appearance}
                className={className}
                cornerRadius={cornerRadius}
                description={description}
                disabled={disabled}
                errorText={errorText}
                icon={icon}
                itemsDirection={itemsDirection}
                label={label || (type === "calendar" ? t('Date') : t('Dates'))}
                labelAppearance={labelAppearance}
                placeholder={placeholder}
                readOnly
                required={required}
                size={size}
                value={shownDate}
                writeProtected={writeProtected}
            />
        </Popover>
    );
};

DatePickerInput.defaultProps = {
    appearance: Input.defaultProps.appearance,
    className: Input.defaultProps.className,
    cornerRadius: Input.defaultProps.cornerRadius,
    date: new Date(),
    dateRange: { startDate: new Date(), endDate: new Date() },
    description: Input.defaultProps.description,
    disabled: Input.defaultProps.disabled,
    errorText: Input.defaultProps.errorText,
    icon: 'icon-calendar',
    itemsDirection: 'end',
    label: Input.defaultProps.label,
    labelAppearance: Input.defaultProps.labelAppearance,
    maxDate: null,
    minDate: null,
    onChange: null,
    onClose: () => null,
    onOpen: () => null,
    placeholder: Input.defaultProps.placeholder,
    required: Input.defaultProps.required,
    size: Input.defaultProps.size,
    staticRanges: [],
    type: 'range',
    writeProtected: false,
    daysUpToToday: true,
    daysStartingToday: true,
};

DatePickerInput.propTypes = {
    appearance: Input.propTypes.appearance,
    className: Input.propTypes.className,
    cornerRadius: Input.propTypes.cornerRadius,
    date: PropTypes.any,
    dateRange: PropTypes.any,
    description: Input.propTypes.description,
    disabled: Input.propTypes.disabled,
    errorText: Input.propTypes.errorText,
    icon: Input.propTypes.icon,
    itemsDirection: Input.propTypes.itemsDirection,
    label: Input.propTypes.label,
    labelAppearance: Input.propTypes.labelAppearance,
    maxDate: PropTypes.any,
    minDate: PropTypes.any,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    placeholder: Input.propTypes.placeholder,
    required: Input.propTypes.required,
    size: Input.propTypes.size,
    staticRanges: PropTypes.any,
    type: PropTypes.oneOf(['range', 'calendar']),
    writeProtected: PropTypes.bool,
    daysUpToToday: PropTypes.bool,
    daysStartingToday: PropTypes.bool,
};

export default DatePickerInput;
