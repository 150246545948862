import React from 'react';
import {Provider} from "react-redux";
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';
import Layout from "./containers/layout";
import AuthorizationHandler from "./middlewares/authorization";
import FiltersMiddleware from "./middlewares/filtersMiddleware";
import ServiceController from "./middlewares/serviceController";
import EventsMiddleware from "./middlewares/eventsMiddleware";
import UIMiddleware from "./middlewares/uiMiddleware";
import ErrorBoundary from "./containers/errorBoundary";
import {BrowserRouter} from "react-router-dom";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [AuthorizationHandler, ServiceController, FiltersMiddleware, UIMiddleware, EventsMiddleware, thunk];

const store = process.env.NODE_ENV === 'development' // eslint-disable-line no-undef
    ? createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))
    : createStore(rootReducer, applyMiddleware(...middlewares));

const App = () => {
    switch (true) {
        case process.env.NODE_ENV === 'development':
            return (
                <BrowserRouter>
                    <Layout/>
                </BrowserRouter>
            );
        default:
            return (
                <ErrorBoundary>
                    <BrowserRouter>
                        <Layout/>
                    </BrowserRouter>
                </ErrorBoundary>
            );
    }
};


export default () => (
    <Provider store={store}>
        <App/>
    </Provider>
);
