import leagueDataService from "../../services/leagueDataService";
import {dataLoadStart, dataLoadDone, dataLoadFail, dataLoadReset} from "./index";
import {FILTER_LEAGUES, LEAGUES} from "../../constants/reducerKeys";
import {mainLoader, registerToastMessage} from "../sync-actions/uiActions";
import {TOAST_TYPES} from "../../constants/uiConstants";

export function getLeaguesByOffset (params, reset, allowLoader = true) {
    return function (dispatch) {
        allowLoader && dispatch(dataLoadStart(LEAGUES));
        leagueDataService.getLeagueByOffset(params, reset)
            .then(res => {
                allowLoader && dispatch(dataLoadDone(LEAGUES, res));
            })
            .catch(err => {
                allowLoader && dispatch(dataLoadFail(LEAGUES, err));
            });
    };
}

export function createLeague (params) {
    return function (dispatch) {
        dispatch(dataLoadStart(LEAGUES));
        leagueDataService.createLeague(params)
            .then(res => {
                dispatch(dataLoadDone(LEAGUES, true));
                dispatch(registerToastMessage({message: 'League created'}));
            })
            .catch(err => {
                dispatch(dataLoadFail(LEAGUES, err));
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"}));
            });
    };
}

export function editLeague (leagueId, payload, showMessage = false, message) {
    return function (dispatch) {
        dispatch(mainLoader(true));
        dispatch(dataLoadStart(LEAGUES));
        leagueDataService.updateLeague(leagueId, payload)
            .then(res => {
                dispatch(mainLoader(false));
                dispatch(dataLoadDone(LEAGUES, true));
                showMessage && dispatch(registerToastMessage({message: message || "League updated"}));
            })
            .catch(err => {
                dispatch(mainLoader(false));
                dispatch(dataLoadFail(LEAGUES, err));
                showMessage && dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: message || "Oops something went wrong"}));
            });
    };
}

export function stopLeagueService () {
    return (dispatch) => {
        leagueDataService.stop();
        dispatch(dataLoadReset(LEAGUES));
        dispatch(dataLoadReset(FILTER_LEAGUES));
    };
}
