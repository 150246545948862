import { apiUrl } from '../configs/mainConfig';
import DataFetcher from "../services/dataFetcherService";
import {
    Logout,
} from "../actions/async-actions/authorization";
import { APP_INIT, LOGOUT } from "../actions/action-types";
import { openPopup } from "../actions/sync-actions/uiActions";
import Helpers from "../helpers/helperFunctions";
import { getUserProfile } from "../actions/async-actions/userAction";
import { popupConfig } from '../configs/popupConfig';
import { dataLoadReset } from "../actions/async-actions";
import routerConfig from '../configs/routerConfig';

const AuthorizationHandler = store => next => action => {
    switch (action.type) {
        case APP_INIT: {
            let params = (new URL(document.location)).searchParams;
            // If user comes from BETCONSTRUCT Product Try to login via BET SSO
            if (params.get("btob")) {
                const returnURL = `${apiUrl}/api/feed-sso/login?redirect_url=${window.location.protocol}//${window.location.host}`
                window.location.replace(`https://api.feedconstruct.com/auth/betconstruct?return=${returnURL}`)
            }

            if (window.location.pathname === '/logout') {
                const sid = (params && params.get("sid")) || Helpers.getQueryStringValue('sid');
                if (sid && process.env.NODE_ENV === 'development') {
                    return;
                }
            }

            next(getUserProfile());

            // open settings modal if route is corresponding
            const locationHash = window.location.hash;
            if (locationHash && Object.keys(routerConfig["/settings"].routes).some(val => val === `/${locationHash.slice(1)}`)) {
                next(openPopup("settings", {
                    closeOnClickOutside: false
                }));
            }

            DataFetcher.data.subscribe((res) => {
                if (res && res.status === 401) {
                    //Logout user if res status is unauthorized.
                    next(openPopup(
                        "message",
                        {
                            ...popupConfig.sessionTimeOut,
                            handleCallBack: () => {
                                Helpers.redirectToLogout();
                                next(dataLoadReset('userState'));
                                next(Logout());
                            }
                        }
                    ));

                }
            });
            break;
        }
        case LOGOUT: {
            sessionStorage.removeItem('organizationId');
            window.Intercom && window.Intercom("hide");
            Helpers.setLastActiveProject();
            Helpers.redirectToLogout();
            break;
        }
        default:
            break;
    }
    next(action);
};

export default AuthorizationHandler;
