import React, {useState} from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";
import Helpers from "../../../../helpers/helperFunctions";
import {format} from "date-fns";
import Button from "../../../shared/button";
import EventCard from "../../../shared/eventCard";
import { registerToastMessage } from '../../../../actions/sync-actions/uiActions';
import { t } from '../../../../helpers/translate';

const CreatedEvents = ({ createdEvents, setSelectedTab, removeEvent, removeAllEvents, setEventEditIndex, dispatch }) => {
    const [openMenuId, setOpenMenuId] = useState();

    return (
        <div className="form-grid col-2">
            {createdEvents?.map((event, index) => {
                const {start_date, teams, league_name, region_name, timeZone} = event;
                const {team_1_name, team_2_name} = teams[0];
                return (
                    <EventCard
                        key={index}
                        team1={team_1_name}
                        team2={team_2_name}
                        time={format(Helpers.getTimeZoneTime(start_date, timeZone), Helpers.getTimeFormat())}
                        date={format(Helpers.getTimeZoneTime(start_date, timeZone), "d MMMM yyyy")}
                        region={region_name}
                        openedMenu={index === openMenuId}
                        onMenuClose={() => setOpenMenuId(null)}
                        onMenuOpen={() => setOpenMenuId(index)}
                        league={league_name}
                        menuData={[
                            {
                                name: t('Edit'),
                                icon: 'icon-edit',
                                onClick: () => {
                                    setEventEditIndex(index);
                                    setOpenMenuId(null);
                                    setSelectedTab('newEvent');
                                }
                            },
                            {
                                type: 'divider',
                            },
                            {
                                name: t('Delete'),
                                color: 'danger',
                                onClick: () => {
                                    const removeAllMode = createdEvents.length > 1;
                                    removeEvent(index);
                                    setOpenMenuId(null);
                                    dispatch(registerToastMessage({
                                        title: removeAllMode ? "Event successfully removed" : '',
                                        message: removeAllMode ? "Do you want to remove all events?" : "Event successfully removed",
                                        mode: removeAllMode ? 'persistent' : 'default',
                                        applyText: removeAllMode ? "Apply to all" : '',
                                        onApply: removeAllEvents
                                    }));
                                },
                                icon: 'icon-delete',
                            },
                        ]}
                    />
                );
            })}
            <div className={classnames('add-event-button-holder', {
                'span-2': !(createdEvents.length % 2)
            })}>
                <Button
                    icon="icon-plus"
                    size="big"
                    flexibility="default"
                    color="confirm"
                    onClick={() => {
                        setSelectedTab('newEvent');
                    }}
                >
                    Add New Event
                </Button>
            </div>
        </div>
    )
};

CreatedEvents.propTypes = {
    createdEvents: PropTypes.arrayOf(PropTypes.object),
    setSelectedTab: PropTypes.func,
    setEventEditIndex: PropTypes.func,
    removeEvent: PropTypes.func
};

export default CreatedEvents;
