import DataFetcher from "./dataFetcherService";
import { BehaviorSubject, from, Subject } from "rxjs";
import { OPERATORS } from "../constants/api-resources";
import {bookedMatchesDefaultParams, operatorsDefaultParams} from "../constants/queryParams";
import { switchMap } from 'rxjs/operators';

class OperatorsDataService {
    constructor () {
        this._operatorsList = new BehaviorSubject({});
        this._bookedMatches = new BehaviorSubject({});
        this._selectedOperator = new BehaviorSubject({});

        this.setupBookedMatchesSource();
    }

    getOperators (params) {
        this._operatorsList.next({...this._operatorsList.getValue(), isLoading: true});
        return DataFetcher.getJson(OPERATORS.OPERATORS_LIST.URL, {queryParams: {...operatorsDefaultParams(), ...params}})
            .then(res => {
                this._operatorsList.next({
                    data: res.data.map(operator => ({
                        ...operator,
                        business_model: operator.current_business_model.business_model,
                    })),
                    total_count: res.total_count,
                    isLoading: false
                });
            })
            .catch(err => Promise.reject(err));
    }

    updateAgreementType (operatorId, params) {
        return DataFetcher.putJson(`${OPERATORS.OPERATORS_LIST.URL}/${operatorId}`, {params})
            .then(res => {
                const operatorsData = this._operatorsList.getValue();
                const index = operatorsData.data.findIndex(val => val.id === operatorId);
                this._operatorsList.next({
                    ...operatorsData,
                    data: [
                        ...operatorsData.data.slice(0, index),
                        {...operatorsData.data[index], ...res.data},
                        ...operatorsData.data.slice(index + 1),
                    ]
                })
            })
            .catch(err => Promise.reject(err));
    }

    setupBookedMatchesSource () {
        this.matchesSource = new Subject();
        this.matchesLastSource = this.matchesSource.pipe(switchMap(
            (val) => {
                const {
                    data: {queryParams, operatorId },
                    abort: {signal, controller},
                    promiseFns: {resolve: resolveFn, reject: rejectFn}
                } = val || {};

                //Abort previous requests.
                this.lastSignal && this.lastController.abort();
                this.lastSignal = signal;
                this.lastController = controller;
                return from(DataFetcher.getJson(`${OPERATORS.OPERATORS_LIST.URL}/${operatorId}/${OPERATORS.BOOKED_MATCHES.URL}`, {queryParams: {...bookedMatchesDefaultParams, ...queryParams}, signal: this.lastSignal })
                    .then(res => ({ res, resolveFn, queryParams }))
                    .catch(err => {
                        rejectFn(err);
                        return Promise.reject(err);
                    }));
            }
        ));

        //Subscription function
        this.matchesLastSource.subscribe(data => {
            const {res, resolveFn} = data;
            this._bookedMatches.next({
                data: res.data,
                total_count: res.total_count,
                isLoading: false
            });
            resolveFn && resolveFn(res);
        });
    }

    getBookedMatches (operatorId = window.location.pathname.match(/\d+$/)[0], params) {
        this._bookedMatches.next({...this._bookedMatches.getValue(), isLoading: true});
        return new Promise((resolve, reject) => {
            //Abort all previous requests if these are loading.
            const controller = new AbortController();
            const signal = controller.signal;
            //Emit new request with params.
            this.matchesLastSource.next({
                data: {queryParams: params, operatorId},
                abort: {controller, signal},
                promiseFns: {resolve, reject}
            });
        });
    }

    getOperatorsByParams = (params) => {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(OPERATORS.OPERATORS_LIST.URL, {queryParams: params})
                .then(res => { resolve(res); })
                .catch(err => { reject(err); });
        });
    };

    getOperatorById (id) {
        return DataFetcher.getJson(`${OPERATORS.OPERATORS_LIST.URL}/${id}`)
            .then(res => {
                this._selectedOperator.next({
                    ...this.selectedOperator.getValue(),
                    ...res.data,
                });
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    }

    getOperatorAttachments (id) {
        return DataFetcher.getJson(`${OPERATORS.OPERATORS_LIST.URL}/${id}/${OPERATORS.ATTACHMENTS.URL}`)
            .then(res => {
                this._selectedOperator.next({
                    ...this.selectedOperator.getValue(),
                    files: {
                        project_delivery_document: res.data[0]
                    },
                });
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    }

    updateOperatorById (id, params) {
        return DataFetcher.putJson(`${OPERATORS.OPERATORS_LIST.URL}/${id}`, { params: { ...params } })
            .then((res) => {
                this._selectedOperator.next({
                    ...this.selectedOperator.getValue(),
                    ...res.data,
                });
            })
            .catch(err => Promise.reject(err));
    }

    createOperatorAttachment = (id, params) => {
        return DataFetcher.postJson(`${OPERATORS.OPERATORS_LIST.URL}/${id}/${OPERATORS.ATTACHMENTS.URL}`, { params, bodyNotStringify: true })
            .then(res => {
                if (res.data) {
                    this._selectedOperator.next({
                        ...this.selectedOperator.getValue(),
                        files: {
                            project_delivery_document: res.data,
                        },
                    });
                    return Promise.resolve(res.data[0]);
                }
            })
            .catch(err => Promise.reject(err));
    }

    deleteAttachment = (id) => {
        return DataFetcher.deleteJson(`${OPERATORS.OPERATORS_LIST.URL}/${id}/${OPERATORS.ATTACHMENTS.URL}`)
            .then(() => {
                this._selectedOperator.next({
                    ...this.selectedOperator.getValue(),
                    files: {
                        project_delivery_document: null,
                    },
                });
            })
            .catch(err => Promise.reject(err));
    }

    clearSelectedOperatorData () {
        this._selectedOperator.next({});
    }

    get operatorsData () {
        return this._operatorsList;
    }

    get bookedMatches () {
        return this._bookedMatches;
    }

    get selectedOperator () {
        return this._selectedOperator;
    }
}

export default new OperatorsDataService();
