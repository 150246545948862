import React, { useMemo } from 'react';
import Checkbox from "../checkbox";
import classnames from 'classnames';
import { t } from "../../../helpers/translate";

import { DAYS_OF_WEEK } from "../../../constants/dataConstants";

import './weekDayPicker.scss';

const WeekDayPicker = props => {
    const {
        label,
        selectedIds,
        onChange,
        disabled,
        className,
        readOnly,
    } = props;

    const days = useMemo(() => ([ ...DAYS_OF_WEEK, { name: "All", id: "all"} ]), []);

    const handleDayChange = (id) => (e) => {
        if (id === 'all') {
            onChange(e.target.checked ? DAYS_OF_WEEK.map(day => day.id) : []);
            return;
        }
        onChange(!e.target.checked ? selectedIds.filter(day => day !== id) : [ ...selectedIds, id]);
    };

    return (
        <div
            className={classnames('weekday-picker-holder', className, {
                'pointer-events-none': readOnly,
            })}
        >
            {label && <div className="input-title ellipsis-text">{label}</div>}
            <div className={classnames("weekday-picker", {disabled})}>
                {days.map(({id, name}) => (
                    <React.Fragment key={id}>
                        <label>
                            <Checkbox
                                readOnly={readOnly}
                                onChange={handleDayChange(id)}
                                checked={selectedIds.includes(id) || (id === "all" && selectedIds.length === 7) }
                            />
                            <span>{t(name)}</span>
                        </label>
                        {id === 1 && (
                            <div className="divider" />
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
};

WeekDayPicker.defaultProps = {
    selectedIds: []
};

export default WeekDayPicker;
