import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { t } from '../../../helpers/translate';

import TimePicker from "../../shared/timePicker";

const formatTime = (time, selector = ":", separator = " : ") => {
    return time.split(selector).join(separator);
};

const TimeRangePicker = props => {
    const {
        appearance,
        className,
        cornerRadius,
        disabled,
        icon,
        itemsDirection,
        labelAppearance,
        onChange,
        range: {start, end},
        required,
        size,
        ...restProps
    } = props;

    const [startos, setStartos] = useState(formatTime(start));
    const [endos, setEndos] = useState(formatTime(end));

    useEffect(() => {
        onChange({start: formatTime(startos, " : ", ":"), end: formatTime(endos, " : ", ":")});
    }, [startos, endos]); // eslint-disable-line react-hooks/exhaustive-deps

    const sharedProps = {
        appearance,
        cornerRadius,
        disabled,
        icon,
        itemsDirection,
        labelAppearance,
        required,
        size,
        ...restProps
    };

    return (
        <div className={classnames('form-grid col-2', className)}>
            <TimePicker
                value={startos}
                onChange={val => {
                    setStartos(val);
                }}
                label={t('Choose Start Time')}
                {...sharedProps}
            />
            <TimePicker
                value={endos}
                onChange={val => {
                    setEndos(val);
                }}
                label={t('Choose End Time')}
                {...sharedProps}
            />
        </div>

    );
};

TimeRangePicker.defaultProps = {
    appearance: TimePicker.defaultProps.appearance,
    className: TimePicker.defaultProps.className,
    cornerRadius: TimePicker.defaultProps.cornerRadius,
    disabled: TimePicker.defaultProps.disabled,
    icon: TimePicker.defaultProps.icon,
    itemsDirection: TimePicker.defaultProps.itemsDirection,
    labelAppearance: TimePicker.defaultProps.labelAppearance,
    onChange: null,
    range: null,
    required: TimePicker.defaultProps.required,
    size: TimePicker.defaultProps.size,
};

TimeRangePicker.propTypes = {
    appearance: TimePicker.propTypes.appearance,
    className: TimePicker.propTypes.className,
    cornerRadius: TimePicker.propTypes.cornerRadius,
    disabled: TimePicker.propTypes.disabled,
    icon: TimePicker.propTypes.icon,
    itemsDirection: TimePicker.propTypes.itemsDirection,
    labelAppearance: TimePicker.propTypes.labelAppearance,
    onChange: PropTypes.func,
    range: PropTypes.object,
    required: TimePicker.propTypes.required,
    size: TimePicker.propTypes.size,
};

export default TimeRangePicker;
