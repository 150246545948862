import UserReducer from "./userReducer";
import { combineReducers } from "redux";
import FiltersReducer from "./filtersReducer";
import FiltersParamsReducer from "./filtersParamsReducer";
import EventDataReducer from "./eventDataReducer";
import PopupReducer from "./popupReducer";
import ToastReducer from "./toastReducer";
import {RESET_INITIAL_STORE} from "../actions/action-types";
import uiReducer from "./uiReducer";
import AutoBookingReducer from "./autoBookingReducer";
import FiltersDataReducer from "./filtersDataReducer";
import PresetReducer from './presetReducer';
import {DataStatesReducer} from "./dataStatesReducer";

/**
 * @description Function will create application store.
 * @type {Reducer<any>}
 */
const appReducer = combineReducers({
    user: UserReducer,
    filters: FiltersReducer,
    filtersParams: FiltersParamsReducer,
    filtersData: FiltersDataReducer,
    presetData: PresetReducer,
    eventData: EventDataReducer,
    autoBookingRules: AutoBookingReducer,
    popup: PopupReducer,
    toast: ToastReducer,
    ui: uiReducer,
    dataStates: DataStatesReducer
});

/**
 * @param state
 * @param action
 * @returns {*}
 */
const rootReducer = (state, action) => {
    if (action.type === RESET_INITIAL_STORE) {
        state = {user: { logoutInProgress: true }};
    }
    return appReducer(state, action);
};

export default rootReducer;
