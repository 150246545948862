import {dataLoadStart, dataLoadDone, dataLoadFail, dataLoadReset} from "./index";
import {BLOCK_PRODUCT, BOOKING, UNBLOCK_PRODUCT} from "../../constants/reducerKeys";
import bookingDataService from "../../services/bookingDataService";
import {registerToastMessage} from "../sync-actions/uiActions";
import {TOAST_TYPES} from "../../constants/uiConstants";
import { t } from "../../helpers/translate";

/**
 * @description Get booking events.
 * @param params
 * @returns {function(*=): Promise<any>}
 */
export function getBookingEvents (params, productId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(dataLoadStart(BOOKING.PRICES));
            bookingDataService.getBookingEvents(params, productId)
                .then(data => {
                    dispatch(dataLoadDone(BOOKING.PRICES, data));
                    resolve(data);
                })
                .catch(error => {
                    dispatch(dataLoadFail(BOOKING.PRICES, error));
                    reject(error);
                });
        });
}

export function setBookingEvents(params, key, callback) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(dataLoadStart(BOOKING.BOOK_EVENTS));
            bookingDataService.setBookingEvents(params, key)
                .then(data => {
                    callback && callback();
                    dispatch(dataLoadDone(BOOKING.BOOK_EVENTS, data));
                    resolve(data);
                    if (data.data.failed.length) {
                        dispatch(registerToastMessage({
                            timeout: 8000,
                            type: TOAST_TYPES.ERROR,
                            message: `The event (${data.data.failed}) is finished, so you can not book it.`
                        }));
                    } else {
                        dispatch(registerToastMessage({ message: "Booked" }));
                    }
                })
                .catch(error => {
                    dispatch(dataLoadFail(BOOKING.BOOK_EVENTS, error));
                    const message = (error.statusCode === 422 && error.body?.message) || t("Oops something went wrong");
                    dispatch(registerToastMessage({ type: TOAST_TYPES.ERROR, message }));
                    reject(error);
                });
        });
}

/**
 * @description Block / Unblock booking events / products.
 * @param params
 * @param callback
 * @returns {function(*=): Promise<any>}
 */
export function blockHandler (params, callback) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const {block, productParams, blockParams, unBlockParams} = params;
            let status = block ? BLOCK_PRODUCT : UNBLOCK_PRODUCT;
            dispatch(dataLoadStart(status));
            bookingDataService.blockHandler(productParams, status)
                .then(data => {
                    callback && callback();
                    dispatch(dataLoadDone(status, data));
                    if ((block ? blockParams : unBlockParams).length > 1) {
                        dispatch(registerToastMessage({
                            title: `Successfully ${block ? 'unbooked' : 'rebooked'}`,
                            message: `Do you want to ${block ? 'unbook' : 'rebook'} all events in your video streaming tab?`,
                            applyText: block ? "Unbook all" : "Rebook all",
                            mode: 'persistent',
                            onApply: () => {
                                bookingDataService.blockHandler(block ? blockParams : unBlockParams, status)
                                    .then(() => {
                                        dispatch(registerToastMessage({
                                            title: `Successfully ${block ? 'unbooked' : 'rebooked'} all events`
                                        }));
                                    });
                            }
                        }, {}));
                    } else {
                        dispatch(registerToastMessage({
                            title: `Successfully ${block ? 'unbooked' : 'rebooked'}`
                        }));
                    }
                    resolve(data);
                })
                .catch(error => {
                    dispatch(dataLoadFail(status, error));
                    dispatch(registerToastMessage({
                        type: TOAST_TYPES.ERROR,
                        message: "Oops something went wrong"
                    }));
                    reject(error);
                });
        });
}

export function getAutoBookingRules (params) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(dataLoadStart(BOOKING.AUTOBOOKING_RULES));
            bookingDataService.getAutoBookingRules(params)
                .then(data => {
                    dispatch(dataLoadDone(BOOKING.AUTOBOOKING_RULES, data));
                    resolve(data);
                })
                .catch(error => {
                    dispatch(dataLoadFail(BOOKING.AUTOBOOKING_RULES, error));
                    reject(error);
                });
        });
}

export function stopAutobooking () {
    return (dispatch) => {
        bookingDataService.stopAutoBookingRules();
        dispatch(dataLoadReset(BOOKING.AUTOBOOKING_RULES));
    };
}
