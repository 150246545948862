import {SERVICE_DATA_TYPES} from "../constants/serviceConstants";
import usersDataService from "../services/usersDataService";
import leagueDataService from "../services/leagueDataService";
import paymentsDataService from "../services/paymentsDataService";
import eventsDataService from "../services/eventsDataService";
import bookingDataService from "../services/bookingDataService";
import reportingDataService from "../services/reportingDataService";

export const getServiceConnectorByName = (name) => {
    switch (name) {
        case SERVICE_DATA_TYPES.USERS_DATA:
        case SERVICE_DATA_TYPES.USER_PROFILE:
        case SERVICE_DATA_TYPES.STREAMERS_LIST:
            return usersDataService[name];
        case SERVICE_DATA_TYPES.SPORT_LIST:
        case SERVICE_DATA_TYPES.SPORT_DATA_LIST:
        case SERVICE_DATA_TYPES.LEAGUES_LIST:
        case SERVICE_DATA_TYPES.FILTER_LEAGUES_LIST:
        case SERVICE_DATA_TYPES.REGIONS_LIST:
        case SERVICE_DATA_TYPES.COUNTRIES_LIST:
        case SERVICE_DATA_TYPES.COUNTRIES_GROUPED_LIST:
        case SERVICE_DATA_TYPES.TEAMS_LIST:
            return leagueDataService[name];
        case SERVICE_DATA_TYPES.PAYMENTS_DATA:
        case SERVICE_DATA_TYPES.PAYMENT_HISTORY:
        case SERVICE_DATA_TYPES.BANK_INFO:
        case SERVICE_DATA_TYPES.WITHDRAW_HISTORY:
        case SERVICE_DATA_TYPES.BALANCE_HISTORY:
        case SERVICE_DATA_TYPES.CURRENT_INVOICE_DATA:
        case SERVICE_DATA_TYPES.CURRENT_INVOICE_EVENTS_DATA:
        case SERVICE_DATA_TYPES.INVOICE_HISTORY:
        case SERVICE_DATA_TYPES.BALANCE_TOTALS:
        case SERVICE_DATA_TYPES.REVENUE_SHARING_MODEL:
            return paymentsDataService[name];
        case SERVICE_DATA_TYPES.EVENTS_DATA:
        case SERVICE_DATA_TYPES.SELECTED_EVENTS_DATA:
            return eventsDataService[name];
        case SERVICE_DATA_TYPES.AUTO_BOOKING_RULES_DATA:
            return bookingDataService[name];
        case SERVICE_DATA_TYPES.REPORTING_DEVICES_LIST:
        case SERVICE_DATA_TYPES.REPORTING_BROWSERS_LIST:
        case SERVICE_DATA_TYPES.REPORTING_LEAGUES_LIST:
        case SERVICE_DATA_TYPES.REPORTING_COUNTRIES_LIST:
        case SERVICE_DATA_TYPES.REPORTING_SPORT_LIST:
        case SERVICE_DATA_TYPES.REPORTING_EMAILS_LIST:
            return reportingDataService[name];
        default:
            return null;
    }
};
