import React from 'react';

import noDataImage from '../../../assets/media/data.svg';
import Button from "../button";
import Icon from "../icon";
import { t } from '../../../helpers/translate';

import './noData.scss';

const NoData = (props) => {
    const {
        buttonText,
        buttonOnClick,
        showButton,
        text,
        icon,
        image,
        size,
        transparent,
    } = props;

    return (
        <div className={`no-data-holder animation-fadeInUp ${transparent ? 'transparent' : ''} s-${size}`}>
            {icon ? (
                <Icon type={icon} size={120} />
            ) : (
                <img src={image} alt="" />
            )}
            <div className="no-data-text">{text}</div>
            {!!showButton && (
                <Button
                    onClick={buttonOnClick}
                    size="big"
                    flexibility="content-size"
                >
                    {buttonText}
                </Button>
            )}
        </div>
    )
};

NoData.defaultProps = {
    image: noDataImage,
    text: t('No data found'),
    size: 'default',
};

export default NoData;
