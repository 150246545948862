import { BehaviorSubject } from "rxjs";
import DataFetcher from "./dataFetcherService";
import { FEDERATIONS } from "../constants/api-resources";
import leagueDataService from "./leagueDataService";
import Helpers from "../helpers/helperFunctions";

class FederationsDataService {
    constructor() {
        this._federationsList = new BehaviorSubject({});
        this._federationUsers = new BehaviorSubject({});
        this._federationsGroup = new BehaviorSubject([]);
    };

    getFederationsList(queryParams) {
        this._federationsList.next({ ...this._federationsList.getValue(), isLoading: true });
        return DataFetcher.getJson(FEDERATIONS.FEDERATIONS_LIST.URL, { queryParams: { active: 1, ...queryParams } })
            .then((res) => {
                this._federationsList.next({
                    ...this._federationsList.getValue(),
                    data: res.data,
                    total_count: res.total_count,
                    isLoading: false
                });
            })
            .catch(err => Promise.reject(err));
    };

    getFederationsByIds(ids, queryParams = {}) {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(FEDERATIONS.FEDERATIONS_LIST.URL, { queryParams: { ids, ...queryParams } })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getFederationsGroupByIds(ids, queryParams = {}) {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(`${FEDERATIONS.FEDERATIONS_GROUP.URL}`, { queryParams: { ids, ...queryParams } })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getFederationsGroup(params) {
        this._federationsGroup.next({ ...this._federationsGroup.getValue(), isLoading: true, });
        return DataFetcher.getJson(`${FEDERATIONS.FEDERATIONS_GROUP.URL}`, {
            queryParams: {
                ...params
            }
        })
            .then((res) => {
                this._federationsGroup.next({
                    ...this._federationsGroup.getValue(),
                    data: res.data,
                    total_count: res.total_count,
                    isLoading: false
                });
                return Promise.resolve(res)
            })
            .catch(err => Promise.reject(err));
    };

    deleteFederationsGroup(id) {
        return DataFetcher.deleteJson(`${FEDERATIONS.FEDERATIONS_GROUP.URL}/${id}`)
            .then(res => {
                const federationsGroup = this._federationsGroup.getValue();
                this._federationsGroup.next( { ...federationsGroup, data: federationsGroup.data.filter(item => item.id !== id)})
                return Promise.resolve(res);
            })
            .catch(err => {
                return Promise.reject(err);
            });
    };

    createFederationsGroup = (params) => {
        return DataFetcher.postJson(`${FEDERATIONS.FEDERATIONS_GROUP.URL}`, { params: { ...params } })
            .then(res => {
                const prevData = this._federationsGroup.getValue();
                this._federationsGroup.next({
                    ...prevData,
                    data: [...prevData.data, ...res.data],
                    total_count: prevData.total_count + 1,
                });
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    };

    updateFederationsGroupByIds(id, params) {
        return DataFetcher.putJson(`${FEDERATIONS.FEDERATIONS_GROUP.URL}/${id}`, { params: { ...params } })
            .then(res => {
                const federationGroupData = this._federationsGroup.getValue();
                const index = federationGroupData.data?.findIndex(val => val.id === id);
                this._federationsGroup.next({
                    ...federationGroupData,
                    data: [
                        ...(federationGroupData.data || []).slice(0, index),
                        { ...(federationGroupData.data || [])?.[index], ...res.data },
                        ...(federationGroupData.data || []).slice(index + 1),
                    ]
                });
                return Promise.resolve(res.data);
            })
            .catch(err => {
                return Promise.reject(err);
            });
    };

    getFederationUsers(federationId, queryParams) {
        this._federationUsers.next({ ...this._federationUsers.getValue(), isLoading: true });
        return DataFetcher.getJson(`${FEDERATIONS.FEDERATIONS_LIST.URL}/${federationId}/${FEDERATIONS.USERS.URL}`, { queryParams })
            .then((res) => {
                this._federationUsers.next({
                    ...this._federationUsers.getValue(),
                    data: res.data,
                    total_count: res.total_count,
                    isLoading: false
                });
            })
            .catch(err => Promise.reject(err));
    };

    updateFederation(id, params) {
        return DataFetcher.putJson(`${FEDERATIONS.FEDERATIONS_LIST.URL}/${id}`, { params })
            .then(res => {
                const federationsData = this._federationsList.getValue();
                const index = federationsData.data?.findIndex(val => val.id === id);
                this._federationsList.next({
                    ...federationsData,
                    data: [
                        ...(federationsData.data || []).slice(0, index),
                        { ...(federationsData.data || [])?.[index], ...res.data },
                        ...(federationsData.data || []).slice(index + 1),
                    ]
                });
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    };

    attachLeaguesToFederation(id, params, attachedLeagues = [], name) {
        return DataFetcher.putJson(`${FEDERATIONS.FEDERATIONS_LIST.URL}/${id}/${FEDERATIONS.FEDERATION_LEAGUES_LIST.URL}`, { params })
            .then(res => {
                if (res?.data) {
                    const leaguesList = leagueDataService.leaguesList.getValue();
                    if (name) {
                        leagueDataService.leaguesList.next({
                            ...leaguesList, data: leaguesList.data.map(item => {
                                    if (item.id === attachedLeagues) {
                                        return {
                                            ...item,
                                            organization_id: id,
                                            organization_name: name
                                        };
                                    } else return item;
                                }
                            )
                        });
                    } else {
                        leagueDataService.leaguesList.next({
                            ...leaguesList,
                            data: [...(leaguesList?.data || []), ...attachedLeagues],
                            total_count: leaguesList.total_count + attachedLeagues.length
                        });

                        const federationsData = this._federationsList.getValue();
                        this._federationsList.next({
                            ...federationsData,
                            data: Helpers.updateDataInArrayOfObjects(federationsData.data, {
                                id,
                                template_id: null
                            }, "id")
                        });
                    }
                    return Promise.resolve(attachedLeagues);
                }
            })
            .catch(err => Promise.reject(err));
    };

    getFederationsByParams(params) {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(FEDERATIONS.FEDERATIONS_LIST.URL, { queryParams: params })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    createFederationAttachment = (id, params) => {
        return DataFetcher.postJson(FEDERATIONS.ATTACHMENT_CREATE.URL, { params, bodyNotStringify: true })
            .then(res => {
                if (res.data) {
                    return Promise.resolve(res.data[0]);
                }
            })
            .catch(err => Promise.reject(err));
    };

    deleteFederationAttachment = (id) => {
        return DataFetcher.deleteJson(`${FEDERATIONS.ATTACHMENT_CREATE.URL}/${id}`, {});
    };

    createFederation = (params) => {
        return DataFetcher.postJson(FEDERATIONS.FEDERATIONS_LIST.URL, { params })
            .then(res => {
                const prevData = this._federationsList.getValue();
                this._federationsList.next({
                    ...prevData,
                    data: [res.data, ...prevData.data],
                    total_count: prevData.total_count + 1,
                });
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    };

    getFederationAttachmentsById = (id) => {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(`${FEDERATIONS.FEDERATIONS_LIST.URL}/${id}/${FEDERATIONS.ATTACHMENTS.URL}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    checkFederationBusinessModel = (id) => {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(`${FEDERATIONS.FEDERATIONS_LIST.URL}/${id}/${FEDERATIONS.HAS_BUSINESS_MODEL.URL}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => reject(err));
        });
    };

    attachBusinessModelToFederation = (id, params) => {
        return DataFetcher.putJson(`${FEDERATIONS.FEDERATIONS_LIST.URL}/${id}/${FEDERATIONS.ATTACH.URL}`, { params })
            .then(res => {
                const federationsData = this._federationsList.getValue();
                const index = federationsData.data?.findIndex(val => val.id === id);
                this._federationsList.next({
                    ...federationsData,
                    data: [
                        ...(federationsData.data || []).slice(0, index),
                        { ...(federationsData.data || [])[index], ...res.data },
                        ...(federationsData.data || []).slice(index + 1),
                    ]
                });

                return Promise.resolve(res.data);
            })
            .catch((err) => Promise.reject(err));
    };

    getFederationLeagues = (id, queryParams) => {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(`${FEDERATIONS.FEDERATIONS_LIST.URL}/${id}/${FEDERATIONS.FEDERATION_LEAGUES_LIST.URL}`, { queryParams })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getModeratorLeagues(queryParams) {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(`${FEDERATIONS.LEAGUES.URL}`, { queryParams })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    get federationsList() {
        return this._federationsList;
    };

    get federationsGroup() {
        return this._federationsGroup;
    };

    get federationUsers() {
        return this._federationUsers;
    };
}

export default new FederationsDataService();
