import React, { useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from "../icon";
import Flag from "../flag";

import {detailRowConfig} from "../../../configs/uiConfig";

import './detailRow.scss';

const DetailRow = props => {
    const {
        icon,
        flag,
        text,
        onClick,
        placeholder,
        appearance,
        theme,
        readOnly,
        capitalize,
        ctaIcon,
        tooltip,
    } = props;

     const ref = useRef(null);

    return (
        <ul
            className={classnames(
                'detail-row-item',
                `a-${appearance}`,
                `t-${theme}`,
                {
                    'cursor-pointer': onClick,
                    'pointer-events-none': readOnly,
                    capitalize,
                }
            )}
            onClick={() => typeof onClick === 'function' && onClick(ref)}
        >
            <li className="dr-icon no-shrink">
                {flag ? (
                    <Flag name={flag} size="medium" />
                ) : (
                    <Icon type={icon} />
                )}
            </li>
            <li className="dr-text f-auto">
                <div className="ellipsis-text">
                    {onClick && text ? (
                        <span ref={ref}>{text}</span>
                    ) : (
                        text || placeholder
                    )}
                </div>
            </li>
            {onClick && (
                <li className="dr-action no-shrink" title={tooltip}>
                    {!readOnly && (
                        <Icon type={ctaIcon} />
                    )}
                </li>
            )}
        </ul>
    );
};

DetailRow.defaultProps = {
    appearance: detailRowConfig.appearance[0],
    theme: detailRowConfig.theme[0],
    readOnly: false,
    capitalize: true,
    ctaIcon: 'icon-arrow-right-line',
};

DetailRow.propTypes = {
    icon: Icon.propTypes.type,
    flag: PropTypes.string,
    text: PropTypes.any,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
    appearance: PropTypes.oneOf(detailRowConfig.appearance),
    theme: PropTypes.oneOf(detailRowConfig.theme),
    readOnly: PropTypes.bool,
    ctaIcon: PropTypes.string,
};

export default DetailRow;
