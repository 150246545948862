export const REPORTS = {
    FILTERS: "filters",
    DASHBOARD: "dashboard",
    WIDGET: "widget",
    CHART: "chart",
    SPORTS: "reportsSport",
    REGIONS: 'reportsRegions',
    COMPETITIONS: "reportsCompetitions",
    COUNTRIES: "reportsCountries",
    LEAGUES: "reportsLeagues",
    EMAILS: "reportingEmails",
    ADD_REPORTING_EMAIL: "addReportingEmail",
    REMOVE_REPORTING_EMAIL: "removeReportingEmail",
    FEDERATIONS: 'federations',
};

export const USERS = {
    UPDATE_USERS: "updateUsers",
    INIT: "initUsers",
    USERS_LIST: "usersList",
    STREAMERS_LIST: "streamers",
    PROFILE: "profile",
    CREATE_USER: "createUser",
    USER_PARTNERS_LIST: "userPartnersList",
    UPDATE_USER_PARTNER: "updateUserPartner"
};

export const BECOME_PARTNER = {
    CREATE_PARTNER_REQUEST: 'createPartnerRequestForm'
};

export const PAYMENTS = {
    WITHDRAW: "withdraw",
    BANK_INFO: "bankInfo",
    BALANCE_HISTORY: "balanceHistory",
    CLIENT_CURRENT_INVOICE: "clientCurrentInvoice",
    CLIENT_CURRENT_INVOICE_EVENTS: "clientCurrentInvoiceEvents"
};

export const EVENTS = "events";
export const LEAGUES = "leagues";
export const SPORTS = "sports";
export const REGIONS = "regions";
export const COUNTRIES = "countries";
export const FILTER_LEAGUES = "filterLeagues";
export const DEVICES = "devices";
export const BROWSERS = "browsers";
export const PRICES = "prices";
export const BOOKING = {
    PRICES: "booking_prices",
    BOOK_EVENTS: "book_events",
    AUTOBOOKING_RULES: "autoBookingRulesDataState"
};
export const AUTOBOOKING_RULES_IDS = "autobooking_rule_ids";
export const BLOCK_PRODUCT = 'block';
export const UNBLOCK_PRODUCT = 'unblock';

export const OPERATORS = {
    STATUS: 'approve_statuses',
    PROJECT_TYPE: 'is_api_partner',
    PARTNER_TYPE: 'is_test',
    PAYMENT_TYPE: 'payment_type',
    ACTIVITY: 'activity',
    FS_VIDEO_STATUS: 'approve_statuses',
    BUSINESS_MODEL: 'business_model',
    IS_ENABLED: 'is_enabled',
};

export const FEDERATIONS = {
    STATUS: 'approve_statuses',
    ORGANIZATION_IDS: 'organization_ids'
};

export const SCOUT_DATA = {
    EVENTS: 'scoutEventsData'
};
