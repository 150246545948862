import React from "react";
import DropDown from "../dropDown";
import {PAYMENT_FREQUENCY} from "../../../constants/dataConstants";
import {nextDateShortcuts} from "../../../constants/dateRanges";
import DatePickerInput from "../datePickerInput";
import templatesDataService from "../../../services/templatesDataService";
import {addYears, startOfDay, endOfDay} from "date-fns";
import Helpers from "../../../helpers/helperFunctions";
import Title from '../title';
import { t } from '../../../helpers/translate';

import './leagueSetupRow.scss';

const isIsoDate = (str) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const date = new Date(str);
    return date.toISOString() === str;
}

const LeagueSetupRow = (props) => {
    const { league, values, setValues, writeProtected } = props
    const leagueIndex = values.leagues?.findIndex(val => val.league_id === (league.league_id || league.id));

    const updateLeagueField = (updatedValue = {}) => {
        setValues({
            ...values,
            leagues: [
                ...values.leagues.slice(0, leagueIndex),
                {...values.leagues[leagueIndex], ...updatedValue},
                ...values.leagues.slice(leagueIndex + 1),
            ]
        })
    };

    const startDate = values.leagues[leagueIndex].start_date;
    const endDate = values.leagues[leagueIndex].end_date;

    return (
        <div className='form-grid col-2'>
            <Title
                label={league.name}
                className='span-2'
            />
            <DropDown
                placeholder={t('Search for a template')}
                onChange={templates => updateLeagueField({template_id: templates.id})}
                initialRequestParams={values.leagues[leagueIndex].template_id ? {ids: values.leagues[leagueIndex].template_id} : null}
                service={templatesDataService.getTemplatesByParams}
                keys={{key: 'id', value: 'name'}}
                label={t('Template')}
                className='span-2'
                writeProtected={writeProtected}
            />
            <DatePickerInput
                dateRange={{
                    startDate: new Date(typeof startDate === "number" ? startDate * 1000 : !isIsoDate(startDate) ? startDate.replace(/-/g, "/") : startDate),
                    endDate:  new Date(typeof startDate === "number" ? endDate * 1000 : !isIsoDate(endDate) ? endDate.replace(/-/g, "/") : endDate),
                }}
                staticRanges={nextDateShortcuts(Helpers.getTimeZoneTime(), true)}
                onChange={({startDate, endDate}) => {
                    updateLeagueField({
                        start_date: startOfDay(startDate).toISOString(),
                        end_date: endOfDay(endDate).toISOString()
                    })
                }}
                label={t('Season start & end')}
                minDate={new Date()}
                maxDate={(addYears(startOfDay(new Date()), 100))}
                writeProtected={writeProtected}
            />
            <DropDown
                label={t('Payment frequency')}
                placeholder={t('Select payment frequency')}
                onChange={(val) => updateLeagueField({payment_type: val.id})}
                initialSelections={values.leagues[leagueIndex].payment_type ? PAYMENT_FREQUENCY.find(item => values.leagues[leagueIndex].payment_type === item.id) : null}
                externalData={PAYMENT_FREQUENCY}
                withLazyLoad={false}
                multiSelect={false}
                searchable={false}
                writeProtected={writeProtected}
            />
        </div>
    )
};

export default LeagueSetupRow;
