import DataFetcher from "./dataFetcherService";
import { REPORTING } from "../constants/api-resources";
import { BehaviorSubject, combineLatest } from "rxjs";
import { reportsDefaultParams } from "../constants/queryParams";
import { loadMoreDataHandler } from "../helpers/dataHandlers";
import { map } from "rxjs/operators";
import Helpers from "../helpers/helperFunctions";
import {
    ADMIN,
    CP_ADMINISTRATOR,
    CP_GROUP_MANAGER,
    FEDERATION_GROUP_COORDINATOR,
    MODERATOR
} from "../constants/userRoles";

class ReportingDataService {
    constructor() {
        this.setServiceInitData();
    }

    setServiceInitData() {
        this.dashboardData = new BehaviorSubject({});
        this.widgets = {};
        this.areaChartData = {};
        this.filtersData = null;
        this._reportingCountriesList = new BehaviorSubject({});
        this._reportingRegionList = new BehaviorSubject({})
        this._reportingSportList = new BehaviorSubject({});
        this._reportingLeaguesList = new BehaviorSubject({});
        this._reportingDevicesList = new BehaviorSubject({});
        this._reportingBrowsersList = new BehaviorSubject({});
        this._reportingEmailsList = new BehaviorSubject([]);

        this.organizationId = null;
    }

    getDashboard(organizationId) {
        this.organizationId = organizationId ?? null;
        return DataFetcher.getJson(`${this.reportingUrl}/${REPORTING.DASHBOARD.URL}`, {queryParams: {...(this.organizationId ? {organization_ids: this.organizationId} : {})}})
            .then(res => {
                this.dashboardData.next(res.data);
                // this.dashboardData.complete(); TODO CHANGE

                return Promise.resolve();
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    getWidgetData(params = {}, id) {
        const queryParams = params;

        if (id) {
            //Set dimensions and measures for request.
            // queryParams.dimension_ids = params.dimension_ids && params.dimension_ids.join();
            // queryParams.measure_ids = params.measure_ids && params.measure_ids.join();
        }

        return DataFetcher.getJson(`${this.reportingUrl}${id ? '' : `/${REPORTING.REPORTS_FULL.URL}`}`, {queryParams: {...queryParams, ...(this.organizationId ? {organization_ids: this.organizationId} : {})}})
            .then(res => {
                if (!id) {
                    this.widgets && Object.keys(this.widgets).forEach(id => {
                        if (res.data && res.data[id]) {
                            this.widgets[id].next(res.data[id]);
                        }
                    });
                } else {
                    //Save data to widgets object by wId for subscriptions.
                    this.widgets[id] = this.widgets[id] || new BehaviorSubject();
                    this.widgets[id].next(res.data);
                }

                return Promise.resolve();
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    getFiltersData() {
        return DataFetcher.getJson(REPORTING.FILTERS.URL)
            .then(res => {
                this.filtersData = res && res.data;
                return Promise.resolve();
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    getCountries = (params = {}, loadMore) => {
        if (params.removeData) return Promise.resolve(this._reportingCountriesList.next({}));
        return DataFetcher.getJson(`${this.reportingUrl}/${REPORTING.COUNTRIES.URL}`, {
            queryParams: {
                ...reportsDefaultParams(),
                ...(this.organizationId ? {organization_ids: this.organizationId} : {}),
                ...params
            }
        })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._reportingCountriesList.getValue(), true);
                this._reportingCountriesList.next({total_count: res.total_count, data});
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    };

    getSports = (params = {}, loadMore) => {
        if (params.removeData) return Promise.resolve(this._reportingSportList.next({}));
        return DataFetcher.getJson(`${this.reportingUrl}/${REPORTING.SPORTS.URL}`, {
            queryParams: {
                ...reportsDefaultParams(),
                ...(this.organizationId ? {organization_ids: this.organizationId} : {}),
                ...params
            }
        })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._reportingSportList.getValue());
                this._reportingSportList.next({total_count: res.total_count, data});
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    };

    getRegions = (params = {}, loadMore) => {
        if (params.removeData) return Promise.resolve(this._reportingRegionList.next({}));
        const additionalParams = {
            includes: "league,league.sport,league.region",
            leagueFields: "region_id,sport_id,name"
        };
        return DataFetcher.getJson(`${this.reportingUrl}/${REPORTING.REGIONS.URL}`, {
            queryParams: {
                ...reportsDefaultParams(),
                ...additionalParams,
                ...(this.organizationId ? {organization_ids: this.organizationId} : {}),
                ...params
            }
        })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._reportingRegionList.getValue());
                this._reportingRegionList.next({total_count: res.total_count, data});
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    };

    getLeagues = (params = {}, loadMore) => {
        if (params.removeData) return Promise.resolve(this._reportingLeaguesList.next({}));

        const additionalParams = {
            includes: "league,league.sport,league.region",
            leagueFields: "region_id,sport_id,name"
        };

        return DataFetcher.getJson(`${this.reportingUrl}/${REPORTING.LEAGUES.URL}`, {
            queryParams: {
                ...reportsDefaultParams(),
                ...additionalParams,
                ...(this.organizationId ? {organization_ids: this.organizationId} : {}),
                ...params
            }
        })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._reportingLeaguesList.getValue());
                this._reportingLeaguesList.next({total_count: res.total_count, data});
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    };

    getDevices = (params = {}, loadMore) => {
        if (params.removeData) return Promise.resolve(this._reportingDevicesList.next({}));
        return DataFetcher.getJson(`${this.reportingUrl}/${REPORTING.DEVICES.URL}`, {
            queryParams: {
                ...reportsDefaultParams(),
                ...(this.organizationId ? {organization_ids: this.organizationId} : {}),
                ...params
            }
        })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._reportingDevicesList.getValue());
                this._reportingDevicesList.next({total_count: res.total_count, data});
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    };

    getBrowsers = (params = {}, loadMore) => {
        if (params.removeData) return Promise.resolve(this._reportingBrowsersList.next({}));
        return DataFetcher.getJson(`${this.reportingUrl}/${REPORTING.BROWSERS.URL}`, {
            queryParams: {
                ...reportsDefaultParams(),
                ...(this.organizationId ? {organization_ids: this.organizationId} : {}),
                ...params
            }
        })
            .then(res => {
                const data = loadMoreDataHandler(loadMore, res.data, this._reportingBrowsersList.getValue());
                this._reportingBrowsersList.next({total_count: res.total_count, data});
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    };

    getAreaChartData(params = {}, id) {
        const queryParams = { //@TODO check
            ...reportsDefaultParams(true),
            ...(this.organizationId ? {organization_ids: this.organizationId} : {}),
            days_interval: 1,
            dimension_ids: 8,
            measure_ids: 1
        };

        return DataFetcher.getJson(this.reportingUrl, {queryParams})
            .then(res => {
                //Save data to widgets object by wId for subscriptions.
                this.areaChartData[id] = this.areaChartData[id] || new BehaviorSubject();
                this.areaChartData[id].next(res.data);

                return Promise.resolve();
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    getReportingEmails() {
        return DataFetcher.getJson(REPORTING.EMAILS.URL)
            .then(res => {
                this._reportingEmailsList.next(res.data);
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    }

    addReportingEMail(params) {
        return new Promise((resolve, reject) => {
            DataFetcher.postJson(REPORTING.EMAILS.URL, {params})
                .then(res => {
                    const newData = Helpers.addOrUpdateArrayOfObjectsValue((this._reportingEmailsList.getValue() || []), res.data, "id");
                    this._reportingEmailsList.next(newData);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    removeReportingEMail(id) {
        return new Promise((resolve, reject) => {
            DataFetcher.deleteJson(`${REPORTING.EMAILS.URL}/${id}`)
                .then(res => {
                    const newData = Helpers.removeObjFromArray((this._reportingEmailsList.getValue() || []), {id});
                    this._reportingEmailsList.next(newData);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    stop() {
        this.setServiceInitData();
    }

    get dashboard() {
        return this.dashboardData;
    }

    get reportingSportList() {
        return this._reportingSportList;
    }

    get reportingCountriesList() {
        return this._reportingCountriesList;
    }

    get reportingLeaguesList() {
        return this._reportingLeaguesList;
    }

    get reportingDevicesList() {
        return this._reportingDevicesList;
    }

    get reportingBrowsersList() {
        return this._reportingBrowsersList;
    }

    get reportingEmailsList() {
        return this._reportingEmailsList;
    }

    getWidget(id) {
        if (!this.widgets[id]) this.widgets[id] = new BehaviorSubject();

        return this.widgets[id];
    }

    getAreaChart(id) {
        if (!this.areaChartData[id]) this.areaChartData[id] = new BehaviorSubject();

        return this.areaChartData[id];
    }

    get filters() {
        return this.filtersData;
    }

    get reportingUrl() {
        return Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, FEDERATION_GROUP_COORDINATOR, CP_GROUP_MANAGER)
            ? REPORTING.MODERATOR_REPORTS.URL
            : REPORTING.REPORTS.URL;
    }

    get connector() {
        return combineLatest(
            //this._countriesList.pipe(map(data => ({type: "reportingCountriesList", data}))),
            this._reportingSportList.pipe(map(data => ({type: "reportingSportList", data}))),
            this._reportingLeaguesList.pipe(map(data => ({type: "reportingLeaguesList", data}))),
            this._reportingDevicesList.pipe(map(data => ({type: "reportingDevicesList", data}))),
            this._reportingBrowsersList.pipe(map(data => ({type: "reportingBrowsersList", data})))
        );
    }
}

export default new ReportingDataService();
