import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './tag.scss';

const Tag = props => {
    const {
        name,
        color,
        clipped,
        onClick,
    } = props;

    return (
        <div
            className={classnames('tag-c', `c-${color}`, {
                clipped,
                clickable: typeof onClick === 'function',
            })}
            onClick={onClick}
        >
            <div className="ellipsis-text">{name}</div>
        </div>
    )
};

Tag.defaultProps = {
    color: 'warning',
};

Tag.propTypes = {
    name: PropTypes.any,
    onClick: PropTypes.func,
    clipped: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'hero', 'accent', 'brand', 'danger', 'confirm', 'warning', 'jaffa',]),
};

export default Tag;
