import { DataFetcher } from './dataFetcherService';
import Config from '../configs/mainConfig';

class ScoutDataFetcher extends DataFetcher {
    constructor () {
        super({
            baseHost: Config.main.scoutDataBaseHost,
            headers: {
                Origin: window.location.origin
            }
        });
    }
}

export default new ScoutDataFetcher()
