import Helpers from "./helperFunctions";
import {getEventType, PER_PAGE_COUNT} from "../constants/queryParams";
import {DeleteFiltersData} from "../actions/sync-actions/filtersData";
import {ClearPresetData, SetAppliedPresetId} from "../actions/sync-actions/presetData";
import {ClearAutobookingRules} from "../actions/sync-actions/autoBooking";
import {ResetFiltersValue, SetMultipleFiltersValue} from "../actions/sync-actions/filters";

/**
 * @param value
 * @returns {*[]|Array}
 */
export const singleObjectToArrayConverter = (value) => {
    return (value && (value.length === "undefined") ? [value] : value) || [];
};

/**
 * @description Function will handle response data by loadMore option.
 * @param loadMore
 * @param newData
 * @param currentData
 * @returns {*}
 */
export const loadMoreDataHandler = (loadMore, newData, currentData, sortable = false) => {
    let data = singleObjectToArrayConverter(newData);
    //Sort data if need
    if (sortable) {
        data = Helpers.sortArrayByKey(data, "name");
    }
    //Merge data if need
    if (loadMore) {
        data = [
            ...(currentData.data || []),
            ...data
        ];
    }

    return data;
};

export const buildPaginationPayload = (currentPage, perPage = PER_PAGE_COUNT, ) => {
    return {
        offset: (!currentPage ? 0 : currentPage - 1) * perPage,
        limit: perPage
    };
};

export const resetFiltersByEventsType = (dispatch, eventsType, payload) => {
    payload = payload || { type: getEventType(eventsType) };
    dispatch(DeleteFiltersData());
    dispatch(ClearPresetData(eventsType));
    dispatch(SetAppliedPresetId(null, eventsType));
    dispatch(ClearAutobookingRules(eventsType));
    dispatch(ResetFiltersValue(eventsType, "events", true));
    dispatch(SetMultipleFiltersValue(eventsType, payload, "events"));
};
