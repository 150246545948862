import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openPopup, registerToastMessage } from '../../../../actions/sync-actions/uiActions';
import { popupConfig } from '../../../../configs/popupConfig';
import { MATCH_STATUSES } from '../../../../constants/statuses';
import { TOAST_TYPES } from '../../../../constants/uiConstants';
import { getContentProvidersData } from '../../../../helpers/eventsHelper';
import Helpers from '../../../../helpers/helperFunctions';
import { getBasePath } from '../../../../helpers/routeHelper';
import { t } from '../../../../helpers/translate';
import useStreamActions from '../../../../hooks/useStreamActions';
import eventsDataService from '../../../../services/eventsDataService';
import streamingService from '../../../../services/streamingService';
import usersDataService from '../../../../services/usersDataService';
import Button from '../../button';
import DetailRow from '../../detailRow';
import DropDown from '../../dropDown';
import Icon from '../../icon';

const StreamActions = ({ event, onEventChange }) => {
    const [streamName, setStreamName] = useState();
    const [showStreamNameList, setShowStreamNameList] = useState(false);
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [streamData, setStreamData] = useState({});
    const [streamInfo, setStreamInfo] = useState({});
    const {
        match_status,
        on_air_status,
        organization_id,
        product_content_id,
        content_providing_user_id,
    } = (event && getContentProvidersData(event)) || {};

    const history = useHistory();
    const dispatch = useDispatch();

    const {
        newStream,
        stopStream,
        startStream,
        setNewStream,
        deleteStream,
        streamStarted,
        changeMatchStatus,
        handleStreamSelect,
        applyStreamNameChange,
    } = useStreamActions(data, event, streamData, setStreamData, setStreamInfo);

    const eventId = event.id;
    const userProfile = usersDataService.profile.getValue();
    const isStreamer = userProfile.id === content_providing_user_id;
    const setupMode = userProfile.id === content_providing_user_id && on_air_status === 0 && match_status === 0;
    const action = useMemo(() => {
        return setupMode ? { type: 'start_stream', eventId, streamId: product_content_id } : { type: 'view', eventId };
    }, [setupMode, eventId, product_content_id]);
    const disableStreaming = on_air_status === 2 || !streamInfo.alive || match_status === MATCH_STATUSES.CANCELED || match_status === MATCH_STATUSES.FINISHED || !isStreamer;

    useEffect(() => {
        setIsOpen(showStreamNameList);
    }, [showStreamNameList]);

    const streamAction = (action) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(`${getBasePath()}/streamer/${action.eventId}`);
    };

    const showMessage = (messageKey, callback) => {
        dispatch(openPopup('message', {
            ...popupConfig[messageKey],
            handleCallBack: callback,
        }));
    };

    const actionsList = useMemo(() => ([
        ...((on_air_status === 0 || on_air_status === 2) ? [{
            tooltip: t('Start streaming'),
            disabled: disableStreaming,
            icon: 'icon-start-streaming',
            color: 'confirm',
            onClick: () => showMessage('startStream', () => startStream(streamData)),
        }] : [{
            tooltip: t('Stop streaming'),
            disabled: disableStreaming,
            icon: 'icon-end-streaming',
            color: 'danger',
            onClick: () => showMessage('stopStream', () => stopStream(streamData)),
        }]),
        ...(match_status === MATCH_STATUSES.NOT_STARTED ? [{
            tooltip: t('Start match'),
            icon: 'icon-start-match',
            color: 'confirm',
            onClick: () => showMessage('matchStart', changeMatchStatus(MATCH_STATUSES.STARTED)),
        }] : []),
        ...(match_status === MATCH_STATUSES.STARTED ? [{
            tooltip: t('End match'),
            icon: 'icon-end-match',
            color: 'danger',
            onClick: () => showMessage('matchEnd', changeMatchStatus(MATCH_STATUSES.FINISHED, onEventChange)),
        }, {
            tooltip: t('Cancel match'),
            icon: 'icon-cancel-match',
            color: 'danger',
            onClick: () => showMessage('matchCancel', changeMatchStatus(MATCH_STATUSES.CANCELED, onEventChange)),
        }] : []),
        {
            onClick: streamAction(action),
            tooltip: setupMode ? t('View setup') : t('View details'),
            icon: setupMode ? 'icon-play' : 'icon-eye',
        },
    ]), [streamData, on_air_status, match_status, disableStreaming]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const subscription = streamingService.streamData.subscribe((streamData) => {
            if(!data.length) {
                setData(streamData[event.id]?.map(itemData => ({
                    id: itemData.id,
                    name: itemData.stream_name,
                    description: `${itemData.credentials[0].server} - ${itemData.credentials[0].prefix}`,
                    credentials: itemData.credentials
                })));
            }
        });

        subscription.add(streamingService.streamConnector.subscribe((data => {
            if(data[eventId]?.alive) {
                setStreamInfo(val => ({ ...val, ...data[event.id] }));
            }
        })));

        if (event.id) {
            streamingService.setupStream(product_content_id, organization_id, event.id);
            eventsDataService.getEventStreamer(event.id)
                .then(val => { setStreamName(val.stream_name)})
                .catch(e => e);
        }

        return () => {
            subscription.unsubscribe();
            let idForUnsubscribe = event.id
            if (event.content_providers?.length > 0 && event.content_providers[0].product_content_id) {
                idForUnsubscribe = event.content_providers[0].product_content_id;
            }
            streamingService.unsubscribeFromStreamUpdates(idForUnsubscribe);
        };
    }, [event.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const stream = data ?
            streamName
                ? data.find(val => val.name === streamName) || {}
                : ((data.find(val => val.id === product_content_id)) || data[0]) || {}
            : {};
        setStreamData(stream);
        !streamName && stream && setStreamName(stream.name);
    }, [data, streamName]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {showStreamNameList ? (
                <ul className="stream-setup-reveal-holder">
                    <li>
                        <DropDown
                            key={data.length}
                            label="Stream name"
                            labelAppearance="none"
                            multiSelect={false}
                            withLazyLoad={false}
                            isOpen={isOpen}
                            externalData={data}
                            appearance="light"
                            initialSelections={data.find((item) => item.name === streamData.name)}
                            onChange={(item) => handleStreamSelect(item, () => {
                                setIsOpen(false);
                                if (!streamStarted) {
                                    setShowStreamNameList(false);
                                    setStreamName(item.name);
                                }
                            })}
                            onClose={() => setShowStreamNameList(false)}
                            rowHasAction
                            rowRenderer={(item) => (
                                <>
                                    <ul className="d-option d-option-vertical">
                                        <li className="ellipsis-text">{item['name']}</li>
                                        <li className="option-description ellipsis-text">{item.description}</li>
                                    </ul>
                                    <Icon
                                        type="icon-trash"
                                        size={18}
                                        onClick={(ev) => deleteStream(ev, item.id, () => setShowStreamNameList(false))}
                                    />
                                </>
                            )}
                        />
                    </li>
                    <li
                        className={classnames('stream-setup-reveal-action', {
                            'active': streamStarted && newStream,
                        })}
                    >
                        <Button
                            onClick={() => {
                                applyStreamNameChange((data) => setStreamName(data.name));
                                setShowStreamNameList(false);
                            }}
                            icon="icon-check"
                            size="medium"
                            appearance="minimal"
                            color="confirm"
                        />
                        <Button
                            onClick={() => {
                                setNewStream(null);
                                setShowStreamNameList(false);
                            }}
                            icon="icon-clear-thick"
                            size="medium"
                            appearance="minimal"
                            color="danger"
                        />
                    </li>
                </ul>
            ) : (
                <DetailRow
                    text={
                        <>
                            {t('Stream name')} - <span>{streamName}</span>
                        </>
                    }
                    placeholder={t('Set stream name')}
                    onClick={() => setShowStreamNameList(true)}
                    icon="icon-ticket"
                />
            )}
            {streamData && (
                <DetailRow
                    text={
                        <>
                            {`${t('Server Url')} - ${streamData.credentials?.[0].url ?? t('None')}`}
                        </>
                    }
                    onClick={(ref) => {
                        Helpers.doCopy(ref, (resp) => {
                            if (resp.type === 'success') {
                                dispatch(registerToastMessage({
                                    type: TOAST_TYPES.SUCCESS,
                                    title: "Copied",
                                    message: `${streamData.credentials?.[0].url} is copied to your clipboard.`,
                                }));
                            } else {
                                dispatch(registerToastMessage({
                                    type: TOAST_TYPES.ERROR,
                                    message: resp.msg || "Oops something went wrong"
                                }));
                            }
                        });
                    }}
                    icon="icon-video"
                    ctaIcon="icon-clone"
                    tooltip={t('Copy')}
                />
            )}
            <div className="stream-inline-actions-holder">
                {(actionsList || []).map((item, index) => (
                    <Button
                        key={index}
                        color="jaffa"
                        appearance="light"
                        tooltipPosition="top"
                        {...item}
                    />
                ))}
            </div>
        </>
    );
};

export default StreamActions;
