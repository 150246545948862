import React from "react";

const DropDownItemWithDescription = ({ item: { name, id, region } }) => (
    <ul className="d-option d-option-vertical">
        <li className="ellipsis-text">{ name } </li>
        <li className="option-description ellipsis-text">{ region?.name } - {region ? '#' : null}{ id }</li>
    </ul>
);

export default DropDownItemWithDescription;
