import { BehaviorSubject, Subject, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { endOfDay, startOfDay } from 'date-fns';

import webWorker from '../webWorker';
import DataFetcher from "./dataFetcherService";

import { processSelectedEventsData } from '../helpers/eventsHelper';
import Helpers from "../helpers/helperFunctions";
import Socket from "../helpers/socket";

import { eventsLeagueDefaultParams, getEventType, setQueryParams } from "../constants/queryParams";
import {
    ADMIN,
    CP_ADMINISTRATOR,
    CP_GROUP_MANAGER,
    FEDERATION_GROUP_COORDINATOR,
    MODERATOR
} from "../constants/userRoles";
import { SOCKET_EVENTS, SUBSCRIBE, UNSUBSCRIBE } from "../constants/socket";
import { EVENTS, MY_GAMES } from "../constants/api-resources";
import { EVENT_TYPES } from "../constants/uiConstants";

// TODO CHANGE AFTER TESTING
const changeGamesDataStructure = (eventListUrl, res = {}) => {
    return (eventListUrl === 'games') ? {
        ...res,
        data: res.data.map(event => Helpers.createArrInObjByKey(event, 'prod_', 'content_providers'))
    } : res;
};

class EventsDataService {
    constructor() {
        this._eventList = new BehaviorSubject({});
        this._selectedEventList = new BehaviorSubject({});
        this._dailyTotals = new BehaviorSubject({});
        this._matchCounts = new BehaviorSubject({});
        this._retrievedEvent = new Subject();
        this.eventListUrl = EVENTS.EVENTS_LIST.URL;
        this.setupEventSource();
        this._subscribedEventsIds = {};

        Socket.connectionStatus.subscribe(
            val => {
                if (val) {
                    this.socketHandler();
                    //Socket.sendRequest(SUBSCRIBE, {'events': ['newEvent:*']});
                }

            }
        );
    }

    obtainRequiredSubscriptionsIds(ids, type = SUBSCRIBE) {
        let requiredIds = [];
        if (type === SUBSCRIBE) {
            ids.forEach(id => {
                if (this._subscribedEventsIds[id]) {
                    this._subscribedEventsIds[id]++;
                } else {
                    this._subscribedEventsIds[id] = 1;
                    requiredIds.push(id);
                }
            });
        } else if (type === UNSUBSCRIBE) {
            ids.forEach(id => {
                if (this._subscribedEventsIds[id]) {
                    if (--this._subscribedEventsIds[id] === 0) {
                        delete this._subscribedEventsIds[id];
                        requiredIds.push(id);
                    }
                }
            });
        } else {
            throw new Error('wrong subscription type');
        }
        return requiredIds;
    }

    socketHandler() {
        Socket.getConnector(SOCKET_EVENTS.EVENT).subscribe(res => {
            const eventLists = Helpers.cloneDeep(this._eventList.getValue());
            const updatedIds = Object.keys(res);
            //Update data from current list
            updatedIds.forEach(id => {
                Object.keys(eventLists).forEach(key => {
                    let currentData = eventLists[key].data;
                    const isUpdate = currentData.some((currentValue, index) => {
                        if (currentValue.creation_id === id) {
                            currentData[index] = {
                                ...currentValue,
                                ...res[id]
                            };
                            return true;
                        }
                        return false;
                    });
                    isUpdate && this._eventList.next({
                        ...eventLists,
                        [key]: { ...eventLists[key], data: currentData }
                    });
                });
            });
        });

        /* Socket.getConnector(SOCKET_EVENTS.NEW_EVENT).subscribe(res => {
             const events = Helpers.cloneDeep(this._eventList.getValue());
             const newEvents = Object.keys(res).map(key => res[key]);
             events['list'].data = [...newEvents, ...events['list'].data];
             this._eventList.next(events);
         });*/
    }

    /**
     * @description Setup event source for subscription.
     */
    setupEventSource() {
        this.eventSource = new Subject();
        this.eventLastSource = this.eventSource.pipe(switchMap(
            (val) => {
                const {
                    data: { queryParams, key },
                    abort: { signal, controller },
                    promiseFns: { resolve: resolveFn, reject: rejectFn },
                    reset
                } = val || {};

                //Abort previous requests.
                this.lastSignal && this.lastController.abort();
                this.lastSignal = signal;
                this.lastController = controller;
                return from(DataFetcher.getJson(this.eventListUrl, { queryParams, signal: this.lastSignal })
                    .then(res => {
                        res = changeGamesDataStructure(this.eventListUrl, res);
                        return { res, key, resolveFn, reset, queryParams };
                    })
                    .catch(err => {
                        rejectFn(err);
                        return Promise.reject(err);
                    }));
            }
        ));

        // Subscription function
        this.eventLastSource.subscribe(data => {
            const { res, key, resolveFn, reset } = data;
            const eventList = this._eventList.getValue() || {};
            if (reset) {
                if (this._selectedEventList.getValue()[key]?.length) {
                    const selectedList = this._selectedEventList.getValue();
                    let selectedListIds = [];
                    selectedList[key].map(({id}) => {
                        selectedListIds.push(id);
                    });
                    DataFetcher.getJson(EVENTS.CHECK_AVAILABLE.URL, {
                        queryParams: {
                            ids: selectedListIds
                        }
                    })
                        .then(resp => {
                            this._selectedEventList.next({
                                ...selectedList,
                                [key]: [...selectedList[key]?.filter((selectedEvent) => {
                                    if (!!resp?.data?.find((item) => item === selectedEvent.id)) {
                                        return { selectedEvent };
                                    } else this.removeSelectedEventById(key, selectedEvent.id);
                                })]
                            });
                        });
                }
                this._eventList.next({ ...eventList, [key]: res });
            } else {
                // const nextData = workerService.newTask("mergeDataBySubKey", eventList, res, key);
                // nextData.subscribe(val => {
                //     this._eventList.next(val);
                // });
                webWorker.open(
                    'helperWorker',
                    'mergeDataBySubKey',
                    [eventList, res, key],
                    (resp) => {
                        this._eventList.next(resp);
                    }
                );
            }
            resolveFn && resolveFn(res);
        });
    }

    /**
     * @description Initialize service.
     * @param dataKey
     * @param key
     * @returns {*}
     */
    init(dataKey, key) {
        this.eventListUrl = dataKey === "myGames" ? MY_GAMES.LIST.URL : Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR) ? EVENTS.MODERATOR_EVENTS_LIST.URL : this.eventListUrl;
        return Promise.resolve(true);
    }

    /**
     * @description Get events data by params.
     * @param params
     * @param key
     * @returns {*}
     */
    update(params = {}, key, reset) {
        const queryParams = setQueryParams(params, "events", key);
        return new Promise((resolve, reject) => {
            //Abort all previous requests if these are loading.
            const controller = new AbortController();
            const signal = controller.signal;
            //Emit new request with params.
            this.eventLastSource.next({
                data: { queryParams, key },
                abort: { controller, signal },
                promiseFns: { resolve, reject },
                reset
            });
        });
    }

    /**
     * @description Connect to socket by command
     * @param request
     * @param command
     * @returns {*}
     */
    connectForUpdate(request = ['event:*'], command = SUBSCRIBE, key) {
        const requiredEventIds = this.obtainRequiredSubscriptionsIds(request, command).map(val => `event:${val}`);
        Socket.sendRequest(command, { 'events': requiredEventIds });
    }

    getEventsByIds(ids, params = {}) {
        return DataFetcher.getJson(this.eventListUrl, { queryParams: { ids, without_pagination: true, ...params } })
            .then(res => {
                res = changeGamesDataStructure(this.eventListUrl, res);
                this._retrievedEvent.next(res.data);
                return Promise.resolve(res.data);
            })
            .catch(err => {
                console.log(err);
                return Promise.reject(err);

            });
    }

    getEventsByIdForUpdate(ids, key, removeEvent) {
        const eventList = this._eventList.getValue();
        const selectedEvents = this._selectedEventList.getValue();

        if (removeEvent) {
            this._selectedEventList.next({
                ...selectedEvents,
                [key]: selectedEvents[key].filter(el => el.id !== ids),
            });
            this._eventList.next({
                ...eventList,
                [key]: {
                    ...eventList[key],
                    data: Helpers.removeObjFromArray(eventList[key].data, { id: ids }),
                    total_count: eventList[key].total_count - 1
                },
            });
            return Promise.resolve({});
        }

        return DataFetcher.getJson(this.eventListUrl, {
            queryParams: {
                ids,
                without_pagination: true, ...eventsLeagueDefaultParams
            }
        })
            .then((res) => {
                webWorker.open(
                    'helperWorker',
                    'mergeDataBySubKey',
                    [eventList, { data: res.data }, key],
                    (resp) => this._eventList.next(resp),
                );

                this._selectedEventList.next({
                    ...selectedEvents,
                    [key]: Helpers.updateDataInArrayOfObjects(selectedEvents[key], res.data[0]),
                });
            })
            .catch(err => Promise.reject(err));
    }

    /**
     * @description Function will toggle selected events by ids
     * @param ids
     * @param key
     * @param itemsData
     */

    toggleSelectedEventsByIds(ids, key, itemsData) {
        const allSelections = this._selectedEventList.getValue();
        const currentSelections = allSelections[key] || [];
        const { selections, missingIds } = processSelectedEventsData(ids, itemsData, currentSelections);

        this._selectedEventList.next({ ...allSelections, [key]: selections });

        missingIds.length && DataFetcher.getJson(this.eventListUrl, {
            queryParams: {
                ids: missingIds,
                without_pagination: true
            }
        })
            .then(res => {
                res = changeGamesDataStructure(this.eventListUrl, res);
                const newData = res?.data;
                newData?.length && this._selectedEventList.next({
                    ...allSelections,
                    [key]: [...selections, ...(newData || [])]
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    /**
     * @description Function will remove ids from selected list.
     * @param key
     * @param ids
     * @param callback
     */
    removeSelectedEventById(key, ids, callback) {
        const currentList = this._selectedEventList.getValue();
        const filteredList = {
            ...currentList,
            [key]: (currentList[key]?.filter(item => Array.isArray(ids) ? !ids.includes(item.id) : item.id !== ids)) || []
        };
        this._selectedEventList.next(filteredList);
        callback && callback();
    }

    /**
     * @description Remove all selected events for provided key
     * @param key
     */
    removeAllSelectedEvents(key) {
        const currentList = this._selectedEventList.getValue(),
            filteredList = { ...currentList, [key]: [] };
        this._selectedEventList.next(filteredList);
    }

    /**
     * @description Create event.
     * @param params
     * @param organizationId
     * @param key
     * @returns {Promise<any>}
     */
    createEvent(params, organizationId, key = EVENT_TYPES.LIST) {
        return new Promise((resolve, reject) => {
            const url = Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR) ? `${EVENTS.MODERATOR_CREATE_EVENT.URL}${organizationId ? '/' + organizationId : ''}` : EVENTS.CREATE_EVENT.URL;
            DataFetcher.postJson(url, { params })
                .then(res => {
                    let eventList = this._eventList.getValue();
                    const sortedData = Helpers.sortData([...(eventList[key]?.data || []), res.data], true, "start_date");
                    this._eventList.next({
                        ...eventList, [key]: {
                            ...eventList[key],
                            data: sortedData,
                            total_count: (eventList[key]?.data || []).length + 1,
                        }
                    });
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    /**
     * @description Update event by id and update eventList by current key.
     * @param eventId
     * @param organizationId
     * @param params
     * @param key
     * @returns {Promise<any>}
     */
    updateEvent(eventId, organizationId, params, key = EVENT_TYPES.LIST) {
        return new Promise((resolve, reject) => {
            const url = Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR) ? `${EVENTS.MODERATOR_UPDATE_EVENTS.URL}/${organizationId}/${EVENTS.UPDATE_EVENTS.URL}` : EVENTS.UPDATE_EVENTS.URL;
            DataFetcher.putJson(url + eventId, { params })
                .then(res => {
                    // let eventList = this._eventList.getValue();
                    // this._eventList.next({...eventList, [key]: [...eventList[key], ...res.data]});//@TODO change
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    rollBackEvent(eventId, key = EVENT_TYPES.HISTORY) {
        return new Promise((resolve, reject) => {
            DataFetcher.postJson(`${EVENTS.MODERATOR_EVENTS_LIST.URL}/${eventId}/rollback`, {})
                .then(res => {
                    const allEvents = this._eventList.getValue();
                    const updatedEvents = Helpers.removeObjFromArray(allEvents[key].data, { id: eventId });
                    this._eventList.next({
                        ...allEvents,
                        [key]: {
                            ...allEvents[key],
                            data: updatedEvents,
                            total_count: allEvents[key].total_count - 1
                        }
                    });
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    removeEventsByKey(key = EVENT_TYPES.LIST, newEvents = []) {
        const allEvents = this._eventList.getValue();
        const eventsList = allEvents[key]?.data || [];

        const updatedEvents = newEvents.reduce((acc, val) => {
            return Helpers.removeObjFromArray(acc, { id: val.id });
        }, eventsList);

        const updatedTotalCount = allEvents[key].total_count - newEvents.length;

        this._eventList.next({
            ...allEvents,
            [key]: {
                ...allEvents[key],
                data: updatedEvents,
                total_count: updatedTotalCount < 0 ? 0 : updatedTotalCount
            }
        });

    }

    resetEvents(type) {
        if (!type) return this._eventList.next({});
        const allEvents = this._eventList.getValue();
        this._eventList.next({
            ...allEvents,
            [type]: {}
        });
    }

    /**
     * @description Function will update selected events by key and res data
     * @param key
     * @param params
     * @returns {Promise<any>}
     */
    updateSelectedEvents(key, params) {
        return new Promise((resolve, reject) => {
            const url = Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR) ? EVENTS.MODERATOR_UPDATE_UPCOMING.URL : EVENTS.UPDATE_UPCOMING.URL;
            DataFetcher.putJson(url, { params })
                .then(res => {
                    try {
                        const newEvents = res?.data || [];
                        const allSelectedEvents = this._selectedEventList.getValue();
                        const allEvents = this._eventList.getValue();
                        const eventsList = allEvents[key]?.data || [];
                        const selectedEvents = allSelectedEvents[key];

                        const updatedEvents = newEvents.reduce((acc, val) => {
                            const index = acc.findIndex(el => el.id === val.id);
                            if (index !== -1) {
                                return [...acc.slice(0, index), val, ...acc.slice(index + 1)];
                            }
                            return acc;
                        }, eventsList);

                        const updatedSelectedEvents = newEvents.reduce((acc, val) => {
                            return Helpers.updateDataInArrayOfObjects(acc, val, 'id');
                        }, selectedEvents);

                        this._selectedEventList.next({ ...allSelectedEvents, [key]: updatedSelectedEvents });
                        this._eventList.next({ ...allEvents, [key]: { ...allEvents[key], data: updatedEvents } });


                        /* debugger;
                         res = Helpers.convertArrayToObjectByKey(res.data, "event_id");
                         const selectedList = this._selectedEventList.getValue(),
                             eventListObj = this._eventList.getValue(),
                             eventListData = Helpers.cloneDeep(eventListObj[key].data),
                             eventListDataByKey = Helpers.convertArrayToObjectByKey(eventListData.data, "id"),
                             selectedListUpdated = Helpers.cloneDeep(selectedList[key]) || [];

                         selectedListUpdated.forEach(data => {
                             if (Object.keys(res).includes(data.id.toString())) {
                                 data.content_providers = Helpers.updateDataInArrayOfObjects(data.content_providers, res[data.id], "product_id");
                                 streamerData && data.content_providers.forEach(provider => {
                                     if (provider.content_providing_user_id === streamerData.id) {
                                         provider.streamer = {
                                             ...provider.streamer,
                                             id: streamerData.id,
                                             first_name: streamerData.first_name,
                                             last_name: streamerData.last_name
                                         };
                                     }
                                 });
                                 eventListDataByKey[data.id] && (eventListDataByKey[data.id].content_providers = data.content_providers);
                             }
                         });
                         if (!Helpers.isEqual(selectedListUpdated, selectedList[key])) {
                             this._selectedEventList.next({...selectedList, [key]: selectedListUpdated});

                             //Set new data for update
                             const updatedEventList = eventListData.data
                                     .map((dataItem, index) => (eventListData.data[index] = eventListDataByKey[dataItem.id]))
                                     .filter(event => !(Object.keys(res).includes(`${event.id}`) && res[event.id].upcoming_status)),
                                 updatedListObj = {
                                     ...eventListData,
                                     data: updatedEventList,
                                     total_count: updatedEventList.length
                                 };

                             this._eventList.next({...updatedListObj, [key]: {...updatedListObj}});
                         }*/
                        return resolve(res);
                    } catch (err) {
                        console.error(err);
                    }

                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    getEventStreamer(eventId) {
        return DataFetcher.getJson(`events/${eventId}/${EVENTS.STREAMER_NAME.URL}`)
            .then(val => Promise.resolve(val.data))
            .catch(err => Promise.reject(err));
    }

    getDailyTotals(params) {
        const queryParams = {
            start: Helpers.toTimeStamp(Helpers.convertDateByTimeZone(startOfDay(new Date()).valueOf())),
            end: Helpers.toTimeStamp(Helpers.convertDateByTimeZone(endOfDay(new Date()).valueOf())),
            type: getEventType(params.type),
            without_pagination: 1,
        };

        return DataFetcher.getJson(`${this.eventListUrl}/${EVENTS.DAILY_TOTALS.URL}`, { queryParams })
            .then(val => {
                this._dailyTotals.next({ ...this._dailyTotals.getValue(), [params.type]: val.data });
                return Promise.resolve(val.data);
            })
            .catch(err => Promise.reject(err));
    }

    getMatchCounts(params, queryParams) {
        return DataFetcher.getJson(`${this.eventListUrl}/${EVENTS.MATCH_COUNTS.URL}`, { queryParams })
            .then(val => {
                this._matchCounts.next({ ...this._matchCounts.getValue(), [params.type]: val.data });
                return Promise.resolve(val.data);
            })
            .catch(err => Promise.reject(err));
    }

    stop() {
        this._eventList.next(null);
    }

    get eventsData() {
        return this._eventList;
    }

    get selectedEventsData() {
        return this._selectedEventList;
    }

    get retrievedEvent() {
        return this._retrievedEvent;
    }

    get dailyTotals() {
        return this._dailyTotals;
    }

    get matchCounts() {
        return this._matchCounts;
    }
}

export default new EventsDataService();
