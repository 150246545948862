import React from 'react';
import classnames from 'classnames';
import Collapser from "react-styled-collapser";
import { EVENT_TYPES } from '../../../constants/uiConstants';
import {
    ADMIN,
    CP_ADMINISTRATOR,
    CP_GROUP_MANAGER,
    FEDERATION_GROUP_COORDINATOR,
    LEAGUE_OWNER,
    MODERATOR
} from '../../../constants/userRoles';
import Helpers from '../../../helpers/helperFunctions';
import Button from "../button";
import Popover from "../popover";
import Menu from "../menu";
import DetailRow from "../detailRow";
import StreamActions from './parts/streamActions';
import { t } from '../../../helpers/translate';

import './eventCard.scss';

const EventCard = props => {
    const {
        time,
        date,
        region,
        league,
        team1,
        team2,
        event,
        onDelete,
        menuData,
        videoUrl,
        className,
        children,
        showVideo,
        hasVideo,
        openedMenu,
        onMenuClose,
        onMenuOpen,
        eventsType,
        toggleVideoPreview,
        getEventsDailyTotals,
    } = props;

    return (
        <div className={classnames('event-card-holder', className)}>
            {onDelete && (
                <div className="event-card-button-holder">
                    <Button
                        icon="icon-clear"
                        appearance="minimal"
                        size="small"
                        color="jaffa"
                        onClick={onDelete}
                        iconSize={18}
                    />
                </div>
            )}
            <ul className="ec-teams">
                <li>
                    <div className="ellipsis-text">{team1}</div>
                </li>
                <li>
                    <div className="vs-holder">{t('vs')}</div>
                </li>
                <li>
                    <div className="ellipsis-text">{team2}</div>
                </li>
            </ul>
            <div className="ec-details-holder">
                <ul
                    className={classnames('ec-details', {
                        'no-border-radius-bottom': showVideo
                    })}
                >
                    <li>
                        {time && (
                            <DetailRow
                                icon="icon-calendar"
                                text={
                                    <>
                                        <span>{time}</span> - {date}
                                    </>
                                }
                            />
                        )}
                        {region && (
                            <DetailRow
                                flag={region}
                                text={
                                    <>
                                        <span>{region}</span> - {league}
                                    </>
                                }
                            />
                        )}
                        {eventsType === EVENT_TYPES.UPCOMING && Helpers.checkRole(MODERATOR, ADMIN, LEAGUE_OWNER, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR) && (
                            <StreamActions
                                event={event}
                                onEventChange={() => getEventsDailyTotals()}
                            />
                        )}
                        {children}
                    </li>
                    {!!menuData?.length && (
                        <li>
                            <Popover
                                selfSizing
                                position="bottom-end"
                                opened={openedMenu}
                                onClose={onMenuClose}
                                closeOnClickOutside
                                content={
                                    <Menu
                                        onMenuItemClick={() => onMenuClose()}
                                        data={menuData}
                                    />
                                }
                            >
                                <Button
                                    icon="icon-more"
                                    appearance="minimal"
                                    color="default"
                                    onClick={onMenuOpen}
                                />
                            </Popover>
                        </li>
                    )}
                </ul>
                {hasVideo && (
                    <Collapser
                        collapsed={!showVideo}
                        className="full-width span-6"
                        duration={150}
                    >
                        <div className="event-video-preview-holder">
                            {showVideo && (
                                <iframe className="event-video-preview" src={videoUrl} title={`${team1} - ${team2}`} />
                            )}
                            <div className='event-video-preview-close'>
                                <Button
                                    appearance="minimal"
                                    color="default"
                                    iconSize={18}
                                    icon="icon-clear"
                                    size="small"
                                    onClick={toggleVideoPreview}
                                />
                            </div>
                        </div>
                    </Collapser>
                )}
            </div>
        </div>
    )
};

export default EventCard;
