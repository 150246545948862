import React from "react";
import {Field} from "formik";
import Input from "../../../../shared/input";
import { t } from '../../../../../helpers/translate';

const PersonalDetailsTab = (props) => {
    const {isEditing, errors, setFieldValue, syncFields, setTouched, touched, values} = props;
    return (
        <div className='form-grid col-2'>
            <Field
                name="email"
                render={({field}) => {
                    return (
                        <Input
                            label={t('Email')}
                            required
                            disabled={isEditing}
                            type='text'
                            placeholder={t('Enter email')}
                            value={values[field.name]}
                            onChange={(e) => {
                                setFieldValue(field.name, e.target.value);
                                setTouched({ ...touched, [field.name]: true });
                            }}
                            errorText={touched[field.name] && errors[field.name]}
                            labelAppearance="title"
                        />
                    )
                }}
            />
            <Field
                name="first_name"
                render={({field}) => (
                    <Input
                    label={t('First Name')}
                    required
                    type='text'
                    placeholder={t('Enter first name')}
                    value={values[field.name]}
                    onChange={(e) => {
                        setFieldValue(field.name, e.target.value);
                        setTouched({ ...touched, [field.name]: true })
                    }}
                    errorText={touched[field.name] && errors[field.name]}
                    labelAppearance="title"
                    />
                )}
            />
            <Field
                name="last_name"
                render={({field}) => (
                    <Input
                        type='text'
                        label={t('Last Name')}
                        placeholder={t('Enter last name')}
                        labelAppearance="title"
                        value={values[field.name]}
                        errorText={touched[field.name] && errors[field.name]}
                        onChange={(e) => {
                            setFieldValue(field.name, e.target.value);
                            setTouched({ ...touched, [field.name]: true })
                        }}
                    />
                )}
            />
            <Field
                name="phone"
                render={({field}) => (
                    <Input
                        value={field.value}
                        type="text"
                        onChange={(e) => {
                            if (!e.target.value || /^[0-9 ()+-]+$/g.test(e.target.value)) {
                                setTouched({...touched, [field.name]: true});
                                syncFields({setFieldValue, values}, e.target.value, 'phone', ['whatsapp']);
                            }
                        }}
                        placeholder={t('Enter phone number here')}
                        labelAppearance="title"
                        errorText={touched[field.name] && errors[field.name]}
                        label={t('Phone Number')}
                        required
                    />
                )}
            />
            <Field
                name="whatsapp"
                render={({field}) => (
                    <Input
                        value={field.value}
                        type="text"
                        onChange={(e) => setFieldValue('whatsapp', e.target.value)}
                        placeholder={t('Enter WhatsApp here')}
                        label={t('WhatsApp')}
                        labelAppearance="title"
                        errorText={touched[field.name] && errors[field.name]}
                    />
                )}
            />
        </div>
    );
};

export default PersonalDetailsTab;
