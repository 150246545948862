import React, {Component} from "react";
import PropTypes from "prop-types";
import { EVENTS_PAGE, REPORTING_PAGE } from "../../../../constants/pageRoutes";
import {exportData} from "../../../../actions/sync-actions/uiActions";
import componentDataProvider from "../../../../hocs/componentDataProvider";
import Button from "../../button";
import {FEDERATIONS_TABS_TYPES, UNIQUE_FEDERATION_TABS_TYPES} from "../../../../constants/uiConstants";
import { t } from "../../../../helpers/translate";

class ExportButton extends Component {
    static propTypes = {
        isDemo: PropTypes.bool,
        pathname: PropTypes.string,
        dispatch: PropTypes.func,
        eventsType: PropTypes.string,
        eventsData: PropTypes.object,
        isExportDisabled: PropTypes.bool
    };

    static requiredProps = {
        eventsData: PropTypes.object,
    };

    /**
     * @description Dispatch export action for current route.
     */
    handleExport = () => {
        const {pathname, eventsType, federationsTabType, uniqueFederationTabType} = this.props;
        const basePath = pathname.match(/\/[a-zA-Z]+/)[0];
        const extraParams = uniqueFederationTabType === UNIQUE_FEDERATION_TABS_TYPES.REPORTING ? {
            organization_ids: pathname.match(/\d+/)
        } : {};

        this.props.dispatch(exportData(
            basePath,
            (basePath !== REPORTING_PAGE
                && federationsTabType !== FEDERATIONS_TABS_TYPES.REPORTING
                && uniqueFederationTabType !== UNIQUE_FEDERATION_TABS_TYPES.REPORTING
            )
                && eventsType,
            extraParams)
        );
    };

    render () {
        const {isDemo, pathname, eventsData, eventsType, isExportDisabled} = this.props;
        const events = (eventsData[eventsType] && eventsData[eventsType].data) || [];
        const isDisabled = isDemo || (pathname === EVENTS_PAGE ? !events.length : isExportDisabled);

        return (
            <Button
                tooltip={t("Export")}
                onClick={this.handleExport}
                icon="icon-export"
                size="medium"
                appearance="light"
                disabled={isDisabled}
            />
        );
    }
}

export default componentDataProvider(ExportButton);
