import { useEffect } from 'react';

const useBeforeUnload = (callback) => {
    useEffect(() => {
        const handleBeforeunload = () => {
            typeof callback === 'function' && callback();
            return '';
        };

        window.addEventListener('beforeunload', handleBeforeunload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeunload);
        };
    }, [callback]);
};

export default useBeforeUnload;
