import { getFilterParamsKey } from "./filters";
import { EVENT_TYPES } from "./uiConstants";
import Helpers from "../helpers/helperFunctions";
import { MATCH_STATUSES } from "./statuses";
import { CLIENT, CLIENT_ADMIN, STREAMING_MANAGER, STREAMING_VIEWER } from "./userRoles";
import { startOfDay, addMonths, endOfDay, subMonths } from "date-fns";
import {GridConfig} from "../configs/gridConfig";
import { SCOUT_DATA } from './reducerKeys';

export const PER_PAGE_COUNT = 25;

export const getEventType = (type) => {
    switch (type) {
        case EVENT_TYPES.LIST:
            return 1;
        case EVENT_TYPES.HISTORY:
            return 2;
        case EVENT_TYPES.BOOKED:
        case EVENT_TYPES.UPCOMING:
            return 3;
        default:
            return 1;
    }
};

export const eventsLeagueDefaultParams = {
    includes: "league,league.streamer",
    leagueFields: "sport_id,default_streamer_id,countries_price,regions_price,default_price,blocked_countries",
    leagueStreamerFields: "first_name,last_name"
};

export const eventsDefaultParams = (key = EVENT_TYPES.LIST) => {
    const isClient = Helpers.checkRole(CLIENT, CLIENT_ADMIN, STREAMING_MANAGER, STREAMING_VIEWER);
    const timeByTimeZone = Helpers.getTimeZoneTime();
    const defaultParams = {
        offset: 0,
        limit: GridConfig.loadLimit,
        order_by: "start_date",
        start: parseInt(startOfDay(timeByTimeZone) / 1000),
        end: parseInt(endOfDay(addMonths(timeByTimeZone, 1)) / 1000),
        type: getEventType(key),
        ...(!isClient ? eventsLeagueDefaultParams : {}),
        order_direction: "asc"
    };

    if (key === EVENT_TYPES.HISTORY) {
        return {
            ...defaultParams,
            start: parseInt(startOfDay(subMonths(timeByTimeZone, 1)) / 1000),
            end: parseInt(endOfDay(timeByTimeZone) / 1000),
            order_direction: "desc"
        };
    }
    return defaultParams;
};

export const selectedEventsDefaultParams = (eventType) => {
    const actualMatchStatuses = eventType !== EVENT_TYPES.HISTORY
        ? [MATCH_STATUSES.NOT_STARTED, MATCH_STATUSES.STARTED].join() : [MATCH_STATUSES.CANCELED, MATCH_STATUSES.FINISHED].join();
    const isClient = Helpers.checkRole(CLIENT, CLIENT_ADMIN, STREAMING_MANAGER, STREAMING_VIEWER);

    const leagueParams = {
        ...eventsLeagueDefaultParams,
        leagueFields: "sport_id,default_streamer_id,countries_price,regions_price,default_price,blocked_countries"
    };

    return {
        match_statuses: actualMatchStatuses,
        ...(!isClient ? leagueParams : {})
    };
};

export const gameListDefaultParams = () => {
    return {
        offset: 0,
        limit: PER_PAGE_COUNT
    };
};

export const reportsDefaultParams = (isAreaChart = false) => {
    const params = {
        start: parseInt(startOfDay(subMonths(Helpers.getTimeZoneTime(), 1)) / 1000),
        end: parseInt(endOfDay(Helpers.getTimeZoneTime()) / 1000)
    };
    if (!isAreaChart) {
        params.offset = 0;
        params.limit = PER_PAGE_COUNT;
    }

    return params;
};

export const accountDefaultParams = () => {
    return {
        without_pagination: 1,
        start: parseInt(startOfDay(subMonths(Helpers.getTimeZoneTime(), 1)) / 1000),
        end: parseInt(endOfDay(Helpers.getTimeZoneTime()) / 1000),
        'order_by': 'id',
        'order_direction': 'desc'
    };
};

export const balanceHistoryDefaultParams = (params = {}) => {
    return {
        start: parseInt(startOfDay(subMonths(Helpers.getTimeZoneTime(), 1)) / 1000),
        end: parseInt(endOfDay(Helpers.getTimeZoneTime()) / 1000),
        includes: "event",
        'order_by': 'start_date',
        'order_direction': 'desc',
        ...params,
    };
};

export const withdrawHistoryDefaultParams = () => {
    return {
        start: parseInt(startOfDay(subMonths(Helpers.getTimeZoneTime(), 1)) / 1000),
        end: parseInt(endOfDay(Helpers.getTimeZoneTime()) / 1000),
        'order_by': 'id',
        'order_direction': 'desc'
    };
};

export const autoBookingRulesParams = () => {
    return {
        includes: "leagues,sport,regions",
        sportFields: "alias,name",
        leaguesFields: "name",
        regionsFields: "name",
        without_pagination: 1
    };
};

export const operatorsDefaultParams = () => {
    return {
        limit: PER_PAGE_COUNT,
        offset: 0,
    }
};

export const federationsDefaultParams = () => {
    return {
        limit: PER_PAGE_COUNT,
        offset: 0,
        order_by: 'created_at',
        order_direction: 'desc'
    }
};

export const groupFederationsDefaultParams = () => {
    return {
        limit: PER_PAGE_COUNT,
        offset: 0,
        without_pagination: 1,
    }
}

export const bookedMatchesDefaultParams = () => {
    const timeByTimeZone = Helpers.getTimeZoneTime();
    return {
        limit: PER_PAGE_COUNT,
        offset: 0,
        order_by: "start_date",
        order_direction: "asc",
        start: parseInt(startOfDay(timeByTimeZone) / 1000),
        end: parseInt(endOfDay(addMonths(timeByTimeZone, 1)) / 1000),
    }
};

export const templatesDefaultParams = () => {
   return {
       order_by: 'created_at',
       order_direction: 'desc',
       limit: PER_PAGE_COUNT,
       offset: 0,
   }
};

export const usersDefaultParams = () => {
    return {
        limit: PER_PAGE_COUNT,
        offset: 0
    }
};

export const scoutEventsDefaultParams = (key = EVENT_TYPES.LIST) => {
    const timeByTimeZone = Helpers.getTimeZoneTime();
    const defaultParams = {
        offset: 0,
        limit: GridConfig.loadLimit,
        start: parseInt(startOfDay(timeByTimeZone) / 1000),
        end: parseInt(endOfDay(addMonths(timeByTimeZone, 1)) / 1000),
        type: getEventType(key),
    };

    if (key === EVENT_TYPES.HISTORY) {
        return {
            ...defaultParams,
            start: parseInt(startOfDay(subMonths(timeByTimeZone, 1)) / 1000),
            end: parseInt(endOfDay(timeByTimeZone) / 1000),
        };
    }
    return defaultParams;
};

export const setQueryParams = (params, type = "events", key) => {
    let queryParams = {};
    switch (type) {
        case "events":
            queryParams = eventsDefaultParams(key);
            break;
        case "gameList":
            queryParams = gameListDefaultParams();
            break;
        case "reports":
            queryParams = reportsDefaultParams();
            break;
        case "account":
            queryParams = accountDefaultParams();
            break;
        case "balanceHistory":
            queryParams = balanceHistoryDefaultParams();
            break;
        case "financialReport":
            queryParams = balanceHistoryDefaultParams({offset: 0, limit: PER_PAGE_COUNT});
            break;
        case "withdrawHistory":
            queryParams = withdrawHistoryDefaultParams();
            break;
        case "autoBookingRules":
            queryParams = autoBookingRulesParams();
            break;
        case "operators":
            queryParams = operatorsDefaultParams();
            break;
        case "bookedMatches":
            queryParams = bookedMatchesDefaultParams();
            break;
        case "federations":
            queryParams = federationsDefaultParams();
            break;
        case "groupFederations":
            queryParams = groupFederationsDefaultParams();
            break;
        case "templates":
            queryParams = templatesDefaultParams();
            break;
        case "users":
        case "moderatorUsers":
            queryParams = usersDefaultParams();
            break;
        case SCOUT_DATA.EVENTS:
            queryParams = scoutEventsDefaultParams(key);
            break;
        default:
            break;
    }

    params && Object.assign(queryParams, params);
    return queryParams;
};

export const getQueryParams = (filters, filtersParams, type = "events", key) => {
    let filter, queryParams = { ...filtersParams }, inverseExcludeKey;

    Object.keys(filters).forEach(key => {
        filter = filters[key];

        if (typeof filter === "object" && filter !== null) {
            const { value, exclude } = filter;
            //Delete inverseKey if exists.
            inverseExcludeKey = getFilterParamsKey(key, !filter.exclude);
            if (inverseExcludeKey in queryParams) {
                delete queryParams[inverseExcludeKey];
            }

            if (value !== undefined && ((Array.isArray(value) && value.length) || !Array.isArray(value))) {
                queryParams[getFilterParamsKey(key, exclude)] = value.join();
            } else delete queryParams[getFilterParamsKey(key, exclude)];
        } else if (filter !== undefined) {
            const filterKey = getFilterParamsKey(key);

            typeof filter === "string" ? (queryParams[filterKey] = filter.toLowerCase()) : queryParams[filterKey] = filter;
            const shouldBeDeleted = queryParams[filterKey] === "" || queryParams[filterKey] === undefined || queryParams[filterKey] === null;
            shouldBeDeleted && delete queryParams[filterKey];
        } else {
            delete queryParams[key];
        }
    });

    delete queryParams.subKeys;
    return setQueryParams(queryParams, type, key);
};
