import React, { useReducer, Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import leagueDataService from "../../../services/leagueDataService";
import eventsDataService from "../../../services/eventsDataService";

import Input from "../input";
import NoData from "../noData";

import {updateSelectedEvents} from "../../../actions/async-actions/eventActions";
import {editLeague} from "../../../actions/async-actions/leagueActions";
import {closeRightPanel} from "../../../actions/sync-actions/uiActions";

import OverlayContent from "../../settings/rightPanel/overlayContent";
import noSearchDataImage from "../../../assets/media/search.svg";
import componentDataProvider from '../../../hocs/componentDataProvider';
import CountriesTreeLeaf from "./pieces/countriesTreeLeaf";


import { t } from "../../../helpers/translate";
import Helpers from "../../../helpers/helperFunctions";
import {calculateResultingCountriesPrices} from "../../../helpers/eventsHelper";

import {VIDEO_STREAMING_PRODUCT_ID} from "../../../constants/dataConstants";
import { ADMIN, MODERATOR, REPORTER } from "../../../constants/userRoles";

import './countriesTree.scss';

const initTreeState = () => {
    return {
        all: null,
        regions: {},
        countries: {}
    }
};

const calculatePrices = (league, event = {}) => {
    const {default_price: leagueDefaultPrice, regions_price: leagueRegionPrices, countries_price: leagueCountryPrices} = league;
    const {default_price: eventDefaultPrice, regions_price: eventRegionPrices, countries_price: eventCountryPrices} = event;
    return {
        all: eventDefaultPrice ?? leagueDefaultPrice ?? null,
        regions: {...(leagueRegionPrices || {}), ...(eventRegionPrices || {})},
        countries: {...(leagueCountryPrices || {}), ...(eventCountryPrices || {})}
    }
};

const calculateBlockedCountries = (countries, league, event = {}) => {
    const leagueBlockedCountries = league.blocked_countries || [];
    const eventBlockedCountries = event.blocked_countries || [];
    const initialBlockedCounties = [...leagueBlockedCountries, ...eventBlockedCountries].reduce((acc, val) => ({
        ...acc,
        [val]: true
    }), {});

    return {
        all: null,
        regions: {},
        countries: {...countries, ...initialBlockedCounties}
    }
};

const checkForCountryValueExistence = (regionsList, regionKey, treeState) => {
    return !!regionsList[regionKey].length && regionsList[regionKey].some(country => {
        return treeState.countries[country['iso3_code']] ?? treeState.regions[regionKey] ?? treeState.all
    });
};

const CountriesTree = (props) => {
    const {
        mode,
        eventId,
        leagueId,
        countriesGroupedList,
        countriesList,
        readOnly,
        dispatch,
        eventsType,
        title,
        subTitle,
        eventDetails,
        expressMode,
        initialData,
    } = props;

    const reducer = (state, action) => {
        if (mode === 'pricing') {
            switch (action.type) {
                case 'init':
                    return action.payload || {};
                case 'all':
                    return {
                        ...state,
                        [action.type]: action.payload,
                        regions: Object.keys(countriesGroupedList).reduce((acc, key) => ({...acc, [key]: action.payload}), {}),
                        countries: countriesList.reduce((acc, curr) => ({...acc, [curr['iso3_code']]: action.payload}), {})
                    };
                case 'regions':
                    return {
                        ...state,
                        [action.type]: {...state[action.type], [action.key]: action.payload},
                        countries: countriesGroupedList[action.key].reduce((acc, val) => ({...acc, [val['iso3_code']]: action.payload}), {})
                    };
                case 'countries':
                    return {
                        ...state,
                        [action.type]: {...state[action.type], [action.key]: action.payload}
                    };
                default:
                    throw new Error();
            }
        } else {
            switch (action.type) {
                case 'init':
                    return action.payload || {};
                case 'all': {
                    return {
                        ...state,
                        countries: action.payload ? countriesList.reduce((acc, val) => ({...acc, [val['iso3_code']]: true}), {}) : {}
                    }
                }
                case 'regions': {
                    const regionId = action.key;
                    const isSelected = action.payload;
                    const regionCountries = countriesGroupedList[regionId].map(val => val['iso3_code']);
                    const newCountries = Helpers.cloneDeep(state.countries);
                    regionCountries.forEach(countryKey => {
                        newCountries[countryKey] = isSelected
                    });
                    return {
                        ...state,
                        countries: newCountries
                    };
                }
                case 'countries':
                    return {
                        ...state,
                        [action.type]: {...state[action.type], [action.key]: action.payload}
                    };
                case 'update':
                    return {
                        ...state,
                        ...action.payload
                    };
                default:
                    throw new Error();
            }
        }
    };

    const [data, setData] = useState(initialData);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [showNoData, setShowNoData] = useState(false);
    const [filteredRegionsList, setFilteredRegionsList] = useState({});
    const [treeState, updateTreeState] = useReducer(reducer, { mode }, initTreeState);
    const {countries} = treeState;

    const stateChanged = useRef(false);

    useEffect(() => {
        leagueId && leagueDataService.getMyLeaguesByParams({ids: leagueId})
            .then(res => {
                setData(res?.data[0])
            });
        eventId && eventsDataService.getEventsByIds(eventId, {
            includes: "league",
            leagueFields: "sport_id,countries_price,regions_price,default_price,blocked_countries",
        })
            .then(res => {
                setData(res[0])
            })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mode === 'checking') {
            const regions = Object.keys(countriesGroupedList).reduce((acc, regionId) => {
                const isRegionChecked = countriesGroupedList[regionId].every(val => countries[val['iso3_code']]);
                return {...acc, [regionId]: isRegionChecked};
            }, {});
            const all = !!Object.keys(regions).length && Object.keys(regions).every(key => regions[key]);
            updateTreeState({type: 'update', payload: {all, regions}});
        }
    }, [mode, countries]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mode === 'checking') {
            const countries = countriesList.reduce((acc, val) => ({...acc, [val['iso3_code']]: false}), {});
            updateTreeState({type: 'init', payload: {all: false, regions: {}, countries}})
        }
    }, [countriesList, mode]);

    useEffect(() => {
        if (countriesGroupedList) {
            const regionsList = Object.keys(countriesGroupedList).reduce((acc, regionKey) => {
                const filteredCountries = searchKeyword ? (
                    countriesGroupedList[regionKey].filter(val => val['name'].toLowerCase().includes(searchKeyword.toLowerCase()))
                ) : (
                    countriesGroupedList[regionKey]
                );
                return filteredCountries.length ? {...acc, [regionKey]: filteredCountries} : acc;
            }, {});
            setShowNoData(!Object.keys(regionsList).length);
            setFilteredRegionsList(regionsList);
        }
    }, [searchKeyword, countriesGroupedList]);

    useEffect(() => {
        if (countriesGroupedList && data) {
            const event = data?.content_providers?.filter(provider => provider.product_id === VIDEO_STREAMING_PRODUCT_ID)[0];
            const {blockedCountries, countriesPrice, regionsPrice, defaultPrice} = eventDetails;
            const eventData = expressMode ? {blocked_countries: blockedCountries, regions_price: regionsPrice, default_price: defaultPrice, countries_price: countriesPrice} : event;
            const leagueData = expressMode ? {} : data.league;
            if (mode === 'pricing') {
                const newState = eventId ? calculatePrices(leagueData, eventData) : calculatePrices(data);
                updateTreeState({type: 'init', payload: newState})
            } else if (countriesList) {
                const newState = eventId ? calculateBlockedCountries(treeState.countries, leagueData, eventData) : calculateBlockedCountries(treeState.countries, data);
                updateTreeState({type: 'init', payload: newState});
            }
        }
    }, [countriesGroupedList, data, mode, countriesList]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSave = () => {
        const isModerator_isAdmin = Helpers.checkRole(MODERATOR, ADMIN);
        const eventIds = expressMode ? eventId : [eventId];
        const {eventsDetailsById} = eventDetails;
        if (mode === 'pricing') {
            const {all: default_price, regions: regions_price, countries: countries_price} = treeState;
            if (eventId) {
                // Update selected events pricing
                const countryPrices = calculateResultingCountriesPrices(countries_price, regions_price, default_price, countriesList, true);

                const params = eventIds.map((id) => {
                    const param = {
                        event_id: id,
                        countries_price: countryPrices,
                        default_price,
                        regions_price
                    };
                    if (isModerator_isAdmin) param.organization_id = eventsDetailsById[id].organizationId;
                    return param;
                });

                // TODO CHECK FOR MODERATOR
                dispatch(updateSelectedEvents(eventsType, params));
            } else { //Update league pricing
                dispatch(editLeague(
                    leagueId, {
                        countries_price,
                        default_price,
                        regions_price
                    }, true, t('Saved')));
            }
        } else {
            const blockedCountries = Object.keys(treeState.countries).filter(key => treeState.countries[key]);
            if (eventId) {
                const params = eventIds.map((id) => {
                    const param = {
                        event_id: id,
                        blocked_countries: blockedCountries
                    };
                    if (isModerator_isAdmin) param.organization_id = eventsDetailsById[id].organizationId;
                    return param;
                });
                // TODO CHECK FOR MODERATOR
                dispatch(updateSelectedEvents(eventsType, params));
            } else {
                dispatch(editLeague(leagueId, {blocked_countries: blockedCountries}));
            }
        }

        dispatch(closeRightPanel())
    };
    return (
        <OverlayContent
            title={title || t('Prices')}
            subTitle={subTitle || t('Choose your country')}
            primaryButton={!readOnly && !Helpers.checkRole(REPORTER) ? {
                color: 'confirm',
                disabled: !stateChanged.current,
                onClick: handleSave,
                name: t('Save'),
            } : null}
            secondaryButton={!readOnly && !Helpers.checkRole(REPORTER) ? {
                name: t('Cancel'),
                onClick: () => {
                    dispatch(closeRightPanel())
                },
            } : null}
            appearance="fit"
        >
            <div className="full-width">
                <div className="tree-search-holder">
                    <Input
                        placeholder={t('Search')}
                        type="search"
                        labelAppearance="none"
                        value={searchKeyword}
                        onChange={e => setSearchKeyword(e.target.value)}
                    />
                </div>
                {!showNoData && (
                    <div className="tree-branches-holder">
                        { (readOnly && Object.keys(filteredRegionsList).map(regionKey => checkForCountryValueExistence(filteredRegionsList, regionKey, treeState))) || (
                            <CountriesTreeLeaf
                                highlighted
                                name={t('All countries')}
                                mode={mode}
                                value={treeState.all ?? ''}
                                readOnly={readOnly || Helpers.checkRole(REPORTER)}
                                onChange={({value}) => {
                                    stateChanged.current = true;
                                    updateTreeState({type: 'all', payload: value})
                                }}
                            />
                        )}
                        {Object.keys(filteredRegionsList).map(regionKey => {
                            if (readOnly && !checkForCountryValueExistence(filteredRegionsList, regionKey, treeState)) return null;

                            return !!filteredRegionsList[regionKey].length && (
                                <Fragment key={regionKey}>
                                    <CountriesTreeLeaf
                                        highlighted
                                        name={countriesGroupedList[regionKey][0].region_name}
                                        flag={countriesGroupedList[regionKey][0].region_name}
                                        mode={mode}
                                        value={
                                            treeState.regions[regionKey] ??
                                            treeState.all ?? ''
                                        }
                                        readOnly={readOnly || Helpers.checkRole(REPORTER)}
                                        onChange={({value}) => {
                                            stateChanged.current = true;
                                            updateTreeState({
                                                type: 'regions',
                                                key: regionKey,
                                                payload: value
                                            })
                                        }}
                                    />
                                    {filteredRegionsList[regionKey].map(country => {
                                        const value = treeState.countries[country['iso3_code']] ?? treeState.regions[regionKey] ?? treeState.all ?? '';
                                        if (readOnly && !value) return null;
                                        return (
                                            <CountriesTreeLeaf
                                                key={`country_${country['iso3_code']}`}
                                                name={country.name}
                                                flag={country.name}
                                                mode={mode}
                                                value={value}
                                                readOnly={readOnly || Helpers.checkRole(REPORTER)}
                                                onChange={({value}) => {
                                                    stateChanged.current = true;
                                                    updateTreeState({
                                                        type: 'countries',
                                                        key: country['iso3_code'],
                                                        payload: value
                                                    })
                                                }}
                                            />
                                        )
                                    })}
                                </Fragment>
                            )
                        })}
                    </div>
                )}
            </div>
            {showNoData && (
                <div className="tree-no-result-holder">
                    <NoData
                        transparent
                        size="medium"
                        image={noSearchDataImage}
                        text={t('And then there were none')}
                    />
                </div>
            )}
        </OverlayContent>
    )
};

CountriesTree.propTypes = {
    mode: PropTypes.oneOf(['pricing', 'checking']).isRequired,
    leagueId: PropTypes.number,
    eventId: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.object,
    eventsType: PropTypes.string,
    countriesGroupedList: PropTypes.object,
    countriesList: PropTypes.array,
    readOnly: PropTypes.bool,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    initialData: PropTypes.any,
};

CountriesTree.defaultProps = {
    mode: 'pricing'
};

CountriesTree.requiredProps = {
    countriesGroupedList: PropTypes.object,
    countriesList: PropTypes.array
};

export default componentDataProvider(CountriesTree);
