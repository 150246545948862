import React, {Component} from "react";
import PropTypes from "prop-types";
import {Cookies} from "react-cookie";
import ModalContent from "./content";
import Icon from "../../icon";
import { t } from "../../../../helpers/translate";

class Message extends Component {
    static propTypes = {
        closePopup: PropTypes.func,
        popupParams: PropTypes.object
    };

    /**
     * @name handelConfirmButton
     * @description When handle the confirm button work call back and close popup
     * @return {undefined}
     * */
    handleConfirmButton = () => {
        const {popupParams, closePopup} = this.props;
        popupParams.handleCallBack && popupParams.handleCallBack();
        (popupParams.closeOnOk === undefined || popupParams.closeOnOk) && closePopup();
    };

    /**
     * @name selectDontShowAgain
     * @param {object} e
     * @description In cookie saved type popup that don`t opened again
     * @return {undefined}
     * */
    selectDontShowAgain = (e) => {
        const now = new Date();
        const cookies = new Cookies();
        cookies.set(this.props.popupParams.dontShowAgainType, e.target.checked, {
            path: "/",
            expires: new Date(now.setMonth(now.getMonth() + 3))
        });
    };

    /**
     * @name handleKeyPress
     * @param {object} e
     * @description Worked when popup is opened and pressing key
     * @return {undefined}
     * */
    handleKeyPress = (e) => {
        (e.keyCode === 13) && this.handelConfirmButton();
    };

    /**
     * @description Handles popup close buttons callback function if exists
     */
    handleClose = () => {
        const {popupParams, closePopup} = this.props;
        closePopup();
        popupParams.handleCloseCallBack && popupParams.handleCloseCallBack();
    };

    render () {
        const {popupParams} = this.props;
        return (
            <ModalContent
                dontShowAgain={!!popupParams.dontShowAgainType}
                onDontShowAgain={this.selectDontShowAgain}
                secondaryButton={{
                    onClick: this.handleClose,
                    ...popupParams.secondaryButton
                }}
                primaryButton={{
                    onClick: this.handleConfirmButton,
                    ...popupParams.primaryButton
                }}
            >
                <ul className="modal-message-holder">
                    <li>
                        <Icon type={`icon-${popupParams.classification}`} size={48} />
                    </li>
                    <li>
                        {t(popupParams.message)}
                    </li>
                </ul>
            </ModalContent>
        );
    }

    /**
     * @name componentDidMount
     * @description when component mount then add event listener with keydown type
     * @return {undefined}
     * */
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    /**
     * @name componentWillUnmount
     * @description when component unmount then remove event listener with keydown type
     * @return {undefined}
     * */
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
}

export default Message;
