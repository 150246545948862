import { t } from "../helpers/translate";

export const LEAGUE_OWNER = 1;
export const CONTENT_CREATOR = 2;
export const STREAMER = 3;
export const MODERATOR = 4;
export const ADMIN = 5;
export const CLIENT = 6;
export const MEDIA_COMPANY = 7;
export const REPORTER = 8;
export const SCOUT_OPERATOR = 9;
export const SCOUT_TRADER = 10;
export const CLIENT_ADMIN = 11;
export const STREAMING_MANAGER = 12;
export const STREAMING_VIEWER = 13;
export const CP_ADMINISTRATOR = 15;
export const CP_GROUP_MANAGER = 16;
export const FEDERATION_GROUP_COORDINATOR = 17;

export const getRoleName = (role) => {
    const roleNames = {
        [LEAGUE_OWNER]: t("Content Provider"),
        [CONTENT_CREATOR]: t("Content Creator"),
        [STREAMER]: t("Streamer"),
        [MODERATOR]: t("Moderator"),
        [ADMIN]: t("Administrator"),
        [CLIENT]: t("Client"),
        [MEDIA_COMPANY]: t("Media Company"),
        [REPORTER]: t("Reporter"),
        [SCOUT_OPERATOR]: t("Scout Operator"),
        [SCOUT_TRADER]: t("Scout Trader"),
        [CLIENT_ADMIN]: t("Client Admin"),
        [STREAMING_MANAGER]: t("Streaming Manager"),
        [STREAMING_VIEWER]: t("Streaming Viewer"),
        [CP_GROUP_MANAGER]: t("Federation Group Manager"),
        [CP_ADMINISTRATOR]: t("CP Administrator"),
        [FEDERATION_GROUP_COORDINATOR]: t("Federation Group Coordinator"),
    };
    return role ? roleNames[role] : roleNames;
};
