export const EVENTS_PAGE = "/events";
export const LEAGUES_PAGE = "/leagues";
export const REPORTING_PAGE = "/reporting";

export const FEDERATIONS_PAGE = "federations";
export const OPERATORS_PAGE = "operators";

export const ALLOWED_ROLES_KEY_BY_PROVIDER = {
    '/streaming': 'allowedForStreaming',
    '/scouting': 'allowedForScouting',
};
