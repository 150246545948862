import React, { useState } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import Input from "../input";
import Popover from "../popover";
import Helpers from "../../../helpers/helperFunctions";
import { t } from "../../../helpers/translate";

import './timePicker.scss';

const TimePicker = props => {
    const {
        appearance,
        className,
        cornerRadius,
        description,
        disabled,
        errorText,
        icon,
        itemsDirection,
        label,
        labelAppearance,
        onChange,
        placeholder,
        required,
        size,
        value,
    } = props;

    const dayInHours = 24, minutes = 60;
    const [selectedHour, setSelectedHour] = useState(value ? value.split(" : ")[0] : 0);
    const [selectedMinute, setSelectedMinute] = useState(value ? value.split(" : ")[1] : 0);

    return (
        <Popover
            content={
                <ul className="time-picker-holder">
                    <li>
                        {Array(dayInHours).fill().map((_, index) => (
                            <div
                                key={index}
                                className={classnames('time-picker-item', {'active': index === selectedHour})}
                                onClick={() => {
                                    setSelectedHour(index);
                                    onChange && onChange(Helpers.getTime(index, selectedMinute));
                                }}
                            >
                                {index}
                            </div>
                        ))}
                    </li>
                    <li>
                        {Array(minutes).fill().map((_, index) => (
                            <div
                                key={index}
                                className={classnames('time-picker-item', {'active': index === selectedMinute})}
                                onClick={() => {
                                    setSelectedMinute(index);
                                    onChange && onChange(Helpers.getTime(selectedHour, index));
                                }}
                            >
                                {Helpers.constructTime(index)}
                            </div>
                        ))}
                    </li>
                </ul>
            }
        >
            <Input
                appearance={appearance}
                className={className}
                cornerRadius={cornerRadius}
                description={description}
                disabled={disabled}
                errorText={t(errorText)}
                icon={icon}
                itemsDirection={itemsDirection}
                label={t(label)}
                labelAppearance={labelAppearance}
                placeholder={t(placeholder)}
                readOnly
                required={required}
                size={size}
                value={Helpers.getTime(selectedHour, selectedMinute)}
            />
        </Popover>
    )
};

TimePicker.defaultProps = {
    appearance: Input.defaultProps.appearance,
    className: Input.defaultProps.className,
    cornerRadius: Input.defaultProps.cornerRadius,
    description: Input.defaultProps.description,
    disabled: Input.defaultProps.disabled,
    errorText: Input.defaultProps.errorText,
    icon: 'icon-clock',
    itemsDirection: 'end',
    label: 'Choose Time',
    labelAppearance: Input.defaultProps.labelAppearance,
    onChange: null,
    placeholder: Input.defaultProps.placeholder,
    required: Input.defaultProps.required,
    size: Input.defaultProps.size,
    value: null,
};

TimePicker.propTypes = {
    appearance: Input.propTypes.appearance,
    className: Input.propTypes.className,
    cornerRadius: Input.propTypes.cornerRadius,
    description: Input.propTypes.description,
    disabled: Input.propTypes.disabled,
    errorText: Input.propTypes.errorText,
    icon: Input.propTypes.icon,
    itemsDirection: Input.propTypes.itemsDirection,
    label: Input.propTypes.label,
    labelAppearance: Input.propTypes.labelAppearance,
    onChange: PropTypes.func,
    placeholder: Input.propTypes.placeholder,
    required: Input.propTypes.required,
    size: Input.propTypes.size,
    value: PropTypes.any,
};

export default TimePicker;
