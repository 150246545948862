import { useEffect, useRef, useState } from 'react';
import { DROP_DOWN_CONFIG } from "../configs/uiConfig";

const useDataFetcher = (service, requestParams = {}, updateData, additionalParams, serviceAdditionalParams = {}) => {
    const {
        isLazy = true,
        limitCount = DROP_DOWN_CONFIG.loadLimit,
        initialRequestParams,
        onInitialDataUpdate,
        isOpened,
        requestOnOpen
    } = additionalParams;
    const limits = useRef({offset: 0, limit: limitCount});
    const [totalCount, setTotalCount] = useState(null);
    const isLoading = useRef(null);

    const fetchInitialData = () => {
        if (service && typeof service === 'function' && initialRequestParams && onInitialDataUpdate && typeof onInitialDataUpdate === 'function') {
            service({
                ...initialRequestParams,
                ...(!isLazy ? {without_pagination: true} : {})
            }, serviceAdditionalParams)
                .then(res => {
                    onInitialDataUpdate(res.data);
                })
        }
    };

    const fetchData = (reset = false) => {
        if (service && typeof service === 'function') {
            isLoading.current = true;
            service({
                ...requestParams,
                ...(isLazy ? limits.current : {without_pagination: true}),
                order_by: 'name'
            }, serviceAdditionalParams)
                .then(res => {
                    reset
                        ? updateData(res.data)
                        : updateData(oldData => [...(oldData || []), ...res.data]);
                    setTotalCount(res.total_count || res.data.length)
                })
                .finally(() => {
                    isLoading.current = false;
                })
        }

    };

    const loadMore = (reset) => {
        if ((!isLoading.current  || reset) && isLazy) {
            let newLimits = null;
            if (reset) {
                newLimits = {offset: 0, limit: limitCount};
                limits.current = {...limits.current, ...newLimits};
            } else {
                limits.current = {
                    ...limits.current,
                    offset: limits.current.offset + limits.current.limit,
                    limit: Math.min(limitCount, Math.abs(totalCount - limits.current.offset - limits.current.limit))
                }
            }
            fetchData(reset);
        }
    };

    useEffect(() => {
        fetchInitialData()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoading.current !== null && (!requestOnOpen || isOpened)) {
            loadMore(true);
        }
    }, [JSON.stringify(requestParams)]); // eslint-disable-line react-hooks/exhaustive-deps

    return [loadMore, totalCount]
};

export default useDataFetcher;
