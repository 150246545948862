import {OPEN_POPUP, CLOSE_POPUP} from "../actions/action-types";

const PopupReducer = (state = [], action) => {
    switch (action.type) {
        case OPEN_POPUP:
            if (action.payload === "settings" && state.find(popup => popup.popupType === 'settings')) return state;
            return [
                ...state,
                {
                    popupType: action.payload,
                    popupParams: action.meta
                }
            ];
        case CLOSE_POPUP:
            return state.filter((el, index) => index !== state.length - 1);
        default:
            return state;
    }
};

export default PopupReducer;
