import React, {useState} from "react";
import PropTypes from "prop-types";
import componentDataProvider from "../../../../hocs/componentDataProvider";
import ModalContent from "./content";
import Input from "../../input";
import DropDown from "../../dropDown";
import {REPORTING_EMAIL_PERIODS} from "../../../../constants/dataConstants";
import {addReportingEMail} from "../../../../actions/async-actions/reporting";
import { useDispatch } from "react-redux";
import { t } from '../../../../helpers/translate';

const AddRecipient = ({ closePopup }) => {
    const [eMailInputValue, setEmailInputValue] = useState('');
    const [eMailPeriod, setEmailPeriod] = useState(REPORTING_EMAIL_PERIODS[0]);

    const dispatch = useDispatch();

    const addReportingEmail = () => {
        dispatch(addReportingEMail({
            email: eMailInputValue,
            frequency: eMailPeriod.id
        }));
        setEmailInputValue('');
        closePopup();
    };

    return (
        <ModalContent
            primaryButton={{
                name: t('Add'),
                onClick: addReportingEmail,
                color: 'confirm',
                disabled: !eMailInputValue
            }}
            secondaryButton={{
                name: t('Cancel'),
                onClick: closePopup
            }}
        >
            <div className="form-grid col-2">
                <Input
                    label={t('E-Mail')}
                    labelAppearance="title"
                    placeholder={t('Enter E-Mail here')}
                    onChange={(e) => setEmailInputValue(e.target.value)}
                    value={eMailInputValue}
                />
                <DropDown
                    multiSelect={false}
                    withLazyLoad={false}
                    searchable={false}
                    externalData={REPORTING_EMAIL_PERIODS}
                    label={t('Report Frequency')}
                    labelAppearance="title"
                    placeholder={t('Select time format')}
                    initialSelections={REPORTING_EMAIL_PERIODS[0]}
                    onChange={setEmailPeriod}
                />
            </div>
        </ModalContent>
    );
};

AddRecipient.propTypes = {
    closePopup: PropTypes.func,
};

export default componentDataProvider(AddRecipient);

