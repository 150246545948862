import React, { useState } from "react";
import classnames from 'classnames';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';

import "./fileInput.scss";

const getFileExtension = (file) => {
    const extension = file?.path?.match(/\.[0-9a-z]{1,5}$/i)[0] || file?.type?.match(/\/[0-9a-z]{1,5}$/i)?.[0];
    return extension?.slice(1);
};

const FileInput = ({ initialFile, accepts, name, label, onChange, className }) => {
    const [file, setFile] = useState(initialFile);
    const [loading, setLoading] = useState(false);

    const fileExtension = getFileExtension(file);

    const onDrop = (e) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append(name, new Blob([selectedFile], { type: selectedFile.type }), selectedFile.name || 'file');

        setFile(selectedFile);
        onChange && onChange(selectedFile);
        setLoading(false);
    };

    const removeFile = (e) => {
        e.stopPropagation();
        setFile(null);
        onChange && onChange(undefined);
    };

    const placeholder = `Select file for ${label.toLowerCase() || 'upload'}`;
    const value = file?.original_name || file?.name;

    return (
        <ul
            className={classnames(
                `file-input-holder`,
                `external-hover`,
                className,
            )}
        >
            <li onDrop={onDrop}>
                {!!file && (
                    <div className="fi-left fi-action-holder">
                        {fileExtension}
                    </div>
                )}
                <Input
                    type='text'
                    labelAppearance="title"
                    placeholder={placeholder}
                    icon={loading ? 'icon-spinner' : file ? 'icon-file-type' : ''}
                    value={file ? value : ''}
                    label={label}
                    title={file ? value : placeholder}
                    readOnly
                />
            </li>
            <li>
                <input
                    type="file"
                    accept={accepts ? accepts.join() : ''}
                    onChange={onDrop}
                />
                {!!file && (
                    <div
                        className='fi-right fi-action-holder'
                        onClick={removeFile}
                    >
                        <Button
                            size="medium"
                            appearance='minimal'
                            color='default'
                            icon='icon-clear'
                        />
                    </div>
                )}
            </li>
        </ul>
    );
};

export default FileInput;
