import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { closeRightPanel, setUserEditId } from "../../../../actions/sync-actions/uiActions";
import { createUser, updateUser } from "../../../../actions/async-actions/userAction";

import {
    ADMIN,
    CLIENT_ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR,
    LEAGUE_OWNER,
    MODERATOR,
    SCOUT_OPERATOR,
    SCOUT_TRADER,
    STREAMING_MANAGER,
    STREAMING_VIEWER
} from "../../../../constants/userRoles";
import OverlayContent from "../../rightPanel/overlayContent";
import usersDataService from "../../../../services/usersDataService";

import Tabs from "../../../shared/tabs";
import Tab from "../../../shared/tab";

import PersonalDetailsTab from "./parts/personalDetailsTab";
import RolesTab from "./parts/rolesTab";

import Helpers from "../../../../helpers/helperFunctions";
import { t } from '../../../../helpers/translate';

const validate = (values) => {
    return Object.keys(values)
        .reduce((acc, curr) => {
            switch (curr) {
                case 'email': {
                    if (!values.email) {
                        acc[curr] = t('Email is required');
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        acc[curr] = t('Invalid email address');
                    }
                    return acc;
                }
                case 'phone': {
                    if ((values[curr].length === 1 && values[curr][0] === '+') || !values[curr]) {
                        acc[curr] = t('Phone number is required');
                    } else if ((values[curr].length < 9 || values[curr].length >= 15) && /^\+[0-9 ()-]+$/g.test(values[curr])) {
                        acc[curr] = t('Invalid phone number');
                    }
                    return acc;
                }
                case 'first_name': {
                    !values[curr].length && (acc[curr] = t('First name is required'));
                    return acc;
                }
                case 'federation_groups': {
                    if (values?.roles[0].id === FEDERATION_GROUP_COORDINATOR || values?.roles[0].id === CP_GROUP_MANAGER) {
                        !values[curr].name && !values[curr].id && (acc[curr] = t('This role required select group'));
                    }
                    return acc;
                }
                default:
                    return acc;
            }
        }, {});
};

const initialState = {
    email: '',
    first_name: '',
    last_name: '',
    address: '',
    phone: '',
    whatsapp: '',
    skype: '',
    roles: [],
    federation_groups: {},
};

const UserForm = (props) => {
    const { type, adminsCreation } = props;
    const isEditing = type === 'editUserForm';
    const createTitle = {
        title: t('Create New User'),
        subTitle: t('Fill the forms, set roles, add permissions to create a new user')
    };
    const editTitle = {
        title: t('Edit User'),
        subTitle: t('Fill the forms, set roles, add permissions to create a new user')
    };
    const pageTitle = isEditing ? editTitle : createTitle;
    const [selectedTab, setSelectedTab] = useState('personalDetailsForm');
    const [userData, setUserData] = useState({});

    const dispatch = useDispatch();
    const { userEditId } = useSelector(createSelector(
        [state => state.ui.userEditId],
        (userEditId) => ({ userEditId })
    ));

    useEffect(() => {
        let subscription;
        if (userEditId) {
            subscription = (!adminsCreation ? usersDataService.usersList : usersDataService.moderatorUsers)
                .subscribe((res) => {
                    setUserData(res.data.find(user => user.id === userEditId));
                });
        }
        return () => subscription && subscription.unsubscribe();
    }, [userEditId, adminsCreation]);

    useEffect(() => {
        return () => dispatch(setUserEditId(null));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initialUserData = Object.keys(initialState).reduce((acc, curr) => {
        if (curr === 'roles') {
            return {
                ...acc,
                [curr]: (userData[curr] && userData[curr].map(role => ({ id: role.id }))) || initialState[curr]
            };
        }
        return { ...acc, [curr]: userData[curr] || initialState[curr] };
    }, {});

    const getSelectedRoles = (value, selectedRoles, uniqueRoles, prevSelectedRole = {}) => {
        if (selectedRoles.some(role => uniqueRoles.includes(role.id)) || uniqueRoles.includes(value.id)) {
            return selectedRoles.find(role => role.id === value.id) ? [] : [value];
        }

        if (selectedRoles.find(role => role.id === value.id)) {
            return selectedRoles.filter(role => role.id !== value.id);
        }

        return [...selectedRoles.filter(role => prevSelectedRole.id !== role.id), value];
    };

    const setSwitchState = (value, values, setFieldValue) => {
        const selectedRoles = values.roles;
        const uniqueRoles = [ADMIN, MODERATOR, LEAGUE_OWNER, CLIENT_ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER];
        const streamingRoles = [STREAMING_MANAGER, STREAMING_VIEWER];
        const scoutingRoles = [SCOUT_OPERATOR, SCOUT_TRADER];

        const roles = streamingRoles.includes(value.id) ? streamingRoles : scoutingRoles.includes(value.id) ? scoutingRoles : [];
        const selection = selectedRoles.find(role => roles.includes(role.id));
        const selections = getSelectedRoles(value, selectedRoles, uniqueRoles, selection);

        setFieldValue('roles', selections);
    };

    const syncFields = ({ values, setFieldValue }, value, name, syncFieldName) => {
        let val = value.includes('+') ? value : `+${value}`;
        if (values[name].length === 1 && values[name][0] === "+" && !value.length) val = '';
        if (values[name] === values[syncFieldName]) {
            setFieldValue(syncFieldName, val);
        }
        setFieldValue(name, val);
    };

    const handleSubmit = (values) => {
        if (type === 'createUserForm') return dispatch(createUser(values));
        if (userData.email === values.email) delete values.email;
        return dispatch(updateUser(userEditId, values));
    };

    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
    };

    return (
        <Formik
            initialValues={Object.keys(initialUserData) ? initialUserData : initialState}
            enableReinitialize
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={() => {
            }}
            validate={validate}
            render={({ isValid, errors, values, setFieldValue, touched, setTouched }) => {
                return (
                    <OverlayContent
                        title={pageTitle.title}
                        subTitle={pageTitle.subTitle}
                        appearance="fit"
                        secondaryButton={{
                            name: t('Cancel'),
                            onClick: () => dispatch(closeRightPanel()),
                        }}
                        primaryButton={{
                            name: selectedTab === 'rolesForm' ? (isEditing ? 'Save' : 'Invite') : 'Next',
                            color: !isEditing ? 'confirm' : 'primary',
                            onClick: () => {
                                if (selectedTab === 'rolesForm') return handleSubmit(values);

                                const tab = selectedTab === 'personalDetailsForm' ? 'rolesForm' : 'personalDetailsForm';
                                setSelectedTab(tab);
                            },
                            disabled: selectedTab === 'rolesForm' && (!isValid || !values.roles.length || (isEditing && Helpers.isEqual(initialUserData, values)))
                        }}
                    >
                        <Tabs appearance="inline" selectedTabId={selectedTab} onChange={handleTabChange}>
                            <Tab title={t('Personal Details')} id="personalDetailsForm">
                                <PersonalDetailsTab
                                    isEditing={isEditing}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    syncFields={syncFields}
                                    touched={touched}
                                    setTouched={setTouched}
                                    values={values}
                                />
                            </Tab>
                            <Tab title={t('User Roles')} id="rolesForm">
                                <RolesTab
                                    setSwitchState={setSwitchState}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    adminsCreation={adminsCreation}
                                />
                            </Tab>
                        </Tabs>
                    </OverlayContent>
                );
            }}>
        </Formik>
    );
};

export default UserForm;
