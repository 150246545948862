import {
    DATA_LOAD_START,
    DATA_LOAD_DONE,
    DATA_LOAD_FAIL,
    DATA_LOAD_RESET
} from "../action-types";

export const dataLoadStart = (reducerKey, payload, meta) => {
    return {
        type: DATA_LOAD_START,
        reducerKey,
        payload,
        meta
    };
};

export const dataLoadDone = (reducerKey, payload, meta) => {
    return {
        type: DATA_LOAD_DONE,
        reducerKey,
        payload,
        meta
    };
};

export const dataLoadFail = (reducerKey, payload, meta) => {
    return {
        type: DATA_LOAD_FAIL,
        reducerKey,
        payload,
        meta
    };
};

export const dataLoadReset = (reducerKey, payload) => {
    return {
        type: DATA_LOAD_RESET,
        reducerKey,
        payload
    };
};
