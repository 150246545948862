import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { VIDEO_STREAMING_PRODUCT_ID } from '../../../constants/dataConstants';
import { DEMO_STREAMS, SUBSCRIBE } from '../../../constants/socket';
import { getProductIframeURL } from '../../../helpers/eventsHelper';
import Socket from '../../../helpers/socket';
import Loader from '../../reusableUIComponents/loader';
import Button from '../../shared/button';
import { t } from "../../../helpers/translate";

const EventVideoPreview = (props) => {
    const { event, isPlaying, onClose } = props;
    const { team_1_name: team1, team_2_name: team2 } = event;

    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [playing, setPlaying] = useState(isPlaying);

    useEffect(() => {
        if (playing) {
            getVideoUrl();
        }
    }, [playing]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        Socket.onEvent(DEMO_STREAMS, (res) => {
            if (Number(res.streamId) === Number(event.id)) {
                setVideoUrl(res.url);
            }
        });
        return () => {
            Socket.unsubscribe({ name: DEMO_STREAMS, streamId: event.id })
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getVideoUrl = () => {
        if (loading) return;
        setLoading(true);
        getProductIframeURL(VIDEO_STREAMING_PRODUCT_ID, event)
            .then(previewUrl => {
                Socket.sendRequest(SUBSCRIBE, { name: DEMO_STREAMS, streamId: event.id });
                setLoading(false);
                setVideoUrl(`${previewUrl}&demo=true`);
            }).catch((e) => {
                setLoading(false);
        });
    };

    return (
        <div className="event-video-preview-holder">
            {!!videoUrl ? (
                <iframe
                    className="event-video-preview"
                    src={videoUrl}
                    title={`${team1} - ${team2}`}
                />
            ) : (
                <div className='event-video-loader-holder'>
                    {loading ? (
                        <Loader/>
                    ) : !playing ? (
                        <>
                            <Button
                                appearance="default"
                                color="contrast"
                                iconSize={24}
                                icon="icon-play"
                                size="big"
                                onClick={() => {
                                    setPlaying(true);
                                }}
                            />
                            <p>{t("You are about to watch a demo video for 2 minutes")}</p>
                        </>
                    ) : null}
                </div>
            )}
            {!!onClose && (
                <div className='event-video-preview-close'>
                    <Button
                        appearance="minimal"
                        color="default"
                        iconSize={18}
                        icon="icon-clear"
                        size="small"
                        onClick={() => onClose(event.id)}
                    />
                </div>
            )}
        </div>
    );
};

EventVideoPreview.propTypes = {
    event: PropTypes.object,
    isPlaying: PropTypes.bool,
    onClose: PropTypes.func,
};

export default EventVideoPreview;
