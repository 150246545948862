import {SELECT_AUTOBOOKING_RULE,
    TOGGLE_AUTOBOOKING_RULES,
    CURRENTLY_EDITING_RULE_ID,
    SET_MULTIPLE_AUTOBOOKING_RULES,
    CLEAR_AUTOBOOKING_RULES,
} from "../action-types";

export const selectAutobookingRule = (key, payload) => {
    return {
        type: SELECT_AUTOBOOKING_RULE,
        key,
        payload
    };
};

export const SetMultipleAutobookingRules = (key, payload) => {
    return {
        type: SET_MULTIPLE_AUTOBOOKING_RULES,
        key,
        payload
    };
};

export const ClearAutobookingRules = (key, payload) => {
    return {
        type: CLEAR_AUTOBOOKING_RULES,
        key,
        payload
    };
};

export const toggleAutobookingRulesSection = () => {
    return {
        type: TOGGLE_AUTOBOOKING_RULES
    };
};

export const setAutobookingRuleEditId = (payload) => {
    return {
        type: CURRENTLY_EDITING_RULE_ID,
        payload
    };
};
