
import {
    SET_EVENT_HOVER_DATA,
    CLEAR_ALL_SELECTED_EVENTS,
    UPDATE_SELECTED_EVENTS
} from "../actions/action-types";

const EventDataReducer = (state = {
    selectedIds: {
        list: [], upcoming: [], booked: [], history: []
    }
}, action = {}) => {
    switch (action.type) {
        case UPDATE_SELECTED_EVENTS: {
            return {
                ...state,
                selectedIds: {
                    ...state.selectedIds,
                    [action.key]: action.payload
                }
            };
        }
        case CLEAR_ALL_SELECTED_EVENTS:
            return {
                ...state,
                selectedIds: {
                    ...state.selectedIds,
                    [action.key]: []
                }
            };
        case SET_EVENT_HOVER_DATA:
            return {
                ...state,
                hoveredEvent: action.payload
            };
        default:
            return state;
    }
};

export default EventDataReducer;
