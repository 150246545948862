import { BehaviorSubject } from "rxjs";
import { productIsBooked } from '../helpers/eventsHelper';
import DataFetcher from "./dataFetcherService";
import { BOOKINGS, MY_GAMES } from "../constants/api-resources";
import eventsDataService from './eventsDataService';
import usersDataService from './usersDataService';
import { share } from 'rxjs/operators';
import EventsDataService from './eventsDataService';
import Helpers from "../helpers/helperFunctions";
import { getEventType, setQueryParams } from "../constants/queryParams";
import { EVENT_TYPES } from "../constants/uiConstants";

class BookingDataService {
    constructor() {
        this._bookEvents = {};
        this._autoBookingRules = new BehaviorSubject();
    }

    /**
     * @description Get booking events cart calculation.
     * @param params
     * @param productId
     * @returns {*}
     */
    getBookingEvents(params, productId) {
        return DataFetcher.putJson(BOOKINGS.EVENTS.URL, {params})
            .then(res => {
                this._bookEvents[productId] = this._bookEvents[productId] || new BehaviorSubject();
                res && res.data && this._bookEvents[productId].next(res.data);
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    setBookingEvents(params, key) {
        return DataFetcher.postJson(BOOKINGS.EVENTS.URL, {params})
            .then(res => {
                const bookedEventsIds = params.items.map(el => el.event_id);
                const eventsData = EventsDataService.eventsData.getValue();
                const eventsDataByKey = eventsData[EVENT_TYPES.LIST]?.data || [];

                EventsDataService.getEventsByIds(bookedEventsIds, {type: getEventType(EVENT_TYPES.BOOKED)})
                    .then(data => {
                        const updatedEvents = data.reduce((acc, val) => {
                            return Helpers.updateDataInArrayOfObjects(acc, val, 'id')
                        }, eventsDataByKey)
                            .filter(val => {
                                if (val.content_providers.every(provider => productIsBooked(provider.product_id, provider))) {
                                    key === EVENT_TYPES.LIST && eventsDataService.getDailyTotals({ type: key });
                                    eventsDataService.getEventsByIdForUpdate(val.id, EVENT_TYPES.LIST, true);
                                    return false;
                                }
                                return true;
                            });

                        EventsDataService.eventsData.next({
                            ...eventsData,
                            [EVENT_TYPES.LIST]: { ...EventsDataService.eventsData.getValue()[EVENT_TYPES.LIST], data: updatedEvents}
                        });
                    })
                    .catch((e) => Promise.reject(e));

                const userProfile = usersDataService._userProfile.getValue();
                usersDataService._userProfile.next({
                    ...userProfile,
                    organization: {
                        ...userProfile.organization,
                        balance: (userProfile.organization.balance - res.data.amount)
                    }
                });
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    /**
     * @description PRODUCT BLOCK HANDLER
     * @param params
     * @param status
     * @returns {*}
     */
    blockHandler(params, status) {
        return DataFetcher.putJson(`${MY_GAMES.BLOCK_HANDLER.URL}/${status}`, {params})
            .then(res => {
                // TODO CHANGE, should work with webSocket !!!
                const eventList = EventsDataService._eventList.getValue();
                const selectedEventList = EventsDataService._selectedEventList.getValue();

                EventsDataService._eventList.next({
                    ...eventList,
                    booked: {
                        ...eventList.booked,
                        data: Helpers.replaceArrayElements(eventList.booked.data, res.data, 'id')
                    }
                });

                EventsDataService._selectedEventList.next({
                    ...selectedEventList,
                    booked: Helpers.replaceArrayElements(selectedEventList.booked, res.data, 'id')
                });

                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    bookingEvents(productId) {
        if (!this._bookEvents[productId]) this._bookEvents[productId] = new BehaviorSubject();
        return this._bookEvents[productId];
    }

    createAutoBookingRule(params) {
        const queryParams = setQueryParams({}, "autoBookingRules");
        return DataFetcher.postJson(BOOKINGS.AUTOBOOKING_RULES.URL, {params, queryParams})
            .then(res => {
                this._autoBookingRules.next([...this._autoBookingRules.getValue(), res.data]);
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    /**
     * @description Gets autoBooking rules
     * @param params
     * @returns {*}
     */
    getAutoBookingRules(params = {}) {
        const queryParams = setQueryParams(params, "autoBookingRules");

        return DataFetcher.getJson(BOOKINGS.AUTOBOOKING_RULES.URL, {queryParams})
            .then(res => {
                this._autoBookingRules.next(res.data);
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    /**
     * @description Updates autoBooking rules
     * @param ruleId
     * @param params
     * @param callBack
     * @returns {Promise<void> | Promise<T> | Promise<T | void> | *}
     */
    updateAutobookingRule(ruleId, params, callBack) {
        const queryParams = setQueryParams({}, "autoBookingRules");

        return DataFetcher.putJson(`${BOOKINGS.AUTOBOOKING_RULES.URL}/${ruleId}`, {queryParams, params})
            .then(res => {
                const rules = this._autoBookingRules.getValue(),
                    updatedRules = Helpers.addOrUpdateArrayOfObjectsValue(rules, res.data, "id");
                this._autoBookingRules.next(updatedRules);
                callBack && callBack();
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    /**
     * @description Deletes autoBooking rules
     * @param ruleId
     * @param params
     * @param callbackFn
     * @returns {Promise<void> | Promise<T> | Promise<T | void> | *}
     */
    deleteRule(ruleId, params = {}, callbackFn) {
        return DataFetcher.deleteJson(`${BOOKINGS.AUTOBOOKING_RULES.URL}/${ruleId}`, {queryParams: params})
            .then(res => {
                if (res.status === "Success.") {
                    this._autoBookingRules.next(this._autoBookingRules.getValue().filter(rule => rule.id !== ruleId));
                    callbackFn && callbackFn();
                }
                return Promise.resolve(res);
            }).catch(err => Promise.reject(err));
    }

    stopAutoBookingRules() {
        this._autoBookingRules.unsubscribe();
    }

    get autoBookingRulesData() {
        return this._autoBookingRules.pipe(share());
    }
}

export default new BookingDataService();
