import React, { useEffect, useState } from 'react';
import braintree from 'braintree-web';
import paypal from 'paypal-checkout';

import './paypalButton.scss';

const PaypalButton = ({ authorization, checkout, setPaypalReady }) => {
    const [data,setData] = useState(null);
    const [payPalInstance, setPayPalInstance] = useState(null);

    useEffect(() => {
        if (data) {
            payPalInstance.tokenizePayment(data)
                .then((nonce) => {
                    checkout(nonce);
                    setData(null);
                });
        }
    }, [data, payPalInstance]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (authorization) {
            braintree.paypalCheckout.create({
                authorization
            }, function (createErr, paypalCheckoutInstance) {
                if (createErr) {
                    console.error('Error!', createErr);
                    return;
                }

                setPayPalInstance(paypalCheckoutInstance);

                paypal.Button.render({
                    env: window.location.hostname !== 'friendship.feedconstruct.com' ? 'sandbox' : 'production',
                    locale: 'en_US',
                    style: {
                        size: 'responsive',
                        color: 'gold',
                        shape: 'pill',
                        label: 'checkout',
                        height: 42,
                        width: '100%',
                        tagline: false,
                    },

                    payment: () => {
                        return paypalCheckoutInstance.createPayment({
                            flow: 'vault'
                        });
                    },

                    onEnter: () => {
                        setPaypalReady(true);
                    },

                    onAuthorize: (data) => {
                        data && setData(data);
                    },

                    onError: function (err) {
                        console.error('paypal error', err);
                    }
                }, '#paypal-button'); // the PayPal button will be rendered in an html element with the id `paypal-button`
            });
        }
    }, [authorization]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="paypal-button" />
    )
};

export default PaypalButton;
