import {checkIsStaging} from "../helpers/userHelpers";

const newsModalBC = userProfile => {
    const data = {
        identifier: userProfile.email,
        token: "3C306915-B364-4674-BD22-772701CCC4E2",
        env: checkIsStaging(window.location.host) ? 'https://back.springbme.com' : 'http://devbmeback.betconstruct.int'
    };
    window.NewsModalBC && (typeof window.NewsModalBC.init === 'function') && window.NewsModalBC.init(data);
};

export default newsModalBC;