import React, { useEffect, useState, Suspense } from 'react';
import Tabs from "../../components/shared/tabs";
import Tab from "../../components/shared/tab";
import { getAvailableRoutes } from "../../helpers/routeHelper";
import { useLocation, useHistory } from "react-router-dom";
import { getSettingsRouteComponent } from "../../helpers/routeHelper";
import { t } from '../../helpers/translate';

const SettingsWrapper = React.memo(({ closeButton, closeAction }) => {
    const location = useLocation();
    const history = useHistory();
    const routes = getAvailableRoutes("/settings");
    const tabIds = Object.keys(routes).reduce((acc, curr, index) => ({...acc, [curr.slice(1)]: index}), {});

    const [selectedTabId, setSelectedTabId] = useState(tabIds[window.location.hash.slice(1)]);

    const handleTabChange = (tabId) => {
        const selectedRoute = Object.keys(tabIds).find(key => tabIds[key] === tabId);
        history.push(`#${selectedRoute}`);
        setSelectedTabId(tabId);
    };

    useEffect(() => {
        if (location.hash && tabIds[location.hash.slice(1)] !== selectedTabId) {
            handleTabChange(tabIds[location.hash.slice(1)]);
        }
    }, [location.hash, selectedTabId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Tabs selectedTabId={selectedTabId} onChange={handleTabChange} appearance="glass" rightAction={closeButton}>
            {Object.keys(routes).map((route, index) => (
                <Tab title={t(routes[route].name)} id={index} key={routes[route].name} icon={routes[route].icon}>
                    <Suspense fallback={null}>
                        {getSettingsRouteComponent(route, routes, closeAction)}
                    </Suspense>
                </Tab>
            ))}
        </Tabs>
    );
});

export default SettingsWrapper;
