import { BehaviorSubject } from "rxjs";
import { LANGUAGES } from '../constants/dataConstants';

class TranslationService {
    constructor () {
        this._translationsIsReady = new BehaviorSubject(false);
        this._translations = new BehaviorSubject({});

        this.lang = this.getLanguage();
        this.getTranslations(this.lang);
    }

    getLanguage () {
        const lang = localStorage?.getItem('lang');
        if (lang && LANGUAGES.find(item => (item.id === lang))) {
            return lang;
        } else {
            return 'eng';
        }
    }

    setLanguage (lang) {
        localStorage.setItem('lang', lang);
        window.location.reload();
    }

    translateByKey (key = '') {
        const foundConstants = key?.match(/{.*?}/g);
        const translations = this._translations.getValue() || {};

        if (Array.isArray(foundConstants)) {
            const removeBrackets = str => str?.replace(/{/g, "")?.replace(/}/g, "");

            let newKey = key;

            foundConstants.forEach((foundConstant, i) => {
                newKey = newKey?.replace(foundConstant, `{${i + 1}}`);
            });

            let val = translations[newKey] || removeBrackets(key);

            const foundValues = val?.match(/{.*?}/g);

            if (Array.isArray(foundValues)) {
                foundValues.forEach((foundValue) => {
                    val = val?.replace(foundValue, removeBrackets(foundConstants[removeBrackets(foundValue) - 1]));
                });
            }

            return val;
        }

        return translations[key] || key;
    }

    getTranslations (lang = this.lang) {
        const url = `${process.env.PUBLIC_URL}/translations/lang_${lang}.json`;

        fetch(url)
            .then(response => response.json())
            .then((data = {}) => this._translations.next(data))
            .catch(err => console.warn("translations - fetch", err))
            .finally(() => this._translationsIsReady.next(true));
    }

    get translationsIsReady () {
        return this._translationsIsReady;
    }
}

export default new TranslationService();

