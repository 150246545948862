import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../helpers/translate';
import Button from '../button';

import DropDown from '../dropDown';

import '../../reusableUIComponents/competitorsDynamicSelect/teamsVS.scss'

const isFunction = (arg) => typeof arg === 'function';

const DynamicSelect = (props) => {
    const {
        data,
        allowAll,
        className,
        separator,
        onDelete,
        interactive,
        allowDelete,
        allowAddMore,
        onAddMore,
        primaryDropdown,
        secondaryDropdown,
        fieldArrayError
    } = props;

    return data.map((val) => {
        const secondaryData = val[secondaryDropdown.keyForAllSelect];
        const showAll = !val[secondaryData] || (Array.isArray(secondaryData && !secondaryData.length)) ?  ((allowAll && t('All')) || secondaryDropdown.placeholder) : '';
        return (
            <ul
                key={val.sport_id || val.id ? `${val.id}_${val.sport_id}` : `${val.user_id}_${val.role_id}`}
                className={classnames(((val.user_id || val.role_id) ? 'user-role' : 'teams-vs-holder'), className, {
                    'interactive': allowAll && interactive
                })}
            >
                <li>
                    <DropDown
                        { ...primaryDropdown }
                        errorText={fieldArrayError ?
                            primaryDropdown.errorText?.find(error => val.user_id === error.id) ?
                                `User is attached to ${primaryDropdown.errorText?.find(error => val.user_id === error.id).federation_group_name} group.` : null : primaryDropdown.errorText}
                        externalData={primaryDropdown.externalData && isFunction(primaryDropdown.externalData) ? primaryDropdown.externalData(val) : primaryDropdown.externalData}
                        initialSelections={primaryDropdown.initialSelections && isFunction(primaryDropdown.initialSelections) ? primaryDropdown.initialSelections(val) : primaryDropdown.initialSelections}
                        onChange={(el) => primaryDropdown.onChange(el, val)}
                        requestParams={{ ...(isFunction(primaryDropdown.requestParams) ? primaryDropdown.requestParams(val) : (primaryDropdown.requestParams || {})) }}
                    />
                </li>
                <li>{separator}</li>
                <li>
                    <DropDown
                        { ...secondaryDropdown }
                        placeholder={showAll}
                        key={val[secondaryDropdown.key]}
                        onChange={(el) => secondaryDropdown.onChange(el, val)}
                        requestParams={{ ...(isFunction(secondaryDropdown.requestParams) ? secondaryDropdown.requestParams(val) : (secondaryDropdown.requestParams || {})) }}
                        initialRequestParams={(isFunction(secondaryDropdown.initialRequestParams) ? secondaryDropdown.initialRequestParams(val) : (secondaryDropdown.initialRequestParams))}
                        initialSelections={secondaryDropdown.initialSelections && isFunction(secondaryDropdown.initialSelections) ? secondaryDropdown.initialSelections(val) : secondaryDropdown.initialSelections}
                    />
                </li>
                {allowAll && (
                    <li>
                        <div className="teams-actions-holder">
                            {(typeof allowDelete === 'function' ? allowDelete() : allowDelete) && (
                                <Button
                                    color="danger"
                                    tooltip={t('Delete')}
                                    icon="icon-trash"
                                    onClick={() => onDelete((val.id || val.user_id) )}
                                    appearance="minimal"
                                    iconSize={18}
                                />
                            )}
                            {(typeof allowAddMore === 'function' ? allowAddMore() : allowAddMore) && (
                                <Button
                                    color="confirm"
                                    tooltip={t('Add more')}
                                    icon="icon-plus"
                                    onClick={onAddMore}
                                    appearance="minimal"
                                />
                            )}
                        </div>
                    </li>
                )}
            </ul>
        )
    });
};

DynamicSelect.defaultProps = {
    data: [],
    allowAll: false,
};

DynamicSelect.propTypes = {
    data: PropTypes.array,
    allowAll: PropTypes.bool,
    separator: PropTypes.any,
    onDelete: PropTypes.func,
    className: PropTypes.string,
    interactive: PropTypes.bool,
    allowDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    allowAddMore: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    primaryDropdown: PropTypes.shape({
        ...DropDown.propTypes,
        requestParams: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        externalData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        initialSelections: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    }),
    secondaryDropdown: PropTypes.shape({
        ...DropDown.propTypes,
        requestParams: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        externalData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        initialRequestParams: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        initialSelections: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    }),
};

export default DynamicSelect;
