import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { switcherConfig } from "../../../configs/uiConfig";

import './switcher.scss';

const Switcher = forwardRef(function(props, ref) {

    const {
        checked,
        color,
        size,
        label,
        labelPosition,
        labelAlignment,
        description,
        readOnly,
        disabled,
        onChange,
        value,
        className,
        ...restProps
    } = props;

    return (
        <label className={classnames(
            'crs-holder',
            className,
            `lp-${labelPosition}`,
            `la-${labelAlignment}`,
            `c-${color}`,
            {
                'read-only': readOnly,
                disabled,
                description,
            },
        )}>
            <div className={classnames('label-holder', {
                'has-label': !!label,
            })}>
                {label && (
                    <div className="crs-item crs-label">
                        <div className="ellipsis-text">{label}</div>
                    </div>
                )}
                <div className="crs-item crs-component">
                    <input
                        type="checkbox"
                        onChange={onChange}
                        checked={checked}
                        disabled={disabled}
                        value={value}
                        ref={ref}
                        {...restProps}
                    />
                    <div
                        className={classnames(
                            'switcher-element',
                            `s-${size}`,
                            {
                                'active': checked,
                                'read-only': readOnly,
                            }
                        )}
                    >
                        <small />
                    </div>
                </div>
            </div>
            {description && (
                <div className="input-description">
                    {description}
                </div>
            )}
        </label>
    )
});

Switcher.propTypes = {
    checked: PropTypes.bool,
    color: PropTypes.oneOf(switcherConfig.color),
    size: PropTypes.oneOf(switcherConfig.size),
    label: PropTypes.node,
    labelPosition: PropTypes.oneOf(switcherConfig.labelPosition),
    labelAlignment: PropTypes.oneOf(switcherConfig.labelAlignment),
    description: PropTypes.any,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
};

Switcher.defaultProps = {
    color: switcherConfig.color[0],
    size: switcherConfig.size[1],
    labelPosition: switcherConfig.labelPosition[0],
    labelAlignment: switcherConfig.labelAlignment[0],
    readOnly: false,
    disabled: false,
    required: false,
};

export default Switcher;
