import { RESET_FILTERS_BY_PARENT_KEY, RESET_FILTERS_DATA, SET_FILTERS_CHANGE } from "../actions/action-types";
import {setFiltersParams} from "./parts/filtersParentSubsPart";

const INITIAL_STATE = {};

const FiltersParamsReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case SET_FILTERS_CHANGE: {
            return setFiltersParams(state, action);
        }
        case RESET_FILTERS_DATA: {
            return {
                ...state,
                [action.parentKey]: {
                    ...(state[action.parentKey]?.subKeys ? {
                        subKeys: {
                            ...((state[action.parentKey] && state[action.parentKey].subKeys) || {}),
                            [action.key]: {}
                        }
                    } : {})
                }
            };
        }
        case RESET_FILTERS_BY_PARENT_KEY: {
            return {
                ...state,
                [action.key]: {},
            }
        }
        default:
            return state;
    }
};

export default FiltersParamsReducer;
