import React, {useEffect, useMemo, useState} from 'react';
import EventFormFields from './eventFormFields';
import CreatedEvents from './createdEvents';
import { useDispatch } from "react-redux";
import OverlayContent from "../../rightPanel/overlayContent";
import Collapser from "react-styled-collapser";
import Helpers from "../../../../helpers/helperFunctions";
import {closeRightPanel, registerToastMessage} from "../../../../actions/sync-actions/uiActions";
import {TOAST_TYPES} from "../../../../constants/uiConstants";
import {Formik} from "formik";
import usersDataService from "../../../../services/usersDataService";
import {createEvents} from "../../../../actions/async-actions/eventActions";
import {useRouteMatch} from "react-router-dom";
import { getBasePath } from '../../../../helpers/routeHelper';
import { t } from '../../../../helpers/translate';

import './eventForm.scss';

const validate = (values) => {
    return Object.keys(values).reduce((acc, curr) => {
        switch (curr) {
            case "league_id":
            case "region_id":
            case "timeZone":
            case "start_date":
                !values[curr] && (acc[curr] = t('This field is required'));
                return acc;
            case "teams":
                if (!values.teams[0].team_1_id || !values.teams[0].team_2_id) {
                    acc[curr] = t('This field is required');
                }
                return acc;
            default:
                return acc;
        }
    }, {});
};

const EventForm = () => {
    const [selectedTab, setSelectedTab] = useState('newEvent');
    const [createdEvents, setCreatedEvents] = useState([]);
    const [eventEditIndex, setEventEditIndex] = useState(null);
    const defaultTimeZone = usersDataService.profile.getValue()?.time_zone;
    const basePath = getBasePath();
    const {id: organizationId} = useRouteMatch(`${basePath}/federations/:id`)?.params || {};
    const dispatch = useDispatch();

    const initialValues = useMemo(() => ({
        "league_id": null,
        "league_name": null,
        "sport_id": null,
        "sport_name": null,
        "region_id": null,
        "region_name": null,
        "start_date": Math.floor(Date.now() / 1000),
        "timeZone": usersDataService.profile.getValue()?.time_zone,
        "teams": [{
            "team_1_id": null,
            "team_1_name": null,
            "team_2_id": null,
            "team_2_name": null
        }]
    }), [defaultTimeZone]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedTab === "createdEvents" && !createdEvents.length) {
            setSelectedTab('newEvent');
        }
    }, [createdEvents.length, selectedTab]);

    const getCleanedUpData = (createdEvents) => {
        return createdEvents.reduce((events, currEvent) => {
            const createdData = Object.keys(currEvent).reduce((acc, el) => {
                if (el === "teams") {
                    const [teams] = currEvent[el];
                    if (teams.team_1_id === -1) teams.team_1_id = null;
                    if (teams.team_2_id === -1) teams.team_2_id = null;
                    delete teams.id;
                    return {...acc, ...teams};
                }

                if (el === "league_id" && currEvent[el] === -1) {
                    currEvent[el] = null;
                }

                if (el === "timeZone") return acc;

                acc[el] = currEvent[el];

                return acc;
            }, organizationId ? {organization_id: Number(organizationId)} : {});

            return [...events, createdData];
        }, []);
    };

    const handleReset = (resetForm) => {
        setEventEditIndex(null);
        setSelectedTab('createdEvents');
        setTimeout(() => resetForm(initialValues), 200);
    };


    const addOrUpdateCreatedEvent = (values, eventEditIndex, resetForm) => {
        if (parseInt(Helpers.getTimeZoneTime(null, values.timeZone) / 1000) > parseInt(Helpers.getTimeZoneTime(values.start_date, values.timeZone) / 1000)) {
            dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "The start time of the event is passed. Please change selected time."}));
            return;
        }

        if (createdEvents[eventEditIndex]) {
            setCreatedEvents(events => [
                ...events.slice(0, eventEditIndex),
                {...values},
                ...events.slice(eventEditIndex + 1)
            ]);
        } else {
            setCreatedEvents(events => [...events, values]);
        }

        handleReset(resetForm);
    };

    const removeEvent = (pos) => {
        setCreatedEvents((events) => events.filter((_, index) => index !== pos));
    };

    const removeAllEvents = () => setCreatedEvents([]);

    const handleCancel = (resetForm) => {
        if (selectedTab === 'createdEvents' || !createdEvents.length) {
            return dispatch(closeRightPanel());
        }

        handleReset(resetForm);
    };

    return (
        <Formik
            key={eventEditIndex}
            initialValues={createdEvents[eventEditIndex] || initialValues}
            validate={validate}
            validateOnChange={true}
            validateOnBlur={true}
            isInitialValid={false}
            onSubmit={() => {}}
            enableReinitialize
            render={({values, errors, resetForm, isValid}) => {
                return (
                    <OverlayContent
                        title={t('New Event')}
                        subTitle={t('Create all the matches you want to provide streaming for')}
                        primaryButton={{
                            name: `${selectedTab === 'createdEvents' && createdEvents.length ? t('Submit') : createdEvents[eventEditIndex] ? t('Edit') : t('Add')}`,
                            color: createdEvents[eventEditIndex] ? 'primary': 'confirm',
                            disabled: (selectedTab === 'newEvent' && !isValid) || (createdEvents[eventEditIndex] && Helpers.isEqual(values, createdEvents[eventEditIndex])),
                            onClick: () => {
                                if (selectedTab === 'createdEvents' && createdEvents.length) {
                                    return dispatch(createEvents(getCleanedUpData(createdEvents), organizationId));
                                }
                                addOrUpdateCreatedEvent(values, eventEditIndex, resetForm);
                            }
                        }}
                        secondaryButton={{
                            name: `${selectedTab === 'newEvent' && createdEvents.length ? t('Go back') : t('Cancel')}`,
                            onClick: () => handleCancel(resetForm),
                        }}
                    >
                        <Collapser
                            collapsed={selectedTab !== 'newEvent'}
                            className="full-width"
                        >
                            <EventFormFields
                                key={selectedTab}
                                setSelectedTab={setSelectedTab}
                                values={values}
                                errors={errors}
                            />
                        </Collapser>
                        <Collapser
                            collapsed={selectedTab !== 'createdEvents'}
                            className="full-width"
                        >
                            <CreatedEvents
                                setSelectedTab={setSelectedTab}
                                setEventEditIndex={setEventEditIndex}
                                createdEvents={createdEvents}
                                removeEvent={removeEvent}
                                removeAllEvents={removeAllEvents}
                                dispatch={dispatch}
                            />
                        </Collapser>
                    </OverlayContent>
                )
            }}
            />
    );
};

export default EventForm;
