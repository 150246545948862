export const AUTHORIZATION = {
    LOGIN: {URL: 'login'},
    LOGOUT: {URL: 'logout '},
};

export const USERS = {
    MODERATOR_USERS: {URL: 'moderator/users'},
    USERS_LIST: {URL: 'users'},
    CLIENT_USERS_LIST: {URL: 'users/client'},
    MODERATOR_USERS_LIST: {URL: 'moderator/users'},
    USER_PROFILE: {URL: 'users/profile'},
    USER_ITEM: {URL: 'users'},
    USER_ROLES: {URL: 'user-roles'},
    CREATE_USER: {URL: 'register'},
    UPDATE_USERS: {URL: 'users'},
    SET_NEW_ROLE: {URL: 'users/profile'},
    GET_USERS_PARTNERS: {URL: 'users/partners'},
    UPDATE_USER_PARTNERS: {URL: 'users/partners'},
    CHECK_USER_EXISTENCE: {URL: 'users/lookup'},
    REFRESH_SIGNATURE: {URL: 'users/signature'},
    MODERATOR_USER_LIST: {URL: 'moderator/users/list'}
};

export const ORGANIZATIONS = {
    SKIP_TRIAL: {URL: 'skipTrial'},
    GO_LIVE: {URL: 'organizations'},
    B2C_BECOME_PARTNER: {URL: 'organizations/sendPartnerSales'},
    CHECK_IS_PARTNER: {URL: 'organizations/checkPartnerSales'},
};

export const BECOME_PARTNER = {
    CREATE_PARTNER_REQUEST: {URL: 'users/partners/request'}
};

export const REGISTRATION = {
    REGISTER_CLIENT: {URL: 'register/client'},
    REGISTER_STREAMER: {URL: 'register/streamer'}
};

export const SPORTS = {
    SPORTS_LIST: {URL: 'sports'},
    SPORT_ITEM: {URL: 'sports/1'},
    CREATE_SPORT: {URL: 'sports'},
    UPDATE_SPORT: {URL: 'sports/'} //sports/{{sport_id}}
};

export const LEAGUES = {
    MODERATOR_LEAGUES: {URL: 'moderator/leagues'},
    FILTER_LEAGUES_LIST: {URL: 'leagues'},
    LEAGUES_LIST: {URL: 'myleagues'},
    CREATE_LEAGUE: {URL: 'leagues-requests'},
    MODERATOR_CREATE_LEAGUE: {URL: 'moderator/leagues-requests'},
    STANDART_LEAGUE: {URL: 'leagues'},
    UPDATE_LEAGUE: {URL: 'myleagues/'}, //leagues/{{league_id}},
    UNATTACH_LEAGUE: {URL: 'moderator/federations'},
    ATTACH_LEAGUE: {URL: 'moderator/federations'},
};

export const MEMBERS = {
    MEMBERS_LIST: {URL: 'members'},
    MEMBERS_VIEW: {URL: 'members/1'},
    CREATE_MEMBER: {URL: 'members'},
    UPDATE_MEMBER: {URL: 'members/3'} //leagues/{{league_id}}
};

export const EVENTS = {
    EVENTS_LIST: {URL: 'events'},
    EVENT_VIEW: {URL: 'events'},
    CREATE_EVENT: {URL: 'events'},
    UPDATE_EVENTS: {URL: 'events/'},
    MODERATOR_CREATE_EVENT: {URL: 'moderator/events'},
    MODERATOR_UPDATE_EVENTS: {URL: 'moderator/federations'},
    UPDATE_UPCOMING: {URL: 'events/upcoming'},
    MODERATOR_EVENTS_LIST: {URL: 'moderator/events'},
    MODERATOR_UPDATE_UPCOMING: {URL: 'moderator/events/upcoming'},
    ON_AIR: {URL: 'events'},
    STREAMER_NAME: {URL: 'get-stream-name '},
    DAILY_TOTALS: {URL: 'daily-totals'},
    MATCH_COUNTS: {URL: 'match-counts'},
    CHECK_AVAILABLE: {URL: 'events/check-available'},
};

export const MY_GAMES = {
    // LIST: {URL: 'games'},
    LIST: {URL: 'mygames'},
    BLOCK_HANDLER: {URL: 'mygames'}
};

export const FILTERS = {
    GET_PRESETS: {URL: 'filters'},
    SET_PRESET: {URL: 'filters'},
    DELETE_PRESET: {URL: 'filters/'},
    UPDATE_PRESET: {URL: 'filters/'}
};

export const PRICES = {
    GET_PRICES: {URL: 'prices'},
    UPDATE_PRICE: {URL: 'prices'},
    RECOMMENDED: {URL: 'prices/recommended'},
    CART: {URL: 'cart'}
};

export const BOOKINGS = {
    EVENTS: {URL: 'book/events'},
    AUTOBOOKING_RULES: {URL: 'autobooking/rules'},
    AUTOBOOKING_RULES_ID: {URL: 'autobooking/rules/:id'}
};

export const TEAMS = {
    TEAMS_LIST: {URL: 'teams'},
    TEAM_VIEW: {URL: 'teams/1'},
    CREATE_TEAM: {URL: 'teams'},
    UPDATE_TEAM: {URL: 'teams/'}
};

export const IFRAME_URLS = {
    STREAMING: {URL: 'streaming'},
    SCOUTING: {URL: 'scouting'},
    ODDS_FEED: {URL: 'odds'},
    STATISTICS: {URL: 'statistic'}
};

export const COUNTRIES = {
    REGIONS: {URL: 'regions'},
    ALL_COUNTRIES: {URL: 'countries'},
    COUNTRIES_GROUPED: {URL: 'countriesGrouped'}
};

export const STREAMS = {
    CREATE: {URL: 'streams'},
    DELETE: {URL: 'streams'},
    SETUP: {URL: 'streams'},
    ON_AIR: {URL: 'events'},
    CHANGE_STREAM_ID: {URL: 'events'},
    PUB_LOCATIONS: {URL: 'pub-locations'},
    STREAM_RECEIVING_OPERATORS: {URL: 'operators'},
    STREAMER_INFO: {URL: 'streamer-info'},

    MODERATOR_STREAMING_PREFIX:  {URL: 'moderator/federations'},
    MODERATOR_STREAMS: {URL: 'moderator/federations'},
    MODERATOR_CREATE: {URL: 'moderator/federations'}
};

export const PAYMENTS = {
    PAYMENT_METHODS: {URL: 'payment-methods'},
    MODERATOR_PAYMENTS: {URL: 'financial-reporting'},
    PAYMENT_HISTORY: {URL: 'payments'},
    PAY: {URL: 'pay'},
    WITHDRAW: {URL: 'payments/withdraw'},
    BANK_INFO: {URL: 'bank-info'},
    BALANCE_HISTORY: {URL: "federation-invoices/events"},
    BALANCE_TOTALS: {URL: "federation-invoices"},
    CURRENT_INVOICE: {URL: "client-invoices"},
    CURRENT_INVOICE_EVENTS: {URL: "client-invoices/events"},
    INVOICE_HISTORY: {URL: "invoice-history"},
    WALLET_BALANCE_HISTORY: {URL: "payments/balance/history"},
    WALLET_DEPOSIT_HISTORY: {URL: "payments/deposit/history"},
    EVENT_BUYERS: {URL: "moderator/federations/event-buyers"},
    REVENUE_SHARING_MODEL: {URL: "conditional-balance-totals"},
    DEPOSIT: {URL: "payments/deposit"},
    WALLET_BALANCE: {URL: "payments/balance"},
    PAYMENT_CLIENT_TOKEN: {URL: "payments/client/token"},
    CHECKOUT: {URL: "payments/checkout"},
    DEFAULT: {URL: "payments/default/card"},
};

export const REPORTING = {
    REPORTS: {URL: 'reports'},
    MODERATOR_REPORTS: {URL: 'moderator/reports'},
    REPORTS_FULL: {URL: 'full'},
    DASHBOARD: {URL: 'my-dashboard'},
    FILTERS: {URL: 'reports/filters'},
    COUNTRIES: {URL: 'country-filters'},
    SPORTS: {URL: 'sport-filters'},
    REGIONS: {URL: 'region-filters'},
    LEAGUES: {URL: 'league-filters'},
    DEVICES: {URL: 'device-filters'},
    BROWSERS: {URL: 'browser-filters'},
    EMAILS: {URL: 'reporting-emails'}
};

export const USER_STORE = {
    STORE_DATA: {URL: 'user-store'},
    GET_DATA: {URL: 'user-store'},
    DELETE_DATA: {URL: 'user-store'}
};

export const HEADER_LINKS = {
    API_DOCUMENTATION: {URL: 'documentation'}
};

export const OPERATORS = {
    OPERATORS_LIST: {URL: 'moderator/operators'},
    BOOKED_MATCHES: {URL: 'booked-matches'},
    ATTACHMENTS: {URL: 'attachments'},
};

export const FEDERATIONS = {
    FEDERATIONS_GROUP: {URL:'moderator/federation-groups'},
    FEDERATIONS_LIST: {URL: 'moderator/federations'},
    LEAGUES: {URL: 'moderator/leagues'},
    USERS: {URL: 'users'},
    FEDERATION_LEAGUES_LIST: {URL: 'leagues'},
    ATTACHMENT_CREATE: {URL: 'moderator/documents/federation-create/attachments'},
    ATTACHMENTS: {URL: 'attachments'},
    ATTACH: {URL: 'attach'},
    SALES_REPORT_EXPORT: {URL: (federationId) => `moderator/federations/${federationId}/sales-report`},
    EXTENDED_SALES_REPORT_EXPORT: {URL: (federationId) => `moderator/federations/${federationId}/extended-sales-report`},
    LEAGUES_REPORT: {URL: (federationId) => `moderator/federations/${federationId}/leagues-report`},
    SPORTS_REPORT: {URL: (federationId) => `moderator/federations/${federationId}/sport-report`},
    HAS_BUSINESS_MODEL: {URL:`has-business-model`},
};

export const TEMPLATES = {
    TEMPLATES_LIST: {URL: 'moderator/billing-templates'}
};

export const SCOUT_PAGES = {
    SPORTS: 'getSport',
    LIST_GAMES: 'getGames',
    GAMES_LIST_BY_IDS: 'getGamesbyid',
    BOOKED_GAMES: 'getBookGames',
    HISTORY_GAMES: 'getHistoryGames',
    COUNTRIES: 'getCountry',
    LEAGUES: 'getLeague',
    BOOK_GAMES: 'bookGames',
    FIN_REPORT: 'financialReport',
    GET_TOKEN: 'getToken'
};
