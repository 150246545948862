export const connectionConfigs = {
    transports: ['websocket']
};

/**
 * @description Set signature for socket connection
 * @param signature
 */
export const setSignature = (signature) => {
    connectionConfigs.query = {signature};
};

