import React, {forwardRef} from 'react';
import {AutoSizer, InfiniteLoader, List, WindowScroller} from "react-virtualized";
import PropTypes from 'prop-types';
import {DROP_DOWN_CONFIG} from "../../../configs/uiConfig";

const VirtualList = forwardRef((props, parentRef) => {
    const {
        data,
        withLazyLoad,
        rowCount,
        onloadMore,
        children,
        cache,
    } = props;

    if (withLazyLoad) {
        return (
            <InfiniteLoader
                isRowLoaded={({index}) => Boolean(data[index])}
                loadMoreRows={onloadMore}
                rowCount={rowCount}
            >
                {({onRowsRendered, registerChild}) => (
                    <WindowScroller scrollElement={parentRef}>
                        {({height, scrollTop}) => (
                            <AutoSizer disableHeight>
                                {({width}) => (
                                    <List
                                        ref={registerChild}
                                        autoHeight
                                        height={height || 0}
                                        width={width}
                                        scrollTop={scrollTop}
                                        overscanRowCount={50}
                                        onRowsRendered={onRowsRendered}
                                        rowCount={Math.min(rowCount, data.length + DROP_DOWN_CONFIG.loadLimit)}
                                        rowHeight={cache.rowHeight}
                                        deferredMeasurementCache={cache}
                                        rowRenderer={
                                            (renderProps) => children({
                                                ...renderProps,
                                                data
                                            })
                                        }
                                    />
                                )}
                            </AutoSizer>
                        )}
                    </WindowScroller>
                )}
            </InfiniteLoader>
        )
    } else {
        return (
            <WindowScroller scrollElement={parentRef}>
                {({height, scrollTop}) => (
                    <AutoSizer disableHeight>
                        {({width}) => (
                            <List
                                autoHeight
                                height={height || 0}
                                width={width}
                                scrollTop={scrollTop}
                                rowCount={data.length}
                                rowHeight={cache.rowHeight}
                                deferredMeasurementCache={cache}
                                rowRenderer={
                                    (renderProps) => children({
                                        ...renderProps,
                                        data
                                    })
                                }
                            />
                        )}
                    </AutoSizer>
                )}
            </WindowScroller>
        )
    }
});

VirtualList.propTypes = {
    data: PropTypes.array,
    withLazyLoad: PropTypes.bool,
    rowCount: PropTypes.number,
    height: PropTypes.number,
    isRowLoaded: PropTypes.bool,
    onloadMore: PropTypes.func,
};

VirtualList.defaultProps = {
    withLazyLoad: false,
    isRowLoaded: false,
};

export default VirtualList;
