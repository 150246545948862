import React, { useEffect, useState } from 'react';
import watchListDataService from '../../../../services/watchListDataService';
import Button from '../../button';
import { getBasePath } from '../../../../helpers/routeHelper';


const WatchListCount = () => {
    const [watchList, setWatchList] = useState([]);

    useEffect(() => {
        const subscription = watchListDataService.watchList.subscribe(setWatchList);
        return () => subscription && subscription.unsubscribe();
    }, []);


    return !!watchList.length && (
        <>
            <div className='divider'/>
            <Button
                tag='NavLink'
                to={`${getBasePath()}/watchlist`}
                tooltip='Watch List'
                icon='icon-play'
                color='accent'
                badge={watchList.length}
            />
        </>
    );
};

export default WatchListCount;
