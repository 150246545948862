export const POPUP_TYPES = {
    WARNING: "warning",
    SUCCESS: "success"
};

export const LEFT_TAB_MENU_ITEMS = {
    FILTERS: "filters",
    PRESETS: "presets"
};

export const RIGHT_TAB_MENU_ITEMS = {
    // XXX
    DETAILS: "details",
    MATCH_DETAILS: 'matchDetails',
    VIDEO_STREAMING: "videoStreaming",
    // ODDS_FEED: "oddsFeed",
    // SCOUTING: "scouting",
    // STATISTICS: "statistics",
    SETUP: "setup",
    PRICING: "pricing"
};

export const RIGHT_MENU_SETUP_TABS = {
    EVENTS: "events",
    PRICES: "prices",
    BLOCKED_COUNTRIES: "blockedCountries"
};

export const EVENT_TYPES = {
    LIST: "list",
    UPCOMING: "upcoming",
    BOOKED: "booked",
    HISTORY: "history"
};

export const OPERATOR_TABS_TYPES = {
    ACCOUNT_INFO: "accountInfo",
    BOOKED_MATCHES: "bookedMatches",
    // AUTOBOOKING: "autobooking",
    REPORTING: "reporting",
    // FINANCIAL_REPORT: "financialReport"
};

export const OPERATOR_TABS = {
    accountInfo: 'Account Information',
    bookedMatches: 'Booked Matches',
    // autobooking: 'Autobooking Rules',
    reporting: 'Performance Reporting',
    // financialReport: "Financial Report"
};

export const FEDERATIONS_TABS = {
    federations: 'Federations',
    groupFederations: 'Group of Federations',
    reporting: 'Performance Reporting',
    templates: 'Templates'
};

export const FEDERATIONS_TABS_TYPES = {
    PROVIDERS: 'federations',
    GROUP_FEDERATIONS: 'groupFederations',
    REPORTING: 'reporting',
    TEMPLATES: 'templates'
};

export const UNIQUE_FEDERATION_TABS = {
    accountInfo: "Account Information",
    // leagues: 'Leagues',
    matches: 'Matches Setup',
    reporting: "Performance Reporting",
    users: "User Management",
    financialReport: "Financial Report"
};

export const UNIQUE_FEDERATION_TABS_TYPES = {
    GROUP_INFO: 'groupInfo',
    ACCOUNT_INFO: 'accountInfo',
    LEAGUES: 'leagues',
    MATCHES: 'matches',
    REPORTING: 'reporting',
    USER_MANAGEMENT: 'users',
    FINANCIAL_REPORT: "financialReport"
};

export const RIGHT_PANEL_ITEMS = {
    EDIT_FEDERATION_GROUP: 'editFederationGroup',
    USER_CART: 'userCart',
    PARTNER_REQUEST_FORM: 'partnerRequestForm',
    EVENT_FORM: 'eventForm',
    EDIT_USER_FORM: 'editUserForm',
    CREATE_USER_FORM: 'createUserForm',
    COUNTRIES_TREE: 'countriesTree',
    FEDERATION_SETUP: 'federationSetup',
    CREATE_LEAGUE_FORM: 'createLeague',
    BUSINESS_MODEL_SETUP: 'businessModelSetup',
    OPERATOR_SETUP: 'operatorSetup',
    OPERATOR_BUSINESS_MODEL_SETUP: 'operatorBusinessModelSetup',
};

export const PAYMENTS_HISTORY_TABS = {
    balanceHistory: "Balance history",
    account: "Withdraw history"
};

export const TOAST_TYPES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    ERROR: 'error'
};

export const PARTNERSHIP_TYPES = {
   FIXED: 'fixed',
   SHARED: 'shared',
   MIXED: 'mixed'
};

export const PRODUCT_TYPES = {
    SCOUTING: '/scouting',
    STREAMING: '/streaming'
};
