import {
    TOGGLE_FILTERS_DATA,
    TOGGLE_MULTIPLE_FILTERS_DATA,
    DELETE_FILTERS_DATA,
    TOGGLE_FILTER_DATA_BY_KEY,
    DELETE_ALL_FILTERS_DATA,
} from "../actions/action-types";

const INITIAL_STATE = {};

/**
 * @description Reducer for saved filters data.
 * @param state
 * @param action
 * @returns {{}}
 * @constructor
 */
const FiltersDataReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case TOGGLE_FILTERS_DATA: {
            const data = state[action.key] || [],
                newData = data.filter(dataItem => dataItem.id !== action.payload.id);
            if (newData.length !== data.length) {
                return {
                    ...state,
                    [action.key]: newData
                };
            }
            return {
                ...state,
                [action.key]: [
                    ...data,
                    action.payload
                ]
            };
        }
        case TOGGLE_FILTER_DATA_BY_KEY:
            return {
                ...state,
                [action.key]: action.payload
            };
        case TOGGLE_MULTIPLE_FILTERS_DATA: {
            return action.payload;
        }
        case DELETE_FILTERS_DATA: {
            return Object.keys(state).reduce((acc, curr) => {
                if (!curr.includes(`${action.parentKey}_${action.key ?? ''}`)) {
                    return {...acc, [curr]: state[curr]};
                }
                return acc;
            },{});
        }
        case DELETE_ALL_FILTERS_DATA: {
            return {};
        }
        default:
            return state;
    }
};

export default FiltersDataReducer;
