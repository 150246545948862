import DataFetcher from "./dataFetcherService";
import { PAYMENTS } from "../constants/api-resources";
import { BehaviorSubject, from } from "rxjs";
import { setQueryParams } from "../constants/queryParams";
import Helpers from "../helpers/helperFunctions";
import { ADMIN, MODERATOR } from "../constants/userRoles";
import { getServiceUrl } from "../helpers/uiHelpers";
import socket from '../helpers/socket';

class PaymentsDataService {

    constructor() {
        this._paymentsData = new BehaviorSubject({});
        this._paymentHistory = new BehaviorSubject(null);
        this._balacneHistory = new BehaviorSubject({});
        this._withdrawHistory = new BehaviorSubject({});
        this._currentInvoice = new BehaviorSubject({});
        this._currentInvoiceEvents = new BehaviorSubject({});
        this._invoiceHistory = new BehaviorSubject([]);
        this._balanceTotals = new BehaviorSubject({});
        this._revenuSharingModel = new BehaviorSubject({});
        this._walletBalance = new BehaviorSubject(null);
    }

    pay(requestData) {
        return DataFetcher.postJson(PAYMENTS.PAY.URL, {params: requestData})
            .then(() => {
                this.getWalletBalance();
            }).catch(e => e);
    }

    getBalanceHistory(params, id) {
        id = id || window.location.pathname.match(/\d+$/)?.[0];
        const urlPreFix = Helpers.checkRole(ADMIN, MODERATOR) ? `moderator/federations/${id}/financial-reporting/` : '';
        const lastQueryParams = setQueryParams(params, "balanceHistory");
        return DataFetcher.getJson(`${urlPreFix}${PAYMENTS.BALANCE_HISTORY.URL}`, {queryParams: lastQueryParams})
            .then(res => {
                this._balacneHistory.next(res);
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    }


    getWithdrawHistory(params) {
        let lastQueryParams = setQueryParams(params, "withdrawHistory");
        return DataFetcher.getJson(PAYMENTS.WITHDRAW.URL, {queryParams: lastQueryParams})
            .then(res => {
                this._withdrawHistory.next(res);
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    }

    getBalanceTotals(params, id) {
        id = id || window.location.pathname.match(/\d+$/)?.[0];
        const urlPreFix = Helpers.checkRole(ADMIN, MODERATOR) ? `moderator/federations/${id}/financial-reporting/` : '';
        const lastQueryParams = setQueryParams(params, "balanceTotals");
        return DataFetcher.getJson(`${urlPreFix}${PAYMENTS.BALANCE_TOTALS.URL}`, {queryParams: lastQueryParams})
            .then(res => {
                this._balanceTotals.next(res.data);
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    }

    getCurrentInvoice(params = {}) {
        return DataFetcher.getJson(PAYMENTS.CURRENT_INVOICE.URL, {queryParams: params})
            .then(res => {
                res && this._currentInvoice.next(res.data);
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    }

    getCurrentInvoiceEvents(params = {}) {
        return DataFetcher.getJson(PAYMENTS.CURRENT_INVOICE_EVENTS.URL, {queryParams: params})
            .then(res => {
                res && this._currentInvoiceEvents.next(res);
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    }

    getInvoiceHistory(params = {}) {
        return DataFetcher.getJson(PAYMENTS.INVOICE_HISTORY.URL, {queryParams: params})
            .then(res => {
                res.data && this._invoiceHistory.next(res.data);
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    }

    getWalletBalanceHistory(params = {}) {
        return from(DataFetcher.getJson(PAYMENTS.WALLET_BALANCE_HISTORY.URL, {queryParams: params}))
    }

    getEventBuyers(eventId) {
        return DataFetcher.getJson(`${PAYMENTS.EVENT_BUYERS.URL}/${eventId}`)
            .then((res) => {
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    }

    getRevenueSharingModelPrediction(params = {}) {
        return DataFetcher.getJson(`${this.paymentsUrl}/${PAYMENTS.REVENUE_SHARING_MODEL.URL}`, {queryParams: params})
            .then(res => {
                this._revenuSharingModel.next(res.data);
                return Promise.resolve(res.data);
            })
            .catch(err => Promise.reject(err));
    }

    getWalletBalance() {
        if (!this._walletBalance.getValue()) {
            socket.getConnector('myBalance').subscribe(data => {
                data?.data && this._walletBalance.next(data.data.balance)
            });
            socket.sendRequest('myBalance', {}); //prevent duplicate subscription TODO: change with more good solution
        }
        return this._walletBalance;
    }

    getPaymentClientToken() {
        return DataFetcher.getJson(PAYMENTS.PAYMENT_CLIENT_TOKEN.URL)
            .then(res => Promise.resolve(res.data))
            .catch(err => Promise.reject(err));
    }

    paymentCheckout(nonce, amount, token) {
        return DataFetcher.postJson(PAYMENTS.CHECKOUT.URL, { params: {nonce, amount, token} })
            .then(res => Promise.resolve(res.data))
            .catch(err => Promise.reject(err));
    }

    makePaymentMethodDefault (params) {
        return DataFetcher.postJson(PAYMENTS.DEFAULT.URL, { params  })
            .then(res => Promise.resolve(res.data))
            .catch(err => Promise.reject(err));
    }

    stop() {
        this._paymentsData.next({});
        this._paymentHistory.next(null);
        this._balacneHistory.next({});
        this._withdrawHistory.next({});
        this._currentInvoice.next({});
        this._currentInvoiceEvents.next({});
        this._invoiceHistory.next([]);
        this._balanceTotals.next({});
        this._revenuSharingModel.next({});
        this._walletBalance.next(null);
    }

    get paymentsUrl() {
        return Helpers.checkRole(MODERATOR, ADMIN) ? getServiceUrl(PAYMENTS.MODERATOR_PAYMENTS.URL, PAYMENTS.MODERATOR_PAYMENTS.URL) : PAYMENTS.PAYMENT_HISTORY.URL;
    }

    get paymentsData() {
        return this._paymentsData;
    }

    get paymentHistory() {
        return this._paymentHistory;
    }

    get invoiceHistory() {
        return this._invoiceHistory;
    }

    get bankInfo() {
        return this._paymentHistory;
    }

    get withdrawHistory() {
        return this._withdrawHistory;
    }

    get balanceHistory() {
        return this._balacneHistory;
    }

    get currentInvoiceData() {
        return this._currentInvoice;
    }

    get currentInvoiceEventsData() {
        return this._currentInvoiceEvents;
    }

    get balanceTotals() {
        return this._balanceTotals;
    }

    get revenueSharingModel() {
        return this._revenuSharingModel;
    }

    get walletBalance() {
        return this._walletBalance;
    }

}

export default new PaymentsDataService();
