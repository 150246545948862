export const APP_INIT = "APP_INIT";

export const LOGGED_IN = "LOGGED_IN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const SET_USER_INIT_DATA = "SET_USER_INIT_DATA";
export const USER_EDIT_ID = "USER_EDIT_ID";
export const SESSION_PROLONGED = "SESSION_PROLONGED";

export const RESET_INITIAL_STORE = "RESET_INITIAL_STORE";
export const APP_DATA_UPDATE_START = "APP_DATA_UPDATE_START";
export const APP_DATA_UPDATE_DONE = "APP_DATA_UPDATE_DONE";
export const APP_DATA_UPDATE_FAIL = "APP_DATA_UPDATE_FAIL";

export const SET_FILTERS_DATA = "SET_FILTERS_DATA";
export const SET_MULTIPLE_FILTERS_DATA = "SET_MULTIPLE_FILTERS_DATA";
export const SET_LAST_FILTERS = "SET_LAST_FILTERS";
export const ADD_FILTERS_SELECTION = "ADD_FILTERS_SELECTION";
export const SET_FILTERS_CHANGE = "SET_FILTERS_CHANGE";
export const RESET_FILTERS_DATA = "RESET_FILTERS_DATA";
export const RESET_FILTERS_BY_PARENT_KEY = "RESET_FILTERS_BY_PARENT_KEY";
export const TOGGLE_FILTERS_DATA = "TOGGLE_FILTERS_DATA";
export const TOGGLE_FILTER_DATA_BY_KEY = "TOGGLE_FILTER_DATA_BY_KEY";
export const TOGGLE_MULTIPLE_FILTERS_DATA = "TOGGLE_MULTIPLE_FILTERS_DATA";
export const DELETE_FILTERS_DATA = "DELETE_FILTERS_DATA";
export const DELETE_ALL_FILTERS_DATA = "DELETE_ALL_FILTERS_DATA";

export const SET_PRESET_DATA = "SET_PRESET_DATA";
export const CLEAR_PRESET_DATA = "CLEAR_PRESET_DATA";
export const CLEAR_All_PRESET_DATA = "CLEAR_All_PRESET_DATA";
export const SET_MULTIPLE_PRESET_DATA = "SET_MULTIPLE_PRESET_DATA";
export const SET_APPLIED_PRESET_ID = "SET_APPLIED_PRESET_ID";
export const RESET_APPLIED_PRESET_IDS = "RESET_APPLIED_PRESET_IDS";

export const SAVE_LEAGUES_TREE_EXPAND_STATE = "SAVE_LEAGUES_TREE_EXPAND_STATE";

export const UPDATE_SELECTED_EVENTS = "UPDATE_SELECTED_EVENTS";
export const SET_EVENT_HOVER_DATA = "SET_EVENT_HOVER_DATA";
export const CLEAR_ALL_SELECTED_EVENTS = "CLEAR_ALL_SELECTED_EVENTS";
export const ADD_TO_UPCOMING = "ADD_TO_UPCOMING";
export const CHECK_CART_EVENTS_EXISTENCE = "CHECK_CART_EVENTS_EXISTENCE";
export const INIT_SELECTIONS_DATA_WATCHER = "INIT_SELECTIONS_DATA_WATCHER";

export const MAIN_LOADER = "MAIN_LOADER";
export const SELECT_LEAGUE_ROW = "SELECT_LEAGUE_ROW";
export const SELECT_TAB_MENU_ITEM = "SELECT_TAB_MENU_ITEM";
export const SELECT_RIGHT_TAB_MENU_ITEM = "SELECT_RIGHT_TAB_MENU_ITEM";
export const RESET_SCROLL_OFFSET = "RESET_SCROLL_OFFSET";

export const SWITCH_INTERCOM = "SWITCH_INTERCOM";
export const SHOW_INTERCOM = "SHOW_INTERCOM";

export const DATA_LOAD_START = "DATA_LOAD_START";
export const DATA_LOAD_DONE = "DATA_LOAD_DONE";
export const DATA_LOAD_FAIL = "DATA_LOAD_FAIL";
export const DATA_LOAD_RESET = "DATA_LOAD_RESET";

export const OPEN_RIGHT_PANEL = "OPEN_RIGHT_PANEL";
export const CLOSE_RIGHT_PANEL = "CLOSE_RIGHT_PANEL";
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const TOGGLE_UI_KEY = "TOGGLE_UI_KEY";
export const CHANGE_COLOR = "CHANGE_COLOR";
export const ENABLE_CREATE_BUTTON = "ENABLE_CREATE_BUTTON";

export const SELECT_EVENTS_TYPE = "SELECT_EVENTS_TYPE";

export const EXPORT_DATA = "EXPORT_DATA";
export const EXPORT_DISABLE = "EXPORT_DISABLE";

export const API_INTEGRATION_PAGE = "API_INTEGRATION_PAGE";

export const SELECT_AUTOBOOKING_RULE = "SELECT_AUTOBOOKING_RULE";
export const SET_MULTIPLE_AUTOBOOKING_RULES = "SET_MULTIPLE_AUTOBOOKING_RULES";
export const CLEAR_AUTOBOOKING_RULES = "CLEAR_AUTOBOOKING_RULES";
export const TOGGLE_AUTOBOOKING_RULES = "TOGGLE_AUTOBOOKING_RULES";
export const CURRENTLY_EDITING_RULE_ID = "CURRENTLY_EDITING_RULE_ID";

export const SET_TOAST = "SET_TOAST";

export const SELECT_OPERATOR_TAB_TYPE = "SELECT_OPERATOR_TAB_TYPE";
export const SELECT_FEDERATIONS_TAB_TYPE = "SELECT_FEDERATIONS_TAB_TYPE";
export const SELECT_UNIQUE_FEDERATION_TAB_TYPE = "SELECT_UNIQUE_FEDERATION_TAB_TYPE";

export const SET_BREADCRUMB_BASE = "SET_BREADCRUMB_BASE";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";
