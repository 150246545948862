import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Menu from '../menu';
import Button from '../button';
import Popover from '../popover';

import { t } from "../../../helpers/translate";

import { TOAST_TYPES } from '../../../constants/uiConstants';
import { popupConfig } from '../../../configs/popupConfig';
import { openPopup, registerToastMessage } from '../../../actions/sync-actions/uiActions';

import './creditCard.scss';

const CreditCard = props => {
    const {
        mode,
        nonce,
        onClick,
        cardType,
        disabled,
        selected,
        setDefault,
        description,
        defaultMethod,
        defaultLoading,
        expirationYear,
        expirationMonth,
        onPaymentMethodAdd,
        deletePaymentMethod,
    } = props;

    const dispatch = useDispatch();
    const [popoverOpened, setPopoverOpened] = useState(false);

    const handleCardDeletion = () => {
        dispatch(openPopup('message', {
            ...popupConfig.deleteCard,
            handleCallBack: () => {
                deletePaymentMethod(nonce)
                    .then(() => dispatch(registerToastMessage({type: TOAST_TYPES.SUCCESS, message: "Successfully Deleted"})))
                    .catch(() => dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"})))
            }
        }));
    };

    const card = cardType ? cardType.toLowerCase().replace(' ', '-') : mode;

    return (
        <div
            className={classnames(
                `card-holder`,
                `cc-${card}`,
                {
                    disabled,
                    selected,
                    'cursor-pointer': !selected && !!onClick,
                }
            )}
            onClick={() => {
                typeof onPaymentMethodAdd === 'function' && onPaymentMethodAdd();
                typeof onClick === 'function' && onClick();
            }}
        >
            {mode === 'add' ? (
                <div className='add-new-card-holder'>
                    <Icon
                        type='icon-plus'
                        size={48}
                    />
                    <p>{t("Add new Card")}</p>
                </div>
            ) : (
                <>
                    <>
                        <div
                            className={classnames(
                                `cc-logo-holder`,
                                `cc-${card}`,
                            )}
                        />
                        <div className='cc-number ellipsis-text'>
                            <strong>{description}</strong>
                        </div>
                        {expirationMonth && (
                            <div className='cc-expiration'>
                                {`${expirationMonth}/${(expirationYear ?? '').slice(-2)}`}
                            </div>
                        )}
                        {defaultMethod && (
                            <div className='cc-default-holder'>
                                <Icon
                                    size={18}
                                    type='icon-check'
                                />
                                <span>{t("Default")}</span>
                            </div>
                        )}
                        {(!defaultMethod || !!deletePaymentMethod) && (
                            <Popover
                                selfSizing
                                position="bottom-end"
                                opened={popoverOpened}
                                onClose={() => setPopoverOpened(false)}
                                content={
                                    <Menu
                                        data={[
                                            {
                                                onClick: () => typeof setDefault === 'function' && setDefault(() => setPopoverOpened(false)),
                                                name: t('Make as default'),
                                                disabled: defaultMethod,
                                                icon: defaultLoading ? 'icon-spinner' : 'icon-check',
                                            },
                                            {
                                                type: 'divider',
                                            },
                                            {
                                                onClick: () => {
                                                    handleCardDeletion();
                                                    setPopoverOpened(false);
                                                },
                                                disabled: !deletePaymentMethod,
                                                name: t('Delete Card'),
                                                color: 'danger',
                                                icon: 'icon-delete',
                                            },
                                        ]}
                                    />
                                }
                            >
                                <div className='cc-actions-holder'>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setPopoverOpened(val => !val);
                                        }}
                                        appearance='light'
                                        color='default'
                                        size="medium"
                                        active={popoverOpened}
                                        icon="icon-more"
                                    />
                                </div>
                            </Popover>
                        )}
                    </>
                </>
            )}
        </div>
    )
};

CreditCard.defaultProps = {
    mode: 'view',
}

CreditCard.propTypes = {
    mode: PropTypes.oneOf(['add', 'view']),
    description: PropTypes.string,
    expirationMonth: PropTypes.string,
    expirationYear: PropTypes.string,
    selected: PropTypes.bool,
    cardType: PropTypes.string,
    nonce: PropTypes.string,
    email: PropTypes.string,
    defaultMethod: PropTypes.bool,
    defaultLoading: PropTypes.bool,
    setDefault: PropTypes.func,
    onPaymentMethodAdd: PropTypes.func,
    deletePaymentMethod: PropTypes.func,
};

export default CreditCard;
