import React from 'react';
import classnames from 'classnames';
import Config from "../../../../configs/mainConfig";
import {InputWithAccept} from "../../../reusableUIComponents/inputWithAccept";
import PropTypes from 'prop-types';
import Checkbox from "../../checkbox";
import Flag from "../../flag";

import './countriesTreeBranch.scss';

const CountriesTreeLeaf = (props) => {
    const {
        mode,
        readOnly,
        name,
        value,
        onChange,
        highlighted,
        flag,
    } = props;

    return (
        <label
            className={classnames('tree-branch external-hover', {
                'cursor-pointer': true,
                'pointer-events-none': readOnly,
                highlighted,
            })}
        >
            <div className="tree-leaf t-flag">
                <Flag
                    name={flag}
                    size={highlighted ? 'default' : 'medium'}
                />
            </div>
            <div className="tree-leaf t-name">{name}</div>
            <div className="tree-leaf t-line" />
            <div
                className={classnames('tree-leaf t-changer', {
                    'padding': mode !== 'pricing',
                })}
            >
                {mode === 'pricing' ? (
                    !readOnly || (readOnly && value) ? (
                        <InputWithAccept
                            indexKey="priceInput"
                            value={value}
                            currency={Config.main.currency}
                            callBackFunction={!readOnly ? onChange : void 0}
                            editable={!readOnly}
                        />
                    ) : null
                ) : (
                    (readOnly && value) || !readOnly ? (
                        <Checkbox
                            readOnly={readOnly}
                            checked={value}
                            onChange={(e) => {!readOnly && onChange({value: e.target.checked})}}
                        />
                    ) : null
                )}
            </div>
        </label>
    )
};

CountriesTreeLeaf.propTypes = {
    flag: PropTypes.string,
    mode: PropTypes.string,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func
};

CountriesTreeLeaf.defaultProps = {
    readOnly: false,
};


export default CountriesTreeLeaf;
