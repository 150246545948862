import {
    DATA_LOAD_START,
    DATA_LOAD_DONE,
    DATA_LOAD_FAIL,
    DATA_LOAD_RESET
} from "../actions/action-types";
import {DATA_STATES} from "../constants/dataConstants";


export const DataStatesReducer = (state = {}, action) => {
    const buildDataStateStructure = (state = {}, action = {}, loadingState = {}) => {
        const {dataStateByDeepKey} = action.meta || {};
        if (dataStateByDeepKey) {
            return {
                ...state,
                [action.reducerKey]: {
                    ...state[action.reducerKey],
                    [dataStateByDeepKey]: {
                        ...DATA_STATES,
                        ...loadingState
                    }
                }
            };
        } else {
            return {
                ...state,
                [action.reducerKey]: {
                    ...DATA_STATES,
                    ...loadingState
                }
            }
        }
    };

    if (!action.reducerKey) return state;
    switch (action.type) {
        case DATA_LOAD_START:
            return buildDataStateStructure(state, action, { loading: true });
        case DATA_LOAD_DONE:
            return buildDataStateStructure(state, action, { loaded: true });
        case DATA_LOAD_FAIL:
            return buildDataStateStructure(state, action, { failed: true });
        case DATA_LOAD_RESET:
            return {
                ...state,
                [action.reducerKey]: DATA_STATES
            };
        default :
            return state;
    }
};
