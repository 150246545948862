import {SET_TOAST} from "../actions/action-types";

const ToastReducer = (state = null, action) => {
    if (action.type === SET_TOAST) {
        return {
            id: Date.now(),
            ...action.payload
        };
    }
    return state;
};

export default ToastReducer;
