import React, { useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from "react-router-dom";

import Icon from '../icon';
import Popover from '../popover';

import { getFederationsSteps, getOperatorsSteps } from '../../../helpers/breadCrumbsHelper';
import { getBasePath } from '../../../helpers/routeHelper';
import { t } from "../../../helpers/translate";

import './breadCrumbs.scss';
import { selectUiDataForBreadCrumbs } from '../../../helpers/selectors';

const BreadCrumbs = ({ type }) => {
    const history = useHistory();
    const basePath = getBasePath();
    const {id} = useRouteMatch(`${basePath}${type}/:id`)?.params;
    const [opened, setOpened] = useState(null);
    const dispatch = useDispatch();
    const { eventsType, federationsTabType, uniqueFederationTabType, operatorTabType, breadcrumbBase } = useSelector(selectUiDataForBreadCrumbs);

    const getDataByType = (type) => {
        switch (type) {
            case "/federations":
                return getFederationsSteps(type, federationsTabType, breadcrumbBase, uniqueFederationTabType, eventsType, dispatch, history, id);
            case "/operators":
                return getOperatorsSteps(type, breadcrumbBase, operatorTabType, dispatch, history);
            default:
                return [];
        }
    };

    const data = getDataByType(type);

    return (
        <ul className='bread-crumbs-holder'>
            {data.map((obj, index) => {
                const className = `bc-item`;

                return (
                    <React.Fragment key={index}>
                        <li>
                            {obj.children ? (
                                <Popover
                                    selfSizing
                                    offsetBounding='-20, 20'
                                    opened={opened === obj.id}
                                    onClose={() => setOpened(null)}
                                    content={
                                        <ul className='navigation-dropdown'>
                                            {obj.children.map((childObj, index) => (
                                                <li
                                                    key={index}
                                                    className={classnames({
                                                        'active': childObj.selected,
                                                    })}
                                                    onClick={() => {
                                                        setOpened(null);
                                                        typeof childObj.onClick === "function" && childObj.onClick();
                                                    }}
                                                >
                                                    {t(childObj.name)}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                >
                                    <div
                                        className={className}
                                        onClick={() => setOpened(obj.id)}
                                    >
                                        <span>{t(obj.name)}</span>
                                        {data.length - 1 === index && (
                                            <Icon
                                                type='icon-arrow-down-outline'
                                                size={20}
                                            />
                                        )}
                                    </div>
                                </Popover>
                            ) : (
                                <div
                                    className={classnames(
                                        className,
                                        {
                                            'cursor-pointer': obj.onClick,
                                        }
                                    )}
                                    onClick={obj.onClick}
                                >
                                    {t(obj.name)}
                                </div>
                            )}
                        </li>
                        {data.length - 1 !== index && (
                            <li>
                                <Icon
                                    type='icon-arrow-right-outline'
                                    size={20}
                                />
                            </li>
                        )}
                    </React.Fragment>
                )
            })}
        </ul>
    )
};

export default BreadCrumbs;
