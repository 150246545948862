import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { VIDEO_STREAMING_PRODUCT_ID } from "../../../constants/dataConstants";
import { Form, Field } from "formik";
import TimeRangePicker from "../../reusableUIComponents/timeRangePicker";
import CompetitorsDynamicSelect from "../../reusableUIComponents/competitorsDynamicSelect";
import leagueDataService from "../../../services/leagueDataService";
import { setAutobookingRuleEditId } from "../../../actions/sync-actions/autoBooking";
import { enableRuleCreationButton, resetStyle } from "../../../actions/sync-actions/uiActions";
import Helpers from "../../../helpers/helperFunctions";
import Input from "../../shared/input";
import DateRangeFilter from "../../shared/dateRageFilter";
import WeekDayPicker from "../../shared/weekDayPicker";
import ModalContent from "../../shared/modal/parts/content";
import DropDown from "../../shared/dropDown";
import Collapser from "react-styled-collapser";
import DropDownItemWithDescription from "../../shared/dropDown/pieces/dropDownItemWithDescription";
import componentDataProvider from '../../../hocs/componentDataProvider';
import Checkbox from '../../shared/checkbox';
import { listTimeZones } from 'timezone-support';
import { addYears, startOfDay } from "date-fns";
import { t } from '../../../helpers/translate';

const RuleCreationForm = (props) => {
    const {handleFormSubmit, initialState, editingRuleId, ruleCreatingButtonState, formProps, closePopup, countriesList} = props;
    const {values, setFieldValue, setTouched, handleReset} = formProps;
    const {sport_id, product_ids, countries, valid_time_period, valid_week_days, valid_date_period, region_ids, time_zone} = values;
    const [selectedSport, setSelectedSport] = useState(values.sport_id);
    const [isRuleIndefinite, setIsRuleIndefinite] = useState(!valid_date_period.start && !valid_date_period.end);

    const selectedTimeZoneIndex = listTimeZones().sort().indexOf(time_zone);

    useEffect(() => {
        if (sport_id === -1) {
            !Helpers.isEqual(initialState.valid_week_days, values.valid_week_days) && setFieldValue("valid_week_days", initialState.valid_week_days);
            !Helpers.isEqual(initialState.valid_time_period, values.valid_time_period) && setFieldValue("valid_time_period", initialState.valid_time_period);
        }
        formProps.dirty && setFieldValue('league_ids', []);
    }, [sport_id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isRuleIndefinite) {
            const date = {
                start: isRuleIndefinite ? null : Math.floor(startOfDay(new Date()).valueOf() / 1000),
                end: isRuleIndefinite ? null : Math.floor(addYears(new Date(), 1).valueOf() / 1000),
            };
            setFieldValue("valid_date_period", date);
        }
    }, [isRuleIndefinite]); // eslint-disable-line react-hooks/exhaustive-deps

    const setTouchedField = (name, form) => {
        !form.touched[name] && setTouched({...form.touched, [name]: true});
    };

    const onReset = () => {
        props.dispatch(enableRuleCreationButton(true));
        editingRuleId && props.dispatch(setAutobookingRuleEditId(null));
        handleReset();
    };

    useEffect(() => {
        return () => onReset();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form>
            <ModalContent
                primaryButton={{
                    name: `${editingRuleId ? t('Update') : t('Create')} ${t('rule')}`,
                    disabled: editingRuleId ? (Helpers.deepEqual(values, formProps.initialValues) || ruleCreatingButtonState) : ruleCreatingButtonState,
                    color: editingRuleId ? 'primary' : 'confirm',
                    onClick: () => {
                        handleFormSubmit(values, formProps)
                    }
                }}
                secondaryButton={{
                    name: t('Cancel'),
                    onClick: () => {
                        closePopup();
                        onReset();
                    }
                }}
            >
                <div className="form-grid col-6">
                    <Field
                        name="name"
                        render={({field, form: {errors}}) => (
                            <Input
                                {...field}
                                placeholder={t('Rule name')}
                                label={t('Name the rule')}
                                errorText={errors.sameNameError || errors.same_rule}
                                className="span-2"
                                labelAppearance="title"
                            />
                        )}
                    />
                    <Field
                        name="countries"
                        render={({field: {name}, form}) => form.values.product_ids.includes(VIDEO_STREAMING_PRODUCT_ID) && (
                            <DropDown
                                keys={{key: 'iso3_code', value: 'name'}}
                                externalData={countriesList}
                                initialRequestParams={values.countries.length ? {iso3_codes: values.countries.join(',')} : null}
                                initialSelections={countriesList.reduce((acc, curr) => {
                                    if (values.countries.includes(curr.iso3_code)) {
                                        return {...acc, [curr.iso3_code]: curr};
                                    }
                                    return acc;
                                }, {})}
                                label={t("Country")}
                                placeholder={t("Select countries")}
                                labelAppearance="title"
                                multiSelect
                                allowSelectAll
                                withLazyLoad={false}
                                onChange={countries => {
                                    props.dispatch(resetStyle("yellow"));
                                    setFieldValue(name, countries.map(val => val["iso3_code"]));
                                    setTouchedField(name, form);
                                }}
                                className="span-2"
                            />
                        )}/>
                    <Field
                        name="sport_id"
                        render={({field: {name}, form}) => (
                            <DropDown
                                service={leagueDataService.getSportsByParams}
                                label={t('Sport')}
                                placeholder={t('Select sport')}
                                labelAppearance="title"
                                disabled={!form.values.product_ids.length}
                                initialSelections={values.sport_id === -1 ? {id: -1, name: t('All')} : null}
                                initialRequestParams={values.sport_id && values.sport_id !== -1 ? {ids: values.sport_id} : null}
                                requestParams={values.product_ids.length ? {product_ids: values.product_ids.join(',')} : {}}
                                allowSelectAll
                                onChange={sport => {
                                    form.setValues(
                                        {
                                            ...initialState,
                                            [name]: sport.id,
                                            name: values.name,
                                            product_ids,
                                            countries,
                                            valid_time_period,
                                            valid_week_days,
                                            valid_date_period,
                                            league_ids: sport.id !== values[name] ? [] : values.league_ids,
                                            region_ids: sport.id !== values[name] ? [] : values.region_ids
                                        }
                                    );
                                    setSelectedSport(sport.id);
                                }}
                                className="span-2"
                            />
                        )}
                    />
                    <Collapser
                        collapsed={(!selectedSport || selectedSport === -1)}
                        className="full-width span-6"
                    >
                        <div className="form-grid col-2">
                            <Field
                                name="region_ids"
                                render={({form, field: {name}}) =>
                                    <DropDown
                                        key={sport_id}
                                        disabled={(!form.values.sport_id || form.values.sport_id === "all")}
                                        requestParams={{
                                            ...(values.product_ids.length ? {product_ids: values.product_ids} : {}),
                                            ...(values.sport_id ? {sport_id: values.sport_id} : {})
                                        }}
                                        initialRequestParams={values.region_ids.length ? {ids: values.region_ids.join(',')} : null}
                                        service={leagueDataService.getRegionsByParams}
                                        placeholder={t('Select regions')}
                                        label={t('Region')}
                                        labelAppearance="title"
                                        multiSelect
                                        onChange={regions => {
                                            form.setValues({
                                                ...initialState,
                                                [name]: regions.map(val => val.id),
                                                sport_id,
                                                name: values.name,
                                                product_ids,
                                                countries,
                                                valid_time_period,
                                                valid_week_days,
                                                valid_date_period,
                                            });
                                            setTouchedField(name, form);
                                        }}
                                    />
                                }
                            />
                            <Field
                                name="league_ids"
                                render={({form, field: {name}}) =>
                                    <DropDown
                                        key={`${sport_id}${region_ids.length}`}
                                        disabled={(!form.values.sport_id || form.values.sport_id === "all")}
                                        requestParams={{
                                            ...(values.product_ids.length ? {product_ids: values.product_ids} : {}),
                                            ...(values.sport_id ? {sport_ids: values.sport_id} : {}),
                                            ...(values.region_ids.length ? {region_ids: values.region_ids} : {}),
                                            ...{
                                                includes: "sport,region",
                                                sportFields: "name,alias",
                                                regionFields: "name"
                                            },
                                            all: 1
                                        }}
                                        initialRequestParams={values.league_ids.length ? {
                                            ids: values.league_ids.join(','),
                                            ...{
                                                includes: "sport,region",
                                                sportFields: "name,alias",
                                                regionFields: "name"
                                            },
                                        } : null}
                                        service={leagueDataService.getLeaguesByParams}
                                        labelAppearance="title"
                                        label={t('League')}
                                        multiSelect
                                        iconKey="sport.alias"
                                        rowRenderer={(item) => <DropDownItemWithDescription item={item}/>}
                                        placeholder={t('Select leagues')}
                                        onChange={leagues => {
                                            form.setValues({
                                                ...initialState,
                                                [name]: leagues.map(val => val.id),
                                                region_ids,
                                                sport_id,
                                                name: values.name,
                                                product_ids,
                                                countries,
                                                valid_time_period,
                                                valid_week_days,
                                                valid_date_period
                                            });
                                            setTouchedField(name, form);
                                        }}
                                    />
                                }
                            />
                        </div>
                    </Collapser>
                    <Field
                        name="time_zone"
                        render={(fieldProps) => (
                            <DropDown
                                label={t('Time zone')}
                                labelAppearance="title"
                                placeholder={t('Select Time Zone')}
                                onChange={(val) => {
                                    fieldProps.form.setFieldValue(fieldProps.field.name, val.name);
                                }}
                                className="span-2"
                                externalData={listTimeZones().sort().map((name, id) => ({id, name}))}
                                withLazyLoad={false}
                                multiSelect={false}
                                initialSelections={{id: selectedTimeZoneIndex, name: time_zone}}
                            />
                        )}
                    />
                    <Field
                        name="valid_time_period"
                        validate={(values) => {
                            const [startHour, startMinute] = values.start.split(':');
                            const [endHour, endMinute] = values.end.split(':');
                            if ((endHour - startHour < 0) || (endHour === startHour && (endMinute - startMinute < 0))) {
                                return t('Wrong range');
                            }
                            return '';
                        }}
                        render={(fieldProps) => (
                            <TimeRangePicker
                                range={fieldProps.field.value}
                                onChange={value => {
                                    fieldProps.form.setFieldValue(fieldProps.field.name, value);
                                }}
                                errorText={fieldProps.form.errors ? fieldProps.form.errors.valid_time_period : ''}
                                className="span-4"
                                labelAppearance="title"
                            />
                        )}
                    />
                    <Field
                        name="valid_date_period"
                        render={(fieldProps) => (
                            <DateRangeFilter
                                start={fieldProps.field.value.start * 1000}
                                end={fieldProps.field.value.end * 1000}
                                startFilterName="startDate"
                                endFilterName="endDate"
                                onChange={(value) => {
                                    fieldProps.form.setFieldValue(fieldProps.field.name, {
                                        start: value.start,
                                        end: value.end
                                    });
                                }}
                                className="span-2"
                                labelAppearance="title"
                                disabled={isRuleIndefinite}
                                daysUpToToday={false}
                            />
                        )}
                    />
                    <div className='span-2 checkbox-in-form'>
                        <Checkbox
                            checked={isRuleIndefinite}
                            label={t('Permanent booking')}
                            onChange={() => setIsRuleIndefinite(!isRuleIndefinite)}
                        />
                    </div>
                    <Field
                        name="valid_week_days"
                        render={(props) => (
                            <WeekDayPicker
                                selectedIds={props.field.value}
                                onChange={(value) => props.form.setFieldValue(props.field.name, value)}
                                className="span-6"
                            />
                        )}
                    />
                    <Field
                        name="teams"
                        render={(fieldProps) => (
                            <Collapser
                                collapsed={(!fieldProps.form.values.sport_id || fieldProps.form.values.sport_id === -1)}
                                className="full-width span-6"
                            >
                                <div className="form-grid col-6">
                                    <CompetitorsDynamicSelect
                                        disabled={(!fieldProps.form.values.sport_id || fieldProps.form.values.sport_id === -1)}
                                        className="span-6"
                                        {...fieldProps}
                                    />
                                </div>
                            </Collapser>
                        )}
                    />
                </div>
            </ModalContent>
        </Form>
    );
};

RuleCreationForm.propTypes = {
    values: PropTypes.object,
    resetForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    handleReset: PropTypes.func,
    isValid: PropTypes.bool,
    editingRuleId: PropTypes.any,
    initialState: PropTypes.object,
    ruleCreatingButtonState: PropTypes.bool
};

RuleCreationForm.requiredProps = {
    countriesList: PropTypes.array
};

export default componentDataProvider(RuleCreationForm);
