import React from "react";
import PropTypes from 'prop-types';
import Button from "../../button";
import Checkbox from "../../checkbox";
import { t } from '../../../../helpers/translate';

const ModalContent = props => {
    const {
        children,
        dontShowAgain,
        onDontShowAgain,
        secondaryButton,
        primaryButton,
    } = props;

    return (
        <>
            <div className="modal-content">
                {children}
            </div>
            {(dontShowAgain || secondaryButton || primaryButton) && (
                <>
                    <ul className="modal-footer">
                        {dontShowAgain && (
                            <li>
                                <Checkbox label={t('Do not show again')} onChange={onDontShowAgain} />
                            </li>
                        )}
                        <li>
                            {secondaryButton && (
                                <Button
                                    onClick={secondaryButton.onClick}
                                    flexibility="default"
                                    appearance="minimal"
                                    color={secondaryButton.color || 'default'}
                                >
                                    {t(secondaryButton.name || 'Cancel')}
                                </Button>
                            )}
                            {primaryButton && (
                                <Button
                                    onClick={primaryButton.onClick}
                                    flexibility="default"
                                    disabled={primaryButton.disabled}
                                    color={primaryButton.color}
                                    icon={primaryButton.icon}
                                >
                                    {t(primaryButton.name || 'Ok')}
                                </Button>
                            )}
                        </li>
                    </ul>
                </>
            )}
        </>
    )
};

ModalContent.propTypes = {
    children: PropTypes.any.isRequired,
    dontShowAgain: PropTypes.bool,
    onDontShowAgain: PropTypes.func,
    secondaryButton: PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        color: Button.propTypes.color,
    }),
    primaryButton: PropTypes.shape({
        name: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        color: Button.propTypes.color,
        icon: ''
    }),
};

ModalContent.defaultProps = {
    dontShowAgain: false,
};

export default ModalContent;
