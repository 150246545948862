import Helpers from "../../helpers/helperFunctions";
import {SET_FILTERS_CHANGE, SET_LAST_FILTERS} from "../../actions/action-types";

/**
 * @description Function will change filters state by parent filter.
 * @param state
 * @param action
 * @param changedPart
 * @returns {{}}
 */
export const changeByParent = (state, action, changedPart) => {
    const subKeys = (state[action.parentKey] && state[action.parentKey].subKeys) || {};
    return {
        ...state,
        [action.parentKey]: {
            ...state[action.parentKey],
            subKeys: {
                ...subKeys,
                [action.key]: changedPart
            }
        }
    };
};

/**
 * @description Function will delete subFilters keys wich are changes in parent Filter.
 * @param state
 * @param key
 * @param deletedFilters
 * @returns {string}
 */
export const deleteSubFilters = (state, key, deletedFilters) => {
    const subKeys = state[key].subKeys;

    return Object.keys(subKeys).reduce((acc, key) => {
        const subKey = acc[key];
        deletedFilters.forEach(filter => {
            if (filter in subKey) {
                delete subKey[filter];
            }
        });

        return {
            ...acc,
            [key]: subKey
        };

    }, Helpers.cloneDeep(subKeys));
};

/**
 * @description Set filters params by parent and subKeys.
 * Function will help filtersReducers for setting filters and multiple filters params.
 *
 * @param state
 * @param action
 * @returns {{}}
 */
export const setFiltersParams = (state, action) => {
    switch (action.type) {
        case SET_LAST_FILTERS:
        case SET_FILTERS_CHANGE: {
            if (action.parentKey) {
                return changeByParent(state, action, action.payload);
            }
            const subKeys = state[action.key] && state[action.key].subKeys && deleteSubFilters(state, action.key, Object.keys(action.payload));

            return {
                ...state,
                [action.key]: {
                    ...action.type === SET_FILTERS_CHANGE ? {} : state[action.key],
                    ...action.payload,
                    subKeys
                }
            };
        }
        default:
            return state;
    }
};
