import dataFetcherService from "./dataFetcherService";
import {USER_STORE} from "../constants/api-resources";
import {from} from "rxjs";
import {pluck} from "rxjs/operators";

class StoreDataService {
    storeData = (key = 'global', params) => {
        return from(dataFetcherService.putJson(`${USER_STORE.STORE_DATA.URL}/${key}`, {params})).pipe(pluck('data'))
    };
    getStoredData = (key = 'global') => {
        return from(dataFetcherService.getJson(`${USER_STORE.GET_DATA.URL}/${key}`)).pipe(pluck('data'));
    };
    deleteStoredData = (key = 'global') => {
        return from(dataFetcherService.deleteJson(`${USER_STORE.DELETE_DATA.URL}/${key}`)).pipe(pluck('data'));
    }
}

export default new StoreDataService();
