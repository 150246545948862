/**
 * @description Get relative filterParams names.
 * @param filterName
 * @returns {*}
 */
import { FILTER_LEAGUES, REGIONS, SPORTS, LEAGUES, REPORTS } from "../constants/reducerKeys";

/**
 * @description Get relative filter params keys by filter name
 * @param filterName
 * @returns {*}
 */
export const getRelativeFilters = filterName => {
    const defaultRFilters = ["start", "end"];

    switch (filterName) {
        case SPORTS:
        case REPORTS.SPORTS:
            return defaultRFilters;
        case REPORTS.REGIONS :
        case REGIONS:
            return [...defaultRFilters, "sport_ids"];
        case FILTER_LEAGUES:
            return [...defaultRFilters, "sport_ids", "region_ids"];
        case LEAGUES:
        case REPORTS.COMPETITIONS:
            return [...defaultRFilters, "sport_ids", "region_ids"];
        default: return [];
    }
};
