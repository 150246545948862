import DataFetcher from "./dataFetcherService";
import {TEMPLATES} from "../constants/api-resources";
import {BehaviorSubject} from "rxjs";
import {templatesDefaultParams} from "../constants/queryParams";
import Helpers from "../helpers/helperFunctions";

class TemplatesDataService {
    constructor () {
        this._templatesData = new BehaviorSubject({});
    }

    getTemplatesList (queryParams = {}) {
        this._templatesData.next({...this._templatesData.getValue(), isLoading: true});
        return DataFetcher.getJson(TEMPLATES.TEMPLATES_LIST.URL, {queryParams: {active: 1, ...queryParams}})
            .then((res) => {
                this._templatesData.next({
                    ...this._templatesData.getValue(),
                    data: res.data,
                    isLoading: false,
                    total_count: res.total_count
                });
            }).catch(e => e);
    }

    createTemplate (params) {
        return DataFetcher.postJson(TEMPLATES.TEMPLATES_LIST.URL, {params})
            .then(res => {
                const templatesData = this._templatesData.getValue();
                const newData = [
                    {...res.data, assigned_to: 0, active: 1},
                    ...(templatesData.data || [])
                ];

                this._templatesData.next({
                    ...this._templatesData.getValue(),
                    data: newData.length > templatesDefaultParams().limit ? newData.slice(0, -1) : newData,
                    total_count: Number(templatesData.total_count ?? 0) + 1
                });
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err))
    }

    updateTemplate (id, params) {
        return DataFetcher.putJson(`${TEMPLATES.TEMPLATES_LIST.URL}/${id}`, {params})
            .then(res => {
                this._templatesData.next({
                    ...this._templatesData.getValue(),
                    data: Helpers.addOrUpdateArrayOfObjectsValue(this._templatesData.getValue().data, res.data, 'id'),
                });
                return Promise.resolve(res);
            }).catch(e => e);
    }

    getTemplatesByParams (params) {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(TEMPLATES.TEMPLATES_LIST.URL, {queryParams: params})
                .then(res => { resolve(res); })
                .catch(err => { reject(err); });
        });
    }

    getTemplateById (id) {
        return DataFetcher.getJson(`${TEMPLATES.TEMPLATES_LIST.URL}/${id}`)
            .then((res) => Promise.resolve(res))
            .catch(err => Promise.reject(err))
    }

    get templatesData () {
        return this._templatesData;
    }
}

export default new TemplatesDataService();
