import React, { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import braintreeLib from 'braintree-web';

const PaymentForm = ({ authorization, styles, setTokenRef, children, setFormStateRef }) => {
    const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null);

    const childes = Children.toArray(children).filter(child => child);

    const collectFieldOptions = (childes) => {
        return childes.reduce((acc, curr) => {
            const { id, type, placeholder } = curr.props;
            return {
                ...acc,
                [type]: {
                    container: `#${id}`,
                    placeholder,
                }
            };
        }, {});
    };

    const tokenize = (options = {
        vault: true
    }) => {
        return new Promise((resolve, reject) => {
            hostedFieldsInstance.tokenize(options)
                .then(payload => resolve(payload))
                .catch(err => reject(err));
        });
    };

    const getState = () => {
        return new Promise((resolve, reject) => {
            const state = hostedFieldsInstance.getState();
            state ? resolve(state) : reject(state);
        });
    }

    useEffect(() => {
        if (hostedFieldsInstance) {
            setTokenRef(tokenize);
            setFormStateRef(getState);
        }
    }, [hostedFieldsInstance]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (authorization) {
            const fields = collectFieldOptions(childes);

            braintreeLib.hostedFields.create({
                authorization,
                styles,
                fields,
                vault: true
            })
                .then(setHostedFieldsInstance)
                .catch((err) => Promise.reject(err));

        }
    }, [authorization]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {children}
        </>
    );
};

PaymentForm.propTypes = {
    authorization: PropTypes.string.isRequired,
    styles: PropTypes.object,
    setTokenRef: PropTypes.func,
    setFormStateRef: PropTypes.func,
}

export default PaymentForm;
