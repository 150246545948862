import usersDataService from "../../services/usersDataService";
import { closeRightPanel, openPopup, registerToastMessage } from "../sync-actions/uiActions";
import { LoggedIn, Logout, SetUserInitData } from "./authorization";
import {dataLoadStart, dataLoadDone, dataLoadFail, dataLoadReset} from "./index";
import {BECOME_PARTNER, USERS} from "../../constants/reducerKeys";
import {setSignature} from "../../configs/socketConfig";
import Socket from "../../helpers/socket";
import {TOAST_TYPES} from "../../constants/uiConstants";
import {getProjectStatus} from "../../helpers/uiHelpers";
import { popupConfig } from '../../configs/popupConfig';

export function updateUser (userId, params, changeStreamer, cb) {
    return function (dispatch) {
        dispatch(dataLoadStart(USERS.UPDATE_USERS));
        return usersDataService.updateUser(userId, params)
            .then(data => {
                dispatch(dataLoadDone(USERS.UPDATE_USERS, data));
                dispatch(registerToastMessage({ message: "User updated" }));
                dispatch(closeRightPanel());
                typeof cb === "function" && cb();
            })
            .catch((e) => {
                dispatch(dataLoadFail(USERS.UPDATE_USERS));
                if (changeStreamer) {
                    dispatch(openPopup("message", {
                        ...popupConfig.streamerChangeWarning,
                        handleCallBack: () => {
                            setTimeout(() => {
                                dispatch(openPopup("changeStreamer", {
                                    title: "Please change the streamer before we can disable this user",
                                    streamerId: userId,
                                }), 200)
                            });
                        }
                    }))
                } else {
                    dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: e?.body?.message || "Oops something went wrong"}));
                }
            });
    };
}

export function skipTrial (partnerId, params, toastMsg = {}) {
    return function (dispatch) {
        return usersDataService.skipTrial(partnerId, params)
            .then(() => {
                dispatch(registerToastMessage({message: toastMsg.successMsg || 'Organization Updated'}));
            })
            .catch(() => {
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"}));
            });
    };
}

/**
 * @description Gets user profile data
 * @return {Function}
 */
export function getUserProfile () {
    return (dispatch) => {
        dispatch(dataLoadStart(USERS.PROFILE));
        usersDataService.getUserProfile()
            .then(res => {
                const {isTrial, onlyIsDemo} = getProjectStatus();
                dispatch(dataLoadDone(USERS.PROFILE, res));
                setSignature(res.signature);
                Socket.connect().then(resp => resp).catch(err => err);
                dispatch(SetUserInitData({
                    roles: res.roles,
                    isRoleChosen: Boolean(res.is_role_chosen),
                    isDemo: onlyIsDemo,
                    isTrial: onlyIsDemo && isTrial
                }));
                dispatch(LoggedIn(true));
                dispatch(dataLoadDone('userState', res));
            })
            .catch(err => {
                dispatch(Logout());
                dispatch(dataLoadFail(USERS.PROFILE, err));
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"}));
            });
    };
}

export function updateUserProfile (params, callback) {
    return (dispatch) => {
        dispatch(dataLoadStart(USERS.PROFILE));
        usersDataService.updateUserProfile(params)
            .then(res => { dispatch(dataLoadDone(USERS.PROFILE, res)); callback && callback(res); })
            .catch(err => {
                dispatch(dataLoadFail(USERS.PROFILE, err));
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message: "Oops something went wrong"}));
            });
    };
}

export function getUsersList (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(USERS.USERS_LIST));
        usersDataService.getUsersList(params)
            .then(res => { dispatch(dataLoadDone(USERS.USERS_LIST, res)); })
            .catch(err => { dispatch(dataLoadFail(USERS.USERS_LIST, err)); });
    };
}

/**
 * Get user partner list
 * @param params
 * @returns {Function}
 */
export function getUserPartnersList (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(USERS.USER_PARTNERS_LIST));

        usersDataService.getUserPartnerList(params)
            .then(res => { dispatch(dataLoadDone(USERS.USER_PARTNERS_LIST, res)); })
            .catch(err => { dispatch(dataLoadFail(USERS.USER_PARTNERS_LIST, err)); });
    };
}

/**
 * Set user partner
 * @param params
 * @param partnerId
 * @param callback
 * @returns {Function}
 */
export function setUserPartner (params, partnerId, callback) {
    return (dispatch) => {
        dispatch(dataLoadStart(USERS.UPDATE_USER_PARTNER));
        usersDataService.setUserPartner(params, partnerId)
            .then(res => {
                dispatch(dataLoadDone(USERS.UPDATE_USER_PARTNER, res));
                callback && callback();
            })
            .catch(err => { dispatch(dataLoadFail(USERS.UPDATE_USER_PARTNER, err)); });
    };
}

export function createUser (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(USERS.CREATE_USER));
        usersDataService.createUser(params)
            .then(res => {
                dispatch(dataLoadDone(USERS.CREATE_USER, res));
                dispatch(registerToastMessage({message: "User Invited"}));
                dispatch(closeRightPanel());
            })
            .catch(err => {
                dispatch(dataLoadFail(USERS.CREATE_USER, err));
                const message = (err.statusCode === 422 && err.body?.message) || "Oops something went wrong";
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message}));
            });
    };
}

export function createPartnerRequest (params) {
    return (dispatch) => {
        dispatch(dataLoadStart(BECOME_PARTNER.CREATE_PARTNER_REQUEST));
        usersDataService.createPartnerRequest(params)
            .then(res => {
                dispatch(dataLoadDone(BECOME_PARTNER.CREATE_PARTNER_REQUEST, res));
                dispatch(registerToastMessage({ title: "Partner request sent" }));
                dispatch(closeRightPanel());
            })
            .catch(err => {
                dispatch(dataLoadFail(BECOME_PARTNER.CREATE_PARTNER_REQUEST, err));
                const message = (err.statusCode === 422 && err.body?.message) || "Oops something went wrong";
                dispatch(registerToastMessage({type: TOAST_TYPES.ERROR, message}));
            });
    };
}

export function stopUsersService () {
    return (dispatch) => {
        usersDataService.stop();
        dispatch(dataLoadReset(USERS.INIT));
    };
}
