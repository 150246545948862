import React, {useState} from 'react';
import classnames from "classnames";
import {Logout} from "../../../../actions/async-actions/authorization";
import mainConfig from '../../../../configs/mainConfig';
import { CLIENT, CLIENT_ADMIN, getRoleName, LEAGUE_OWNER } from '../../../../constants/userRoles';
import { getAvailableRoutes, getBasePath } from "../../../../helpers/routeHelper";
import { getRoleIds } from '../../../../helpers/userHelpers';
import usersDataService from '../../../../services/usersDataService';
import UserPartnersList from "../../userPartnersList";
import { useHistory } from "react-router-dom";
import {dataLoadReset} from "../../../../actions/async-actions";
import { openPopup, switchIntercomState } from "../../../../actions/sync-actions/uiActions";
import Icon from "../../icon";
import Menu from "../../menu";
import { t } from '../../../../helpers/translate';

import './accountPopover.scss';

const rolesMap = {
    ...getRoleName(),
    [CLIENT]: t('Admin'),
    [CLIENT_ADMIN]: t('Admin'),
    [LEAGUE_OWNER]: t('Content Provider'),
}

const HeaderMenu = (props) => {
    const { first_name, last_name, email, organization } = props;
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const routes = getAvailableRoutes("/settings");
    const profile = usersDataService.profile.getValue();
    const roles = getRoleIds(profile.roles) || [];
    const isScouting = getBasePath() === '/scouting';

    /**
     * @name handelClickLiveChat
     * @description Open and close intercom live chat
     * @return {undefined}
     * */
    const handelClickLiveChat = () => {
        props.dispatch(switchIntercomState())
    };

    const logOut = () => {
        props.dispatch(Logout());
        props.dispatch(dataLoadReset('userState'));
    };

    const handleNavigationChange = (route) => () => {
        history.push(`#${route.slice(1)}`);
        props.dispatch(openPopup("settings", {
            closeOnClickOutside: false
        }));
    };

    const openMyAccountURL = () => {
        const basePath = getBasePath();
        if (profile.is_betconstruct_user) {
            window.location.replace(mainConfig.main.betSsoProfile);
        }
        history.push(`${basePath}/account`);
    };

    return (
        <div className="account-popover">
            <ul className="user-profile-info-holder">
                <li>
                    <Icon type="icon-user-outline" size={32}/>
                </li>
                <li>
                    <h3 className="ellipsis-text">{`${first_name} ${last_name || ''}`}</h3>
                    <p className="ellipsis-text not-animation-fadeIn">{email}</p>
                    <p className="ellipsis-text not-animation-fadeIn">{roles.map(role => rolesMap[role]).join(', ')}</p>
                </li>
            </ul>
            <div className={classnames(
                `select-project-holder`,
                {
                    active: isOpen,
                    disabled: isScouting,
                }
            )}>
                <ul onClick={() => setIsOpen(!isOpen)}>
                    <li title={isScouting ? '' : (organization?.name ?? '')} className="ellipsis-text">
                        {isScouting ? 'Scouting Project' : (organization?.name ?? '')}
                    </li>
                    {!isScouting && <li className="not-animation-fadeIn">
                        <Icon type="icon-arrow-right-outline"/>
                    </li>}
                </ul>
            </div>
            {isOpen ? (
                <UserPartnersList/>
            ) : (
                <Menu
                    data={[
                        {
                            onClick: openMyAccountURL,
                            name: t('My Account'),
                            icon: "icon-user",
                        },
                        ...Object.keys(routes).map(key => {
                            const newRoute = routes[key];
                            return {
                                ...newRoute,
                                name: t(newRoute.name),
                                onClick: handleNavigationChange(key)
                            };
                        }),
                        {
                            onClick: handelClickLiveChat,
                            icon: 'icon-help',
                            name: t('Contact Support'),
                        },
                        {
                            type: 'divider',
                        },
                        {
                            onClick: logOut,
                            icon: 'icon-sign-out',
                            name: t('Log out'),
                            color: 'danger',
                        },
                    ]}
                />
            )}
        </div>
    );
};

export default HeaderMenu;
