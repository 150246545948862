import React, {useEffect, useState, Fragment} from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Manager, Reference, Popper} from 'react-popper';
import { useLocation } from 'react-router-dom';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { popoverConfig } from '../../../configs/uiConfig';

import './popover.scss';

const Popover = props => {
    const {
        header,
        footer,
        opened,
        sticky,
        content,
        maxItems,
        children,
        position,
        backdrop,
        className,
        animation,
        itemHeight,
        selfSizing,
        destination,
        onClickOutside,
        closeOnClickOutside,
        offsetBounding,
        scrollerRef,
        onClose,
        onOpen
    } = props;

    const [openedState, setOpenedState] = useState(opened);
    const [popperRef, hasPopperClickedOutside] = useClickOutside();
    const [referenceRef, hasReferenceClickedOutside] = useClickOutside();
    const location = useLocation();

    const wrappedDestination = destination === popoverConfig.destination[1];

    useEffect(() => {
        setOpenedState(opened);
    }, [opened]);

    useEffect(() => {
        if(closeOnClickOutside && (wrappedDestination ? hasPopperClickedOutside : hasPopperClickedOutside && hasReferenceClickedOutside)) {
            onClickOutside ? onClickOutside() : setOpenedState(false);
            onClose && onClose();
        }
    }, [closeOnClickOutside, hasPopperClickedOutside, hasReferenceClickedOutside, wrappedDestination, onClickOutside]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setOpenedState(false);
    }, [location.pathname, location.hash]); // eslint-disable-line react-hooks/exhaustive-deps

    const popperContent = () => (
        <Popper
            positionFixed={sticky}
            placement={position}
            modifiers={{
                preventOverflow: {
                    padding: 0,
                    boundariesElement: 'window'
                },
                offset: {
                    offset: offsetBounding,
                },
                computeStyle: {
                    gpuAcceleration: false,
                },
            }}
            innerRef={wrappedDestination ? null : (innerRef) => popperRef.current = innerRef}
        >
            {({ref, style}) => (
                <>
                    {backdrop && (
                        <div className='modal-backdrop t-popover' onClick={(e) => e.stopPropagation()}/>
                    )}
                    <div
                        ref={ref}
                        style={
                            {
                                width: !selfSizing ? wrappedDestination ? '100%' : referenceRef.current.clientWidth : null,
                                ...style,
                            }
                        }
                        className={
                            classnames('popover-holder', ` animation-${animation}`, className, {
                                'p-sticky': sticky,
                            })
                        }
                    >
                        <ul className="popover-c">
                            {header && (
                                <li className="po-header">{header}</li>
                            )}
                            <li
                                className="po-content"
                                ref={val => {typeof scrollerRef === 'function' && scrollerRef(val)}}
                                style={{
                                    maxHeight: maxItems ? `${(maxItems * itemHeight) / 10}rem` : null,
                                }}
                            >
                                {content}
                            </li>
                            {footer && (
                                <li className="po-footer">
                                    {footer}
                                </li>
                            )}
                        </ul>
                    </div>
                </>
            )}
        </Popper>
    );

    const managerContent = () => (
        <Fragment>
            <Reference innerRef={(innerRef) => referenceRef.current = innerRef}>
                {({ ref }) => React.cloneElement(children, {
                    ref: ref,
                    onClick: (children.props.onClick) ? children.props.onClick : (e) => {
                        e.stopPropagation();
                        setOpenedState((val) => !val);
                        typeof onOpen === 'function' && onOpen(true);
                    },
                    className: classnames(children.props.className, 'cursor-pointer', {
                        'popover-opened': openedState,
                    })
                })}
            </Reference>
            {openedState && (
                wrappedDestination ? popperContent() : ReactDOM.createPortal(popperContent(), document.body)
            )}
        </Fragment>
    );

    return (
        <Manager>
            {wrappedDestination ? (
                <div
                    className={classnames('popover-destination', 'cursor-pointer', {
                        'popover-opened': openedState,
                    })}
                    ref={popperRef}
                >
                    {managerContent()}
                </div>
            ) : managerContent()}
        </Manager>
    )
};

Popover.defaultProps = {
    header: null,
    footer: null,
    opened: false,
    sticky: false,
    content: null,
    maxItems: null,
    children: PropTypes.any,
    position: popoverConfig.position[10],
    animation: popoverConfig.animation[0],
    className: null,
    controlled: false,
    itemHeight: 48,
    selfSizing: false,
    destination: popoverConfig.destination[0],
    closeOnClickOutside: true,
    onClickOutside: null,
    offsetBounding: `0, 10`,
};

Popover.propTypes = {
    header: PropTypes.any,
    footer: PropTypes.any,
    opened: PropTypes.bool,
    sticky: PropTypes.bool,
    content: PropTypes.any.isRequired,
    maxItems: PropTypes.number,
    children: PropTypes.any.isRequired,
    position: PropTypes.oneOf(popoverConfig.position),
    backdrop: PropTypes.bool,
    className: PropTypes.string,
    animation: PropTypes.oneOf(popoverConfig.animation),
    controlled: PropTypes.bool,
    itemHeight: PropTypes.number,
    selfSizing: PropTypes.bool,
    closeOnClickOutside: PropTypes.bool,
    destination: PropTypes.oneOf(popoverConfig.destination),
    onClickOutside: PropTypes.func,
    offsetBounding: PropTypes.string,
};

export default Popover;
