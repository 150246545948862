import DataFetcher from "./dataFetcherService";
import watchListDataService from './watchListDataService';
import { BECOME_PARTNER, ORGANIZATIONS, USERS } from "../constants/api-resources";
import {
    STREAMER,
    LEAGUE_OWNER,
    MODERATOR,
    ADMIN,
    CLIENT,
    CP_ADMINISTRATOR,
    CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR
} from "../constants/userRoles";
import Helpers from "../helpers/helperFunctions";
import { usersDefaultParams } from "../constants/queryParams";
import { BehaviorSubject } from "rxjs";
import { listTimeZones } from "timezone-support";
import { getServiceUrl } from "../helpers/uiHelpers";

class UsersDataService {
    constructor() {
        this._usersList = new BehaviorSubject({});
        this._moderatorUsersList = new BehaviorSubject({});
        this._userProfile = new BehaviorSubject(null);
        this._streamersList = new BehaviorSubject([]);
        this._usersPartnersList = new BehaviorSubject(null);
    }

    getUsersList(params = {}) {
        const url = Helpers.checkRole(CLIENT) ? USERS.CLIENT_USERS_LIST.URL : this.usersUrl;
        return DataFetcher.getJson(url, {queryParams: {...usersDefaultParams(), ...params}})
            .then(res => {
                Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER) && window.location.hash
                    ? this._moderatorUsersList.next(res)
                    : this._usersList.next(res);
            }).catch(err => Promise.reject(err));
    }

    goLiveForApiPartner() {
        const {organization_id} = this._userProfile.getValue();
        return DataFetcher.postJson(`${ORGANIZATIONS.GO_LIVE.URL}/golive`, {params: {id: organization_id}})
            .then(res => {
                this.getUserProfile();

            }).catch(err => Promise.reject(err));
    }

    getStreamersList() {
        const queryParams = {
            role_ids: `${STREAMER}, ${LEAGUE_OWNER}`,
            active: true
        };
        Helpers.checkRole(MODERATOR, ADMIN) && delete queryParams.role_ids;

        return DataFetcher.getJson(this.usersUrl, {queryParams})
            .then(res => {
                this._streamersList.next([...res.data, this._userProfile.getValue()]);
            }).catch(err => Promise.reject(err));
    }

    getStreamersByParams = (params) => {
        return new Promise((resolve, reject) => {
            DataFetcher.getJson(this.usersUrl, {queryParams: {...params, all: 1, order_by: "first_name"}})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getUserProfile() {
        return DataFetcher.getJson(USERS.USER_PROFILE.URL, {}).then((response) => {
            if (response.data) {
                this._userProfile.next({
                    ...response.data,
                    organization: response.data.organization || {},
                    default_activity_countries: response.data.default_activity_countries || [],
                    time_zone: listTimeZones().includes(response.data.time_zone)
                        ? response.data.time_zone
                        : Intl.DateTimeFormat().resolvedOptions().timeZone
                });

                if (!response.data.time_zone) {
                    this.updateUserProfile({time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone});
                }
                Helpers.checkRole(MODERATOR, ADMIN) && watchListDataService.setWatchListStorageKey(response.data.organization_id);

                return Promise.resolve(response.data);
            }
        }).catch(err => Promise.reject(err));
    }

    updateUserProfile(params) {
        return DataFetcher.putJson(`${USERS.USER_PROFILE.URL}`, {params}).then((response) => {
            if (response.data) {
                this._userProfile.next({...this._userProfile.getValue(), ...response.data});
                return Promise.resolve(response);
            }
        }).catch(err => Promise.reject(err));
    }

    createUser(params) {
        return new Promise((resolve, reject) => {
            DataFetcher.postJson(this.usersUrl, {params})
                .then(res => {
                    const isAdmin = !!(Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER) && window.location.hash);
                    const usersList = isAdmin ? this._moderatorUsersList.getValue() : this._usersList.getValue();
                    const {total_count: totalCount = 0} = usersList || {};
                    const payload = {
                        ...res,
                        data: Helpers.addOrUpdateArrayOfObjectsValue((usersList.data || []), res.data, "id"),
                        total_count: totalCount + 1
                    };

                    isAdmin ? this._moderatorUsersList.next(payload) : this._usersList.next(payload);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    createPartnerRequest(params) {
        return new Promise((resolve, reject) => {
            DataFetcher.postJson(BECOME_PARTNER.CREATE_PARTNER_REQUEST.URL, {params})
                .then(res => {
                    // TODO CHANGE !!!
                    this.getUserProfile();
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateUser(userId, params) {
        return new Promise((resolve, reject) => {
            DataFetcher.putJson(`${this.usersUrl}/${userId}`, {params})
                .then(res => {
                    const isAdmin = !!(Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER) && window.location.hash);
                    const usersList = isAdmin ? this._moderatorUsersList.getValue() : this._usersList.getValue();
                    const payload = {
                        ...usersList,
                        data: Helpers.addOrUpdateArrayOfObjectsValue(usersList.data, res.data, "id")
                    };
                    isAdmin ? this._moderatorUsersList.next(payload) : this._usersList.next(payload);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getUsersByParams(params) {
        return DataFetcher.getJson(`${USERS.MODERATOR_USER_LIST.URL}`, { queryParams: params })
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(err => Promise.reject(err));
    };

    getUserPartnerList(params = {}) {
        return DataFetcher.getJson(USERS.GET_USERS_PARTNERS.URL, {...params, without_pagination: 1})
            .then(res => {
                this._usersPartnersList.next(res.data || {});
                return Promise.resolve(res.data);
            }).catch(err => Promise.reject(err));
    }

    setUserPartner(params = {}, id) {
        return DataFetcher.putJson(`${USERS.UPDATE_USER_PARTNERS.URL}/${id}`, {
            params: {id: params},
            headers: {"Content-Type": "application/json", 'x-organization-id': null}
        })
            .then(res => {
                return Promise.resolve(res.data);
            }).catch(err => Promise.reject(err));
    }

    checkUserExistence(url, type, value, callBack) {
        DataFetcher.getJson(`${url}?${type}=${value}`)
            .then((res) => {
                callBack(res);
            })
            .catch(err => callBack(err));
    }

    refreshSignature() {
        return DataFetcher.getJson(USERS.REFRESH_SIGNATURE.URL)
            .then(res => {
                this._userProfile.next({...this._userProfile.getValue(), signature: res.data.signature});
                return Promise.resolve(res.data.signature);
            });
    }

    skipTrial(id, params = {}) {
        return DataFetcher.putJson(`${ORGANIZATIONS.SKIP_TRIAL.URL}/${id}`, {params})
            .then(res => {
                this._userProfile.next({...this._userProfile.getValue(), organization: res.data});
                return Promise.resolve(res.data);
            }).catch(err => Promise.reject(err));
    }

    b2cBecomePartner (params) {
        return DataFetcher.postJson(ORGANIZATIONS.B2C_BECOME_PARTNER.URL, {params})
            .then(res => Promise.resolve(res))
            .catch(err => Promise.reject(err));
    }

    isAlreadyPartner () {
        return DataFetcher.getJson(ORGANIZATIONS.CHECK_IS_PARTNER.URL)
            .then(res => Promise.resolve(res))
            .catch(err => Promise.reject(err));
    }

    stop() {
        this._usersList.next({});
        this._moderatorUsersList.next({});
        this._userProfile.next(null);
        this._streamersList.next(null);
        this._usersPartnersList.next(null);
    }

    get usersUrl() {
        return Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER, FEDERATION_GROUP_COORDINATOR)
            ? getServiceUrl(USERS.USERS_LIST.URL, USERS.MODERATOR_USERS.URL, !window.location.hash)
            : USERS.USERS_LIST.URL;
    }

    get profile() {
        return this._userProfile;
    }

    get streamersList() {
        this.getStreamersList();
        return this._streamersList;
    }

    get usersData() {
        this.getUsersList();
        return Helpers.checkRole(MODERATOR, ADMIN, CP_ADMINISTRATOR, CP_GROUP_MANAGER) && window.location.hash ? this._moderatorUsersList : this._usersList;
    }

    get usersList() {
        return this._usersList;
    }

    get moderatorUsers() {
        return this._moderatorUsersList;
    }

    get userProfile() {
        return this._userProfile;
    }

    get userPartnersList() {
        return this._usersPartnersList;
    }
}

export default new UsersDataService();

