import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TOAST_TYPES } from '../../constants/uiConstants';
import paymentsDataService from '../../services/paymentsDataService';
import Helpers from '../../helpers/helperFunctions';
import Config from '../../configs/mainConfig';
import useVaultManager from '../../hooks/useVaultManager';
import Loader from '../reusableUIComponents/loader';
import CreditCard from '../shared/creditCard';
import { openPopup, registerToastMessage } from '../../actions/sync-actions/uiActions';
import Button from '../shared/button';
import { t } from '../../helpers/translate';
import classnames from 'classnames';
import Input from '../shared/input';
import PaypalButton from './parts/paypalButton';

import './wallet.scss';

const Wallet = ({ closeAction }) => {
    const [currentBalance, setCurrentBalance] = useState(null);
    const [paypalReady, setPaypalReady] = useState(false);
    const [amount, setAmount] = useState('');
    const [defaultLoadingId, setDefaultLoadingId] = useState(null);
    const [selectedCardId, setSelectedCardId] = useState(null);
    const [showInputField, setShowInputField] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);

    const {
        authorization,
        paymentMethods,
        addPaymentMethod,
        setPaymentMethods,
        deletePaymentMethod,
    } = useVaultManager();

    const paypalInfo = useMemo(() => (paymentMethods || []).find(el => el.type === 'PayPalAccount') || {}, [paymentMethods]);
    const billingAgreementId = useMemo(() => paypalInfo.payerInfo?.billingAgreementId, [paypalInfo]);

    const dispatch = useDispatch();

    useEffect(() => {
        const subscription = paymentsDataService.walletBalance.subscribe(setCurrentBalance);
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (paymentMethods) {
            const defaultPaymentMethod = paymentMethods.find(el => el.default) || paymentMethods?.[0];
            const bin = defaultPaymentMethod?.bin;
            if (paymentMethods.length === 1 && !defaultPaymentMethod.default && (bin || billingAgreementId)) {
                changeDefaultPaymentMethod(bin ? { bin } : { billingAgreementId }, undefined, false);
            }
            setSelectedCardId(defaultPaymentMethod?.bin || defaultPaymentMethod?.payerInfo?.billingAgreementId);
        }
    }, [paymentMethods?.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const checkout = () => {
        const nonce = paymentMethods.find(method => method.bin === selectedCardId || method.payerInfo?.billingAgreementId === selectedCardId)?.nonce;
        setCheckoutLoading(true);
        paymentsDataService.paymentCheckout(nonce, Number(amount), authorization)
            .then(() => {
                setCheckoutLoading(false);
                dispatch(registerToastMessage({ message: `${amount}${Config.main.currency} has been successfully added to your balance` }));
                closeAction(true);
            })
            .catch((e) => {
                setCheckoutLoading(false);
                dispatch(registerToastMessage({
                    type: TOAST_TYPES.ERROR,
                    message: e.message === 'Failed to fetch' ? t("Please check your internet connection") : e.message,
                }));
            });
    };

    const handlePaymentMethodAddition = () => {
        dispatch(openPopup('addPaymentMethod', {
            title: t('Add Payment'),
            authorization,
            paymentMethods,
            addPaymentMethod,
            closeOnClickOutside: false,
            callback: () => closeAction(true),
        }));
    };

    const changeDefaultPaymentMethod = (params, cb = () => {}, showToaster = true) => {
        setDefaultLoadingId(params.bin || params.billingAgreementId);
        paymentsDataService.makePaymentMethodDefault(params)
            .then(() => {
                setDefaultLoadingId(null);
                setPaymentMethods(val => val.map((el) => {
                    if ((params.bin && params.bin === el.bin) || (params.billingAgreementId && el.type === 'PayPalAccount' && params.billingAgreementId === billingAgreementId)) {
                        setSelectedCardId(params.bin || params.billingAgreementId);
                        return { ...el, default: true };
                    }
                    return { ...el, default: false };
                }));
                typeof cb === 'function' && cb();
                showToaster && dispatch(registerToastMessage({ message: t('Successfully saved') }));
            })
            .catch(() => {
                setDefaultLoadingId(null);
                typeof cb === 'function' && cb();
                showToaster && (
                    dispatch(registerToastMessage({
                        type: TOAST_TYPES.ERROR,
                        message: t('Oops something went wrong'),
                    }))
                );
            });
    };

    return (
        <ul className='wallet-holder'>
            <li>
                <h3>{t("Your Account Balance")}</h3>
                <ul className='wallet-balance-holder'>
                    <li>
                        <h3>{`${Config.main.currency} ${Helpers.amountFormat(currentBalance)}`}</h3>
                    </li>
                    <li className='hr'/>
                    <li>
                        <ul
                            className={classnames(
                                `deposit-toggle-holder`,
                                {
                                    'active': showInputField,
                                }
                            )}
                        >
                            <li>
                                <Button
                                    size='big'
                                    icon='icon-plus'
                                    color='contrast'
                                    disabled={!paymentMethods}
                                    onClick={() => setShowInputField(true)}
                                >
                                    {t("Deposit")}
                                </Button>
                            </li>
                            <li>
                                <div className='deposit-input-holder'>
                                    <Input
                                        value={amount}
                                        labelAppearance="swap"
                                        placeholder={t('Deposit Amount')}
                                        onChange={(e) => {
                                            if (/^-?\d*[.]?\d*$/.test(e.target.value)) {
                                                setAmount(e.target.value);
                                            }
                                        }}
                                    />
                                    <label>
                                        <ul className={classnames(
                                            'wallet-d-buttons',
                                            {
                                                'pointer-events-none': checkoutLoading,
                                            })}
                                        >
                                            <li>
                                                <Button
                                                    size='big'
                                                    icon={`${!checkoutLoading ? 'icon-plus' : 'icon-spinner'}`}
                                                    color='confirm'
                                                    onClick={checkout}
                                                    appearance='light'
                                                    disabled={!paymentMethods || !amount || checkoutLoading}
                                                />
                                            </li>
                                            <li className={classnames({ 'pointer-events-none': (selectedCardId !== billingAgreementId || checkoutLoading) })}>
                                                {authorization && (
                                                    <PaypalButton
                                                        checkout={checkout}
                                                        authorization={authorization}
                                                        setPaypalReady={setPaypalReady}
                                                    />
                                                )}
                                            </li>
                                        </ul>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                {paymentMethods ? (
                    <>
                        <h3>{t("Saved Payment Methods")}</h3>
                        <div className='saved-payments-holder'>
                            <CreditCard
                                mode="add"
                                onPaymentMethodAdd={handlePaymentMethodAddition}
                            />
                            {paypalReady && (
                                <CreditCard
                                    cardType="paypal"
                                    nonce={paypalInfo.nonce}
                                    defaultLoading={defaultLoadingId === paypalInfo?.payerInfo?.billingAgreementId}
                                    defaultMethod={paypalInfo.default}
                                    description={paypalInfo.email || '**** **** **** ****'}
                                    selected={billingAgreementId === selectedCardId}
                                    setDefault={(cb) => changeDefaultPaymentMethod({ billingAgreementId }, cb)}
                                    onClick={() => setSelectedCardId(billingAgreementId)}
                                />
                            )}
                            {paymentMethods
                                .filter(el => el.type !== 'PayPalAccount')
                                .map((card, index) => {
                                    const {
                                        bin,
                                        nonce,
                                        cardType,
                                        lastFour,
                                        expirationYear,
                                        expirationMonth,
                                        default: defaultMethod,
                                    } = card;

                                    return (
                                        <CreditCard
                                            bin={bin}
                                            key={index}
                                            nonce={nonce}
                                            onClick={() => setSelectedCardId(bin)}
                                            cardType={cardType}
                                            defaultMethod={defaultMethod}
                                            expirationYear={expirationYear}
                                            selected={bin === selectedCardId}
                                            expirationMonth={expirationMonth}
                                            defaultLoading={defaultLoadingId === bin}
                                            description={`**** **** **** ${lastFour}`}
                                            deletePaymentMethod={deletePaymentMethod}
                                            setDefault={(cb) => changeDefaultPaymentMethod({ bin }, cb)}
                                        />
                                    );
                                })
                            }
                        </div>
                    </>
                ) : (
                    <Loader/>
                )}
            </li>
        </ul>
    );
};

export default Wallet;
